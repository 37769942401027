import { enhancedApi as api } from '../generated/hubs';

export const enhancedApi = api.enhanceEndpoints({
  endpoints: {
    getApiChargePointHubs: (endpoint) => {
      endpoint.providesTags = [{ type: 'Hubs', id: 'LIST' }];
    },
    getApiChargePointHubsById: (endpoint) => {
      endpoint.providesTags = (result, error, arg) => [
        { type: 'Hubs', id: arg },
      ];
    },
    postApiChargePointHubs: (endpoint) => {
      endpoint.invalidatesTags = [{ type: 'Hubs', id: 'LIST' }];
    },
    patchApiChargePointHubsById: (endpoint) => {
      endpoint.invalidatesTags = (result, error, arg) => [
        { type: 'Hubs', id: 'LIST' },
        {
          type: 'Hubs',
          id: arg.id,
        },
      ];
    },
  },
});

export const {
  useGetApiChargePointHubsQuery: useGetHubs,
  useGetApiChargePointHubsByIdQuery: useGetHub,
  usePostApiChargePointHubsMutation: useAddHub,
  usePatchApiChargePointHubsByIdMutation: useUpdateHub,
} = enhancedApi;
