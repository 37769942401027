import { baseApi as api } from '../../../../base-new/base-api';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getApiChargePointMetricsChargepoints: build.query<
      GetApiChargePointMetricsChargepointsApiResponse,
      GetApiChargePointMetricsChargepointsApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/Metrics/CHARGEPOINTS`,
        params: {
          companyIds: queryArg.companyIds,
          isTest: queryArg.isTest,
          chargePointStatuses: queryArg.chargePointStatuses,
          notСompanyIds: queryArg.notСompanyIds,
        },
      }),
    }),
    getApiChargePointMetricsConnectors: build.query<
      GetApiChargePointMetricsConnectorsApiResponse,
      GetApiChargePointMetricsConnectorsApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/Metrics/CONNECTORS`,
        params: {
          companyIds: queryArg.companyIds,
          notСompanyIds: queryArg.notСompanyIds,
          isTest: queryArg.isTest,
          chargePointStatuses: queryArg.chargePointStatuses,
          connectorStatuses: queryArg.connectorStatuses,
        },
      }),
    }),
    getApiChargePointMetricsSessionCharging: build.query<
      GetApiChargePointMetricsSessionChargingApiResponse,
      GetApiChargePointMetricsSessionChargingApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/Metrics/SESSION_CHARGING`,
        params: {
          companyIds: queryArg.companyIds,
          notСompanyIds: queryArg.notСompanyIds,
          isTest: queryArg.isTest,
        },
      }),
    }),
    getApiChargePointMetricsSuccessfulSessions: build.query<
      GetApiChargePointMetricsSuccessfulSessionsApiResponse,
      GetApiChargePointMetricsSuccessfulSessionsApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/Metrics/SUCCESSFUL_SESSIONS`,
        params: {
          companyIds: queryArg.companyIds,
          notСompanyIds: queryArg.notСompanyIds,
          isTest: queryArg.isTest,
          dateFrom: queryArg.dateFrom,
          dateTo: queryArg.dateTo,
          groupByPeriod: queryArg.groupByPeriod,
        },
      }),
    }),
    getApiChargePointMetricsAmountOfConsumedEnergy: build.query<
      GetApiChargePointMetricsAmountOfConsumedEnergyApiResponse,
      GetApiChargePointMetricsAmountOfConsumedEnergyApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/Metrics/AMOUNT_OF_CONSUMED_ENERGY`,
        params: {
          companyIds: queryArg.companyIds,
          notСompanyIds: queryArg.notСompanyIds,
          isTest: queryArg.isTest,
          dateFrom: queryArg.dateFrom,
          dateTo: queryArg.dateTo,
          groupByPeriod: queryArg.groupByPeriod,
        },
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type GetApiChargePointMetricsChargepointsApiResponse =
  /** status 200 Success */ GetChargePointsMetricsVmBaseResponse;
export type GetApiChargePointMetricsChargepointsApiArg = {
  companyIds?: string[];
  isTest?: boolean;
  chargePointStatuses?: ChargePointStatus[];
  notСompanyIds?: string[];
};
export type GetApiChargePointMetricsConnectorsApiResponse =
  /** status 200 Success */ GetConnectorsMetricsVmBaseResponse;
export type GetApiChargePointMetricsConnectorsApiArg = {
  companyIds?: string[];
  notСompanyIds?: string[];
  isTest?: boolean;
  chargePointStatuses?: ChargePointStatus[];
  connectorStatuses?: ConnectorStatus[];
};
export type GetApiChargePointMetricsSessionChargingApiResponse =
  /** status 200 Success */ GetSessionChargingMetricsVmBaseResponse;
export type GetApiChargePointMetricsSessionChargingApiArg = {
  companyIds?: string[];
  notСompanyIds?: string[];
  isTest?: boolean;
};
export type GetApiChargePointMetricsSuccessfulSessionsApiResponse =
  /** status 200 Success */ GetSuccessfulSessionMetricsVmIReadOnlyCollectionBaseResponse;
export type GetApiChargePointMetricsSuccessfulSessionsApiArg = {
  companyIds?: string[];
  notСompanyIds?: string[];
  isTest?: boolean;
  dateFrom?: string;
  dateTo?: string;
  groupByPeriod?: GroupByPeriod;
};
export type GetApiChargePointMetricsAmountOfConsumedEnergyApiResponse =
  /** status 200 Success */ GetAmountOfConsumedEnergyMetricsVmIReadOnlyCollectionBaseResponse;
export type GetApiChargePointMetricsAmountOfConsumedEnergyApiArg = {
  companyIds?: string[];
  notСompanyIds?: string[];
  isTest?: boolean;
  dateFrom?: string;
  dateTo?: string;
  groupByPeriod?: GroupByPeriod;
};
export type Status = 'SUCCESS' | 'ERROR' | 'PART_UNAVAILABLE';
export type UnavailableServices =
  | 'CHARGE_POINT_SERVICE'
  | 'CP_WS_CONTROLLER'
  | 'CP_SOAP_CONTROLLER'
  | 'BILLING_SERVICE'
  | 'PAYMENT_SERVICE'
  | 'FISCAL_SERVICE'
  | 'SBER_PAY'
  | 'ARMENIA_PAY'
  | 'E_PAY'
  | 'FISCAL_RUSSIA'
  | 'FISCAL_ARMENIA'
  | 'BONUS_SERVICE'
  | 'DISCOUNT_SERVICE'
  | 'MOBILE_API_BFF'
  | 'USER_SERVICE'
  | 'RESERVE_SERVICE'
  | 'OCPI_SERVICE'
  | 'SMS_SERVICE'
  | 'PUSH_NOTIFICATION_SERVICE'
  | 'SYSTEM_INFROMATION_SERVICE'
  | 'IDENTITY_SERVICE'
  | 'ANALYTICS_SERVICE';
export type GetChargePointsMetricsVm = {
  count?: number;
  of?: number;
};
export type GetChargePointsMetricsVmBaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: GetChargePointsMetricsVm;
};
export type ChargePointStatus =
  | 'NO_CONNECT'
  | 'AVAILABLE'
  | 'FAULTED'
  | 'UNAVAILABLE';
export type GetConnectorsMetricsVm = {
  count?: number;
  of?: number;
};
export type GetConnectorsMetricsVmBaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: GetConnectorsMetricsVm;
};
export type ConnectorStatus =
  | 'UNDEFINED'
  | 'AVAILABLE'
  | 'PREPARING'
  | 'CHARGING'
  | 'SUSPENDED_EVSE'
  | 'SUSPENDED_EV'
  | 'FINISHING'
  | 'RESERVED'
  | 'UNAVAILABLE'
  | 'FAULTED';
export type GetSessionChargingMetricsVm = {
  count?: number;
};
export type GetSessionChargingMetricsVmBaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: GetSessionChargingMetricsVm;
};
export type GetSuccessfulSessionMetricsVm = {
  date?: string | null;
  value?: number;
};
export type GetSuccessfulSessionMetricsVmIReadOnlyCollectionBaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: GetSuccessfulSessionMetricsVm[] | null;
};
export type GroupByPeriod = 'DAY' | 'MONTH';
export type GetAmountOfConsumedEnergyMetricsVm = {
  date?: string | null;
  value?: number;
};
export type GetAmountOfConsumedEnergyMetricsVmIReadOnlyCollectionBaseResponse =
  {
    status?: Status;
    statusMessage?: string | null;
    unavailables?: UnavailableServices[] | null;
    data?: GetAmountOfConsumedEnergyMetricsVm[] | null;
  };
export const {
  useGetApiChargePointMetricsChargepointsQuery,
  useGetApiChargePointMetricsConnectorsQuery,
  useGetApiChargePointMetricsSessionChargingQuery,
  useGetApiChargePointMetricsSuccessfulSessionsQuery,
  useGetApiChargePointMetricsAmountOfConsumedEnergyQuery,
} = injectedRtkApi;
