import { baseApi as api } from '../../../../base-new/base-api';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getApiChargePointHubsById: build.query<
      GetApiChargePointHubsByIdApiResponse,
      GetApiChargePointHubsByIdApiArg
    >({
      query: (queryArg) => ({ url: `/Api/ChargePoint/Hubs/${queryArg}` }),
    }),
    patchApiChargePointHubsById: build.mutation<
      PatchApiChargePointHubsByIdApiResponse,
      PatchApiChargePointHubsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/Hubs/${queryArg.id}`,
        method: 'PATCH',
        body: queryArg.updateHubRequest,
      }),
    }),
    deleteApiChargePointHubsById: build.mutation<
      DeleteApiChargePointHubsByIdApiResponse,
      DeleteApiChargePointHubsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/Hubs/${queryArg}`,
        method: 'DELETE',
      }),
    }),
    getApiChargePointHubs: build.query<
      GetApiChargePointHubsApiResponse,
      GetApiChargePointHubsApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/Hubs`,
        params: { Limit: queryArg.limit, Offset: queryArg.offset },
      }),
    }),
    postApiChargePointHubs: build.mutation<
      PostApiChargePointHubsApiResponse,
      PostApiChargePointHubsApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/Hubs`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    getApiChargePointHubsCount: build.query<
      GetApiChargePointHubsCountApiResponse,
      GetApiChargePointHubsCountApiArg
    >({
      query: () => ({ url: `/Api/ChargePoint/Hubs/Count` }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type GetApiChargePointHubsByIdApiResponse =
  /** status 200 Success */ GetHubVmBaseResponse;
export type GetApiChargePointHubsByIdApiArg =
  /** Идентификатор сущности */ string;
export type PatchApiChargePointHubsByIdApiResponse =
  /** status 200 Success */ GetHubVmBaseResponse;
export type PatchApiChargePointHubsByIdApiArg = {
  /** Идентификатор сущности */
  id: string;
  /** Параметры */
  updateHubRequest: UpdateHubRequest;
};
export type DeleteApiChargePointHubsByIdApiResponse =
  /** status 200 Success */ BaseResponse;
export type DeleteApiChargePointHubsByIdApiArg =
  /** Идентификатор сущности */ string;
export type GetApiChargePointHubsApiResponse =
  /** status 200 Success */ GetHubVmIReadOnlyCollectionBaseResponse;
export type GetApiChargePointHubsApiArg = {
  limit?: number;
  offset?: number;
};
export type PostApiChargePointHubsApiResponse =
  /** status 200 Success */ GetHubVmBaseResponse;
export type PostApiChargePointHubsApiArg = /** Параметры */ AddHubRequest;
export type GetApiChargePointHubsCountApiResponse =
  /** status 200 Success */ Int64BaseResponse;
export type GetApiChargePointHubsCountApiArg = void;
export type Status = 'SUCCESS' | 'ERROR' | 'PART_UNAVAILABLE';
export type UnavailableServices =
  | 'CHARGE_POINT_SERVICE'
  | 'CP_WS_CONTROLLER'
  | 'CP_SOAP_CONTROLLER'
  | 'BILLING_SERVICE'
  | 'PAYMENT_SERVICE'
  | 'FISCAL_SERVICE'
  | 'SBER_PAY'
  | 'ARMENIA_PAY'
  | 'E_PAY'
  | 'FISCAL_RUSSIA'
  | 'FISCAL_ARMENIA'
  | 'BONUS_SERVICE'
  | 'DISCOUNT_SERVICE'
  | 'MOBILE_API_BFF'
  | 'USER_SERVICE'
  | 'RESERVE_SERVICE'
  | 'OCPI_SERVICE'
  | 'SMS_SERVICE'
  | 'PUSH_NOTIFICATION_SERVICE'
  | 'SYSTEM_INFROMATION_SERVICE'
  | 'IDENTITY_SERVICE'
  | 'ANALYTICS_SERVICE';
export type GetHubVm = {
  id: string;
  name: string;
  description?: string | null;
  address: string;
  latitude: number;
  longitude: number;
};
export type GetHubVmBaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: GetHubVm;
};
export type UpdateHubRequest = {
  name?: string | null;
  description?: string | null;
  address?: string | null;
  latitude?: number | null;
  longitude?: number | null;
};
export type BaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
};
export type GetHubVmIReadOnlyCollectionBaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: GetHubVm[] | null;
};
export type AddHubRequest = {
  name: string;
  description?: string | null;
  address: string;
  latitude: number;
  longitude: number;
};
export type Int64BaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: number;
};
export const {
  useGetApiChargePointHubsByIdQuery,
  usePatchApiChargePointHubsByIdMutation,
  useDeleteApiChargePointHubsByIdMutation,
  useGetApiChargePointHubsQuery,
  usePostApiChargePointHubsMutation,
  useGetApiChargePointHubsCountQuery,
} = injectedRtkApi;
