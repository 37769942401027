import { baseApi as api } from '../../../../base-new/base-api';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getApiPushNotificationAppInfo: build.query<
      GetApiPushNotificationAppInfoApiResponse,
      GetApiPushNotificationAppInfoApiArg
    >({
      query: () => ({ url: `/Api/PushNotification/AppInfo` }),
    }),
    getApiPushNotificationBatchesByBatchId: build.query<
      GetApiPushNotificationBatchesByBatchIdApiResponse,
      GetApiPushNotificationBatchesByBatchIdApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/PushNotification/Batches/${queryArg}`,
      }),
    }),
    deleteApiPushNotificationBatchesByBatchId: build.mutation<
      DeleteApiPushNotificationBatchesByBatchIdApiResponse,
      DeleteApiPushNotificationBatchesByBatchIdApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/PushNotification/Batches/${queryArg}`,
        method: 'DELETE',
      }),
    }),
    getApiPushNotificationBatches: build.query<
      GetApiPushNotificationBatchesApiResponse,
      GetApiPushNotificationBatchesApiArg
    >({
      query: () => ({ url: `/Api/PushNotification/Batches` }),
    }),
    postApiPushNotificationBatches: build.mutation<
      PostApiPushNotificationBatchesApiResponse,
      PostApiPushNotificationBatchesApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/PushNotification/Batches`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    postApiPushNotificationBatchesSend: build.mutation<
      PostApiPushNotificationBatchesSendApiResponse,
      PostApiPushNotificationBatchesSendApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/PushNotification/Batches/send`,
        method: 'POST',
        params: { batchId: queryArg },
      }),
    }),
    getApiPushNotificationMessagesStatuses: build.query<
      GetApiPushNotificationMessagesStatusesApiResponse,
      GetApiPushNotificationMessagesStatusesApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/PushNotification/Messages/_statuses`,
        params: {
          BatchId: queryArg.batchId,
          Status: queryArg.status,
          Limit: queryArg.limit,
          Offset: queryArg.offset,
        },
      }),
    }),
    postApiPushNotificationMessagesFromCsvWithDevicesIds: build.mutation<
      PostApiPushNotificationMessagesFromCsvWithDevicesIdsApiResponse,
      PostApiPushNotificationMessagesFromCsvWithDevicesIdsApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/PushNotification/Messages/fromCsvWithDevicesIds`,
        method: 'POST',
        body: queryArg.body,
        params: { batchId: queryArg.batchId },
      }),
    }),
    getApiPushNotificationTemplatesByTemplateId: build.query<
      GetApiPushNotificationTemplatesByTemplateIdApiResponse,
      GetApiPushNotificationTemplatesByTemplateIdApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/PushNotification/Templates/${queryArg}`,
      }),
    }),
    patchApiPushNotificationTemplatesByTemplateId: build.mutation<
      PatchApiPushNotificationTemplatesByTemplateIdApiResponse,
      PatchApiPushNotificationTemplatesByTemplateIdApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/PushNotification/Templates/${queryArg.templateId}`,
        method: 'PATCH',
        body: queryArg.updateTemplateRequestDto,
      }),
    }),
    deleteApiPushNotificationTemplatesByTemplateId: build.mutation<
      DeleteApiPushNotificationTemplatesByTemplateIdApiResponse,
      DeleteApiPushNotificationTemplatesByTemplateIdApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/PushNotification/Templates/${queryArg}`,
        method: 'DELETE',
      }),
    }),
    getApiPushNotificationTemplates: build.query<
      GetApiPushNotificationTemplatesApiResponse,
      GetApiPushNotificationTemplatesApiArg
    >({
      query: () => ({ url: `/Api/PushNotification/Templates` }),
    }),
    postApiPushNotificationTemplates: build.mutation<
      PostApiPushNotificationTemplatesApiResponse,
      PostApiPushNotificationTemplatesApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/PushNotification/Templates`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    postApiPushNotificationTesting: build.mutation<
      PostApiPushNotificationTestingApiResponse,
      PostApiPushNotificationTestingApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/PushNotification/Testing`,
        method: 'POST',
        params: { deviceId: queryArg },
      }),
    }),
    putApiPushNotificationTokens: build.mutation<
      PutApiPushNotificationTokensApiResponse,
      PutApiPushNotificationTokensApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/PushNotification/Tokens`,
        method: 'PUT',
        body: queryArg,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type GetApiPushNotificationAppInfoApiResponse =
  /** status 200 Success */ string;
export type GetApiPushNotificationAppInfoApiArg = void;
export type GetApiPushNotificationBatchesByBatchIdApiResponse =
  /** status 200 Success */ BatchDtoResponseBase;
export type GetApiPushNotificationBatchesByBatchIdApiArg = string;
export type DeleteApiPushNotificationBatchesByBatchIdApiResponse =
  /** status 200 Success */ ResponseBase;
export type DeleteApiPushNotificationBatchesByBatchIdApiArg = string;
export type GetApiPushNotificationBatchesApiResponse =
  /** status 200 Success */ BatchDtoIReadOnlyCollectionResponseBase;
export type GetApiPushNotificationBatchesApiArg = void;
export type PostApiPushNotificationBatchesApiResponse =
  /** status 200 Success */ BatchDtoResponseBase;
export type PostApiPushNotificationBatchesApiArg = CreateBatchRequestDto;
export type PostApiPushNotificationBatchesSendApiResponse =
  /** status 200 Success */ ResponseBase;
export type PostApiPushNotificationBatchesSendApiArg = string;
export type GetApiPushNotificationMessagesStatusesApiResponse =
  /** status 200 Success */ MessageStatusDtoIReadOnlyCollectionResponseBase;
export type GetApiPushNotificationMessagesStatusesApiArg = {
  batchId?: string;
  status?: MessageStatus;
  limit?: number;
  offset?: number;
};
export type PostApiPushNotificationMessagesFromCsvWithDevicesIdsApiResponse =
  /** status 200 Success */ LoadMessagesStatisticsDtoResponseBase;
export type PostApiPushNotificationMessagesFromCsvWithDevicesIdsApiArg = {
  batchId?: string;
  body: {
    CsvFile: Blob;
  };
};
export type GetApiPushNotificationTemplatesByTemplateIdApiResponse =
  /** status 200 Success */ TemplateDtoResponseBase;
export type GetApiPushNotificationTemplatesByTemplateIdApiArg = string;
export type PatchApiPushNotificationTemplatesByTemplateIdApiResponse =
  /** status 200 Success */ TemplateDtoResponseBase;
export type PatchApiPushNotificationTemplatesByTemplateIdApiArg = {
  templateId: string;
  updateTemplateRequestDto: UpdateTemplateRequestDto;
};
export type DeleteApiPushNotificationTemplatesByTemplateIdApiResponse =
  /** status 200 Success */ ResponseBase;
export type DeleteApiPushNotificationTemplatesByTemplateIdApiArg = string;
export type GetApiPushNotificationTemplatesApiResponse =
  /** status 200 Success */ TemplateDtoIReadOnlyCollectionResponseBase;
export type GetApiPushNotificationTemplatesApiArg = void;
export type PostApiPushNotificationTemplatesApiResponse =
  /** status 200 Success */ TemplateDtoResponseBase;
export type PostApiPushNotificationTemplatesApiArg = CreateTemplateRequestDto;
export type PostApiPushNotificationTestingApiResponse = unknown;
export type PostApiPushNotificationTestingApiArg = string;
export type PutApiPushNotificationTokensApiResponse =
  /** status 200 Success */ TokenDtoResponseBase;
export type PutApiPushNotificationTokensApiArg = CreateOrUpdateTokenRequestDto;
export type BatchStatus = 'NEW' | 'SENT' | 'SUCCESS' | 'ERROR';
export type BatchDto = {
  id: string;
  status: BatchStatus;
  createdDate: string;
  sentDate?: string | null;
  totalCount: number;
  sentCount: number;
  deliveredCount: number;
  templateId: string;
};
export type Status = 'SUCCESS' | 'ERROR' | 'PART_UNAVAILABLE';
export type UnavailableServices =
  | 'CHARGE_POINT_SERVICE'
  | 'CP_WS_CONTROLLER'
  | 'CP_SOAP_CONTROLLER'
  | 'BILLING_SERVICE'
  | 'PAYMENT_SERVICE'
  | 'FISCAL_SERVICE'
  | 'SBER_PAY'
  | 'ARMENIA_PAY'
  | 'E_PAY'
  | 'FISCAL_RUSSIA'
  | 'FISCAL_ARMENIA'
  | 'BONUS_SERVICE'
  | 'DISCOUNT_SERVICE'
  | 'MOBILE_API_BFF'
  | 'USER_SERVICE'
  | 'RESERVE_SERVICE'
  | 'OCPI_SERVICE'
  | 'SMS_SERVICE'
  | 'PUSH_NOTIFICATION_SERVICE'
  | 'SYSTEM_INFROMATION_SERVICE'
  | 'IDENTITY_SERVICE'
  | 'ANALYTICS_SERVICE';
export type BatchDtoResponseBase = {
  data?: BatchDto;
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
};
export type ResponseBase = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
};
export type BatchDtoIReadOnlyCollectionResponseBase = {
  data?: BatchDto[] | null;
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
};
export type CreateBatchRequestDto = {
  templateId?: string;
};
export type MessageStatus = 'LOADED' | 'SENT' | 'DELIVERED' | 'ERROR';
export type MessageStatusDto = {
  templateId?: string;
  status?: MessageStatus;
  deviceId?: string;
};
export type MessageStatusDtoIReadOnlyCollectionResponseBase = {
  data?: MessageStatusDto[] | null;
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
};
export type LoadMessagesStatisticsDto = {
  receivedDevicesIdsCount: number;
  successfullyCreatedCount: number;
};
export type LoadMessagesStatisticsDtoResponseBase = {
  data?: LoadMessagesStatisticsDto;
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
};
export type TemplateDto = {
  id: string;
  name: string;
  title: string;
  text: string;
  imgUrl?: string | null;
  deepLink?: string | null;
  browserUrl: string;
  createdDate: string;
};
export type TemplateDtoResponseBase = {
  data?: TemplateDto;
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
};
export type UpdateTemplateRequestDto = {
  name?: string | null;
  title?: string | null;
  text?: string | null;
  imgUrl?: string | null;
  deepLink?: string | null;
  browserUrl?: string | null;
};
export type TemplateDtoIReadOnlyCollectionResponseBase = {
  data?: TemplateDto[] | null;
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
};
export type CreateTemplateRequestDto = {
  name: string;
  title: string;
  text: string;
  imgUrl?: string | null;
  deepLink?: string | null;
  browserUrl: string;
};
export type TokenDto = {
  id: string;
  deviceId: string;
  value: string;
  lastUpdate: string;
};
export type TokenDtoResponseBase = {
  data?: TokenDto;
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
};
export type CreateOrUpdateTokenRequestDto = {
  deviceId: string;
  value: string;
};
export const {
  useGetApiPushNotificationAppInfoQuery,
  useGetApiPushNotificationBatchesByBatchIdQuery,
  useDeleteApiPushNotificationBatchesByBatchIdMutation,
  useGetApiPushNotificationBatchesQuery,
  usePostApiPushNotificationBatchesMutation,
  usePostApiPushNotificationBatchesSendMutation,
  useGetApiPushNotificationMessagesStatusesQuery,
  usePostApiPushNotificationMessagesFromCsvWithDevicesIdsMutation,
  useGetApiPushNotificationTemplatesByTemplateIdQuery,
  usePatchApiPushNotificationTemplatesByTemplateIdMutation,
  useDeleteApiPushNotificationTemplatesByTemplateIdMutation,
  useGetApiPushNotificationTemplatesQuery,
  usePostApiPushNotificationTemplatesMutation,
  usePostApiPushNotificationTestingMutation,
  usePutApiPushNotificationTokensMutation,
} = injectedRtkApi;
