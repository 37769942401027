/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * BillingService.WebApi
 * OpenAPI spec version: 1.0
 */
import { z as zod } from 'zod';

/**
 * @summary Получение балансов по фильтру.
 */
export const getApiBillingBalancesQueryParams = zod.object({
  BalanceId: zod.string().uuid().optional(),
  UserId: zod.string().uuid().optional(),
  DateFrom: zod.string().datetime().optional(),
  DateTo: zod.string().datetime().optional(),
  Limit: zod.number().optional(),
  Offset: zod.number().optional(),
  OrderingField: zod.string().optional(),
  OrderingType: zod.enum(['ASC', 'DESC']).optional(),
});

export const getApiBillingBalancesResponse = zod.object({
  data: zod
    .array(
      zod.object({
        id: zod.string().uuid(),
        name: zod.enum(['PERSONAL', 'BUSINESS']),
        userId: zod.string().uuid(),
        value: zod.number(),
        countryId: zod.string().min(1),
        isFrozen: zod.boolean(),
        createdDate: zod.string().datetime(),
        lastUpdate: zod.string().datetime(),
      })
    )
    .nullish(),
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Получение количества балансов по фильтру.
 */
export const getApiBillingBalancesCountQueryParams = zod.object({
  BalanceId: zod.string().uuid().optional(),
  UserId: zod.string().uuid().optional(),
  DateFrom: zod.string().datetime().optional(),
  DateTo: zod.string().datetime().optional(),
});

export const getApiBillingBalancesCountResponse = zod.object({
  data: zod.number().optional(),
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Получение транзакций по фильтру.
 */
export const getApiBillingBalancesTransactionsQueryParams = zod.object({
  BalanceId: zod.string().uuid().optional(),
  UserId: zod.string().uuid().optional(),
  Operation: zod
    .enum(['UNDEFINED', 'DEBIT', 'CREDIT', 'RESET', 'SET'])
    .optional(),
  DateFrom: zod.string().datetime().optional(),
  DateTo: zod.string().datetime().optional(),
  Limit: zod.number().optional(),
  Offset: zod.number().optional(),
  OrderingField: zod.string().optional(),
  OrderingType: zod.enum(['ASC', 'DESC']).optional(),
});

export const getApiBillingBalancesTransactionsResponse = zod.object({
  data: zod
    .array(
      zod.object({
        id: zod.string().uuid(),
        balanceId: zod.string().uuid(),
        operation: zod.enum(['UNDEFINED', 'DEBIT', 'CREDIT', 'RESET', 'SET']),
        value: zod.number(),
        reason: zod.string().min(1),
        createdDate: zod.string().datetime(),
      })
    )
    .nullish(),
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Получение количества транзакций по фильтру.
 */
export const getApiBillingBalancesTransactionsCountQueryParams = zod.object({
  BalanceId: zod.string().uuid().optional(),
  UserId: zod.string().uuid().optional(),
  Operation: zod
    .enum(['UNDEFINED', 'DEBIT', 'CREDIT', 'RESET', 'SET'])
    .optional(),
  DateFrom: zod.string().datetime().optional(),
  DateTo: zod.string().datetime().optional(),
});

export const getApiBillingBalancesTransactionsCountResponse = zod.object({
  data: zod.number().optional(),
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Создание баланса.
 */
export const postApiBillingBalancesActionCreateBody = zod.object({
  userId: zod.string().uuid(),
  countryId: zod.string().min(1),
});

export const postApiBillingBalancesActionCreateResponse = zod.object({
  data: zod.string().uuid().optional(),
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Устанавливает значение баланса.
 */
export const postApiBillingBalancesActionSetBody = zod.object({
  balanceId: zod.string().uuid(),
  value: zod.number(),
});

export const postApiBillingBalancesActionSetResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Зачисление средств на баланс.
 */
export const postApiBillingBalancesActionCreditBody = zod.object({
  balanceId: zod.string().uuid(),
  value: zod.number(),
});

export const postApiBillingBalancesActionCreditResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Снятие средств с баланса.
 */
export const postApiBillingBalancesActionDebitBody = zod.object({
  balanceId: zod.string().uuid(),
  value: zod.number(),
});

export const postApiBillingBalancesActionDebitResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Сбрасывает баланс (делает равным 0).
 */
export const postApiBillingBalancesActionResetBody = zod.object({
  balanceId: zod.string().uuid(),
});

export const postApiBillingBalancesActionResetResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Заморозить баланс.
 */
export const postApiBillingBalancesActionFreezeQueryParams = zod.object({
  balanceId: zod.string().uuid().optional(),
});

export const postApiBillingBalancesActionFreezeResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Разморозить баланс.
 */
export const postApiBillingBalancesActionUnfreezeQueryParams = zod.object({
  balanceId: zod.string().uuid().optional(),
});

export const postApiBillingBalancesActionUnfreezeResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Получить биллинг по идентификатору.
 */
export const getApiBillingBillingsBillingIdParams = zod.object({
  billingId: zod.string().uuid(),
});

export const getApiBillingBillingsBillingIdResponse = zod.object({
  data: zod
    .object({
      id: zod.string().uuid(),
      userId: zod.string().uuid(),
      balanceId: zod.string().uuid(),
      status: zod.enum(['ERROR', 'IN_PROCESS', 'COMPLETED']),
      paymentStatus: zod.enum([
        'NOT_PAID',
        'ERROR',
        'IN_PROCESS',
        'NOT_REQUIRED',
        'PAID',
        'HOLD',
        'DEPOSITED',
        'ERROR_PAYMENT',
      ]),
      fiscalStatus: zod.enum([
        'NOT_FISCALIZED',
        'ERROR',
        'IN_PROCESS',
        'NOT_REQUIRED',
        'FISCALIZED',
      ]),
      createdDate: zod.string().datetime(),
      closedDate: zod.string().datetime().nullish(),
      balanceAtClosing: zod.number(),
      discount: zod.number(),
      payment: zod.number(),
      bonusesPayment: zod.number(),
      benefitPayment: zod.number(),
      invoiceSum: zod.number(),
      lastUpdate: zod.string().datetime(),
      providedServices: zod.array(
        zod.object({
          id: zod.string().uuid(),
          serviceId: zod.string().uuid(),
          billingId: zod.string().uuid(),
          sessionId: zod.string().uuid(),
          status: zod.enum(['ERROR', 'RUNNING', 'COMPLETED']),
          beginDate: zod.string().datetime(),
          endDate: zod.string().datetime().nullish(),
          payment: zod.number(),
          discount: zod.number(),
          benefitPayment: zod.number(),
          lastUpdate: zod.string().datetime(),
          providedStages: zod.array(
            zod.object({
              id: zod.string().uuid(),
              providedServiceId: zod.string().uuid(),
              stageId: zod.string().uuid(),
              beginDate: zod.string().datetime(),
              endDate: zod.string().datetime().nullish(),
              value: zod.number().nullish(),
              payment: zod.number(),
              discount: zod.number(),
              benefitPayment: zod.number(),
              reachedEndConditionDate: zod.string().datetime().nullish(),
              lastUpdate: zod.string().datetime(),
            })
          ),
        })
      ),
    })
    .optional(),
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Получить биллинги по фильтру.
 */
export const getApiBillingBillingsQueryParams = zod.object({
  BalanceId: zod.string().uuid().optional(),
  SessionId: zod.string().uuid().optional(),
  UserId: zod.string().uuid().optional(),
  Status: zod.enum(['ERROR', 'IN_PROCESS', 'COMPLETED']).optional(),
  DateFrom: zod.string().datetime().optional(),
  DateTo: zod.string().datetime().optional(),
  Limit: zod.number().optional(),
  Offset: zod.number().optional(),
  OrderingField: zod.string().optional(),
  OrderingType: zod.enum(['ASC', 'DESC']).optional(),
});

export const getApiBillingBillingsResponse = zod.object({
  data: zod
    .array(
      zod.object({
        id: zod.string().uuid(),
        userId: zod.string().uuid(),
        balanceId: zod.string().uuid(),
        status: zod.enum(['ERROR', 'IN_PROCESS', 'COMPLETED']),
        paymentStatus: zod.enum([
          'NOT_PAID',
          'ERROR',
          'IN_PROCESS',
          'NOT_REQUIRED',
          'PAID',
          'HOLD',
          'DEPOSITED',
          'ERROR_PAYMENT',
        ]),
        fiscalStatus: zod.enum([
          'NOT_FISCALIZED',
          'ERROR',
          'IN_PROCESS',
          'NOT_REQUIRED',
          'FISCALIZED',
        ]),
        createdDate: zod.string().datetime(),
        closedDate: zod.string().datetime().nullish(),
        balanceAtClosing: zod.number(),
        discount: zod.number(),
        payment: zod.number(),
        bonusesPayment: zod.number(),
        benefitPayment: zod.number(),
        invoiceSum: zod.number(),
        lastUpdate: zod.string().datetime(),
        providedServices: zod.array(
          zod.object({
            id: zod.string().uuid(),
            serviceId: zod.string().uuid(),
            billingId: zod.string().uuid(),
            sessionId: zod.string().uuid(),
            status: zod.enum(['ERROR', 'RUNNING', 'COMPLETED']),
            beginDate: zod.string().datetime(),
            endDate: zod.string().datetime().nullish(),
            payment: zod.number(),
            discount: zod.number(),
            benefitPayment: zod.number(),
            lastUpdate: zod.string().datetime(),
            providedStages: zod.array(
              zod.object({
                id: zod.string().uuid(),
                providedServiceId: zod.string().uuid(),
                stageId: zod.string().uuid(),
                beginDate: zod.string().datetime(),
                endDate: zod.string().datetime().nullish(),
                value: zod.number().nullish(),
                payment: zod.number(),
                discount: zod.number(),
                benefitPayment: zod.number(),
                reachedEndConditionDate: zod.string().datetime().nullish(),
                lastUpdate: zod.string().datetime(),
              })
            ),
          })
        ),
      })
    )
    .nullish(),
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Получить количество биллингов по фильтру.
 */
export const getApiBillingBillingsCountQueryParams = zod.object({
  BalanceId: zod.string().uuid().optional(),
  SessionId: zod.string().uuid().optional(),
  UserId: zod.string().uuid().optional(),
  Status: zod.enum(['ERROR', 'IN_PROCESS', 'COMPLETED']).optional(),
  DateFrom: zod.string().datetime().optional(),
  DateTo: zod.string().datetime().optional(),
});

export const getApiBillingBillingsCountResponse = zod.object({
  data: zod.number().optional(),
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Закрывает биллинг.
 */
export const postApiBillingBillingsActionCloseBody = zod.object({
  billingId: zod.string().uuid(),
});

export const postApiBillingBillingsActionCloseResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Закрывает активный биллинг пользователя.
 */
export const postApiBillingBillingsActionCloseactiveBody = zod.object({
  userId: zod.string().uuid(),
});

export const postApiBillingBillingsActionCloseactiveResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Рассчитывает последний созданный биллинг пользователя.
Если биллинг еще не закрыт, то рассчитывает оплату для активных оказываемых услуг и стадий.
Если закрыт - то возвращает уже рассчитанный биллинг.
 */
export const postApiBillingBillingsActionCalculatelastBody = zod.object({
  userId: zod.string().uuid(),
});

export const postApiBillingBillingsActionCalculatelastResponse = zod.object({
  data: zod
    .object({
      id: zod.string().uuid(),
      userId: zod.string().uuid(),
      balanceId: zod.string().uuid(),
      status: zod.enum(['ERROR', 'IN_PROCESS', 'COMPLETED']),
      paymentStatus: zod.enum([
        'NOT_PAID',
        'ERROR',
        'IN_PROCESS',
        'NOT_REQUIRED',
        'PAID',
        'HOLD',
        'DEPOSITED',
        'ERROR_PAYMENT',
      ]),
      fiscalStatus: zod.enum([
        'NOT_FISCALIZED',
        'ERROR',
        'IN_PROCESS',
        'NOT_REQUIRED',
        'FISCALIZED',
      ]),
      createdDate: zod.string().datetime(),
      closedDate: zod.string().datetime().nullish(),
      balanceAtClosing: zod.number(),
      discount: zod.number(),
      payment: zod.number(),
      bonusesPayment: zod.number(),
      benefitPayment: zod.number(),
      invoiceSum: zod.number(),
      lastUpdate: zod.string().datetime(),
      providedServices: zod.array(
        zod.object({
          id: zod.string().uuid(),
          serviceId: zod.string().uuid(),
          billingId: zod.string().uuid(),
          sessionId: zod.string().uuid(),
          status: zod.enum(['ERROR', 'RUNNING', 'COMPLETED']),
          beginDate: zod.string().datetime(),
          endDate: zod.string().datetime().nullish(),
          payment: zod.number(),
          discount: zod.number(),
          benefitPayment: zod.number(),
          lastUpdate: zod.string().datetime(),
          providedStages: zod.array(
            zod.object({
              id: zod.string().uuid(),
              providedServiceId: zod.string().uuid(),
              stageId: zod.string().uuid(),
              beginDate: zod.string().datetime(),
              endDate: zod.string().datetime().nullish(),
              value: zod.number().nullish(),
              payment: zod.number(),
              discount: zod.number(),
              benefitPayment: zod.number(),
              reachedEndConditionDate: zod.string().datetime().nullish(),
              lastUpdate: zod.string().datetime(),
            })
          ),
        })
      ),
    })
    .optional(),
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Получить отчет по оплатам пользователей.
 */
export const getApiBillingReportsUsersPaymentSumQueryParams = zod.object({
  DateFrom: zod.string().datetime().optional(),
  DateTo: zod.string().datetime().optional(),
  BalancesIds: zod.array(zod.string().uuid()).optional(),
});

export const getApiBillingReportsUsersPaymentSumResponse = zod.object({
  data: zod
    .object({
      usersCalculatedValues: zod.array(
        zod.object({
          userId: zod.string().uuid(),
          calculatedValue: zod.number(),
        })
      ),
    })
    .optional(),
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Получить отчет по счетам пользователей.
 */
export const getApiBillingReportsUsersInvoiceSumQueryParams = zod.object({
  DateFrom: zod.string().datetime().optional(),
  DateTo: zod.string().datetime().optional(),
  CountryId: zod.string().optional(),
});

export const getApiBillingReportsUsersInvoiceSumResponse = zod.object({
  data: zod
    .object({
      usersCalculatedValues: zod.array(
        zod.object({
          userId: zod.string().uuid(),
          calculatedValue: zod.number(),
        })
      ),
    })
    .optional(),
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Начинает оказываемую услугу.
 */
export const postApiBillingActionBeginBody = zod.object({
  userId: zod.string().uuid(),
  sessionId: zod.string().uuid(),
  balanceId: zod.string().uuid(),
  serviceId: zod.string().uuid(),
});

export const postApiBillingActionBeginResponse = zod.object({
  data: zod.string().uuid().optional(),
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Завершает оказываемую услугу.
 */
export const postApiBillingActionEndBody = zod.object({
  providedServiceId: zod.string().uuid(),
});

export const postApiBillingActionEndResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Завершает активную оказываемую услугу.
 */
export const postApiBillingActionEndactiveQueryParams = zod.object({
  userId: zod.string().uuid().optional(),
});

export const postApiBillingActionEndactiveResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Создает услугу.
 */
export const postApiBillingServicesBody = zod.object({
  type: zod.enum(['RESERVING', 'CHARGING', 'PARKING']),
  name: zod.string().min(1),
});

export const postApiBillingServicesResponse = zod.object({
  data: zod
    .object({
      id: zod.string().uuid(),
      name: zod.string().min(1),
      type: zod.enum(['RESERVING', 'CHARGING', 'PARKING']),
      createdDate: zod.string().datetime(),
      stages: zod
        .array(
          zod.object({
            id: zod.string().uuid(),
            name: zod.string().min(1),
            serviceId: zod.string().uuid(),
            tariffId: zod.string().uuid(),
            endType: zod.enum([
              'MANUAL',
              'RIGHT_NOW',
              'ENERGY_PERCENT',
              'TIME_MINUTE',
              'POWER_LOSS',
            ]),
            endConditionValue: zod.number(),
            serialNumber: zod.number(),
            createdDate: zod.string().datetime(),
            tariff: zod.object({
              id: zod.string().uuid(),
              name: zod.string().min(1),
              countryId: zod.string().min(1),
              type: zod.enum(['ENERGY_KWH', 'FLAT_FEE', 'TIME_MINUTE']),
              price: zod.number(),
              createdDate: zod.string().datetime(),
            }),
          })
        )
        .nullish(),
    })
    .optional(),
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Выдает услуги по фильтру.
 */
export const getApiBillingServicesQueryParams = zod.object({
  Limit: zod.number().optional(),
  Offset: zod.number().optional(),
});

export const getApiBillingServicesResponse = zod.object({
  data: zod
    .array(
      zod.object({
        id: zod.string().uuid(),
        name: zod.string().min(1),
        type: zod.enum(['RESERVING', 'CHARGING', 'PARKING']),
        createdDate: zod.string().datetime(),
        stages: zod
          .array(
            zod.object({
              id: zod.string().uuid(),
              name: zod.string().min(1),
              serviceId: zod.string().uuid(),
              tariffId: zod.string().uuid(),
              endType: zod.enum([
                'MANUAL',
                'RIGHT_NOW',
                'ENERGY_PERCENT',
                'TIME_MINUTE',
                'POWER_LOSS',
              ]),
              endConditionValue: zod.number(),
              serialNumber: zod.number(),
              createdDate: zod.string().datetime(),
              tariff: zod.object({
                id: zod.string().uuid(),
                name: zod.string().min(1),
                countryId: zod.string().min(1),
                type: zod.enum(['ENERGY_KWH', 'FLAT_FEE', 'TIME_MINUTE']),
                price: zod.number(),
                createdDate: zod.string().datetime(),
              }),
            })
          )
          .nullish(),
      })
    )
    .nullish(),
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Выдает количество услуг по фильтру.
 */
export const getApiBillingServicesCountQueryParams = zod.object({
  requestDto: zod.object({}).optional(),
});

export const getApiBillingServicesCountResponse = zod.object({
  data: zod.number().optional(),
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Удаяет услугу.
 */
export const deleteApiBillingServicesIdParams = zod.object({
  id: zod.string().uuid(),
});

export const deleteApiBillingServicesIdResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Создает привязку списка услуг к любой сущности.
 */
export const postApiBillingServicesListLinksBody = zod.object({
  targetId: zod.string().uuid(),
  servicesListId: zod.string().uuid(),
});

export const postApiBillingServicesListLinksResponse = zod.object({
  data: zod
    .object({
      id: zod.string().uuid(),
      targetId: zod.string().uuid(),
      servicesListId: zod.string().uuid(),
      lastUpdate: zod.string().datetime(),
    })
    .optional(),
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Выдает привязки списков услуг по фильтру.
 */
export const getApiBillingServicesListLinksQueryParams = zod.object({
  ServicesListId: zod.string().uuid().optional(),
  Limit: zod.number().optional(),
  Offset: zod.number().optional(),
});

export const getApiBillingServicesListLinksResponse = zod.object({
  data: zod
    .array(
      zod.object({
        id: zod.string().uuid(),
        targetId: zod.string().uuid(),
        servicesListId: zod.string().uuid(),
        lastUpdate: zod.string().datetime(),
      })
    )
    .nullish(),
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Выдает количество привязок списков услуг по фильтру.
 */
export const getApiBillingServicesListLinksCountQueryParams = zod.object({
  ServicesListId: zod.string().uuid().optional(),
});

export const getApiBillingServicesListLinksCountResponse = zod.object({
  data: zod.number().optional(),
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Удаление привязки списка услуг.
 */
export const deleteApiBillingServicesListLinksIdParams = zod.object({
  id: zod.string().uuid(),
});

export const deleteApiBillingServicesListLinksIdResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Получить список услуг по идентификатору.
 */
export const getApiBillingServicesListsIdParams = zod.object({
  id: zod.string().uuid(),
});

export const getApiBillingServicesListsIdResponse = zod.object({
  data: zod
    .object({
      id: zod.string().uuid(),
      name: zod.string().min(1),
      lastUpdate: zod.string().datetime(),
      services: zod
        .array(
          zod.object({
            id: zod.string().uuid(),
            name: zod.string().min(1),
            type: zod.enum(['RESERVING', 'CHARGING', 'PARKING']),
            createdDate: zod.string().datetime(),
            stages: zod
              .array(
                zod.object({
                  id: zod.string().uuid(),
                  name: zod.string().min(1),
                  serviceId: zod.string().uuid(),
                  tariffId: zod.string().uuid(),
                  endType: zod.enum([
                    'MANUAL',
                    'RIGHT_NOW',
                    'ENERGY_PERCENT',
                    'TIME_MINUTE',
                    'POWER_LOSS',
                  ]),
                  endConditionValue: zod.number(),
                  serialNumber: zod.number(),
                  createdDate: zod.string().datetime(),
                  tariff: zod.object({
                    id: zod.string().uuid(),
                    name: zod.string().min(1),
                    countryId: zod.string().min(1),
                    type: zod.enum(['ENERGY_KWH', 'FLAT_FEE', 'TIME_MINUTE']),
                    price: zod.number(),
                    createdDate: zod.string().datetime(),
                  }),
                })
              )
              .nullish(),
          })
        )
        .nullish(),
    })
    .optional(),
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Обновить список услуг.
 */
export const patchApiBillingServicesListsIdParams = zod.object({
  id: zod.string().uuid(),
});

export const patchApiBillingServicesListsIdBody = zod.object({
  name: zod.string().nullish(),
  servicesIds: zod.array(zod.string().uuid()).nullish(),
});

export const patchApiBillingServicesListsIdResponse = zod.object({
  data: zod
    .object({
      id: zod.string().uuid(),
      name: zod.string().min(1),
      lastUpdate: zod.string().datetime(),
      services: zod
        .array(
          zod.object({
            id: zod.string().uuid(),
            name: zod.string().min(1),
            type: zod.enum(['RESERVING', 'CHARGING', 'PARKING']),
            createdDate: zod.string().datetime(),
            stages: zod
              .array(
                zod.object({
                  id: zod.string().uuid(),
                  name: zod.string().min(1),
                  serviceId: zod.string().uuid(),
                  tariffId: zod.string().uuid(),
                  endType: zod.enum([
                    'MANUAL',
                    'RIGHT_NOW',
                    'ENERGY_PERCENT',
                    'TIME_MINUTE',
                    'POWER_LOSS',
                  ]),
                  endConditionValue: zod.number(),
                  serialNumber: zod.number(),
                  createdDate: zod.string().datetime(),
                  tariff: zod.object({
                    id: zod.string().uuid(),
                    name: zod.string().min(1),
                    countryId: zod.string().min(1),
                    type: zod.enum(['ENERGY_KWH', 'FLAT_FEE', 'TIME_MINUTE']),
                    price: zod.number(),
                    createdDate: zod.string().datetime(),
                  }),
                })
              )
              .nullish(),
          })
        )
        .nullish(),
    })
    .optional(),
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Удалить список услуг.
 */
export const deleteApiBillingServicesListsIdParams = zod.object({
  id: zod.string().uuid(),
});

export const deleteApiBillingServicesListsIdResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Получить списки услуг по фильтру.
 */
export const getApiBillingServicesListsResponse = zod.object({
  data: zod
    .array(
      zod.object({
        id: zod.string().uuid(),
        name: zod.string().min(1),
        lastUpdate: zod.string().datetime(),
        services: zod
          .array(
            zod.object({
              id: zod.string().uuid(),
              name: zod.string().min(1),
              type: zod.enum(['RESERVING', 'CHARGING', 'PARKING']),
              createdDate: zod.string().datetime(),
              stages: zod
                .array(
                  zod.object({
                    id: zod.string().uuid(),
                    name: zod.string().min(1),
                    serviceId: zod.string().uuid(),
                    tariffId: zod.string().uuid(),
                    endType: zod.enum([
                      'MANUAL',
                      'RIGHT_NOW',
                      'ENERGY_PERCENT',
                      'TIME_MINUTE',
                      'POWER_LOSS',
                    ]),
                    endConditionValue: zod.number(),
                    serialNumber: zod.number(),
                    createdDate: zod.string().datetime(),
                    tariff: zod.object({
                      id: zod.string().uuid(),
                      name: zod.string().min(1),
                      countryId: zod.string().min(1),
                      type: zod.enum(['ENERGY_KWH', 'FLAT_FEE', 'TIME_MINUTE']),
                      price: zod.number(),
                      createdDate: zod.string().datetime(),
                    }),
                  })
                )
                .nullish(),
            })
          )
          .nullish(),
      })
    )
    .nullish(),
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Создать список услуг.
 */
export const postApiBillingServicesListsBody = zod.object({
  name: zod.string().min(1),
});

export const postApiBillingServicesListsResponse = zod.object({
  data: zod
    .object({
      id: zod.string().uuid(),
      name: zod.string().min(1),
      lastUpdate: zod.string().datetime(),
      services: zod
        .array(
          zod.object({
            id: zod.string().uuid(),
            name: zod.string().min(1),
            type: zod.enum(['RESERVING', 'CHARGING', 'PARKING']),
            createdDate: zod.string().datetime(),
            stages: zod
              .array(
                zod.object({
                  id: zod.string().uuid(),
                  name: zod.string().min(1),
                  serviceId: zod.string().uuid(),
                  tariffId: zod.string().uuid(),
                  endType: zod.enum([
                    'MANUAL',
                    'RIGHT_NOW',
                    'ENERGY_PERCENT',
                    'TIME_MINUTE',
                    'POWER_LOSS',
                  ]),
                  endConditionValue: zod.number(),
                  serialNumber: zod.number(),
                  createdDate: zod.string().datetime(),
                  tariff: zod.object({
                    id: zod.string().uuid(),
                    name: zod.string().min(1),
                    countryId: zod.string().min(1),
                    type: zod.enum(['ENERGY_KWH', 'FLAT_FEE', 'TIME_MINUTE']),
                    price: zod.number(),
                    createdDate: zod.string().datetime(),
                  }),
                })
              )
              .nullish(),
          })
        )
        .nullish(),
    })
    .optional(),
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Получить количество списков услуг по фильтру.
 */
export const getApiBillingServicesListsCountParams = zod.object({
  requestDto: zod.object({}),
});

export const getApiBillingServicesListsCountResponse = zod.object({
  data: zod.number().optional(),
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Создает стадию услуги.
 */
export const postApiBillingStagesBody = zod.object({
  name: zod.string().min(1),
  serviceId: zod.string().uuid(),
  tariffId: zod.string().uuid(),
  endType: zod.enum([
    'MANUAL',
    'RIGHT_NOW',
    'ENERGY_PERCENT',
    'TIME_MINUTE',
    'POWER_LOSS',
  ]),
  endConditionValue: zod.number(),
  serialNumber: zod.number(),
});

export const postApiBillingStagesResponse = zod.object({
  data: zod
    .object({
      id: zod.string().uuid(),
      name: zod.string().min(1),
      serviceId: zod.string().uuid(),
      tariffId: zod.string().uuid(),
      endType: zod.enum([
        'MANUAL',
        'RIGHT_NOW',
        'ENERGY_PERCENT',
        'TIME_MINUTE',
        'POWER_LOSS',
      ]),
      endConditionValue: zod.number(),
      serialNumber: zod.number(),
      createdDate: zod.string().datetime(),
      tariff: zod.object({
        id: zod.string().uuid(),
        name: zod.string().min(1),
        countryId: zod.string().min(1),
        type: zod.enum(['ENERGY_KWH', 'FLAT_FEE', 'TIME_MINUTE']),
        price: zod.number(),
        createdDate: zod.string().datetime(),
      }),
    })
    .optional(),
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Выдает стадии услуги по фильтру.
 */
export const getApiBillingStagesQueryParams = zod.object({
  Limit: zod.number().optional(),
  Offset: zod.number().optional(),
});

export const getApiBillingStagesResponse = zod.object({
  data: zod
    .array(
      zod.object({
        id: zod.string().uuid(),
        name: zod.string().min(1),
        serviceId: zod.string().uuid(),
        tariffId: zod.string().uuid(),
        endType: zod.enum([
          'MANUAL',
          'RIGHT_NOW',
          'ENERGY_PERCENT',
          'TIME_MINUTE',
          'POWER_LOSS',
        ]),
        endConditionValue: zod.number(),
        serialNumber: zod.number(),
        createdDate: zod.string().datetime(),
        tariff: zod.object({
          id: zod.string().uuid(),
          name: zod.string().min(1),
          countryId: zod.string().min(1),
          type: zod.enum(['ENERGY_KWH', 'FLAT_FEE', 'TIME_MINUTE']),
          price: zod.number(),
          createdDate: zod.string().datetime(),
        }),
      })
    )
    .nullish(),
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Выдает количество стадий услуг по фильтру.
 */
export const getApiBillingStagesCountQueryParams = zod.object({
  requestDto: zod.object({}).optional(),
});

export const getApiBillingStagesCountResponse = zod.object({
  data: zod.number().optional(),
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Удаяет стадию услуги.
 */
export const deleteApiBillingStagesIdParams = zod.object({
  id: zod.string().uuid(),
});

export const deleteApiBillingStagesIdResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Получить привязанные списки услуг для сущностей (напр. коннекторов).
 */
export const getApiBillingTargetsServicesListsQueryParams = zod.object({
  TargetsIds: zod.array(zod.string().uuid()),
});

export const getApiBillingTargetsServicesListsResponse = zod.object({
  data: zod
    .array(
      zod.object({
        targetId: zod.string().uuid().optional(),
        servicesListId: zod.string().uuid().optional(),
        name: zod.string().nullish(),
        lastUpdate: zod.string().datetime().optional(),
        services: zod
          .array(
            zod.object({
              id: zod.string().uuid(),
              name: zod.string().min(1),
              type: zod.enum(['RESERVING', 'CHARGING', 'PARKING']),
              createdDate: zod.string().datetime(),
              stages: zod
                .array(
                  zod.object({
                    id: zod.string().uuid(),
                    name: zod.string().min(1),
                    serviceId: zod.string().uuid(),
                    tariffId: zod.string().uuid(),
                    endType: zod.enum([
                      'MANUAL',
                      'RIGHT_NOW',
                      'ENERGY_PERCENT',
                      'TIME_MINUTE',
                      'POWER_LOSS',
                    ]),
                    endConditionValue: zod.number(),
                    serialNumber: zod.number(),
                    createdDate: zod.string().datetime(),
                    tariff: zod.object({
                      id: zod.string().uuid(),
                      name: zod.string().min(1),
                      countryId: zod.string().min(1),
                      type: zod.enum(['ENERGY_KWH', 'FLAT_FEE', 'TIME_MINUTE']),
                      price: zod.number(),
                      createdDate: zod.string().datetime(),
                    }),
                  })
                )
                .nullish(),
            })
          )
          .nullish(),
      })
    )
    .nullish(),
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Создает тариф.
 */
export const postApiBillingTariffsBody = zod.object({
  name: zod.string().min(1),
  countryId: zod.string().min(1),
  type: zod.enum(['ENERGY_KWH', 'FLAT_FEE', 'TIME_MINUTE']),
  price: zod.number(),
});

export const postApiBillingTariffsResponse = zod.object({
  data: zod
    .object({
      id: zod.string().uuid(),
      name: zod.string().min(1),
      countryId: zod.string().min(1),
      type: zod.enum(['ENERGY_KWH', 'FLAT_FEE', 'TIME_MINUTE']),
      price: zod.number(),
      createdDate: zod.string().datetime(),
    })
    .optional(),
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Выдает тарифы по фильтру.
 */
export const getApiBillingTariffsQueryParams = zod.object({
  Limit: zod.number().optional(),
  Offset: zod.number().optional(),
});

export const getApiBillingTariffsResponse = zod.object({
  data: zod
    .array(
      zod.object({
        id: zod.string().uuid(),
        name: zod.string().min(1),
        countryId: zod.string().min(1),
        type: zod.enum(['ENERGY_KWH', 'FLAT_FEE', 'TIME_MINUTE']),
        price: zod.number(),
        createdDate: zod.string().datetime(),
      })
    )
    .nullish(),
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Выдает количество тарифов по фильтру.
 */
export const getApiBillingTariffsCountQueryParams = zod.object({
  requestDto: zod.object({}).optional(),
});

export const getApiBillingTariffsCountResponse = zod.object({
  data: zod.number().optional(),
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Удаяет тариф.
 */
export const deleteApiBillingTariffsIdParams = zod.object({
  id: zod.string().uuid(),
});

export const deleteApiBillingTariffsIdResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});
