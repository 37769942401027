import { Col, Spin } from 'antd';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  useForm,
  FormProvider,
  useFormContext,
  useFieldArray,
} from 'react-hook-form';

import {
  ButtonsContainer,
  UiSubmitButton,
  UiCancelFormButton,
  FormControlsContainer,
} from 'shared/ui';
import { CustomInput } from 'shared/ui/form/custom-input';
import {
  isErrorWithMessage,
  openErrorNotification,
  openSuccessNotification,
} from 'shared/lib';
import { useAddCompany } from 'shared/api/services/company/enhanced';
import {
  ServicesListDto,
  TariffDto,
} from 'shared/api/services/billing/rtk/generated';

import {
  DEFAULT_VALUES,
  FormSchema,
  FormInput,
  FormOutput,
} from '../consts/schema';
import { FORM_TITLE, ADD_ERROR, ADD_SUCCESS } from '../consts';

import { CardFormWrapper, FormRow } from '../../../../../common-styles';
import { ControlButton, FormFieldsContainer, FormFieldsGroup } from './styles';

import { Service } from './service';
import { useAddServices } from 'shared/api/services/billing/rtk/enhanced';

type Props = {
  serviceList: ServicesListDto;
  tariffs: TariffDto[];
};

export function Form({ tariffs, serviceList }: Props) {
  const form = useForm<FormInput, void, FormOutput>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      ...DEFAULT_VALUES,
    },
  });

  const [trigger, { isLoading }] = useAddServices();

  const handleSubmit = form.handleSubmit(async (data) => {
    const { id } = serviceList;

    try {
      const res = await trigger({
        stages: data,
        id,
      }).unwrap();

      openSuccessNotification(ADD_SUCCESS);
    } catch (err) {
      const hasErrorMessage = isErrorWithMessage(err);

      const errorText = hasErrorMessage ? err.data.statusMessage : ADD_ERROR;

      openErrorNotification(errorText);
    }
  });

  return (
    <CardFormWrapper title={FORM_TITLE}>
      <FormProvider {...form}>
        <Spin spinning={isLoading}>
          <form onSubmit={handleSubmit}>
            <Form.Fields tariffs={tariffs} name={serviceList.name} />
            <Form.Buttons />
          </form>
        </Spin>
      </FormProvider>
    </CardFormWrapper>
  );
}

type FieldsProps = {
  tariffs: TariffDto[];
  name: string;
};

Form.Fields = function Fields({ tariffs, name }: FieldsProps) {
  const {
    control,
    formState: { errors },
  } = useFormContext<FormInput, void, FormOutput>();

  const reservingFieldArray = useFieldArray({
    name: 'reservingStages',
    control,
  });

  const chargingFieldArray = useFieldArray({
    name: 'chargingStages',
    control,
  });

  return (
    <FormFieldsContainer>
      <FormFieldsGroup>
        <FormRow gutter={20}>
          <Col span={6}>
            {/* <CustomInput value={name} label="Название биллинга" disabled /> */}
          </Col>
        </FormRow>
      </FormFieldsGroup>

      <Service
        fieldArray={reservingFieldArray}
        tariffs={tariffs}
        addButtonLabel="Добавить услугу Бронирование"
        name="reservingStages"
        label="Услуга Бронирование"
      />
      <Service
        fieldArray={chargingFieldArray}
        tariffs={tariffs}
        addButtonLabel="Добавить услугу Заряд"
        name="chargingStages"
        label="Услуга Заряд"
      />
    </FormFieldsContainer>
  );
};

Form.Buttons = function Buttons() {
  return (
    <FormControlsContainer>
      <ButtonsContainer>
        <UiSubmitButton />
        <UiCancelFormButton />
      </ButtonsContainer>
    </FormControlsContainer>
  );
};
