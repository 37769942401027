import React from 'react';
import { DefaultOptionType } from 'antd/lib/select';

import {
  TariffDto,
  TariffType,
} from 'shared/api/services/billing/rtk/generated';
import { Code } from 'shared/api/services/information/rtk/generated/countries';
import { toCurrencyUnit } from 'shared/lib';

import { COUNTRY_CURRENCY } from '../consts';
import { OptionsGroupLabel } from './styles';

type OptionsByCountry = Record<Code, DefaultOptionType[]>;

const getOptionLabel = (tariff: TariffDto) => {
  const { countryId, price, type } = tariff;

  const currencyUnitPrice = toCurrencyUnit(price);
  const currencyName = COUNTRY_CURRENCY[countryId as Code];

  switch (type) {
    case 'FLAT_FEE':
      return `${currencyUnitPrice} ${currencyName}`;

    case 'ENERGY_KWH':
      return `${currencyUnitPrice} ${currencyName} / кВт*ч`;

    case 'TIME_MINUTE':
      return `${currencyUnitPrice} ${currencyName} / мин`;
  }
};

const getSortedOptions = (options: DefaultOptionType[]) => {
  return options.sort((a, b) => a.price - b.price);
};

export const getTariffOptionsList = (tariffs: TariffDto[]) => {
  const dic = tariffs.reduce(
    (acc, el) => {
      const { type, price, countryId, id } = el;

      const label = getOptionLabel(el);

      acc[type].optionsByCountry[countryId as Code].push({
        label,
        value: id,
        price,
      });

      return acc;
    },
    {
      ENERGY_KWH: {
        title: 'кВт*ч',
        optionsByCountry: { RU: [], AM: [], KG: [], BY: [], KZ: [] },
      },
      TIME_MINUTE: {
        title: 'Минуты',
        optionsByCountry: { RU: [], AM: [], KG: [], BY: [], KZ: [] },
      },
      FLAT_FEE: {
        title: 'Разовые платы',
        optionsByCountry: { RU: [], AM: [], KG: [], BY: [], KZ: [] },
      },
    } as Record<
      TariffType,
      {
        title: React.ReactNode;
        optionsByCountry: OptionsByCountry;
      }
    >
  );

  return Object.values(dic).reduce((acc, el) => {
    const { optionsByCountry, title } = el;

    const { AM, BY, KG, KZ, RU } = optionsByCountry;

    const options = [
      ...getSortedOptions(RU),
      ...getSortedOptions(AM),
      ...getSortedOptions(KG),
    ];

    if (!options.length) {
      return acc;
    }

    return [
      ...acc,
      { label: <OptionsGroupLabel>{title}</OptionsGroupLabel>, options },
    ];
  }, [] as Array<{ label: React.ReactNode; options: DefaultOptionType[] }>);
};
