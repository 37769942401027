/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * BillingService.WebApi
 * OpenAPI spec version: 1.0
 */
import type {
  BalanceDtoIReadOnlyCollectionResponseBase,
  BalanceTransactionDtoIReadOnlyCollectionResponseBase,
  BeginProvidedServiceRequestDto,
  BillingDtoIReadOnlyCollectionResponseBase,
  BillingDtoResponseBase,
  CalculateUserLastBillingPaymentRequestDto,
  CloseActiveBillingRequestDto,
  CloseBillingRequestDto,
  CompleteProvidedServiceRequestDto,
  CreateBalanceRequestDto,
  CreateServiceRequestDto,
  CreateServicesListLinkRequestDto,
  CreateServicesListRequestDto,
  CreateStageRequestDto,
  CreateTariffRequestDto,
  CreditBalanceRequestDto,
  DebitBalanceRequestDto,
  GetApiBillingBalancesCountParams,
  GetApiBillingBalancesParams,
  GetApiBillingBalancesTransactionsCountParams,
  GetApiBillingBalancesTransactionsParams,
  GetApiBillingBillingsCountParams,
  GetApiBillingBillingsParams,
  GetApiBillingReportsUsersInvoiceSumParams,
  GetApiBillingReportsUsersPaymentSumParams,
  GetApiBillingServicesCountParams,
  GetApiBillingServicesListLinksCountParams,
  GetApiBillingServicesListLinksParams,
  GetApiBillingServicesParams,
  GetApiBillingStagesCountParams,
  GetApiBillingStagesParams,
  GetApiBillingTargetsServicesListsParams,
  GetApiBillingTariffsCountParams,
  GetApiBillingTariffsParams,
  GuidResponseBase,
  PostApiBillingActionEndactiveParams,
  PostApiBillingBalancesActionFreezeParams,
  PostApiBillingBalancesActionUnfreezeParams,
  ResetBalanceRequestDto,
  ResponseBase,
  ServiceDtoIReadOnlyCollectionResponseBase,
  ServiceDtoResponseBase,
  ServicesListDtoIReadOnlyCollectionResponseBase,
  ServicesListDtoResponseBase,
  ServicesListLinkDtoIReadOnlyCollectionResponseBase,
  ServicesListLinkDtoResponseBase,
  SetBalanceRequestDto,
  StageDtoIReadOnlyCollectionResponseBase,
  StageDtoResponseBase,
  TargetsServicesListsDtoIReadOnlyCollectionResponseBase,
  TariffDtoIReadOnlyCollectionResponseBase,
  TariffDtoResponseBase,
  UInt32ResponseBase,
  UpdateServicesListRequestDto,
  UsersInvoiceSumReportDtoResponseBase,
  UsersPaymentSumReportDtoResponseBase
} from './schemas'
import { customInstance } from '../../../../../axios';



type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


  export const getBillingServiceWebApi = () => {
/**
 * @summary Получение балансов по фильтру.
 */
const getApiBillingBalances = (
    params?: GetApiBillingBalancesParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<BalanceDtoIReadOnlyCollectionResponseBase>(
      {url: `/Api/Billing/Balances`, method: 'GET',
        params
    },
      options);
    }
  
/**
 * @summary Получение количества балансов по фильтру.
 */
const getApiBillingBalancesCount = (
    params?: GetApiBillingBalancesCountParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<UInt32ResponseBase>(
      {url: `/Api/Billing/Balances/count`, method: 'GET',
        params
    },
      options);
    }
  
/**
 * @summary Получение транзакций по фильтру.
 */
const getApiBillingBalancesTransactions = (
    params?: GetApiBillingBalancesTransactionsParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<BalanceTransactionDtoIReadOnlyCollectionResponseBase>(
      {url: `/Api/Billing/Balances/transactions`, method: 'GET',
        params
    },
      options);
    }
  
/**
 * @summary Получение количества транзакций по фильтру.
 */
const getApiBillingBalancesTransactionsCount = (
    params?: GetApiBillingBalancesTransactionsCountParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<UInt32ResponseBase>(
      {url: `/Api/Billing/Balances/transactions/count`, method: 'GET',
        params
    },
      options);
    }
  
/**
 * @summary Создание баланса.
 */
const postApiBillingBalancesActionCreate = (
    createBalanceRequestDto: CreateBalanceRequestDto,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<GuidResponseBase>(
      {url: `/Api/Billing/Balances/action/create`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: createBalanceRequestDto
    },
      options);
    }
  
/**
 * @summary Устанавливает значение баланса.
 */
const postApiBillingBalancesActionSet = (
    setBalanceRequestDto: SetBalanceRequestDto,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<ResponseBase>(
      {url: `/Api/Billing/Balances/action/set`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: setBalanceRequestDto
    },
      options);
    }
  
/**
 * @summary Зачисление средств на баланс.
 */
const postApiBillingBalancesActionCredit = (
    creditBalanceRequestDto: CreditBalanceRequestDto,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<ResponseBase>(
      {url: `/Api/Billing/Balances/action/credit`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: creditBalanceRequestDto
    },
      options);
    }
  
/**
 * @summary Снятие средств с баланса.
 */
const postApiBillingBalancesActionDebit = (
    debitBalanceRequestDto: DebitBalanceRequestDto,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<ResponseBase>(
      {url: `/Api/Billing/Balances/action/debit`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: debitBalanceRequestDto
    },
      options);
    }
  
/**
 * @summary Сбрасывает баланс (делает равным 0).
 */
const postApiBillingBalancesActionReset = (
    resetBalanceRequestDto: ResetBalanceRequestDto,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<ResponseBase>(
      {url: `/Api/Billing/Balances/action/reset`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: resetBalanceRequestDto
    },
      options);
    }
  
/**
 * @summary Заморозить баланс.
 */
const postApiBillingBalancesActionFreeze = (
    params?: PostApiBillingBalancesActionFreezeParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<ResponseBase>(
      {url: `/Api/Billing/Balances/action/freeze`, method: 'POST',
        params
    },
      options);
    }
  
/**
 * @summary Разморозить баланс.
 */
const postApiBillingBalancesActionUnfreeze = (
    params?: PostApiBillingBalancesActionUnfreezeParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<ResponseBase>(
      {url: `/Api/Billing/Balances/action/unfreeze`, method: 'POST',
        params
    },
      options);
    }
  
/**
 * @summary Получить биллинг по идентификатору.
 */
const getApiBillingBillingsBillingId = (
    billingId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<BillingDtoResponseBase>(
      {url: `/Api/Billing/Billings/${billingId}`, method: 'GET'
    },
      options);
    }
  
/**
 * @summary Получить биллинги по фильтру.
 */
const getApiBillingBillings = (
    params?: GetApiBillingBillingsParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<BillingDtoIReadOnlyCollectionResponseBase>(
      {url: `/Api/Billing/Billings`, method: 'GET',
        params
    },
      options);
    }
  
/**
 * @summary Получить количество биллингов по фильтру.
 */
const getApiBillingBillingsCount = (
    params?: GetApiBillingBillingsCountParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<UInt32ResponseBase>(
      {url: `/Api/Billing/Billings/count`, method: 'GET',
        params
    },
      options);
    }
  
/**
 * @summary Закрывает биллинг.
 */
const postApiBillingBillingsActionClose = (
    closeBillingRequestDto: CloseBillingRequestDto,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<ResponseBase>(
      {url: `/Api/Billing/Billings/action/close`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: closeBillingRequestDto
    },
      options);
    }
  
/**
 * @summary Закрывает активный биллинг пользователя.
 */
const postApiBillingBillingsActionCloseactive = (
    closeActiveBillingRequestDto: CloseActiveBillingRequestDto,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<ResponseBase>(
      {url: `/Api/Billing/Billings/action/closeactive`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: closeActiveBillingRequestDto
    },
      options);
    }
  
/**
 * @summary Рассчитывает последний созданный биллинг пользователя.
Если биллинг еще не закрыт, то рассчитывает оплату для активных оказываемых услуг и стадий.
Если закрыт - то возвращает уже рассчитанный биллинг.
 */
const postApiBillingBillingsActionCalculatelast = (
    calculateUserLastBillingPaymentRequestDto: CalculateUserLastBillingPaymentRequestDto,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<BillingDtoResponseBase>(
      {url: `/Api/Billing/Billings/action/calculatelast`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: calculateUserLastBillingPaymentRequestDto
    },
      options);
    }
  
/**
 * @summary Получить отчет по оплатам пользователей.
 */
const getApiBillingReportsUsersPaymentSum = (
    params?: GetApiBillingReportsUsersPaymentSumParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<UsersPaymentSumReportDtoResponseBase>(
      {url: `/Api/Billing/Reports/usersPaymentSum`, method: 'GET',
        params
    },
      options);
    }
  
/**
 * @summary Получить отчет по счетам пользователей.
 */
const getApiBillingReportsUsersInvoiceSum = (
    params?: GetApiBillingReportsUsersInvoiceSumParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<UsersInvoiceSumReportDtoResponseBase>(
      {url: `/Api/Billing/Reports/usersInvoiceSum`, method: 'GET',
        params
    },
      options);
    }
  
/**
 * @summary Начинает оказываемую услугу.
 */
const postApiBillingActionBegin = (
    beginProvidedServiceRequestDto: BeginProvidedServiceRequestDto,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<GuidResponseBase>(
      {url: `/Api/Billing/action/begin`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: beginProvidedServiceRequestDto
    },
      options);
    }
  
/**
 * @summary Завершает оказываемую услугу.
 */
const postApiBillingActionEnd = (
    completeProvidedServiceRequestDto: CompleteProvidedServiceRequestDto,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<ResponseBase>(
      {url: `/Api/Billing/action/end`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: completeProvidedServiceRequestDto
    },
      options);
    }
  
/**
 * @summary Завершает активную оказываемую услугу.
 */
const postApiBillingActionEndactive = (
    params?: PostApiBillingActionEndactiveParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<ResponseBase>(
      {url: `/Api/Billing/action/endactive`, method: 'POST',
        params
    },
      options);
    }
  
/**
 * @summary Создает услугу.
 */
const postApiBillingServices = (
    createServiceRequestDto: CreateServiceRequestDto,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<ServiceDtoResponseBase>(
      {url: `/Api/Billing/Services`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: createServiceRequestDto
    },
      options);
    }
  
/**
 * @summary Выдает услуги по фильтру.
 */
const getApiBillingServices = (
    params?: GetApiBillingServicesParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<ServiceDtoIReadOnlyCollectionResponseBase>(
      {url: `/Api/Billing/Services`, method: 'GET',
        params
    },
      options);
    }
  
/**
 * @summary Выдает количество услуг по фильтру.
 */
const getApiBillingServicesCount = (
    params?: GetApiBillingServicesCountParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<UInt32ResponseBase>(
      {url: `/Api/Billing/Services/count`, method: 'GET',
        params
    },
      options);
    }
  
/**
 * @summary Удаяет услугу.
 */
const deleteApiBillingServicesId = (
    id: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<ResponseBase>(
      {url: `/Api/Billing/Services/${id}`, method: 'DELETE'
    },
      options);
    }
  
/**
 * @summary Создает привязку списка услуг к любой сущности.
 */
const postApiBillingServicesListLinks = (
    createServicesListLinkRequestDto: CreateServicesListLinkRequestDto,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<ServicesListLinkDtoResponseBase>(
      {url: `/Api/Billing/ServicesListLinks`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: createServicesListLinkRequestDto
    },
      options);
    }
  
/**
 * @summary Выдает привязки списков услуг по фильтру.
 */
const getApiBillingServicesListLinks = (
    params?: GetApiBillingServicesListLinksParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<ServicesListLinkDtoIReadOnlyCollectionResponseBase>(
      {url: `/Api/Billing/ServicesListLinks`, method: 'GET',
        params
    },
      options);
    }
  
/**
 * @summary Выдает количество привязок списков услуг по фильтру.
 */
const getApiBillingServicesListLinksCount = (
    params?: GetApiBillingServicesListLinksCountParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<UInt32ResponseBase>(
      {url: `/Api/Billing/ServicesListLinks/count`, method: 'GET',
        params
    },
      options);
    }
  
/**
 * @summary Удаление привязки списка услуг.
 */
const deleteApiBillingServicesListLinksId = (
    id: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<ResponseBase>(
      {url: `/Api/Billing/ServicesListLinks/${id}`, method: 'DELETE'
    },
      options);
    }
  
/**
 * @summary Получить список услуг по идентификатору.
 */
const getApiBillingServicesListsId = (
    id: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<ServicesListDtoResponseBase>(
      {url: `/Api/Billing/ServicesLists/${id}`, method: 'GET'
    },
      options);
    }
  
/**
 * @summary Обновить список услуг.
 */
const patchApiBillingServicesListsId = (
    id: string,
    updateServicesListRequestDto: UpdateServicesListRequestDto,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<ServicesListDtoResponseBase>(
      {url: `/Api/Billing/ServicesLists/${id}`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: updateServicesListRequestDto
    },
      options);
    }
  
/**
 * @summary Удалить список услуг.
 */
const deleteApiBillingServicesListsId = (
    id: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<ResponseBase>(
      {url: `/Api/Billing/ServicesLists/${id}`, method: 'DELETE'
    },
      options);
    }
  
/**
 * @summary Получить списки услуг по фильтру.
 */
const getApiBillingServicesLists = (
    
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<ServicesListDtoIReadOnlyCollectionResponseBase>(
      {url: `/Api/Billing/ServicesLists`, method: 'GET'
    },
      options);
    }
  
/**
 * @summary Создать список услуг.
 */
const postApiBillingServicesLists = (
    createServicesListRequestDto: CreateServicesListRequestDto,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<ServicesListDtoResponseBase>(
      {url: `/Api/Billing/ServicesLists`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: createServicesListRequestDto
    },
      options);
    }
  
/**
 * @summary Получить количество списков услуг по фильтру.
 */
const getApiBillingServicesListsCount = (
    
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<UInt32ResponseBase>(
      {url: `/Api/Billing/ServicesLists/count`, method: 'GET'
    },
      options);
    }
  
/**
 * @summary Создает стадию услуги.
 */
const postApiBillingStages = (
    createStageRequestDto: CreateStageRequestDto,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<StageDtoResponseBase>(
      {url: `/Api/Billing/Stages`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: createStageRequestDto
    },
      options);
    }
  
/**
 * @summary Выдает стадии услуги по фильтру.
 */
const getApiBillingStages = (
    params?: GetApiBillingStagesParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<StageDtoIReadOnlyCollectionResponseBase>(
      {url: `/Api/Billing/Stages`, method: 'GET',
        params
    },
      options);
    }
  
/**
 * @summary Выдает количество стадий услуг по фильтру.
 */
const getApiBillingStagesCount = (
    params?: GetApiBillingStagesCountParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<UInt32ResponseBase>(
      {url: `/Api/Billing/Stages/count`, method: 'GET',
        params
    },
      options);
    }
  
/**
 * @summary Удаяет стадию услуги.
 */
const deleteApiBillingStagesId = (
    id: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<ResponseBase>(
      {url: `/Api/Billing/Stages/${id}`, method: 'DELETE'
    },
      options);
    }
  
/**
 * @summary Получить привязанные списки услуг для сущностей (напр. коннекторов).
 */
const getApiBillingTargetsServicesLists = (
    params: GetApiBillingTargetsServicesListsParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<TargetsServicesListsDtoIReadOnlyCollectionResponseBase>(
      {url: `/Api/Billing/TargetsServicesLists`, method: 'GET',
        params
    },
      options);
    }
  
/**
 * @summary Создает тариф.
 */
const postApiBillingTariffs = (
    createTariffRequestDto: CreateTariffRequestDto,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<TariffDtoResponseBase>(
      {url: `/Api/Billing/Tariffs`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: createTariffRequestDto
    },
      options);
    }
  
/**
 * @summary Выдает тарифы по фильтру.
 */
const getApiBillingTariffs = (
    params?: GetApiBillingTariffsParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<TariffDtoIReadOnlyCollectionResponseBase>(
      {url: `/Api/Billing/Tariffs`, method: 'GET',
        params
    },
      options);
    }
  
/**
 * @summary Выдает количество тарифов по фильтру.
 */
const getApiBillingTariffsCount = (
    params?: GetApiBillingTariffsCountParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<UInt32ResponseBase>(
      {url: `/Api/Billing/Tariffs/count`, method: 'GET',
        params
    },
      options);
    }
  
/**
 * @summary Удаяет тариф.
 */
const deleteApiBillingTariffsId = (
    id: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<ResponseBase>(
      {url: `/Api/Billing/Tariffs/${id}`, method: 'DELETE'
    },
      options);
    }
  
return {getApiBillingBalances,getApiBillingBalancesCount,getApiBillingBalancesTransactions,getApiBillingBalancesTransactionsCount,postApiBillingBalancesActionCreate,postApiBillingBalancesActionSet,postApiBillingBalancesActionCredit,postApiBillingBalancesActionDebit,postApiBillingBalancesActionReset,postApiBillingBalancesActionFreeze,postApiBillingBalancesActionUnfreeze,getApiBillingBillingsBillingId,getApiBillingBillings,getApiBillingBillingsCount,postApiBillingBillingsActionClose,postApiBillingBillingsActionCloseactive,postApiBillingBillingsActionCalculatelast,getApiBillingReportsUsersPaymentSum,getApiBillingReportsUsersInvoiceSum,postApiBillingActionBegin,postApiBillingActionEnd,postApiBillingActionEndactive,postApiBillingServices,getApiBillingServices,getApiBillingServicesCount,deleteApiBillingServicesId,postApiBillingServicesListLinks,getApiBillingServicesListLinks,getApiBillingServicesListLinksCount,deleteApiBillingServicesListLinksId,getApiBillingServicesListsId,patchApiBillingServicesListsId,deleteApiBillingServicesListsId,getApiBillingServicesLists,postApiBillingServicesLists,getApiBillingServicesListsCount,postApiBillingStages,getApiBillingStages,getApiBillingStagesCount,deleteApiBillingStagesId,getApiBillingTargetsServicesLists,postApiBillingTariffs,getApiBillingTariffs,getApiBillingTariffsCount,deleteApiBillingTariffsId}};
export type GetApiBillingBalancesResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getBillingServiceWebApi>['getApiBillingBalances']>>>
export type GetApiBillingBalancesCountResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getBillingServiceWebApi>['getApiBillingBalancesCount']>>>
export type GetApiBillingBalancesTransactionsResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getBillingServiceWebApi>['getApiBillingBalancesTransactions']>>>
export type GetApiBillingBalancesTransactionsCountResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getBillingServiceWebApi>['getApiBillingBalancesTransactionsCount']>>>
export type PostApiBillingBalancesActionCreateResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getBillingServiceWebApi>['postApiBillingBalancesActionCreate']>>>
export type PostApiBillingBalancesActionSetResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getBillingServiceWebApi>['postApiBillingBalancesActionSet']>>>
export type PostApiBillingBalancesActionCreditResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getBillingServiceWebApi>['postApiBillingBalancesActionCredit']>>>
export type PostApiBillingBalancesActionDebitResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getBillingServiceWebApi>['postApiBillingBalancesActionDebit']>>>
export type PostApiBillingBalancesActionResetResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getBillingServiceWebApi>['postApiBillingBalancesActionReset']>>>
export type PostApiBillingBalancesActionFreezeResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getBillingServiceWebApi>['postApiBillingBalancesActionFreeze']>>>
export type PostApiBillingBalancesActionUnfreezeResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getBillingServiceWebApi>['postApiBillingBalancesActionUnfreeze']>>>
export type GetApiBillingBillingsBillingIdResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getBillingServiceWebApi>['getApiBillingBillingsBillingId']>>>
export type GetApiBillingBillingsResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getBillingServiceWebApi>['getApiBillingBillings']>>>
export type GetApiBillingBillingsCountResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getBillingServiceWebApi>['getApiBillingBillingsCount']>>>
export type PostApiBillingBillingsActionCloseResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getBillingServiceWebApi>['postApiBillingBillingsActionClose']>>>
export type PostApiBillingBillingsActionCloseactiveResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getBillingServiceWebApi>['postApiBillingBillingsActionCloseactive']>>>
export type PostApiBillingBillingsActionCalculatelastResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getBillingServiceWebApi>['postApiBillingBillingsActionCalculatelast']>>>
export type GetApiBillingReportsUsersPaymentSumResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getBillingServiceWebApi>['getApiBillingReportsUsersPaymentSum']>>>
export type GetApiBillingReportsUsersInvoiceSumResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getBillingServiceWebApi>['getApiBillingReportsUsersInvoiceSum']>>>
export type PostApiBillingActionBeginResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getBillingServiceWebApi>['postApiBillingActionBegin']>>>
export type PostApiBillingActionEndResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getBillingServiceWebApi>['postApiBillingActionEnd']>>>
export type PostApiBillingActionEndactiveResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getBillingServiceWebApi>['postApiBillingActionEndactive']>>>
export type PostApiBillingServicesResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getBillingServiceWebApi>['postApiBillingServices']>>>
export type GetApiBillingServicesResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getBillingServiceWebApi>['getApiBillingServices']>>>
export type GetApiBillingServicesCountResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getBillingServiceWebApi>['getApiBillingServicesCount']>>>
export type DeleteApiBillingServicesIdResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getBillingServiceWebApi>['deleteApiBillingServicesId']>>>
export type PostApiBillingServicesListLinksResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getBillingServiceWebApi>['postApiBillingServicesListLinks']>>>
export type GetApiBillingServicesListLinksResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getBillingServiceWebApi>['getApiBillingServicesListLinks']>>>
export type GetApiBillingServicesListLinksCountResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getBillingServiceWebApi>['getApiBillingServicesListLinksCount']>>>
export type DeleteApiBillingServicesListLinksIdResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getBillingServiceWebApi>['deleteApiBillingServicesListLinksId']>>>
export type GetApiBillingServicesListsIdResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getBillingServiceWebApi>['getApiBillingServicesListsId']>>>
export type PatchApiBillingServicesListsIdResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getBillingServiceWebApi>['patchApiBillingServicesListsId']>>>
export type DeleteApiBillingServicesListsIdResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getBillingServiceWebApi>['deleteApiBillingServicesListsId']>>>
export type GetApiBillingServicesListsResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getBillingServiceWebApi>['getApiBillingServicesLists']>>>
export type PostApiBillingServicesListsResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getBillingServiceWebApi>['postApiBillingServicesLists']>>>
export type GetApiBillingServicesListsCountResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getBillingServiceWebApi>['getApiBillingServicesListsCount']>>>
export type PostApiBillingStagesResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getBillingServiceWebApi>['postApiBillingStages']>>>
export type GetApiBillingStagesResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getBillingServiceWebApi>['getApiBillingStages']>>>
export type GetApiBillingStagesCountResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getBillingServiceWebApi>['getApiBillingStagesCount']>>>
export type DeleteApiBillingStagesIdResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getBillingServiceWebApi>['deleteApiBillingStagesId']>>>
export type GetApiBillingTargetsServicesListsResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getBillingServiceWebApi>['getApiBillingTargetsServicesLists']>>>
export type PostApiBillingTariffsResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getBillingServiceWebApi>['postApiBillingTariffs']>>>
export type GetApiBillingTariffsResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getBillingServiceWebApi>['getApiBillingTariffs']>>>
export type GetApiBillingTariffsCountResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getBillingServiceWebApi>['getApiBillingTariffsCount']>>>
export type DeleteApiBillingTariffsIdResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getBillingServiceWebApi>['deleteApiBillingTariffsId']>>>
