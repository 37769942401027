import { useMemo, useState, useCallback } from 'react';

import { ErrorMessage, EmptyData } from 'shared/ui';
import { getServerErrorText } from 'shared/lib';
import {
  useGetChargePoint,
  useGetChargePoints,
} from 'shared/api/services/chargepoint/rtk';

import ChangeConfigurationForm from '../ChangeConfiguration';

const getInitialValues = (
  chargePointName: string,
  ocppConfigLineOptions: Array<{ label: string; value: string }>
): {
  chargePointName: string;
  ocppConfigLine: string;
} => {
  const ocppConfigLine = ocppConfigLineOptions.length
    ? ocppConfigLineOptions[0].value
    : '';

  return {
    chargePointName,
    ocppConfigLine,
  };
};

type Props = Pick<
  React.ComponentProps<typeof ChangeConfigurationForm>,
  'commandType' | 'closeModal' | 'isDisabled'
> & { cpName: string };

export function ConnectedChangeConfigurationForm(props: Props) {
  const [cpNameParam, setcpNameParam] = useState(props.cpName);

  const {
    data: chargePoint,
    isFetching: isChargePointFetching,
    error: chargePointError,
    currentData: currentChargePointData,
  } = useGetChargePoint(cpNameParam, {
    skip: !cpNameParam,
  });

  const {
    data: chargePoints,
    isLoading: isChargePointsLoading,
    error: chargePointsError,
    currentData: currentChargePointsData,
  } = useGetChargePoints({});

  const ocppConfigLineOptions = useMemo(() => {
    if (!chargePoint?.chargePoint.ocppConfig) {
      return [];
    }

    const configObject = JSON.parse(chargePoint.chargePoint.ocppConfig);

    return Object.entries(configObject).map(([key, value], idx) => ({
      label: `${key}=${value}`,
      value: String(idx + 1),
    }));
  }, [chargePoint]);

  const initialValues = useMemo(
    () => getInitialValues(cpNameParam, ocppConfigLineOptions),
    [cpNameParam, ocppConfigLineOptions]
  );

  const handleCpNameParamChange = useCallback(
    (value: string) => setcpNameParam(value),
    []
  );

  const apiResponseError = [chargePoint, chargePoints].find(
    (el) => el && el.statusCode !== 0
  );

  if (apiResponseError) {
    return <ErrorMessage text={apiResponseError.statusDescription} />;
  }

  const error = [chargePointError, chargePointsError].find(
    (err) => err !== undefined
  );

  if (error) {
    return <ErrorMessage text={getServerErrorText(error)} />;
  }

  // if (isSuccess && !chargePoint) {
  //   return <EmptyData />;
  // }

  // const initialValues = getInitialValues(cpNameParam, ocppConfigLineOptions);

  const loading =
    (isChargePointFetching &&
      currentChargePointData?.chargePoint.name !== cpNameParam) ||
    isChargePointsLoading;

  return (
    <ChangeConfigurationForm
      {...props}
      handleCpNameParamChange={handleCpNameParamChange}
      chargePoints={chargePoints?.chargePoints || []}
      ocppConfigOptions={ocppConfigLineOptions}
      initialValues={initialValues}
      isDisabled={
        initialValues.chargePointName === '' ||
        initialValues.ocppConfigLine === ''
      }
      loading={loading}
    />
  );
}
