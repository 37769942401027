import { Col, Spin } from 'antd';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm, FormProvider, useFormContext } from 'react-hook-form';
import { useState } from 'react';

import {
  ButtonsContainer,
  UiSubmitButton,
  UiCancelFormButton,
  FormControlsContainer,
  UiEditFormButton,
} from 'shared/ui';
import { CustomInput } from 'shared/ui/form/custom-input';
import {
  isErrorWithMessage,
  openErrorNotification,
  openSuccessNotification,
} from 'shared/lib';
import { useUpdateHub } from 'shared/api/services/chargepoint/rtk';

import { useAbility } from 'shared/lib/ability/context';

import { FormInput, FormOutput, FormSchema } from '../consts/schema';

import { CardFormWrapper, FormRow } from '../../../../common-styles';

import { EDIT_SUCCESS, EDIT_ERROR } from '../consts';
import { GetHubVm } from 'shared/api/services/chargepoint/rtk/generated/hubs';

type Props = {
  hub: GetHubVm;
};

export function Form({ hub }: Props) {
  const form = useForm<FormInput, void, FormOutput>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      ...hub,
      latitude: String(hub.latitude),
      longitude: String(hub.longitude),
    },
  });

  const [trigger, { isLoading }] = useUpdateHub();

  const [isDisabled, setIsDisabled] = useState(true);

  const enableForm = () => setIsDisabled(false);

  const handleSubmit = form.handleSubmit(async (data) => {
    try {
      const res = await trigger({
        id: hub.id,
        updateHubRequest: data,
      }).unwrap();

      openSuccessNotification(EDIT_SUCCESS);
    } catch (err) {
      const hasErrorMessage = isErrorWithMessage(err);

      const errorText = hasErrorMessage ? err.data.statusMessage : EDIT_ERROR;

      openErrorNotification(errorText);
    }
  });

  return (
    <CardFormWrapper title={hub.name}>
      <FormProvider {...form}>
        <Spin spinning={isLoading}>
          <form onSubmit={handleSubmit}>
            <Form.Fields isDisabled={isDisabled} />
            <Form.Buttons isDisabled={isDisabled} enableForm={enableForm} />
          </form>
        </Spin>
      </FormProvider>
    </CardFormWrapper>
  );
}

type FieldsProps = {
  isDisabled: boolean;
};

Form.Fields = function Fields({ isDisabled }: FieldsProps) {
  const {
    formState: { errors },
  } = useFormContext<FormInput, void, FormOutput>();

  return (
    <>
      <FormRow>
        <Col span={24}>
          <CustomInput<FormInput>
            name="name"
            label="Название"
            required
            disabled={isDisabled}
          />
        </Col>
      </FormRow>
      <FormRow>
        <Col span={24}>
          <CustomInput<FormInput>
            name="address"
            label="Адрес"
            required
            disabled={isDisabled}
          />
        </Col>
      </FormRow>
      <FormRow>
        <Col span={24}>
          <CustomInput<FormInput>
            name="description"
            label="Описание"
            disabled={isDisabled}
          />
        </Col>
      </FormRow>
      <FormRow>
        <Col span={24}>
          <CustomInput<FormInput>
            name="latitude"
            label="Широта"
            disabled={isDisabled}
            required
          />
        </Col>
      </FormRow>
      <FormRow>
        <Col span={24}>
          <CustomInput<FormInput>
            name="longitude"
            label="Долгота"
            disabled={isDisabled}
            required
          />
        </Col>
      </FormRow>
    </>
  );
};

type ButtonsProps = {
  enableForm: () => void;
  isDisabled: boolean;
};

Form.Buttons = function Buttons({ enableForm, isDisabled }: ButtonsProps) {
  const ability = useAbility();

  if (!ability.can('Write', 'Hub')) {
    return null;
  }

  return (
    <FormControlsContainer>
      <ButtonsContainer>
        {isDisabled ? (
          <UiEditFormButton onClick={enableForm} />
        ) : (
          <UiSubmitButton />
        )}
        <UiCancelFormButton />
      </ButtonsContainer>
    </FormControlsContainer>
  );
};
