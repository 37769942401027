import { ADD_PREFIX, PUSH_NOTIFICATIONS } from '../names';

export const PUSH_NOTIFICATION = ':pushNotificationMessageId';

const ROOT = `/${PUSH_NOTIFICATIONS}`;

export const PUSH_NOTIFICATION_ROUTES = {
  PUSH_NOTIFICATIONS: ROOT,
  PUSH_NOTIFICATION: `${ROOT}/${PUSH_NOTIFICATION}`,
  ADD_PUSH_NOTIFICATION: `${ROOT}/${ADD_PREFIX}`,
};
