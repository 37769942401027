import { baseApi as api } from '../../../../base-new/base-api';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    postApiFiscalActionsFiscalize: build.mutation<
      PostApiFiscalActionsFiscalizeApiResponse,
      PostApiFiscalActionsFiscalizeApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Fiscal/Actions/fiscalize`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    getApiFiscalChecksById: build.query<
      GetApiFiscalChecksByIdApiResponse,
      GetApiFiscalChecksByIdApiArg
    >({
      query: (queryArg) => ({ url: `/Api/Fiscal/Checks/${queryArg}` }),
    }),
    getApiFiscalChecks: build.query<
      GetApiFiscalChecksApiResponse,
      GetApiFiscalChecksApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Fiscal/Checks`,
        params: { requestDto: queryArg },
      }),
    }),
    getApiFiscalChecksCount: build.query<
      GetApiFiscalChecksCountApiResponse,
      GetApiFiscalChecksCountApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Fiscal/Checks/count`,
        params: { requestDto: queryArg },
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type PostApiFiscalActionsFiscalizeApiResponse =
  /** status 200 Success */ ResponseBase;
export type PostApiFiscalActionsFiscalizeApiArg = FiscalizeRequestDto;
export type GetApiFiscalChecksByIdApiResponse =
  /** status 200 Success */ CheckDtoResponseBase;
export type GetApiFiscalChecksByIdApiArg = string;
export type GetApiFiscalChecksApiResponse =
  /** status 200 Success */ CheckDtoIReadOnlyCollectionResponseBase;
export type GetApiFiscalChecksApiArg = GetChecksByFilterRequestDto;
export type GetApiFiscalChecksCountApiResponse =
  /** status 200 Success */ UInt32ResponseBase;
export type GetApiFiscalChecksCountApiArg = GetChecksCountByFilterRequestDto;
export type Status = 'SUCCESS' | 'ERROR' | 'PART_UNAVAILABLE';
export type UnavailableServices =
  | 'CHARGE_POINT_SERVICE'
  | 'CP_WS_CONTROLLER'
  | 'CP_SOAP_CONTROLLER'
  | 'BILLING_SERVICE'
  | 'PAYMENT_SERVICE'
  | 'FISCAL_SERVICE'
  | 'SBER_PAY'
  | 'ARMENIA_PAY'
  | 'E_PAY'
  | 'FISCAL_RUSSIA'
  | 'FISCAL_ARMENIA'
  | 'BONUS_SERVICE'
  | 'DISCOUNT_SERVICE'
  | 'MOBILE_API_BFF'
  | 'USER_SERVICE'
  | 'RESERVE_SERVICE'
  | 'OCPI_SERVICE'
  | 'SMS_SERVICE'
  | 'PUSH_NOTIFICATION_SERVICE'
  | 'SYSTEM_INFROMATION_SERVICE'
  | 'IDENTITY_SERVICE'
  | 'ANALYTICS_SERVICE';
export type ResponseBase = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
};
export type UserInfo = {
  email?: string | null;
  phoneNumber?: string | null;
};
export type AgentInfo = {
  inn: string;
  legalName: string;
  phoneNumber: string;
};
export type GoodDto = {
  name: string;
  amount: number;
};
export type FiscalizeRequestDto = {
  billingId: string;
  totalAmount: number;
  countryId: string;
  userInfo: UserInfo;
  agentInfo?: AgentInfo;
  goods: GoodDto[];
};
export type CheckStatus =
  | 'UNDEFINED'
  | 'ERROR'
  | 'TIME_OUT_ERROR'
  | 'IN_PROCESS'
  | 'FISCALIZED';
export type CheckErrorReason =
  | 'UNDEFINED'
  | 'INNER_FISCAL_SERVICE_ERROR'
  | 'FISCAL_SERVICE_IS_UNAVAILABLE'
  | 'FISCAL_RESPONSE_ERROR'
  | 'TIMEOUT_ERROR'
  | 'UNKNOWN_ERROR';
export type CheckDto = {
  id: string;
  billingId: string;
  countryId: string;
  docNumber: string;
  totalAmount: number;
  email?: string | null;
  phoneNumber?: string | null;
  agentInn?: string | null;
  agentLegalName?: string | null;
  agentPhoneNumber?: string | null;
  status: CheckStatus;
  errorReason?: CheckErrorReason;
  errorText?: string | null;
  responseFiscalService?: string | null;
  retryErrorFiscalizeCount: number;
  receiptUrl?: string | null;
  fiscalResponse?: string | null;
  fiscalDate?: string | null;
  createdDate: string;
  lastUpdate: string;
};
export type CheckDtoResponseBase = {
  data?: CheckDto;
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
};
export type CheckDtoIReadOnlyCollectionResponseBase = {
  data?: CheckDto[] | null;
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
};
export type GetChecksByFilterRequestDto = object;
export type UInt32ResponseBase = {
  data?: number;
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
};
export type GetChecksCountByFilterRequestDto = object;
export const {
  usePostApiFiscalActionsFiscalizeMutation,
  useGetApiFiscalChecksByIdQuery,
  useGetApiFiscalChecksQuery,
  useGetApiFiscalChecksCountQuery,
} = injectedRtkApi;
