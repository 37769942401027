import { z } from 'zod';

import { postApiChargePointChargePointsChargePointsIdConnectorBody } from 'shared/api/services/chargepoint/orval/zod/schemas';
import { requiredNumberInString } from 'shared/lib/schema';
import {
  ConnectorFormat,
  ConnectorStatus,
  ConnectorType,
} from 'shared/api/services/chargepoint/rtk/generated/charge-points';

const type: z.ZodType<ConnectorType> = z.enum([
  'SCHUKO',
  'TYPE2',
  'CCS2',
  'CHADEMO',
  'GBTDC',
  'GBTAC',
  'CCS1',
  'TYPE1',
  'TESLA',
]);

const format: z.ZodType<ConnectorFormat> = z.enum(['SOCKET', 'CABLE']);

const status: z.ZodType<ConnectorStatus> = z.enum([
  'UNDEFINED',
  'AVAILABLE',
  'PREPARING',
  'CHARGING',
  'SUSPENDED_EVSE',
  'SUSPENDED_EV',
  'FINISHING',
  'RESERVED',
  'UNAVAILABLE',
  'FAULTED',
]);

export const FormSchema =
  postApiChargePointChargePointsChargePointsIdConnectorBody
    .omit({
      type: true,
      format: true,
      status: true,
      maxPower: true,
      maxAmperage: true,
      maxVoltage: true,
      innerConnectorId: true,
    })
    .extend({
      type: type.nullish().transform((val, ctx) => {
        if (val === undefined || val === null) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Required field',
          });

          return z.NEVER;
        }

        return val;
      }),
      format: format.nullish().transform((val, ctx) => {
        if (val === undefined || val === null) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Required field',
          });

          return z.NEVER;
        }

        return val;
      }),
      status: status.nullish().transform((val, ctx) => {
        if (val === undefined || val === null) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Required field',
          });

          return z.NEVER;
        }

        return val;
      }),
      maxPower: requiredNumberInString.pipe(z.number().min(0)),
      maxAmperage: requiredNumberInString.pipe(z.number().min(0)),
      maxVoltage: requiredNumberInString.pipe(z.number().min(0)),
      innerConnectorId: requiredNumberInString.pipe(z.number()),
    });

export type FormInput = z.input<typeof FormSchema>;
export type FormOutput = z.output<typeof FormSchema>;

export const DEFAULT_VALUES: FormInput = {
  liter: '',
};
