import useWindowFocus from 'use-window-focus';

import { useGetConnectorsMetrics } from 'shared/api/services/chargepoint/rtk/enhanced/metrics';
import {
  ChargePointStatus,
  ConnectorStatus,
} from 'shared/api/services/chargepoint/rtk/generated/metrics';
import { ErrorMessage } from 'shared/ui';
import { getServerErrorText } from 'shared/lib';

import { METRICS_POLLING_INTERVAL } from 'entities/charge-point/consts';

import { MetricsCard } from './card';

type Props = {
  chargePointStatuses: ChargePointStatus[];
  companyIds: string[];
  isTest: boolean;
  connectorStatuses: ConnectorStatus[];
};

export function MetricsContainer({
  chargePointStatuses,
  companyIds,
  isTest,
  connectorStatuses,
}: Props) {
  const isWindowFocused = useWindowFocus();

  const { data, isFetching, error, currentData } = useGetConnectorsMetrics(
    {
      chargePointStatuses,
      companyIds,
      isTest,
      connectorStatuses,
    },
    {
      pollingInterval: isWindowFocused ? METRICS_POLLING_INTERVAL : undefined,
    }
  );

  if (error) {
    return <ErrorMessage text={getServerErrorText(error)} />;
  }

  if (data && data.status === 'ERROR') {
    return <ErrorMessage text={data.statusMessage} />;
  }

  return (
    <MetricsCard
      loading={isFetching && !currentData}
      metricsData={data?.data || { count: 0, of: 0 }}
    />
  );
}
