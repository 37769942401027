import { baseApi as api } from '../../../../base-new/base-api';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getApiInformationSupportById: build.query<
      GetApiInformationSupportByIdApiResponse,
      GetApiInformationSupportByIdApiArg
    >({
      query: (queryArg) => ({ url: `/Api/Information/Support/${queryArg}` }),
    }),
    patchApiInformationSupportById: build.mutation<
      PatchApiInformationSupportByIdApiResponse,
      PatchApiInformationSupportByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Information/Support/${queryArg.id}`,
        method: 'PATCH',
        body: queryArg.updateSupportRequest,
      }),
    }),
    deleteApiInformationSupportById: build.mutation<
      DeleteApiInformationSupportByIdApiResponse,
      DeleteApiInformationSupportByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Information/Support/${queryArg}`,
        method: 'DELETE',
      }),
    }),
    getApiInformationSupport: build.query<
      GetApiInformationSupportApiResponse,
      GetApiInformationSupportApiArg
    >({
      query: () => ({ url: `/Api/Information/Support` }),
    }),
    postApiInformationSupport: build.mutation<
      PostApiInformationSupportApiResponse,
      PostApiInformationSupportApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Information/Support`,
        method: 'POST',
        body: queryArg,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type GetApiInformationSupportByIdApiResponse =
  /** status 200 Success */ GetSupportVmBaseResponse;
export type GetApiInformationSupportByIdApiArg = /** Идентификатор */ string;
export type PatchApiInformationSupportByIdApiResponse =
  /** status 200 Success */ UpdateSupportVmBaseResponse;
export type PatchApiInformationSupportByIdApiArg = {
  /** Идентификатор саппорта */
  id: string;
  /** Параметры */
  updateSupportRequest: UpdateSupportRequest;
};
export type DeleteApiInformationSupportByIdApiResponse =
  /** status 200 Success */ BaseResponse;
export type DeleteApiInformationSupportByIdApiArg = /** Идентификатор */ string;
export type GetApiInformationSupportApiResponse =
  /** status 200 Success */ GetSupportVmIReadOnlyCollectionBaseResponse;
export type GetApiInformationSupportApiArg = void;
export type PostApiInformationSupportApiResponse =
  /** status 200 Success */ AddSupportVmBaseResponse;
export type PostApiInformationSupportApiArg = AddSupportRequest;
export type Status = 'SUCCESS' | 'ERROR' | 'PART_UNAVAILABLE';
export type UnavailableServices =
  | 'CHARGE_POINT_SERVICE'
  | 'CP_WS_CONTROLLER'
  | 'CP_SOAP_CONTROLLER'
  | 'BILLING_SERVICE'
  | 'PAYMENT_SERVICE'
  | 'FISCAL_SERVICE'
  | 'SBER_PAY'
  | 'ARMENIA_PAY'
  | 'E_PAY'
  | 'FISCAL_RUSSIA'
  | 'FISCAL_ARMENIA'
  | 'BONUS_SERVICE'
  | 'DISCOUNT_SERVICE'
  | 'MOBILE_API_BFF'
  | 'USER_SERVICE'
  | 'RESERVE_SERVICE'
  | 'OCPI_SERVICE'
  | 'SMS_SERVICE'
  | 'PUSH_NOTIFICATION_SERVICE'
  | 'SYSTEM_INFROMATION_SERVICE'
  | 'IDENTITY_SERVICE'
  | 'ANALYTICS_SERVICE';
export type GetSupportVm = {
  id: string;
  userAgreementUrl: string;
  urlFaq: string;
  supportPhone: string;
  telegrammBot?: string | null;
  countryId: string;
};
export type GetSupportVmBaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: GetSupportVm;
};
export type UpdateSupportVm = {
  id: string;
  userAgreementUrl: string;
  urlFaq: string;
  supportPhone: string;
  telegrammBot: string;
  countryId: string;
};
export type UpdateSupportVmBaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: UpdateSupportVm;
};
export type UpdateSupportRequest = {
  userAgreementUrl?: string | null;
  urlFaq?: string | null;
  supportPhone?: string | null;
  telegrammBot?: string | null;
};
export type BaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
};
export type GetSupportVmIReadOnlyCollectionBaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: GetSupportVm[] | null;
};
export type AddSupportVm = {
  id: string;
  userAgreementUrl: string;
  urlFaq: string;
  supportPhone: string;
  telegrammBot?: string | null;
  countryId: string;
};
export type AddSupportVmBaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: AddSupportVm;
};
export type AddSupportRequest = {
  userAgreementUrl: string;
  urlFaq: string;
  supportPhone: string;
  telegrammBot?: string | null;
  countryId: string;
};
export const {
  useGetApiInformationSupportByIdQuery,
  usePatchApiInformationSupportByIdMutation,
  useDeleteApiInformationSupportByIdMutation,
  useGetApiInformationSupportQuery,
  usePostApiInformationSupportMutation,
} = injectedRtkApi;
