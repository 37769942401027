import { useNavigate } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import { Col, Spin } from 'antd';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { useState } from 'react';

import { CustomSelect } from 'shared/ui/form/custom-select';
import { CustomInput } from 'shared/ui/form/custom-input';
import { useUpdateChargePoint } from 'shared/api/services/chargepoint/rtk';
import { CHARGE_POINT_ROUTES } from 'shared/consts/routes/charge-point';
import {
  isErrorWithMessage,
  openErrorNotification,
  openSuccessNotification,
} from 'shared/lib';
import { GetGroupVm } from 'shared/api/services/chargepoint/rtk/generated/groups';
import { GetHubVm } from 'shared/api/services/chargepoint/rtk/generated/hubs';
import { CompanyDto } from 'shared/api/services/company/rtk/generated';
import { CustomTextarea } from 'shared/ui/form/custom-textarea';
import { CustomCheckbox } from 'shared/ui/form/custom-checkbox';
import {
  FormControlsContainer,
  UiCancelFormButton,
  UiSubmitButton,
  ButtonsContainer,
  UiEditFormButton,
} from 'shared/ui';
import { GetChargePointVm } from 'shared/api/services/chargepoint/rtk/generated/charge-points';

import { buildOcppConfigObject } from 'entities/charge-point/lib/build-ocpp-config-object';
import {
  CHARGE_POINT_PROTOCOL,
  CHARGE_POINT_SPEED_TYPE,
  CHARGE_POINT_STATUS,
  COUNTRY_CODE,
  INFRASTRUCTURE_INFO,
} from 'entities/charge-point/consts';
import { getOcppConfigFromString } from 'entities/charge-point/lib/get-ocpp-config-string';

import { FormOutput, FormInput, FormSchema } from '../../consts/schema';
import { EDIT_ERROR, EDIT_SUCCESS } from '../../consts';

import { FormRow, CardFormWrapper } from '../../../../../common-styles';
import { StyledTitle } from './styles';

type Props = {
  groups: GetGroupVm[];
  hubs: GetHubVm[];
  companies: CompanyDto[];
  chargePoint: GetChargePointVm;
};

const getInfrastructureDefaultValue = (
  infrastructureInfo: string | null | undefined
): string[] => {
  if (!infrastructureInfo) {
    return [];
  }

  try {
    const o = JSON.parse(infrastructureInfo);

    return Object.keys(o).filter((key) => o[key] === 'true');
  } catch (e) {
    return [];
  }
};

const getDefaultValues = (chargePoint: GetChargePointVm): FormInput => {
  const { latitude, longitude, ocppConfig, infrastructureInfo, ...rest } =
    chargePoint;

  return {
    ...rest,
    coordinates: `${latitude}, ${longitude}`,
    ocppConfig: ocppConfig ? getOcppConfigFromString(ocppConfig) : '',
    infrastructureInfo: getInfrastructureDefaultValue(infrastructureInfo),
  };
};

export function Form({ groups, hubs, companies, chargePoint }: Props) {
  const form = useForm<FormInput, void, FormOutput>({
    resolver: zodResolver(FormSchema),
    defaultValues: getDefaultValues(chargePoint),
  });

  const [trigger, { isLoading }] = useUpdateChargePoint();

  const [isDisabled, setIsDisabled] = useState(true);

  const enableForm = () => setIsDisabled(false);

  const navigate = useNavigate();

  const handleSubmit = form.handleSubmit(async (data) => {
    const { coordinates, ocppConfig, infrastructureInfo, ...rest } = data;

    const [latitude, longitude] = coordinates.split(',');

    try {
      const req = {
        ...rest,
        ocppConfig: buildOcppConfigObject(ocppConfig),
        latitude: Number(latitude.trim()),
        longitude: Number(longitude.trim()),
        infrastructureInfo: infrastructureInfo.length
          ? JSON.stringify(
              infrastructureInfo.reduce((acc, el) => {
                acc[el] = 'true';

                return acc;
              }, {} as Record<string, string>)
            )
          : null,
      };

      const res = await trigger({
        id: chargePoint.id,
        updateChargePointRequest: req,
      }).unwrap();

      openSuccessNotification(EDIT_SUCCESS);

      if (res.data) {
        const { group, id } = res.data;

        navigate(
          `${CHARGE_POINT_ROUTES.CHARGE_POINTS}/${group?.id ?? null}/${id}`
        );
      }
    } catch (err) {
      const hasErrorMessage = isErrorWithMessage(err);

      const errorText = hasErrorMessage ? err.data.statusMessage : EDIT_ERROR;

      openErrorNotification(errorText);
    }
  });

  return (
    <CardFormWrapper>
      <FormProvider {...form}>
        <Spin spinning={isLoading}>
          <form onSubmit={handleSubmit}>
            <Form.Fields
              groups={groups}
              hubs={hubs}
              companies={companies}
              isDisabled={isDisabled}
            />
            <Form.Buttons isDisabled={isDisabled} enableForm={enableForm} />
          </form>
        </Spin>
      </FormProvider>
    </CardFormWrapper>
  );
}

type FieldsProps = {
  groups: GetGroupVm[];
  hubs: GetHubVm[];
  companies: CompanyDto[];
  isDisabled: boolean;
};

Form.Fields = function Fields({
  companies,
  groups,
  hubs,
  isDisabled,
}: FieldsProps) {
  const {
    formState: { errors },
  } = useFormContext<FormInput, void, FormOutput>();

  return (
    <>
      <FormRow gutter={40}>
        <Col span={12}>
          <StyledTitle>О станции</StyledTitle>
          <FormRow gutter={20}>
            <Col span={12}>
              <CustomInput<FormInput>
                name="name"
                label="Название"
                required
                disabled={isDisabled}
              />
            </Col>
            <Col span={12}>
              <CustomInput<FormInput>
                name="coordinates"
                label="Координаты"
                required
                disabled={isDisabled}
              />
            </Col>
          </FormRow>
          <FormRow gutter={20}>
            <Col span={12}>
              <CustomInput<FormInput>
                name="address"
                label="Адрес"
                required
                disabled={isDisabled}
              />
            </Col>
            <Col span={12}>
              <CustomInput<FormInput>
                name="maxPower"
                label="Максимальная мощность"
                required
                disabled={isDisabled}
              />
            </Col>
          </FormRow>
          <FormRow gutter={20}>
            <Col span={12}>
              <CustomInput<FormInput>
                name="city"
                label="Город"
                required
                disabled={isDisabled}
              />
            </Col>
            <Col span={12}>
              <CustomSelect<FormInput>
                name="status"
                label="Статус"
                options={Object.entries(CHARGE_POINT_STATUS).map((entry) => {
                  const [key, label] = entry;

                  return {
                    value: key,
                    label,
                  };
                })}
                required
                disabled={isDisabled}
              />
            </Col>
          </FormRow>
          <FormRow gutter={20}>
            <Col span={12}>
              <CustomSelect<FormInput>
                name="groupId"
                label="Группа"
                options={groups.map(({ id, name }) => ({
                  value: id,
                  label: name,
                }))}
                allowClear
                required
                disabled={isDisabled}
              />
            </Col>
            <Col span={12}>
              <CustomSelect<FormInput>
                name="companyId"
                label="Владелец"
                options={companies.map(({ id, name }) => ({
                  value: id,
                  label: name,
                }))}
                allowClear
                required
                disabled={isDisabled}
              />
            </Col>
          </FormRow>
          <FormRow gutter={20}>
            <Col span={12}>
              <CustomSelect<FormInput>
                name="hubId"
                label="Хаб"
                options={hubs.map(({ id, name }) => ({
                  value: id,
                  label: name,
                }))}
                allowClear
                disabled={isDisabled}
              />
            </Col>
            <Col span={12}>
              <CustomSelect<FormInput>
                name=""
                label="Тариф"
                options={hubs.map(({ id, name }) => ({
                  value: id,
                  label: name,
                }))}
                allowClear
                disabled
              />
            </Col>
          </FormRow>
          <FormRow gutter={20}>
            <Col span={12}>
              <CustomSelect<FormInput>
                name="infrastructureInfo"
                label="Что рядом"
                options={Object.entries(INFRASTRUCTURE_INFO).map((entry) => {
                  const [key, label] = entry;

                  return {
                    value: key,
                    label,
                  };
                })}
                mode="multiple"
                disabled={isDisabled}
              />
            </Col>
            <Col span={12}>
              <CustomInput<FormInput>
                name=""
                label="Макс. % оплаты баллами"
                disabled
              />
            </Col>
          </FormRow>
          <FormRow gutter={20}>
            <Col span={12}>
              <CustomInput<FormInput>
                name="workingTime"
                label="Время работы"
                disabled={isDisabled}
              />
            </Col>
            <Col span={12}>
              <CustomInput<FormInput>
                name=""
                label="Доступны скидки"
                disabled
              />
            </Col>
          </FormRow>
          <FormRow gutter={20}>
            <Col span={12}>
              <CustomTextarea<FormInput>
                name="publicDescription"
                label="Публичное описание"
                disabled={isDisabled}
              />
            </Col>
            <Col span={12}>
              <CustomSelect<FormInput>
                name="countryCode"
                label="Код страны"
                options={Object.entries(COUNTRY_CODE).map((entry) => {
                  const [key, label] = entry;

                  return {
                    value: key,
                    label,
                  };
                })}
                required
                disabled={isDisabled}
              />
            </Col>
          </FormRow>
        </Col>

        <Col span={12}>
          <StyledTitle>Техническая информация</StyledTitle>

          <FormRow gutter={20}>
            <Col span={12}>
              <CustomSelect<FormInput>
                name="speedType"
                label="Тип станции"
                options={Object.entries(CHARGE_POINT_SPEED_TYPE).map(
                  (entry) => {
                    const [key, label] = entry;

                    return {
                      value: key,
                      label,
                    };
                  }
                )}
                required
                disabled={isDisabled}
              />
            </Col>
            <Col span={12}>
              <CustomInput<FormInput>
                name="simNumber"
                label="Номер SIM"
                disabled={isDisabled}
              />
            </Col>
          </FormRow>

          <FormRow gutter={20}>
            <Col span={12}>
              <CustomInput<FormInput>
                name="manufacturer"
                label="Производитель"
                disabled={isDisabled}
              />
            </Col>
            <Col span={12}>
              <CustomInput<FormInput>
                name="commentary"
                label="Внутреннее описание"
                disabled={isDisabled}
              />
            </Col>
          </FormRow>

          <FormRow gutter={20}>
            <Col span={12}>
              <CustomInput<FormInput>
                name="number"
                label="Cерийный номер"
                disabled={isDisabled}
              />
            </Col>
            <Col span={12}>
              <CustomInput<FormInput>
                name="chargeBoxIdentity"
                label="СhargeBoxIdentity"
                required
                disabled={isDisabled}
              />
            </Col>
          </FormRow>

          <FormRow gutter={20}>
            <Col span={12}>
              <CustomSelect<FormInput>
                name="chargePointProtocol"
                label="Протокол ЭЗС"
                options={Object.entries(CHARGE_POINT_PROTOCOL).map((entry) => {
                  const [key, label] = entry;

                  return {
                    value: key,
                    label,
                  };
                })}
                required
                disabled={isDisabled}
              />
            </Col>
            <Col span={12}>
              <CustomInput<FormInput>
                name="ipAddress"
                label="IP-адрес"
                required
                disabled={isDisabled}
              />
            </Col>
          </FormRow>
          <FormRow gutter={20}>
            <Col span={12}>
              <CustomInput<FormInput>
                name="softwareRevision"
                label="Версия ПО"
                disabled={isDisabled}
              />
            </Col>
            <Col span={12}>
              <CustomInput<FormInput>
                name="userLocationCheckDistance"
                label="Макс. расстояние до пользователя"
                disabled={isDisabled}
              />
            </Col>
          </FormRow>

          <FormRow gutter={20}>
            <Col span={12}>
              <CustomTextarea<FormInput>
                name="ocppConfig"
                label="OCPP конфиг"
                disabled={isDisabled}
              />
            </Col>
            <Col span={12}>
              <CustomTextarea<FormInput>
                name="warningText"
                label="Аварийное сообщение в приложении"
                disabled={isDisabled}
              />
            </Col>
          </FormRow>

          <FormRow gutter={20}>
            <Col>
              <CustomCheckbox<FormInput>
                name="isVisible"
                label="Показывать на карте"
                disabled={isDisabled}
              />
            </Col>
          </FormRow>
          <FormRow gutter={20}>
            <Col>
              <CustomCheckbox<FormInput>
                name="isTestCp"
                label="Тестовая"
                disabled={isDisabled}
              />
            </Col>
          </FormRow>
          <FormRow gutter={20}>
            <Col>
              <CustomCheckbox<FormInput>
                name="multiconnectorSupport"
                label="Поддержка мультиконнектора (не убирать без причины)"
                disabled={isDisabled}
              />
            </Col>
          </FormRow>
        </Col>
      </FormRow>
    </>
  );
};

type ButtonsProps = {
  enableForm: () => void;
  isDisabled: boolean;
};

Form.Buttons = function Buttons({ enableForm, isDisabled }: ButtonsProps) {
  return (
    <FormControlsContainer>
      <ButtonsContainer>
        {isDisabled ? (
          <UiEditFormButton onClick={enableForm} />
        ) : (
          <UiSubmitButton />
        )}
        <UiCancelFormButton />
      </ButtonsContainer>
    </FormControlsContainer>
  );
};
