import { ErrorMessage, EmptyData } from 'shared/ui';
import { getServerErrorText } from 'shared/lib';

import { Filters } from '../full-filters';
import { useGetCompanies } from 'shared/api/services/company/rtk/enhanced';

type Props = {
  closeModal: () => void;
};

export function ConnectedChargePointFilters({ closeModal }: Props) {
  const { data, isFetching, error } = useGetCompanies(false);

  if (isFetching) {
    return <div style={{ padding: '10px' }}>Loading...</div>;
  }

  if (error) {
    return <ErrorMessage text={getServerErrorText(error)} />;
  }

  if (data && data.status === 'ERROR') {
    return <ErrorMessage text={data.statusMessage} />;
  }

  if (!data) {
    return <EmptyData />;
  }

  return <Filters ownersList={data.owners} closeModal={closeModal} />;
}
