import { enhancedApi as api } from '../generated/sessions';

export const enhancedApi = api.enhanceEndpoints({
  endpoints: {
    getApiChargePointSessions: (endpoint) => {
      endpoint.providesTags = [{ type: 'Sessions', id: 'LIST' }];
    },
  },
});

export const {
  useGetApiChargePointSessionsQuery: useGetSessions,
  useGetApiChargePointSessionsCountQuery: useGetSessionsCount,
  useGetApiChargePointSessionsByIdQuery: useGetSession,
} = enhancedApi;
