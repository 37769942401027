import { Col, Spin } from 'antd';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm, FormProvider, useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router';

import {
  ButtonsContainer,
  UiSubmitButton,
  UiCancelFormButton,
  FormControlsContainer,
} from 'shared/ui';
import { CustomInput } from 'shared/ui/form/custom-input';
import {
  isErrorWithMessage,
  openErrorNotification,
  openSuccessNotification,
} from 'shared/lib';

import { CustomCheckbox } from 'shared/ui/form/custom-checkbox';
import { useAddUser } from 'shared/api/services/user/rtk/enhanced';
import { UiDivider } from 'shared/ui/ui-kit';
import { CustomSelect } from 'shared/ui/form/custom-select';
import { USER_ROUTES } from 'shared/consts/routes/user';

import { SEX } from 'entities/user';

import { FORM_TITLE, ADD_ERROR, ADD_SUCCESS } from '../consts';

import { CardFormWrapper, FormRow } from '../../../../common-styles';

import {
  DEFAULT_VALUES,
  FormInput,
  FormOutput,
  FormSchema,
} from '../consts/schema';
import { GetCountryVm } from 'shared/api/services/information/rtk/generated/countries';
import { GroupDto } from 'shared/api/services/user/rtk/generated';

type Props = {
  countries: GetCountryVm[];
  userGroups: GroupDto[];
};

export function Form(props: Props) {
  const form = useForm<FormInput, void, FormOutput>({
    resolver: zodResolver(FormSchema),
    defaultValues: { ...DEFAULT_VALUES },
  });

  const [trigger, { isLoading }] = useAddUser();

  const navigate = useNavigate();

  const handleSubmit = form.handleSubmit(async (data) => {
    try {
      const res = await trigger(data).unwrap();

      openSuccessNotification(ADD_SUCCESS);

      navigate(`${USER_ROUTES.PERSONS_LIST}`);
    } catch (err) {
      const hasErrorMessage = isErrorWithMessage(err);

      const errorText = hasErrorMessage ? err.data.statusMessage : ADD_ERROR;

      openErrorNotification(errorText);
    }
  });

  return (
    <CardFormWrapper title={FORM_TITLE}>
      <FormProvider {...form}>
        <Spin spinning={isLoading}>
          <form onSubmit={handleSubmit}>
            <Form.Fields {...props} />
            <Form.Buttons />
          </form>
        </Spin>
      </FormProvider>
    </CardFormWrapper>
  );
}

type FieldsProps = {
  countries: GetCountryVm[];
  userGroups: GroupDto[];
};

Form.Fields = function Fields({ countries, userGroups }: FieldsProps) {
  const {
    formState: { errors },
  } = useFormContext<FormInput, void, FormOutput>();

  return (
    <>
      <FormRow gutter={10}>
        <Col span={6}>
          <CustomInput<FormInput>
            name="login"
            label="Номер телефона"
            required
          />
        </Col>
        <Col span={6}>
          <CustomInput<FormInput> name="email" label="Email" />
        </Col>
      </FormRow>

      <FormRow gutter={10}>
        <Col span={6}>
          <CustomInput<FormInput> name="lastName" label="Фамилия" />
        </Col>
        <Col span={6}>
          <CustomInput<FormInput> name="firstName" label="Имя" />
        </Col>
        <Col span={6}>
          <CustomInput<FormInput> name="middleName" label="Отчество" />
        </Col>
      </FormRow>

      <FormRow gutter={10}>
        <Col span={6}>
          <CustomInput<FormInput> name="birthDate" label="Дата рождения" />
        </Col>
        <Col span={6}>
          <CustomSelect<FormInput>
            name="sex"
            label="Пол"
            options={Object.entries(SEX).map((entry) => {
              const [key, label] = entry;

              return { value: key, label };
            })}
          />
        </Col>
        <Col span={6}>
          <CustomSelect<FormInput>
            name="countryId"
            options={countries.map(({ id, name }) => ({
              value: id,
              label: name,
            }))}
            label="Страна"
            required
          />
        </Col>
      </FormRow>

      <FormRow gutter={10}>
        <Col span={6}>
          <CustomSelect<FormInput>
            name="groupId"
            options={userGroups.map(({ id, name }) => ({
              value: id,
              label: name,
            }))}
            label="Добавить в группу"
            allowClear
          />
        </Col>
      </FormRow>

      <UiDivider />

      <FormRow gutter={10}>
        {/* <Col span={6}>
          <FormItem
            label="Задолженность"
            name="clientsDebt"
            rules={[
              VALIDATION_RULE.NULL_OR_POSITIVE_NUMBER,
              VALIDATION_RULE.REQUIRED,
            ]}
          >
            <UiInput disabled={isFormDisabled} />
          </FormItem>
        </Col> */}
      </FormRow>

      <UiDivider />

      <FormRow gutter={10}>
        <Col span={12}>
          <CustomInput<FormInput>
            name="notesRelatedWithUser"
            label="Комментарий"
          />
        </Col>
      </FormRow>

      <UiDivider />

      <FormRow style={{ gap: '10px' }}>
        <Col>
          <CustomCheckbox<FormInput>
            name="userAgreementAccepted"
            label="Флаг принятия условий ПС"
          />
        </Col>
        <Col>
          <CustomCheckbox<FormInput> name="isDebug" label="isDebug" />
        </Col>
      </FormRow>
    </>
  );
};

Form.Buttons = function Buttons() {
  return (
    <FormControlsContainer>
      <ButtonsContainer>
        <UiSubmitButton />
        <UiCancelFormButton />
      </ButtonsContainer>
    </FormControlsContainer>
  );
};
