import { ConsumedEnergyMetricsGraph } from '../consumed-energy';
import { SuccessfulSessionsMetricsGraph } from '../successful-sessions';

import { GraphsContainer } from './styles';

export function MetricsGraphs() {
  return (
    <GraphsContainer>
      <ConsumedEnergyMetricsGraph />
      <SuccessfulSessionsMetricsGraph />
    </GraphsContainer>
  );
}
