import { baseApi as api } from '../../../../base-new/base-api';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    postApiReserveActionReserveByReserveId: build.mutation<
      PostApiReserveActionReserveByReserveIdApiResponse,
      PostApiReserveActionReserveByReserveIdApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Reserve/Action/Reserve/${queryArg.reserveId}`,
        method: 'POST',
        body: queryArg.addReserveRequest,
      }),
    }),
    postApiReserveActionResetByReserveId: build.mutation<
      PostApiReserveActionResetByReserveIdApiResponse,
      PostApiReserveActionResetByReserveIdApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Reserve/Action/Reset/${queryArg.reserveId}`,
        method: 'POST',
        params: { userId: queryArg.userId },
      }),
    }),
    postApiReserveActionExtendByReserveId: build.mutation<
      PostApiReserveActionExtendByReserveIdApiResponse,
      PostApiReserveActionExtendByReserveIdApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Reserve/Action/Extend/${queryArg.reserveId}`,
        method: 'POST',
        params: { TimeSpan: queryArg.timeSpan, UserId: queryArg.userId },
      }),
    }),
    getApiReserveLocks: build.query<
      GetApiReserveLocksApiResponse,
      GetApiReserveLocksApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Reserve/Locks`,
        params: {
          UserId: queryArg.userId,
          ReserveIds: queryArg.reserveIds,
          ReserveName: queryArg.reserveName,
          IsActive: queryArg.isActive,
          Limit: queryArg.limit,
          Offset: queryArg.offset,
        },
      }),
    }),
    getApiReserveLocksById: build.query<
      GetApiReserveLocksByIdApiResponse,
      GetApiReserveLocksByIdApiArg
    >({
      query: (queryArg) => ({ url: `/Api/Reserve/Locks/${queryArg}` }),
    }),
    getApiReserveLocksCount: build.query<
      GetApiReserveLocksCountApiResponse,
      GetApiReserveLocksCountApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Reserve/Locks/Count`,
        params: {
          UserId: queryArg.userId,
          ReservesIds: queryArg.reservesIds,
          IsActive: queryArg.isActive,
          Limit: queryArg.limit,
          Offset: queryArg.offset,
        },
      }),
    }),
    getApiReserveSessionsById: build.query<
      GetApiReserveSessionsByIdApiResponse,
      GetApiReserveSessionsByIdApiArg
    >({
      query: (queryArg) => ({ url: `/Api/Reserve/Sessions/${queryArg}` }),
    }),
    getApiReserveSessions: build.query<
      GetApiReserveSessionsApiResponse,
      GetApiReserveSessionsApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Reserve/Sessions`,
        params: {
          UserId: queryArg.userId,
          ReserveId: queryArg.reserveId,
          BeginDateFrom: queryArg.beginDateFrom,
          BeginDateTo: queryArg.beginDateTo,
          EndDateFrom: queryArg.endDateFrom,
          EndDateTo: queryArg.endDateTo,
          LastUpdateFrom: queryArg.lastUpdateFrom,
          LastUpdateTo: queryArg.lastUpdateTo,
          OrderingField: queryArg.orderingField,
          OrderingType: queryArg.orderingType,
          Offset: queryArg.offset,
          Limit: queryArg.limit,
        },
      }),
    }),
    getApiReserveSessionsCount: build.query<
      GetApiReserveSessionsCountApiResponse,
      GetApiReserveSessionsCountApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Reserve/Sessions/Count`,
        params: {
          UserId: queryArg.userId,
          ReserveId: queryArg.reserveId,
          BeginDateFrom: queryArg.beginDateFrom,
          BeginDateTo: queryArg.beginDateTo,
          EndDateFrom: queryArg.endDateFrom,
          EndDateTo: queryArg.endDateTo,
          LastUpdateFrom: queryArg.lastUpdateFrom,
          LastUpdateTo: queryArg.lastUpdateTo,
        },
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type PostApiReserveActionReserveByReserveIdApiResponse =
  /** status 200 Success */ AddReserveResponseBaseResponse;
export type PostApiReserveActionReserveByReserveIdApiArg = {
  /** Идентификатор обьекта резерва */
  reserveId: string;
  /** Параметры */
  addReserveRequest: AddReserveRequest;
};
export type PostApiReserveActionResetByReserveIdApiResponse =
  /** status 200 Success */ ResetReserveResponseBaseResponse;
export type PostApiReserveActionResetByReserveIdApiArg = {
  /** Идентификатор обьекта резерва */
  reserveId: string;
  /** Фильтр, разрешающий снять резерв если юзер совпадает с текущем юзером брони */
  userId?: string;
};
export type PostApiReserveActionExtendByReserveIdApiResponse =
  /** status 200 Success */ ExtendReserveResponseBaseResponse;
export type PostApiReserveActionExtendByReserveIdApiArg = {
  /** Идентификатор обьекта резерва */
  reserveId: string;
  timeSpan: TimeSpan;
  userId?: string;
};
export type GetApiReserveLocksApiResponse =
  /** status 200 Success */ GetLocksResponseArrayBaseResponse;
export type GetApiReserveLocksApiArg = {
  userId?: string;
  reserveIds?: string[];
  reserveName?: string;
  isActive?: boolean;
  limit?: number;
  offset?: number;
};
export type GetApiReserveLocksByIdApiResponse =
  /** status 200 Success */ GetLocksResponseBaseResponse;
export type GetApiReserveLocksByIdApiArg = /** Идентификатор резерва */ string;
export type GetApiReserveLocksCountApiResponse =
  /** status 200 Success */ Int64BaseResponse;
export type GetApiReserveLocksCountApiArg = {
  userId?: string;
  reservesIds?: string[];
  isActive?: boolean;
  limit?: number;
  offset?: number;
};
export type GetApiReserveSessionsByIdApiResponse =
  /** status 200 Success */ GetSessionResponseBaseResponse;
export type GetApiReserveSessionsByIdApiArg =
  /** Идентификатор обьекта резерва */ string;
export type GetApiReserveSessionsApiResponse =
  /** status 200 Success */ GetSessionResponseArrayBaseResponse;
export type GetApiReserveSessionsApiArg = {
  userId?: string;
  reserveId?: string;
  beginDateFrom?: string;
  beginDateTo?: string;
  endDateFrom?: string;
  endDateTo?: string;
  lastUpdateFrom?: string;
  lastUpdateTo?: string;
  orderingField?: string;
  orderingType?: OrderBy;
  offset?: number;
  limit?: number;
};
export type GetApiReserveSessionsCountApiResponse =
  /** status 200 Success */ Int64BaseResponse;
export type GetApiReserveSessionsCountApiArg = {
  userId?: string;
  reserveId?: string;
  beginDateFrom?: string;
  beginDateTo?: string;
  endDateFrom?: string;
  endDateTo?: string;
  lastUpdateFrom?: string;
  lastUpdateTo?: string;
};
export type Status = 0 | 1 | 2;
export type UnavailableServices =
  | 0
  | 1
  | 2
  | 3
  | 4
  | 5
  | 6
  | 7
  | 8
  | 9
  | 10
  | 11
  | 12
  | 13
  | 14
  | 15
  | 16
  | 17
  | 18
  | 19
  | 20
  | 21;
export type AddReserveResponse = {
  id: string;
  reserveId: string;
  reserveName: string;
  userId: string;
  dateFromReserve: string;
  dateToReserve: string;
  lastUpdate: string;
};
export type AddReserveResponseBaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: AddReserveResponse;
};
export type TimeSpan = {
  ticks?: number;
};
export type TimeSpanRead = {
  ticks?: number;
  days?: number;
  hours?: number;
  milliseconds?: number;
  microseconds?: number;
  nanoseconds?: number;
  minutes?: number;
  seconds?: number;
  totalDays?: number;
  totalHours?: number;
  totalMilliseconds?: number;
  totalMicroseconds?: number;
  totalNanoseconds?: number;
  totalMinutes?: number;
  totalSeconds?: number;
};
export type AddReserveRequest = {
  userId: string;
  timeSpan: TimeSpan;
  reserveName: string;
};
export type AddReserveRequestRead = {
  userId: string;
  timeSpan: TimeSpanRead;
  reserveName: string;
};
export type ResetReserveResponse = {
  id: string;
  reserveId: string;
  reserveName: string;
  userId: string;
  dateFromReserve: string;
  dateToReserve: string;
  lastUpdate: string;
};
export type ResetReserveResponseBaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: ResetReserveResponse;
};
export type ExtendReserveResponse = {
  id: string;
  reserveId: string;
  reserveName: string;
  userId: string;
  dateFromReserve: string;
  dateToReserve: string;
  lastUpdate: string;
};
export type ExtendReserveResponseBaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: ExtendReserveResponse;
};
export type GetLocksResponse = {
  id: string;
  reserveId: string;
  reserveName: string;
  userId: string;
  dateFromReserve: string;
  dateToReserve: string;
};
export type GetLocksResponseArrayBaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: GetLocksResponse[] | null;
};
export type GetLocksResponseBaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: GetLocksResponse;
};
export type Int64BaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: number;
};
export type GetSessionResponse = {
  id: string;
  reserveId: string;
  reserveName: string;
  userId: string;
  dateFromReserve: string;
  dateToReserve: string;
  lastUpdate: string;
};
export type GetSessionResponseBaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: GetSessionResponse;
};
export type GetSessionResponseArrayBaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: GetSessionResponse[] | null;
};
export type OrderBy = 'ASC' | 'DESC';
export const {
  usePostApiReserveActionReserveByReserveIdMutation,
  usePostApiReserveActionResetByReserveIdMutation,
  usePostApiReserveActionExtendByReserveIdMutation,
  useGetApiReserveLocksQuery,
  useGetApiReserveLocksByIdQuery,
  useGetApiReserveLocksCountQuery,
  useGetApiReserveSessionsByIdQuery,
  useGetApiReserveSessionsQuery,
  useGetApiReserveSessionsCountQuery,
} = injectedRtkApi;
