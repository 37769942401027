import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
  getServerErrorText,
  hasNoLetters,
  openErrorNotification,
} from 'shared/lib';
import { CHARGE_POINT_ROUTES } from 'shared/consts/routes/charge-point';
import { USER_ROUTES, PROFILE } from 'shared/consts/routes/user';

import { selectTokenName } from 'entities/authorization';

import { Header } from '../header';
import {
  useLazyGetChargePoints,
  useLazyGetChargePointsCount,
} from 'shared/api/services/chargepoint/rtk';
import {
  useLazyGetUsers,
  useLazyGetUsersCount,
} from 'shared/api/services/user/rtk/enhanced';

export function ConnectedHeader() {
  const [term, setTerm] = useState('');
  const [searchByChargePointName, setSearchByChargePointName] = useState(false);

  // const userName = useSelector(selectTokenName);

  const userName = 'userName';

  const navigate = useNavigate();

  // TODO: Вынести отдельно плашку юзера
  // const { data, isFetching, error } = useGetUserQuery(userName);

  const [getUsers, { isLoading: isUsersLoading }] = useLazyGetUsers();
  const [getUsersCount, { isLoading: isUsersCountLoading }] =
    useLazyGetUsersCount();

  const [getChargePoints, { isLoading: isChargePointsLoading }] =
    useLazyGetChargePoints({});
  const [getChargePointsCount, { isLoading: isChargePointsCountLoading }] =
    useLazyGetChargePointsCount({});

  const onSearch = async () => {
    if (hasNoLetters(term) && searchByChargePointName) {
      const [chargePoints, chargePointsCount] = await Promise.all([
        getChargePoints({ name: term }),
        getChargePointsCount({
          name: term,
        }),
      ]);

      const error = [chargePoints.error, chargePointsCount.error].find(
        (err) => err !== undefined
      );

      const apiResponseError = [chargePoints, chargePointsCount].find(
        (el) => el && el.data?.status === 'ERROR'
      );

      if (error) {
        openErrorNotification(getServerErrorText(error));

        return;
      }

      if (apiResponseError?.data) {
        openErrorNotification(apiResponseError.data.statusMessage);

        return;
      }

      if (!chargePoints?.data?.data || !chargePointsCount?.data?.data) {
        openErrorNotification('Данные отсутствуют');

        return;
      }

      if (chargePointsCount.data.data > 1) {
        return navigate(
          `${CHARGE_POINT_ROUTES.CHARGE_POINTS}?search=name&searchText=${term}`
        );
      }

      if (chargePointsCount.data.data === 1) {
        const { group, name } = chargePoints.data.data[0];

        const groupId = group?.id || null;

        `${CHARGE_POINT_ROUTES.CHARGE_POINTS}/${groupId}/${name}`;
      }

      if (chargePointsCount.data.data === 0) {
        // Сейчас у сессий нет такого фильтра
        //
        // return navigate(
        //   `${CHARGE_POINT_ROUTES.CHARGE_POINTS_SESSION_LOG}?search=operation.cpAddress&searchText=${term}`
        // );
      }
    }

    if (hasNoLetters(term) && !searchByChargePointName) {
      const [users, usersCount] = await Promise.all([
        getUsers({ login: term.replaceAll(/[\s\(\)-]/g, '') }),
        getUsersCount({
          login: term.replaceAll(/[\s\(\)-]/g, ''),
        }),
      ]);

      const error = [users.error, usersCount.error].find(
        (err) => err !== undefined
      );

      const apiResponseError = [users, usersCount].find(
        (el) => el && el.data?.status === 'ERROR'
      );

      if (error) {
        openErrorNotification(getServerErrorText(error));

        return;
      }

      if (apiResponseError?.data) {
        openErrorNotification(apiResponseError.data.statusMessage);

        return;
      }

      if (!users?.data?.data || !usersCount?.data?.data) {
        openErrorNotification('Данные отсутствуют');

        return;
      }

      if (usersCount.data.data > 1) {
        return navigate(
          `${USER_ROUTES.PERSONS_LIST}?search=login&searchText=${term}`
        );
      }

      if (usersCount.data.data === 1) {
        const { login, groupId } = users.data.data[0];

        return navigate(
          `${USER_ROUTES.PERSONS_LIST}/${groupId || null}/${login}/${PROFILE}`
        );
      }

      if (usersCount.data.data === 0) {
        // Сейчас у сессий нет такого фильтра
        //
        // return navigate(
        //   `${USER_ROUTES.PERSONS_SESSION_LOG}?search=userBrowseName&searchText=${term}`
        // );
      }

      // if (res.data) {
      //   return navigate(`${USER_ROUTES.PERSONS_LIST}?phone=${term}`);
      // }
    }

    // Сейчас у сессий нет такого фильтра
    //
    // return navigate(
    //   `${CHARGE_POINT_ROUTES.CHARGE_POINTS_SESSION_LOG}?search=operation.cpAddress&searchText=${term}`
    // );
  };

  const onCheckboxChange = (e: CheckboxChangeEvent) => {
    setSearchByChargePointName(e.target.checked);
  };

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // Отлов события очищения инпут-поля по крестику
    if (e.type !== 'change') {
      setTerm(e.target.value);
    }

    setTerm(e.target.value);
  };

  const firstName = 'Тест';
  const lastName = 'Юзер';

  // const firstName = data?.user.firstName ?? '';
  // const lastName = data?.user.lastName ?? '';

  const isLoading =
    isUsersLoading ||
    isUsersCountLoading ||
    isChargePointsLoading ||
    isChargePointsCountLoading;

  return (
    <Header
      onCheckboxChange={onCheckboxChange}
      onInputChange={onInputChange}
      onSearch={onSearch}
      searchByChargePointName={searchByChargePointName}
      term={term}
      loading={isLoading}
      firstName={firstName}
      lastName={lastName}
    />
  );
}
