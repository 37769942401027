import { Col, Space, Spin } from 'antd';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm, FormProvider, useFormContext } from 'react-hook-form';
import { DevTool } from '@hookform/devtools';
import { useEffect } from 'react';
import { EditIcon } from 'shared/icons/edit';

import {
  ButtonsContainer,
  UiSubmitButton,
  UiCancelFormButton,
  FormControlsContainer,
} from 'shared/ui';
import { CustomInput } from 'shared/ui/form/custom-input';
import {
  isErrorWithMessage,
  openErrorNotification,
  openSuccessNotification,
} from 'shared/lib';
import { CustomSelect } from 'shared/ui/form/custom-select';

import { TemplateDto } from 'shared/api/services/pushnotification/rtk/generated';
import { CustomTextarea } from 'shared/ui/form/custom-textarea';

import {
  DEFAULT_VALUES,
  FormSchema,
  FormInput,
  FormOutput,
} from '../consts/schema';
import { FORM_TITLE, ADD_ERROR, ADD_SUCCESS } from '../consts';

import { CardFormWrapper, FormRow } from '../../../../common-styles';

import { ControlButton } from './styles';
import { useAddCompany } from 'shared/api/services/company/rtk/enhanced';

type Props = {
  templates: TemplateDto[];
};

export function Form({ templates }: Props) {
  const form = useForm<FormInput, void, FormOutput>({
    resolver: zodResolver(FormSchema),
    defaultValues: { ...DEFAULT_VALUES },
  });

  const [trigger, { isLoading }] = useAddCompany();

  const handleSubmit = form.handleSubmit(async (data) => {
    //
    // try {
    //   const res = await trigger(data).unwrap();
    //   openSuccessNotification(ADD_SUCCESS);
    // } catch (err) {
    //   const hasErrorMessage = isErrorWithMessage(err);
    //   const errorText = hasErrorMessage ? err.data.statusMessage : ADD_ERROR;
    //   openErrorNotification(errorText);
    // }
  });

  return (
    <CardFormWrapper title={FORM_TITLE}>
      <FormProvider {...form}>
        <DevTool control={form.control} />
        <Spin spinning={isLoading}>
          <form onSubmit={handleSubmit}>
            <Form.Fields templates={templates} />
            <Form.Buttons />
          </form>
        </Spin>
      </FormProvider>
    </CardFormWrapper>
  );
}

type FieldsProps = {
  templates: TemplateDto[];
};

Form.Fields = function Fields({ templates }: FieldsProps) {
  const {
    watch,
    formState: { errors },
    setValue,
  } = useFormContext<FormInput, void, FormOutput>();

  const templateId = watch('templateId');

  useEffect(() => {
    if (templateId) {
      const template = templates.find((el) => el.id === templateId);

      if (template) {
        const { text, title, browserUrl, deepLink, name } = template;

        setValue('text', text);
        setValue('title', title);
        setValue('browserUrl', browserUrl);
        setValue('deepLink', deepLink);
        setValue('name', name);
      }
    }
  }, [templateId]);

  const templatesOptions = [
    { value: 0, label: 'Создать новый' },
    ...templates.map(({ id, name }) => ({
      value: id,
      label: name,
    })),
  ];

  const isTemplateFieldsDisabled = Boolean(templateId);

  return (
    <>
      <FormRow gutter={20}>
        <Col span={6}>
          <CustomSelect<FormInput>
            name="templateId"
            label="Шаблон сообщения"
            options={templatesOptions}
            required
          />
        </Col>
        {templateId !== undefined && (
          <Col span={6}>
            <CustomInput<FormInput>
              name="name"
              label="Название"
              required
              disabled={isTemplateFieldsDisabled}
            />
          </Col>
        )}
      </FormRow>

      {templateId !== undefined && (
        <>
          <FormRow gutter={20}>
            <Col span={6}>
              <CustomInput<FormInput>
                name="title"
                label="Заголовок"
                required
                disabled={isTemplateFieldsDisabled}
              />
            </Col>
            <Col span={6}>
              <CustomInput<FormInput>
                name="browserUrl"
                label="URL для браузера"
                required
                disabled={isTemplateFieldsDisabled}
              />
            </Col>
            <Col span={6}>
              <CustomInput<FormInput>
                name="deepLink"
                label="Deeplink для приложения"
                disabled={isTemplateFieldsDisabled}
              />
            </Col>

            {templateId !== '' && (
              <Space
                size={20}
                style={{ alignSelf: 'flex-start', paddingTop: '28px' }}
              >
                <ControlButton
                  onClick={() => {
                    setValue('templateId', 0);
                  }}
                >
                  <EditIcon />
                </ControlButton>
              </Space>
            )}
          </FormRow>
          <FormRow gutter={20}>
            <Col span={6}>
              <CustomTextarea<FormInput>
                name="text"
                label="Текст"
                required
                disabled={isTemplateFieldsDisabled}
              />
            </Col>
          </FormRow>
        </>
      )}
    </>
  );
};

Form.Buttons = function Buttons() {
  return (
    <FormControlsContainer>
      <ButtonsContainer>
        <UiSubmitButton />
        <UiCancelFormButton />
      </ButtonsContainer>
    </FormControlsContainer>
  );
};
