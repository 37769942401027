import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import axios, { Axios, AxiosError } from 'axios';
import { FormOutput } from 'features/push-notification/add-batch/consts/schema';
import { BaseResponse } from 'shared/api/services/chargepoint/rtk/generated/charge-points';
import { getPushNotificationServiceWebApi } from '../../orval/axios/client';
import { enhancedApi as api, ResponseBase } from '../generated';

const enhancedApi = api
  .enhanceEndpoints({
    endpoints: {
      // Батчи
      getApiPushNotificationBatches: (endpoint) => {
        endpoint.providesTags = [{ type: 'Batches', id: 'LIST' }];
      },
      getApiPushNotificationBatchesByBatchId: (endpoint) => {
        endpoint.providesTags = (result, error, arg) => [
          { type: 'Batches', id: arg },
        ];
      },
      // Шаблоны
      getApiPushNotificationTemplates: (endpoint) => {
        endpoint.providesTags = [{ type: 'Templates', id: 'LIST' }];
      },
      getApiPushNotificationTemplatesByTemplateId: (endpoint) => {
        endpoint.providesTags = (result, error, arg) => [
          { type: 'Templates', id: arg },
        ];
      },
    },
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      addBatch: builder.mutation<BaseResponse, FormOutput>({
        queryFn: async (args, _queryApi, _extraOptions, baseQuery) => {
          const { templateId } = args;

          const {
            postApiPushNotificationBatches,
            postApiPushNotificationTemplates,
          } = getPushNotificationServiceWebApi();

          try {
            if (templateId === 0) {
              const { templateId, ...rest } = args;

              const addTemplateRes = await postApiPushNotificationTemplates({
                ...rest,
              });

              const addBatchRes = await postApiPushNotificationBatches({
                templateId: addTemplateRes.data?.id,
              });
            } else {
              const addBatchRes = await postApiPushNotificationBatches({
                templateId,
              });
            }

            return { data: { status: 'SUCCESS', statusMessage: '' } };
          } catch (err) {
            if (axios.isAxiosError(err)) {
              const { response } = err;
            }

            return {
              error: {
                error: 'error',
                status: `CUSTOM_ERROR`,
              },
            };

            // if (!axios.isAxiosError(err)) {
            //   const x = err;
            //   return { error: err };
            // }

            // return {
            //   error: {
            //     status: err.response?.status,
            //     data: err.response?.data || err.message,
            //   },
            // };
          }
        },
      }),
    }),
  });
// .injectEndpoints({
//   endpoints: (builder) => ({
//     addBatch: builder.mutation<ResponseBase, FormOutput>({
//       queryFn: async (args, _queryApi, _extraOptions, baseQuery) => {
//         const { templateId } = args;

//         const {
//           postApiPushNotificationBatches,
//           postApiPushNotificationTemplates,
//         } = getPushNotificationServiceWebApi();

//         try {
//           if (templateId === 0) {
//             const { templateId, ...rest } = args;

//             const addTemplateRes = await postApiPushNotificationTemplates({
//               ...rest,
//             });

//             const addBatchRes = await postApiPushNotificationBatches({
//               templateId: addTemplateRes.data?.id,
//             });
//           } else {
//             const addBatchRes = await postApiPushNotificationBatches({
//               templateId,
//             });
//           }

//           return { data: { status: 'SUCCESS', statusMessage: '' } };
//         } catch (axiosError) {
//           const err = axiosError as AxiosError;

//           const err2: FetchBaseQueryError = {
//             error: {
//               status: err.response?.status,
//               data: err.response?.data || err.message,
//             },
//           };

//           return {
//             error: {
//               status: err.response?.status,
//               data: err.response?.data || err.message,
//             },
//           };
//         }
//       },
//     }),
//   }),
// });

export const {
  // Батчи
  useGetApiPushNotificationBatchesQuery: useGetBatches,
  useGetApiPushNotificationBatchesByBatchIdQuery: useGetBatch,
  // Шаблоны
  useGetApiPushNotificationTemplatesQuery: useGetTemplates,
  useGetApiPushNotificationTemplatesByTemplateIdQuery: useGetTemplate,
} = enhancedApi;
