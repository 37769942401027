import { EmptyData, ErrorMessage } from 'shared/ui';
import { getServerErrorText } from 'shared/lib';
import {
  useGetBatches,
  useGetTemplates,
} from 'shared/api/services/pushnotification/rtk/enhanced';

import { TableInstance } from './table';
import { TemplateDto } from 'shared/api/services/pushnotification/rtk/generated';

export function TableContainer() {
  const batchesQuery = useGetBatches();
  const templatesQuery = useGetTemplates();

  const isLoading = batchesQuery.isLoading || templatesQuery.isLoading;
  const isFetching = batchesQuery.isFetching || templatesQuery.isFetching;

  const apiResponseError = [batchesQuery.data, templatesQuery.data].find(
    (el) => el && el.status === 'ERROR'
  );

  const error = [batchesQuery.error, templatesQuery.error].find(
    (err) => err !== undefined
  );

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (apiResponseError) {
    return <ErrorMessage text={apiResponseError.statusMessage || ''} />;
  }

  if (error) {
    return <ErrorMessage text={getServerErrorText(error)} />;
  }

  if (!batchesQuery?.data?.data || !templatesQuery?.data?.data) {
    return <EmptyData />;
  }

  // Нормализация списка шаблонов для доступа по templateId

  const templatesDict = templatesQuery.data.data.reduce((acc, el) => {
    acc[el.id] = el;
    return acc;
  }, {} as Record<string, TemplateDto>);

  return (
    <TableInstance
      loading={isFetching}
      data={batchesQuery.data.data || []}
      templatesDict={templatesDict}
    />
  );
}
