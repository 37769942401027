import {
  enhancedApi as api,
  GetApiChargePointMetricsAmountOfConsumedEnergyApiResponse,
  GetApiChargePointMetricsSuccessfulSessionsApiResponse,
} from '../generated/metrics';

import { padMetricsData } from 'entities/metrics/lib/pad-metrics-data';

export const enhancedApi = api.enhanceEndpoints({
  endpoints: {
    getApiChargePointMetricsAmountOfConsumedEnergy: (endpoint) => {
      endpoint.transformResponse = (
        res: GetApiChargePointMetricsAmountOfConsumedEnergyApiResponse,
        meta,
        arg
      ) => {
        const { data } = res;
        const { dateFrom, dateTo } = arg;

        const paddedMetrics = padMetricsData(dateFrom, dateTo, data);

        return {
          ...res,
          data: paddedMetrics,
        };
      };
    },
    getApiChargePointMetricsSuccessfulSessions: (endpoint) => {
      endpoint.transformResponse = (
        res: GetApiChargePointMetricsSuccessfulSessionsApiResponse,
        meta,
        arg
      ) => {
        const { data } = res;
        const { dateFrom, dateTo } = arg;

        const paddedMetrics = padMetricsData(dateFrom, dateTo, data);

        return {
          ...res,
          data: paddedMetrics,
        };
      };
    },
  },
});

export const {
  useGetApiChargePointMetricsChargepointsQuery: useGetChargePointsMetrics,
  useGetApiChargePointMetricsConnectorsQuery: useGetConnectorsMetrics,
  useGetApiChargePointMetricsSessionChargingQuery:
    useGetChargingSessionsMetrics,
  useGetApiChargePointMetricsAmountOfConsumedEnergyQuery:
    useGetGraphComsumedEnergy,
  useGetApiChargePointMetricsSuccessfulSessionsQuery:
    useGetGraphSuccessfulSessions,
} = enhancedApi;
