import { ADD_PREFIX, BILLINGS } from '../names';

export const BILLING = ':serviceListId';

export const SERVICE_LIST = ':serviceListId';

const ROOT = `/${BILLINGS}`;

export const BILLING_ROUTES = {
  LIST: ROOT,
  TARIFF: `${ROOT}/${BILLING}`,
  ADD: `${ROOT}/${ADD_PREFIX}`,
};
