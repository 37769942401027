import { enhancedApi as api } from '../generated/groups';

export const enhancedApi = api.enhanceEndpoints({
  endpoints: {
    getApiChargePointGroups: (endpoint) => {
      endpoint.providesTags = [{ type: 'ChargePointGroups', id: 'LIST' }];
    },
    getApiChargePointGroupsById: (endpoint) => {
      endpoint.providesTags = (result, error, arg) => [
        { type: 'ChargePointGroups', id: arg },
      ];
    },
    postApiChargePointGroups: (endpoint) => {
      endpoint.invalidatesTags = [{ type: 'ChargePointGroups', id: 'LIST' }];
    },
    patchApiChargePointGroupsById: (endpoint) => {
      endpoint.invalidatesTags = (result, error, arg) => [
        { type: 'ChargePointGroups', id: 'LIST' },
        {
          type: 'ChargePointGroups',
          id: arg.id,
        },
      ];
    },
    deleteApiChargePointGroupsById: (endpoint) => {
      endpoint.invalidatesTags = [{ type: 'ChargePointGroups', id: 'LIST' }];
    },
  },
});

export const {
  useGetApiChargePointGroupsQuery: useGetChargePointGroups,
  useGetApiChargePointGroupsCountQuery: useGetChargePointGroupsCount,
  useGetApiChargePointGroupsByIdQuery: useGetChargePointGroup,
  usePostApiChargePointGroupsMutation: useAddChargePointGroup,
  usePatchApiChargePointGroupsByIdMutation: useUpdateChargePointGroup,
  useDeleteApiChargePointGroupsByIdMutation: useDeleteChargePointGroup,
} = enhancedApi;
