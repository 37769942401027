import { getWebApi } from 'shared/api/services/reserve/orval/axios/client';
import { getChargePointServiceWebApi } from '../../orval/axios/client';
import { GetStatusesVm } from '../../orval/axios/schemas';
import {
  enhancedApi as api,
  GetStatusesConnectorVm,
} from '../generated/charge-points';

// export type ChargePointMapData = Omit<
//   GetStatusesVm,
//   'connectors' | 'chargePointId'
// > & {
//   connectors: {
//     [key: string]: GetStatusesConnectorVm;
//   };
// };

type ChargePointsMapping = {
  [key: string]: GetStatusesVm;
};

export const enhancedApi = api
  .enhanceEndpoints({
    endpoints: {
      // ЭЗС
      getApiChargePointChargePoints: (endpoint) => {
        endpoint.providesTags = [{ type: 'ChargePoints', id: 'LIST' }];
      },
      getApiChargePointChargePointsById: (endpoint) => {
        endpoint.providesTags = (result, error, arg) => [
          { type: 'ChargePoints', id: arg },
        ];
      },
      postApiChargePointChargePoints: (endpoint) => {
        endpoint.invalidatesTags = [
          { type: 'ChargePoints', id: 'LIST' },
          {
            type: 'ChargePointGroups',
            id: 'LIST',
          },
        ];
      },
      patchApiChargePointChargePointsById: (endpoint) => {
        endpoint.invalidatesTags = (result, error, arg) => [
          { type: 'ChargePoints', id: 'LIST' },
          { type: 'ChargePoints', id: arg.id },
          {
            type: 'ChargePointGroups',
            id: 'LIST',
          },
        ];
      },
      getApiChargePointChargePointsCpStatuses: (endpoint) => {
        // TODO
        // endpoint.providesTags = [{ type: 'ChargePoints', id: 'LIST' }];
      },

      // Коннекторы
      postApiChargePointChargePointsByChargePointsIdConnector: (endpoint) => {
        endpoint.invalidatesTags = (result, error, arg) => [
          // для коннекторов ранее не делал
          {
            type: 'ChargePoints',
            id: arg.chargePointsId,
          },
        ];
      },
      patchApiChargePointChargePointsByChargePointsIdAndConnectorId: (
        endpoint
      ) => {
        endpoint.invalidatesTags = (result, error, arg) => [
          // для коннекторов ранее не делал
          {
            type: 'ChargePoints',
            id: arg.chargePointsId,
          },
        ];
      },
      deleteApiChargePointChargePointsByChargePointsIdAndConnectorId: (
        endpoint
      ) => {
        endpoint.invalidatesTags = (result, error, arg) => [
          // для коннекторов ранее не делал
          {
            type: 'ChargePoints',
            id: arg.chargePointsId,
          },
        ];
      },
    },
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getChargePointsMapData: builder.query<GetStatusesVm[], void>({
        queryFn: async (args, _queryApi, _extraOptions, baseQuery) => {
          const { getApiChargePointChargePointsCpStatuses } =
            getChargePointServiceWebApi();
          const { getApiReserveReserves } = getWebApi();

          const [chargePoints, reserves] = await Promise.allSettled([
            getApiChargePointChargePointsCpStatuses(),
            // Add OCPI call
            getApiReserveReserves(),
          ]);

          const chargePointsMapping: ChargePointsMapping = {};
          const connectorChargePointMapping: { [key: string]: string } = {};

          if (chargePoints.status === 'fulfilled') {
            chargePoints.value.data?.map((el) => {
              const { connectors, chargePointId, ...rest } = el;

              connectors?.map((connector) => {
                const { id } = connector;

                connectorChargePointMapping[id] = chargePointId;
              });

              chargePointsMapping[chargePointId] = {
                ...el,
              };
            });
          }

          if (reserves.status === 'fulfilled') {
            reserves.value.data?.map((reserve) => {
              const { guidTargetReserve } = reserve;

              //
              // Если guidTargetReserve === chargePointId, всем коннекторам зарядки ставим 'RESERVED'
              //
              // Если guidTargetReserve !== chargePointId, значит резерв на коннекторе
              //

              if (chargePointsMapping[guidTargetReserve]) {
                chargePointsMapping[guidTargetReserve].connectors?.forEach(
                  (connector) => connector.status === 'RESERVED'
                );
              } else if (connectorChargePointMapping[guidTargetReserve]) {
                const chargePointId =
                  connectorChargePointMapping[guidTargetReserve];

                chargePointsMapping[chargePointId].connectors?.forEach(
                  (connector) => {
                    if (connector.id === guidTargetReserve) {
                      connector.status === 'RESERVED';
                    }
                  }
                );
              }
            });
          }

          // console.log('chargePointsMapping', chargePointsMapping);

          return {
            data: Object.values(chargePointsMapping),
          };
        },
      }),
    }),
  });

export const {
  // ЭЗС
  useGetApiChargePointChargePointsQuery: useGetChargePoints,
  useLazyGetApiChargePointChargePointsQuery: useLazyGetChargePoints,
  useGetApiChargePointChargePointsCountQuery: useGetChargePointsCount,
  useLazyGetApiChargePointChargePointsCountQuery: useLazyGetChargePointsCount,
  useGetApiChargePointChargePointsByIdQuery: useGetChargePoint,
  usePostApiChargePointChargePointsMutation: useAddChargePoint,
  usePatchApiChargePointChargePointsByIdMutation: useUpdateChargePoint,
  useGetApiChargePointChargePointsCpStatusesQuery: useGetChargePointsStatuses,
  // Кастомный квэри
  useGetChargePointsMapDataQuery: useGetChargePointsMapData,
  // Коннекторы
  usePostApiChargePointChargePointsByChargePointsIdConnectorMutation:
    useAddConnector,
  usePatchApiChargePointChargePointsByChargePointsIdAndConnectorIdMutation:
    useUpdateConnector,
  useDeleteApiChargePointChargePointsByChargePointsIdAndConnectorIdMutation:
    useDeleteConnector,
} = enhancedApi;
