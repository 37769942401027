/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * WebApi
 * OpenAPI spec version: 1.0
 */
import type {
  AddReserveRequest,
  AddReserveResponseBaseResponse,
  ExtendReserveResponseBaseResponse,
  GetApiReserveReservesCountParams,
  GetApiReserveReservesParams,
  GetApiReserveSessionsCountParams,
  GetApiReserveSessionsParams,
  GetReservesResponseBaseResponse,
  GetReservesResponseListBaseResponse,
  GetSessionResponseBaseResponse,
  GetSessionResponseListBaseResponse,
  Int64BaseResponse,
  PostApiReserveActionExtendGuidTargetReserveParams,
  PostApiReserveActionResetGuidTargetReserveParams,
  ResetReserveResponseBaseResponse
} from './schemas'
import { customInstance } from '../../../../../axios';



type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


  export const getWebApi = () => {
/**
 * @summary Зарезервировать обьект
 */
const postApiReserveActionReserveGuidTargetReserve = (
    guidTargetReserve: string,
    addReserveRequest: AddReserveRequest,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<AddReserveResponseBaseResponse>(
      {url: `/Api/Reserve/Action/Reserve/${guidTargetReserve}`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: addReserveRequest
    },
      options);
    }
  
/**
 * @summary Сбросить(отменить) резерв обьекта
 */
const postApiReserveActionResetGuidTargetReserve = (
    guidTargetReserve: string,
    params?: PostApiReserveActionResetGuidTargetReserveParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<ResetReserveResponseBaseResponse>(
      {url: `/Api/Reserve/Action/Reset/${guidTargetReserve}`, method: 'POST',
        params
    },
      options);
    }
  
/**
 * @summary Увеличить время резерва на временой интервал
 */
const postApiReserveActionExtendGuidTargetReserve = (
    guidTargetReserve: string,
    params?: PostApiReserveActionExtendGuidTargetReserveParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<ExtendReserveResponseBaseResponse>(
      {url: `/Api/Reserve/Action/Extend/${guidTargetReserve}`, method: 'POST',
        params
    },
      options);
    }
  
/**
 * @summary Получить резервы(обьекты блокировки) резерва
 */
const getApiReserveReserves = (
    params?: GetApiReserveReservesParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<GetReservesResponseListBaseResponse>(
      {url: `/Api/Reserve/Reserves`, method: 'GET',
        params
    },
      options);
    }
  
/**
 * @summary Получить резерв(обьект блокировки) резерва
 */
const getApiReserveReservesId = (
    id: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<GetReservesResponseBaseResponse>(
      {url: `/Api/Reserve/Reserves/${id}`, method: 'GET'
    },
      options);
    }
  
/**
 * @summary Количество зарезервированных объектов
 */
const getApiReserveReservesCount = (
    params?: GetApiReserveReservesCountParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<Int64BaseResponse>(
      {url: `/Api/Reserve/Reserves/Count`, method: 'GET',
        params
    },
      options);
    }
  
/**
 * @summary Получить сессию резерва
 */
const getApiReserveSessionsId = (
    id: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<GetSessionResponseBaseResponse>(
      {url: `/Api/Reserve/Sessions/${id}`, method: 'GET'
    },
      options);
    }
  
/**
 * @summary Получить сессии
 */
const getApiReserveSessions = (
    params?: GetApiReserveSessionsParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<GetSessionResponseListBaseResponse>(
      {url: `/Api/Reserve/Sessions`, method: 'GET',
        params
    },
      options);
    }
  
/**
 * @summary Получить кол-во записей
 */
const getApiReserveSessionsCount = (
    params?: GetApiReserveSessionsCountParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<Int64BaseResponse>(
      {url: `/Api/Reserve/Sessions/Count`, method: 'GET',
        params
    },
      options);
    }
  
return {postApiReserveActionReserveGuidTargetReserve,postApiReserveActionResetGuidTargetReserve,postApiReserveActionExtendGuidTargetReserve,getApiReserveReserves,getApiReserveReservesId,getApiReserveReservesCount,getApiReserveSessionsId,getApiReserveSessions,getApiReserveSessionsCount}};
export type PostApiReserveActionReserveGuidTargetReserveResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getWebApi>['postApiReserveActionReserveGuidTargetReserve']>>>
export type PostApiReserveActionResetGuidTargetReserveResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getWebApi>['postApiReserveActionResetGuidTargetReserve']>>>
export type PostApiReserveActionExtendGuidTargetReserveResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getWebApi>['postApiReserveActionExtendGuidTargetReserve']>>>
export type GetApiReserveReservesResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getWebApi>['getApiReserveReserves']>>>
export type GetApiReserveReservesIdResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getWebApi>['getApiReserveReservesId']>>>
export type GetApiReserveReservesCountResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getWebApi>['getApiReserveReservesCount']>>>
export type GetApiReserveSessionsIdResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getWebApi>['getApiReserveSessionsId']>>>
export type GetApiReserveSessionsResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getWebApi>['getApiReserveSessions']>>>
export type GetApiReserveSessionsCountResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getWebApi>['getApiReserveSessionsCount']>>>
