import { baseApi as api } from '../../../../base-new/base-api';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    postApiPaymentActionsPay: build.mutation<
      PostApiPaymentActionsPayApiResponse,
      PostApiPaymentActionsPayApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Payment/Actions/pay`,
        method: 'POST',
        body: queryArg.payRequestDto,
        headers: { idempotencyKey: queryArg.idempotencyKey },
      }),
    }),
    postApiPaymentActionsBinding: build.mutation<
      PostApiPaymentActionsBindingApiResponse,
      PostApiPaymentActionsBindingApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Payment/Actions/binding`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    postApiPaymentActionsUnbind: build.mutation<
      PostApiPaymentActionsUnbindApiResponse,
      PostApiPaymentActionsUnbindApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Payment/Actions/unbind`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    postApiPaymentActionsHold: build.mutation<
      PostApiPaymentActionsHoldApiResponse,
      PostApiPaymentActionsHoldApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Payment/Actions/hold`,
        method: 'POST',
        body: queryArg.holdRequestDto,
        headers: { idempotencyKey: queryArg.idempotencyKey },
      }),
    }),
    postApiPaymentActionsDeposit: build.mutation<
      PostApiPaymentActionsDepositApiResponse,
      PostApiPaymentActionsDepositApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Payment/Actions/deposit`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    postApiPaymentActionsReverse: build.mutation<
      PostApiPaymentActionsReverseApiResponse,
      PostApiPaymentActionsReverseApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Payment/Actions/reverse`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    postApiPaymentActionsSelectcard: build.mutation<
      PostApiPaymentActionsSelectcardApiResponse,
      PostApiPaymentActionsSelectcardApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Payment/Actions/selectcard`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    getApiPaymentAppInfo: build.query<
      GetApiPaymentAppInfoApiResponse,
      GetApiPaymentAppInfoApiArg
    >({
      query: () => ({ url: `/Api/Payment/AppInfo` }),
    }),
    getApiPaymentBanksById: build.query<
      GetApiPaymentBanksByIdApiResponse,
      GetApiPaymentBanksByIdApiArg
    >({
      query: (queryArg) => ({ url: `/Api/Payment/Banks/${queryArg}` }),
    }),
    patchApiPaymentBanksById: build.mutation<
      PatchApiPaymentBanksByIdApiResponse,
      PatchApiPaymentBanksByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Payment/Banks/${queryArg.id}`,
        method: 'PATCH',
        body: queryArg.updateBankRequestDto,
      }),
    }),
    deleteApiPaymentBanksById: build.mutation<
      DeleteApiPaymentBanksByIdApiResponse,
      DeleteApiPaymentBanksByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Payment/Banks/${queryArg}`,
        method: 'DELETE',
      }),
    }),
    getApiPaymentBanks: build.query<
      GetApiPaymentBanksApiResponse,
      GetApiPaymentBanksApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Payment/Banks`,
        params: { requestDto: queryArg },
      }),
    }),
    postApiPaymentBanks: build.mutation<
      PostApiPaymentBanksApiResponse,
      PostApiPaymentBanksApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Payment/Banks`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    getApiPaymentBindingLogsById: build.query<
      GetApiPaymentBindingLogsByIdApiResponse,
      GetApiPaymentBindingLogsByIdApiArg
    >({
      query: (queryArg) => ({ url: `/Api/Payment/BindingLogs/${queryArg}` }),
    }),
    getApiPaymentBindingLogs: build.query<
      GetApiPaymentBindingLogsApiResponse,
      GetApiPaymentBindingLogsApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Payment/BindingLogs`,
        params: {
          UserId: queryArg.userId,
          BankId: queryArg.bankId,
          Status: queryArg.status,
          OrderNumber: queryArg.orderNumber,
          BankOrderId: queryArg.bankOrderId,
          ErrorReason: queryArg.errorReason,
          Limit: queryArg.limit,
          Offset: queryArg.offset,
          OrderingField: queryArg.orderingField,
          OrderingType: queryArg.orderingType,
        },
      }),
    }),
    getApiPaymentBindingLogsCount: build.query<
      GetApiPaymentBindingLogsCountApiResponse,
      GetApiPaymentBindingLogsCountApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Payment/BindingLogs/count`,
        params: {
          UserId: queryArg.userId,
          BankId: queryArg.bankId,
          Status: queryArg.status,
          OrderNumber: queryArg.orderNumber,
          BankOrderId: queryArg.bankOrderId,
          ErrorReason: queryArg.errorReason,
        },
      }),
    }),
    getApiPaymentCardsById: build.query<
      GetApiPaymentCardsByIdApiResponse,
      GetApiPaymentCardsByIdApiArg
    >({
      query: (queryArg) => ({ url: `/Api/Payment/Cards/${queryArg}` }),
    }),
    getApiPaymentCards: build.query<
      GetApiPaymentCardsApiResponse,
      GetApiPaymentCardsApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Payment/Cards`,
        params: {
          UserId: queryArg.userId,
          BankId: queryArg.bankId,
          BindingId: queryArg.bindingId,
          IsSelected: queryArg.isSelected,
          Limit: queryArg.limit,
          Offset: queryArg.offset,
          OrderingField: queryArg.orderingField,
          OrderingType: queryArg.orderingType,
        },
      }),
    }),
    getApiPaymentCardsCount: build.query<
      GetApiPaymentCardsCountApiResponse,
      GetApiPaymentCardsCountApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Payment/Cards/count`,
        params: {
          UserId: queryArg.userId,
          BankId: queryArg.bankId,
          BindingId: queryArg.bindingId,
          IsSelected: queryArg.isSelected,
        },
      }),
    }),
    getApiPaymentPaymentsById: build.query<
      GetApiPaymentPaymentsByIdApiResponse,
      GetApiPaymentPaymentsByIdApiArg
    >({
      query: (queryArg) => ({ url: `/Api/Payment/Payments/${queryArg}` }),
    }),
    getApiPaymentPayments: build.query<
      GetApiPaymentPaymentsApiResponse,
      GetApiPaymentPaymentsApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Payment/Payments`,
        params: {
          BillingId: queryArg.billingId,
          UserId: queryArg.userId,
          BankId: queryArg.bankId,
          CardId: queryArg.cardId,
          OrderNumber: queryArg.orderNumber,
          BankOrderId: queryArg.bankOrderId,
          Status: queryArg.status,
          ErrorReason: queryArg.errorReason,
          Limit: queryArg.limit,
          Offset: queryArg.offset,
          OrderingField: queryArg.orderingField,
          OrderingType: queryArg.orderingType,
        },
      }),
    }),
    getApiPaymentPaymentsCount: build.query<
      GetApiPaymentPaymentsCountApiResponse,
      GetApiPaymentPaymentsCountApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Payment/Payments/count`,
        params: {
          BillingId: queryArg.billingId,
          UserId: queryArg.userId,
          BankId: queryArg.bankId,
          CardId: queryArg.cardId,
          OrderNumber: queryArg.orderNumber,
          BankOrderId: queryArg.bankOrderId,
          Status: queryArg.status,
          ErrorReason: queryArg.errorReason,
        },
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type PostApiPaymentActionsPayApiResponse =
  /** status 200 Success */ ResponseBase;
export type PostApiPaymentActionsPayApiArg = {
  idempotencyKey: string;
  payRequestDto: PayRequestDto;
};
export type PostApiPaymentActionsBindingApiResponse =
  /** status 200 Success */ InitBindingResultDtoResponseBase;
export type PostApiPaymentActionsBindingApiArg = BindingRequestDto;
export type PostApiPaymentActionsUnbindApiResponse =
  /** status 200 Success */ ResponseBase;
export type PostApiPaymentActionsUnbindApiArg = UnbindCardRequestDto;
export type PostApiPaymentActionsHoldApiResponse =
  /** status 200 Success */ ResponseBase;
export type PostApiPaymentActionsHoldApiArg = {
  idempotencyKey?: string;
  holdRequestDto: HoldRequestDto;
};
export type PostApiPaymentActionsDepositApiResponse =
  /** status 200 Success */ ResponseBase;
export type PostApiPaymentActionsDepositApiArg = DepositRequestDto;
export type PostApiPaymentActionsReverseApiResponse =
  /** status 200 Success */ ResponseBase;
export type PostApiPaymentActionsReverseApiArg = ReverseRequestDto;
export type PostApiPaymentActionsSelectcardApiResponse =
  /** status 200 Success */ ResponseBase;
export type PostApiPaymentActionsSelectcardApiArg = SetSelectedCardRequestDto;
export type GetApiPaymentAppInfoApiResponse = /** status 200 Success */ string;
export type GetApiPaymentAppInfoApiArg = void;
export type GetApiPaymentBanksByIdApiResponse =
  /** status 200 Success */ BankDtoResponseBase;
export type GetApiPaymentBanksByIdApiArg = string;
export type PatchApiPaymentBanksByIdApiResponse =
  /** status 200 Success */ BankDtoResponseBase;
export type PatchApiPaymentBanksByIdApiArg = {
  id: string;
  updateBankRequestDto: UpdateBankRequestDto;
};
export type DeleteApiPaymentBanksByIdApiResponse =
  /** status 200 Success */ ResponseBase;
export type DeleteApiPaymentBanksByIdApiArg = string;
export type GetApiPaymentBanksApiResponse =
  /** status 200 Success */ BankDtoIReadOnlyCollectionResponseBase;
export type GetApiPaymentBanksApiArg = GetBanksByFilterRequestDto;
export type PostApiPaymentBanksApiResponse =
  /** status 200 Success */ BankDtoResponseBase;
export type PostApiPaymentBanksApiArg = CreateBankRequestDto;
export type GetApiPaymentBindingLogsByIdApiResponse =
  /** status 200 Success */ BindingLogDtoResponseBase;
export type GetApiPaymentBindingLogsByIdApiArg = string;
export type GetApiPaymentBindingLogsApiResponse =
  /** status 200 Success */ BindingLogDtoIReadOnlyCollectionResponseBase;
export type GetApiPaymentBindingLogsApiArg = {
  userId?: string;
  bankId?: string;
  status?: PaymentStatus;
  orderNumber?: string;
  bankOrderId?: string;
  errorReason?: PaymentErrorReason;
  limit?: number;
  offset?: number;
  orderingField?: string;
  orderingType?: OrderingType;
};
export type GetApiPaymentBindingLogsCountApiResponse =
  /** status 200 Success */ UInt32ResponseBase;
export type GetApiPaymentBindingLogsCountApiArg = {
  userId?: string;
  bankId?: string;
  status?: PaymentStatus;
  orderNumber?: string;
  bankOrderId?: string;
  errorReason?: PaymentErrorReason;
};
export type GetApiPaymentCardsByIdApiResponse =
  /** status 200 Success */ CardDtoResponseBase;
export type GetApiPaymentCardsByIdApiArg = string;
export type GetApiPaymentCardsApiResponse =
  /** status 200 Success */ CardDtoIReadOnlyCollectionResponseBase;
export type GetApiPaymentCardsApiArg = {
  userId?: string;
  bankId?: string;
  bindingId?: string;
  isSelected?: boolean;
  limit?: number;
  offset?: number;
  orderingField?: string;
  orderingType?: OrderingType;
};
export type GetApiPaymentCardsCountApiResponse =
  /** status 200 Success */ UInt32ResponseBase;
export type GetApiPaymentCardsCountApiArg = {
  userId?: string;
  bankId?: string;
  bindingId?: string;
  isSelected?: boolean;
};
export type GetApiPaymentPaymentsByIdApiResponse =
  /** status 200 Success */ PaymentDtoResponseBase;
export type GetApiPaymentPaymentsByIdApiArg = string;
export type GetApiPaymentPaymentsApiResponse =
  /** status 200 Success */ PaymentDtoIReadOnlyCollectionResponseBase;
export type GetApiPaymentPaymentsApiArg = {
  billingId?: string;
  userId?: string;
  bankId?: string;
  cardId?: string;
  orderNumber?: string;
  bankOrderId?: string;
  status?: PaymentStatus;
  errorReason?: PaymentErrorReason;
  limit?: number;
  offset?: number;
  orderingField?: string;
  orderingType?: OrderingType;
};
export type GetApiPaymentPaymentsCountApiResponse =
  /** status 200 Success */ UInt32ResponseBase;
export type GetApiPaymentPaymentsCountApiArg = {
  billingId?: string;
  userId?: string;
  bankId?: string;
  cardId?: string;
  orderNumber?: string;
  bankOrderId?: string;
  status?: PaymentStatus;
  errorReason?: PaymentErrorReason;
};
export type Status = 'SUCCESS' | 'ERROR' | 'PART_UNAVAILABLE';
export type UnavailableServices =
  | 'CHARGE_POINT_SERVICE'
  | 'CP_WS_CONTROLLER'
  | 'CP_SOAP_CONTROLLER'
  | 'BILLING_SERVICE'
  | 'PAYMENT_SERVICE'
  | 'FISCAL_SERVICE'
  | 'SBER_PAY'
  | 'ARMENIA_PAY'
  | 'E_PAY'
  | 'FISCAL_RUSSIA'
  | 'FISCAL_ARMENIA'
  | 'BONUS_SERVICE'
  | 'DISCOUNT_SERVICE'
  | 'MOBILE_API_BFF'
  | 'USER_SERVICE'
  | 'RESERVE_SERVICE'
  | 'OCPI_SERVICE'
  | 'SMS_SERVICE'
  | 'PUSH_NOTIFICATION_SERVICE'
  | 'SYSTEM_INFROMATION_SERVICE'
  | 'IDENTITY_SERVICE'
  | 'ANALYTICS_SERVICE';
export type ResponseBase = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
};
export type PayRequestDto = {
  userId: string;
  billingId: string;
  countryId: string;
  invoiceSum: number;
  phone: string;
  email?: string | null;
};
export type InitBindingResultDto = {
  orderNumber: string;
  formUrl: string;
  returnUrl: string;
};
export type InitBindingResultDtoResponseBase = {
  data?: InitBindingResultDto;
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
};
export type BindingRequestDto = {
  userId: string;
  countryId: string;
  phone: string;
  email?: string | null;
};
export type UnbindCardRequestDto = {
  cardId: string;
};
export type HoldRequestDto = {
  userId: string;
  billingId: string;
  countryId: string;
  holdSum: number;
  phone: string;
  email?: string | null;
};
export type DepositRequestDto = {
  userId: string;
  billingId: string;
  debetSum: number;
};
export type ReverseRequestDto = {
  userId: string;
  billingId: string;
};
export type SetSelectedCardRequestDto = {
  cardId: string;
};
export type BankDto = {
  id: string;
  name: string;
  countryId: string;
  createdDate: string;
  lastUpdate: string;
};
export type BankDtoResponseBase = {
  data?: BankDto;
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
};
export type UpdateBankRequestDto = {
  name?: string | null;
};
export type BankDtoIReadOnlyCollectionResponseBase = {
  data?: BankDto[] | null;
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
};
export type GetBanksByFilterRequestDto = object;
export type CreateBankRequestDto = {
  id: string;
  name: string;
  countryId: string;
};
export type PaymentStatus =
  | 'NEW'
  | 'IN_PROCESS'
  | 'PAID'
  | 'ERROR'
  | 'HOLD'
  | 'DEPOSITED'
  | 'REVERSE'
  | 'ERROR_PAYMENT'
  | 'UNBINDED';
export type PaymentErrorReason =
  | 'UNDEFINED'
  | 'NOT_ENOUGH_MONEY'
  | 'PAY_SERVICE_ERROR'
  | 'BANK_SERVICE_IS_UNAVAILABLE'
  | 'TIMEOUT_ERROR'
  | 'UNKNOWN_ERROR';
export type BindingLogDto = {
  id: string;
  userId: string;
  bankId: string;
  status: PaymentStatus;
  orderNumber: string;
  bankOrderId?: string | null;
  formUrl?: string | null;
  createdDate: string;
  errorReason?: PaymentErrorReason;
  errorText?: string | null;
  responseBank?: string | null;
};
export type BindingLogDtoResponseBase = {
  data?: BindingLogDto;
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
};
export type BindingLogDtoIReadOnlyCollectionResponseBase = {
  data?: BindingLogDto[] | null;
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
};
export type OrderingType = 'ASC' | 'DESC';
export type UInt32ResponseBase = {
  data?: number;
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
};
export type CardDto = {
  id: string;
  userId: string;
  bindingId: string;
  maskedPan: string;
  expirationString: string;
  expirationDate: string;
  isSelected: boolean;
  createdDate: string;
  bank: BankDto;
};
export type CardDtoResponseBase = {
  data?: CardDto;
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
};
export type CardDtoIReadOnlyCollectionResponseBase = {
  data?: CardDto[] | null;
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
};
export type PaymentOperation = 'PAYMENT' | 'HOLDING';
export type PaymentDto = {
  id: string;
  billingId: string;
  userId: string;
  bankId: string;
  cardId: string;
  operation: PaymentOperation;
  orderNumber: string;
  bankOrderId?: string | null;
  invoiceSum: number;
  holdSum?: number | null;
  description?: string | null;
  status: PaymentStatus;
  errorReason?: PaymentErrorReason;
  errorText?: string | null;
  responseBank?: string | null;
  createdDate: string;
  lastUpdate: string;
  idempotencyKey: string;
};
export type PaymentDtoResponseBase = {
  data?: PaymentDto;
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
};
export type PaymentDtoIReadOnlyCollectionResponseBase = {
  data?: PaymentDto[] | null;
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
};
export const {
  usePostApiPaymentActionsPayMutation,
  usePostApiPaymentActionsBindingMutation,
  usePostApiPaymentActionsUnbindMutation,
  usePostApiPaymentActionsHoldMutation,
  usePostApiPaymentActionsDepositMutation,
  usePostApiPaymentActionsReverseMutation,
  usePostApiPaymentActionsSelectcardMutation,
  useGetApiPaymentAppInfoQuery,
  useGetApiPaymentBanksByIdQuery,
  usePatchApiPaymentBanksByIdMutation,
  useDeleteApiPaymentBanksByIdMutation,
  useGetApiPaymentBanksQuery,
  usePostApiPaymentBanksMutation,
  useGetApiPaymentBindingLogsByIdQuery,
  useGetApiPaymentBindingLogsQuery,
  useGetApiPaymentBindingLogsCountQuery,
  useGetApiPaymentCardsByIdQuery,
  useGetApiPaymentCardsQuery,
  useGetApiPaymentCardsCountQuery,
  useGetApiPaymentPaymentsByIdQuery,
  useGetApiPaymentPaymentsQuery,
  useGetApiPaymentPaymentsCountQuery,
} = injectedRtkApi;
