import { enhancedApi as api } from '../generated';

export const enhancedApi = api.enhanceEndpoints({
  endpoints: {
    // Сессии
    getApiReserveSessions: (endpoint) => {
      endpoint.providesTags = [{ type: 'ReserveSessions', id: 'LIST' }];
    },
    // Объекты резерва
  },
});

export const {
  useGetApiReserveSessionsQuery: useGetReserveSessions,
  useGetApiReserveSessionsCountQuery: useGetReserveSessionsCount,
  useGetApiReserveSessionsByIdQuery: useGetReserveSession,
} = enhancedApi;
