import { useNavigate } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import { Col, Spin } from 'antd';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';

import { CustomSelect } from 'shared/ui/form/custom-select';
import { CustomInput } from 'shared/ui/form/custom-input';
import { useAddChargePoint } from 'shared/api/services/chargepoint/rtk';
import { CHARGE_POINT_ROUTES } from 'shared/consts/routes/charge-point';
import {
  isErrorWithMessage,
  openErrorNotification,
  openSuccessNotification,
} from 'shared/lib';
import { GetGroupVm } from 'shared/api/services/chargepoint/rtk/generated/groups';
import { GetHubVm } from 'shared/api/services/chargepoint/rtk/generated/hubs';
import { CompanyDto } from 'shared/api/services/company/rtk/generated';
import { CustomTextarea } from 'shared/ui/form/custom-textarea';
import { CustomCheckbox } from 'shared/ui/form/custom-checkbox';
import {
  FormControlsContainer,
  UiCancelFormButton,
  UiSubmitButton,
  ButtonsContainer,
} from 'shared/ui';

import { buildOcppConfigObject } from 'entities/charge-point/lib/build-ocpp-config-object';
import {
  CHARGE_POINT_PROTOCOL,
  CHARGE_POINT_SPEED_TYPE,
  CHARGE_POINT_STATUS,
  COUNTRY_CODE,
  InfrastructureInfo,
  INFRASTRUCTURE_INFO,
} from 'entities/charge-point/consts';

import {
  FormOutput,
  FormInput,
  FormSchema,
  DEFAULT_VALUES,
} from '../../consts/schema';
import { ADD_ERROR, ADD_SUCCESS } from '../../consts';

import { FormRow, CardFormWrapper } from '../../../../../common-styles';
import { StyledTitle } from './styles';

type Props = {
  groups: GetGroupVm[];
  hubs: GetHubVm[];
  companies: CompanyDto[];
};

export function Form({ groups, hubs, companies }: Props) {
  const form = useForm<FormInput, void, FormOutput>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      ...DEFAULT_VALUES,
    },
  });

  const [trigger, { isLoading }] = useAddChargePoint();

  const navigate = useNavigate();

  const handleSubmit = form.handleSubmit(async (data) => {
    const { coordinates, ocppConfig, infrastructureInfo, ...rest } = data;

    const [latitude, longitude] = coordinates.split(',');

    try {
      const req = {
        ...rest,
        ocppConfig: buildOcppConfigObject(ocppConfig),
        latitude: Number(latitude.trim()),
        longitude: Number(longitude.trim()),
        infrastructureInfo: infrastructureInfo.length
          ? JSON.stringify(
              infrastructureInfo.reduce((acc, el) => {
                acc[el] = 'true';

                return acc;
              }, {} as Record<string, string>)
            )
          : null,
      };

      const res = await trigger(req).unwrap();

      openSuccessNotification(ADD_SUCCESS);

      navigate(CHARGE_POINT_ROUTES.CHARGE_POINTS);
    } catch (err) {
      const hasErrorMessage = isErrorWithMessage(err);

      const errorText = hasErrorMessage ? err.data.statusMessage : ADD_ERROR;

      openErrorNotification(errorText);
    }
  });

  return (
    <CardFormWrapper>
      <FormProvider {...form}>
        <Spin spinning={isLoading}>
          <form onSubmit={handleSubmit}>
            <Form.Fields groups={groups} hubs={hubs} companies={companies} />
            <Form.Buttons />
          </form>
        </Spin>
      </FormProvider>
    </CardFormWrapper>
  );
}

type FieldsProps = {
  groups: GetGroupVm[];
  hubs: GetHubVm[];
  companies: CompanyDto[];
};

Form.Fields = function Fields({ companies, groups, hubs }: FieldsProps) {
  const {
    formState: { errors },
  } = useFormContext<FormInput, void, FormOutput>();

  return (
    <>
      <FormRow gutter={40}>
        <Col span={12}>
          <StyledTitle>О станции</StyledTitle>
          <FormRow gutter={20}>
            <Col span={12}>
              <CustomInput<FormInput> name="name" label="Название" required />
            </Col>
            <Col span={12}>
              <CustomInput<FormInput>
                name="coordinates"
                label="Координаты"
                required
              />
            </Col>
          </FormRow>
          <FormRow gutter={20}>
            <Col span={12}>
              <CustomInput<FormInput> name="address" label="Адрес" required />
            </Col>
            <Col span={12}>
              <CustomInput<FormInput>
                name="maxPower"
                label="Максимальная мощность"
                required
              />
            </Col>
          </FormRow>
          <FormRow gutter={20}>
            <Col span={12}>
              <CustomInput<FormInput> name="city" label="Город" required />
            </Col>
            <Col span={12}>
              <CustomSelect<FormInput>
                name="status"
                label="Статус"
                options={Object.entries(CHARGE_POINT_STATUS).map((entry) => {
                  const [key, label] = entry;

                  return {
                    value: key,
                    label,
                  };
                })}
                required
              />
            </Col>
          </FormRow>
          <FormRow gutter={20}>
            <Col span={12}>
              <CustomSelect<FormInput>
                name="groupId"
                label="Группа"
                options={groups.map(({ id, name }) => ({
                  value: id,
                  label: name,
                }))}
                allowClear
                required
              />
            </Col>
            <Col span={12}>
              <CustomSelect<FormInput>
                name="companyId"
                label="Владелец"
                options={companies.map(({ id, name }) => ({
                  value: id,
                  label: name,
                }))}
                allowClear
                required
              />
            </Col>
          </FormRow>
          <FormRow gutter={20}>
            <Col span={12}>
              <CustomSelect<FormInput>
                name="hubId"
                label="Хаб"
                options={hubs.map(({ id, name }) => ({
                  value: id,
                  label: name,
                }))}
                allowClear
              />
            </Col>
            <Col span={12}>
              <CustomSelect<FormInput>
                name=""
                label="Тариф"
                options={hubs.map(({ id, name }) => ({
                  value: id,
                  label: name,
                }))}
                allowClear
                disabled
              />
            </Col>
          </FormRow>
          <FormRow gutter={20}>
            <Col span={12}>
              <CustomSelect<FormInput>
                name="infrastructureInfo"
                label="Что рядом"
                options={Object.entries(INFRASTRUCTURE_INFO).map((entry) => {
                  const [key, label] = entry;

                  return {
                    value: key,
                    label,
                  };
                })}
                mode="multiple"
              />
            </Col>
            <Col span={12}>
              <CustomInput<FormInput>
                name=""
                label="Макс. % оплаты баллами"
                disabled
              />
            </Col>
          </FormRow>
          <FormRow gutter={20}>
            <Col span={12}>
              <CustomInput<FormInput> name="workingTime" label="Время работы" />
            </Col>
            <Col span={12}>
              <CustomInput<FormInput>
                name=""
                label="Доступны скидки"
                disabled
              />
            </Col>
          </FormRow>
          <FormRow gutter={20}>
            <Col span={12}>
              <CustomTextarea<FormInput>
                name="publicDescription"
                label="Публичное описание"
              />
            </Col>
            <Col span={12}>
              <CustomSelect<FormInput>
                name="countryCode"
                label="Код страны"
                options={Object.entries(COUNTRY_CODE).map((entry) => {
                  const [key, label] = entry;

                  return {
                    value: key,
                    label,
                  };
                })}
                required
              />
            </Col>
          </FormRow>
        </Col>

        <Col span={12}>
          <StyledTitle>Техническая информация</StyledTitle>

          <FormRow gutter={20}>
            <Col span={12}>
              <CustomSelect<FormInput>
                name="speedType"
                label="Тип станции"
                options={Object.entries(CHARGE_POINT_SPEED_TYPE).map(
                  (entry) => {
                    const [key, label] = entry;

                    return {
                      value: key,
                      label,
                    };
                  }
                )}
                required
              />
            </Col>
            <Col span={12}>
              <CustomInput<FormInput> name="simNumber" label="Номер SIM" />
            </Col>
          </FormRow>

          <FormRow gutter={20}>
            <Col span={12}>
              <CustomInput<FormInput>
                name="manufacturer"
                label="Производитель"
              />
            </Col>
            <Col span={12}>
              <CustomInput<FormInput>
                name="commentary"
                label="Внутреннее описание"
              />
            </Col>
          </FormRow>

          <FormRow gutter={20}>
            <Col span={12}>
              <CustomInput<FormInput> name="number" label="Cерийный номер" />
            </Col>
            <Col span={12}>
              <CustomInput<FormInput>
                name="chargeBoxIdentity"
                label="СhargeBoxIdentity"
                required
              />
            </Col>
          </FormRow>

          <FormRow gutter={20}>
            <Col span={12}>
              <CustomSelect<FormInput>
                name="chargePointProtocol"
                label="Протокол ЭЗС"
                options={Object.entries(CHARGE_POINT_PROTOCOL).map((entry) => {
                  const [key, label] = entry;

                  return {
                    value: key,
                    label,
                  };
                })}
                required
              />
            </Col>
            <Col span={12}>
              <CustomInput<FormInput>
                name="ipAddress"
                label="IP-адрес"
                required
              />
            </Col>
          </FormRow>
          <FormRow gutter={20}>
            <Col span={12}>
              <CustomInput<FormInput>
                name="softwareRevision"
                label="Версия ПО"
              />
            </Col>
            <Col span={12}>
              <CustomInput<FormInput>
                name="userLocationCheckDistance"
                label="Макс. расстояние до пользователя"
              />
            </Col>
          </FormRow>

          <FormRow gutter={20}>
            <Col span={12}>
              <CustomTextarea<FormInput>
                name="ocppConfig"
                label="OCPP конфиг"
              />
            </Col>
            <Col span={12}>
              <CustomTextarea<FormInput>
                name="warningText"
                label="Аварийное сообщение в приложении"
              />
            </Col>
          </FormRow>

          <FormRow gutter={20}>
            <Col>
              <CustomCheckbox<FormInput>
                name="isVisible"
                label="Показывать на карте"
              />
            </Col>
          </FormRow>
          <FormRow gutter={20}>
            <Col>
              <CustomCheckbox<FormInput> name="isTestCp" label="Тестовая" />
            </Col>
          </FormRow>
          <FormRow gutter={20}>
            <Col>
              <CustomCheckbox<FormInput>
                name="multiconnectorSupport"
                label="Поддержка мультиконнектора (не убирать без причины)"
              />
            </Col>
          </FormRow>
        </Col>
      </FormRow>
    </>
  );
};

Form.Buttons = function Buttons() {
  return (
    <FormControlsContainer>
      <ButtonsContainer>
        <UiSubmitButton />
        <UiCancelFormButton />
      </ButtonsContainer>
    </FormControlsContainer>
  );
};
