import { Route } from 'react-router-dom';

import { MainContentLayout, SectionLayout } from 'shared/ui/layout';
import { ROLE } from 'shared/consts/routes/identity-role';

import { RequireAuth } from 'entities/authorization';

// import { ConnectedRoleChargePointRights } from '../../../components/Security/Role/components/ConnectedRoleChargePointRights';
// import { ConnectedRoleControllerRights } from '../../../components/Security/Role/components//ConnectedRoleControllerRights';

import { IdentityRolesLayout } from 'entities/identity-role/ui/roles-layout';
import { IdentityRoleLayout } from 'entities/identity-role/ui/role-layout';

import { AddIdentityRolePage } from 'pages/identity-role/add';
import { EditIdentityRolePage } from 'pages/identity-role/edit';

import { IdentityRolesMenu } from 'features/identity-role/menu';
import { RoleListTable } from 'features/identity-role/table';
import { RoleUserListTable } from 'features/identity-role/users-table';
import {
  ADD_PREFIX,
  IDENTITY_ROLES,
  IDENTITY_USERS,
} from 'shared/consts/names';

export const RoleRoutes = (
  <Route element={<RequireAuth controllerName="Identity" rightType="Read" />}>
    <Route
      path={IDENTITY_ROLES}
      element={<SectionLayout menu={<IdentityRolesMenu />} />}
    >
      <Route element={<IdentityRolesLayout />}>
        <Route index element={<RoleListTable />} />
      </Route>

      <Route
        element={<RequireAuth controllerName="Identity" rightType="Write" />}
      >
        <Route
          path={ADD_PREFIX}
          element={<MainContentLayout element={<AddIdentityRolePage />} />}
        />
      </Route>
      <Route path={ROLE} element={<IdentityRoleLayout />}>
        <Route index element={<EditIdentityRolePage />} />
        {/* <Route
          path={CONTROLLER_CLAIMS}
          element={<ConnectedRoleControllerRights />}
        />
        <Route
          path={CHARGE_POINTS_RIGHTS}
          element={<ConnectedRoleChargePointRights />}
        /> */}
        <Route path={IDENTITY_USERS} element={<RoleUserListTable />} />
      </Route>
    </Route>
  </Route>
);
