import { baseApi as api } from '../../../../base-new/base-api';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getApiBillingBalances: build.query<
      GetApiBillingBalancesApiResponse,
      GetApiBillingBalancesApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Billing/Balances`,
        params: {
          BalanceId: queryArg.balanceId,
          UserId: queryArg.userId,
          DateFrom: queryArg.dateFrom,
          DateTo: queryArg.dateTo,
          Limit: queryArg.limit,
          Offset: queryArg.offset,
          OrderingField: queryArg.orderingField,
          OrderingType: queryArg.orderingType,
        },
      }),
    }),
    getApiBillingBalancesCount: build.query<
      GetApiBillingBalancesCountApiResponse,
      GetApiBillingBalancesCountApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Billing/Balances/count`,
        params: {
          BalanceId: queryArg.balanceId,
          UserId: queryArg.userId,
          DateFrom: queryArg.dateFrom,
          DateTo: queryArg.dateTo,
        },
      }),
    }),
    getApiBillingBalancesTransactions: build.query<
      GetApiBillingBalancesTransactionsApiResponse,
      GetApiBillingBalancesTransactionsApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Billing/Balances/transactions`,
        params: {
          BalanceId: queryArg.balanceId,
          UserId: queryArg.userId,
          Operation: queryArg.operation,
          DateFrom: queryArg.dateFrom,
          DateTo: queryArg.dateTo,
          Limit: queryArg.limit,
          Offset: queryArg.offset,
          OrderingField: queryArg.orderingField,
          OrderingType: queryArg.orderingType,
        },
      }),
    }),
    getApiBillingBalancesTransactionsCount: build.query<
      GetApiBillingBalancesTransactionsCountApiResponse,
      GetApiBillingBalancesTransactionsCountApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Billing/Balances/transactions/count`,
        params: {
          BalanceId: queryArg.balanceId,
          UserId: queryArg.userId,
          Operation: queryArg.operation,
          DateFrom: queryArg.dateFrom,
          DateTo: queryArg.dateTo,
        },
      }),
    }),
    postApiBillingBalancesActionCreate: build.mutation<
      PostApiBillingBalancesActionCreateApiResponse,
      PostApiBillingBalancesActionCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Billing/Balances/action/create`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    postApiBillingBalancesActionSet: build.mutation<
      PostApiBillingBalancesActionSetApiResponse,
      PostApiBillingBalancesActionSetApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Billing/Balances/action/set`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    postApiBillingBalancesActionCredit: build.mutation<
      PostApiBillingBalancesActionCreditApiResponse,
      PostApiBillingBalancesActionCreditApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Billing/Balances/action/credit`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    postApiBillingBalancesActionDebit: build.mutation<
      PostApiBillingBalancesActionDebitApiResponse,
      PostApiBillingBalancesActionDebitApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Billing/Balances/action/debit`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    postApiBillingBalancesActionReset: build.mutation<
      PostApiBillingBalancesActionResetApiResponse,
      PostApiBillingBalancesActionResetApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Billing/Balances/action/reset`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    postApiBillingBalancesActionFreeze: build.mutation<
      PostApiBillingBalancesActionFreezeApiResponse,
      PostApiBillingBalancesActionFreezeApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Billing/Balances/action/freeze`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    postApiBillingBalancesActionUnfreeze: build.mutation<
      PostApiBillingBalancesActionUnfreezeApiResponse,
      PostApiBillingBalancesActionUnfreezeApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Billing/Balances/action/unfreeze`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    getApiBillingBillingsByBillingId: build.query<
      GetApiBillingBillingsByBillingIdApiResponse,
      GetApiBillingBillingsByBillingIdApiArg
    >({
      query: (queryArg) => ({ url: `/Api/Billing/Billings/${queryArg}` }),
    }),
    getApiBillingBillings: build.query<
      GetApiBillingBillingsApiResponse,
      GetApiBillingBillingsApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Billing/Billings`,
        params: {
          BalanceId: queryArg.balanceId,
          SessionId: queryArg.sessionId,
          UserId: queryArg.userId,
          Status: queryArg.status,
          DateFrom: queryArg.dateFrom,
          DateTo: queryArg.dateTo,
          ServiceType: queryArg.serviceType,
          Limit: queryArg.limit,
          Offset: queryArg.offset,
          OrderingField: queryArg.orderingField,
          OrderingType: queryArg.orderingType,
        },
      }),
    }),
    getApiBillingBillingsCount: build.query<
      GetApiBillingBillingsCountApiResponse,
      GetApiBillingBillingsCountApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Billing/Billings/count`,
        params: {
          BalanceId: queryArg.balanceId,
          SessionId: queryArg.sessionId,
          UserId: queryArg.userId,
          Status: queryArg.status,
          DateFrom: queryArg.dateFrom,
          DateTo: queryArg.dateTo,
          ServiceType: queryArg.serviceType,
        },
      }),
    }),
    postApiBillingBillingsActionClose: build.mutation<
      PostApiBillingBillingsActionCloseApiResponse,
      PostApiBillingBillingsActionCloseApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Billing/Billings/action/close`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    postApiBillingBillingsActionCloseactive: build.mutation<
      PostApiBillingBillingsActionCloseactiveApiResponse,
      PostApiBillingBillingsActionCloseactiveApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Billing/Billings/action/closeactive`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    postApiBillingBillingsActionCalculatelast: build.mutation<
      PostApiBillingBillingsActionCalculatelastApiResponse,
      PostApiBillingBillingsActionCalculatelastApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Billing/Billings/action/calculatelast`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    getApiBillingReportsUsersPaymentSum: build.query<
      GetApiBillingReportsUsersPaymentSumApiResponse,
      GetApiBillingReportsUsersPaymentSumApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Billing/Reports/usersPaymentSum`,
        params: {
          DateFrom: queryArg.dateFrom,
          DateTo: queryArg.dateTo,
          BalancesIds: queryArg.balancesIds,
        },
      }),
    }),
    getApiBillingReportsUsersInvoiceSum: build.query<
      GetApiBillingReportsUsersInvoiceSumApiResponse,
      GetApiBillingReportsUsersInvoiceSumApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Billing/Reports/usersInvoiceSum`,
        params: {
          DateFrom: queryArg.dateFrom,
          DateTo: queryArg.dateTo,
          CountryId: queryArg.countryId,
        },
      }),
    }),
    postApiBillingActionBegin: build.mutation<
      PostApiBillingActionBeginApiResponse,
      PostApiBillingActionBeginApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Billing/action/begin`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    postApiBillingActionEnd: build.mutation<
      PostApiBillingActionEndApiResponse,
      PostApiBillingActionEndApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Billing/action/end`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    postApiBillingActionEndactive: build.mutation<
      PostApiBillingActionEndactiveApiResponse,
      PostApiBillingActionEndactiveApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Billing/action/endactive`,
        method: 'POST',
        params: { userId: queryArg },
      }),
    }),
    postApiBillingServices: build.mutation<
      PostApiBillingServicesApiResponse,
      PostApiBillingServicesApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Billing/Services`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    getApiBillingServices: build.query<
      GetApiBillingServicesApiResponse,
      GetApiBillingServicesApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Billing/Services`,
        params: {
          Limit: queryArg.limit,
          Offset: queryArg.offset,
          OrderingField: queryArg.orderingField,
          OrderingType: queryArg.orderingType,
        },
      }),
    }),
    getApiBillingServicesById: build.query<
      GetApiBillingServicesByIdApiResponse,
      GetApiBillingServicesByIdApiArg
    >({
      query: (queryArg) => ({ url: `/Api/Billing/Services/${queryArg}` }),
    }),
    deleteApiBillingServicesById: build.mutation<
      DeleteApiBillingServicesByIdApiResponse,
      DeleteApiBillingServicesByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Billing/Services/${queryArg}`,
        method: 'DELETE',
      }),
    }),
    getApiBillingServicesCount: build.query<
      GetApiBillingServicesCountApiResponse,
      GetApiBillingServicesCountApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Billing/Services/count`,
        params: { requestDto: queryArg },
      }),
    }),
    postApiBillingServicesListLinks: build.mutation<
      PostApiBillingServicesListLinksApiResponse,
      PostApiBillingServicesListLinksApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Billing/ServicesListLinks`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    getApiBillingServicesListLinks: build.query<
      GetApiBillingServicesListLinksApiResponse,
      GetApiBillingServicesListLinksApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Billing/ServicesListLinks`,
        params: {
          ServicesListId: queryArg.servicesListId,
          Limit: queryArg.limit,
          Offset: queryArg.offset,
        },
      }),
    }),
    getApiBillingServicesListLinksCount: build.query<
      GetApiBillingServicesListLinksCountApiResponse,
      GetApiBillingServicesListLinksCountApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Billing/ServicesListLinks/count`,
        params: { ServicesListId: queryArg },
      }),
    }),
    deleteApiBillingServicesListLinksById: build.mutation<
      DeleteApiBillingServicesListLinksByIdApiResponse,
      DeleteApiBillingServicesListLinksByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Billing/ServicesListLinks/${queryArg}`,
        method: 'DELETE',
      }),
    }),
    getApiBillingServicesListsById: build.query<
      GetApiBillingServicesListsByIdApiResponse,
      GetApiBillingServicesListsByIdApiArg
    >({
      query: (queryArg) => ({ url: `/Api/Billing/ServicesLists/${queryArg}` }),
    }),
    patchApiBillingServicesListsById: build.mutation<
      PatchApiBillingServicesListsByIdApiResponse,
      PatchApiBillingServicesListsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Billing/ServicesLists/${queryArg.id}`,
        method: 'PATCH',
        body: queryArg.updateServicesListRequestDto,
      }),
    }),
    deleteApiBillingServicesListsById: build.mutation<
      DeleteApiBillingServicesListsByIdApiResponse,
      DeleteApiBillingServicesListsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Billing/ServicesLists/${queryArg}`,
        method: 'DELETE',
      }),
    }),
    getApiBillingServicesLists: build.query<
      GetApiBillingServicesListsApiResponse,
      GetApiBillingServicesListsApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Billing/ServicesLists`,
        params: {
          ServiceId: queryArg.serviceId,
          Limit: queryArg.limit,
          Offset: queryArg.offset,
        },
      }),
    }),
    postApiBillingServicesLists: build.mutation<
      PostApiBillingServicesListsApiResponse,
      PostApiBillingServicesListsApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Billing/ServicesLists`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    getApiBillingServicesListsCount: build.query<
      GetApiBillingServicesListsCountApiResponse,
      GetApiBillingServicesListsCountApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Billing/ServicesLists/count`,
        params: { ServiceId: queryArg },
      }),
    }),
    postApiBillingStages: build.mutation<
      PostApiBillingStagesApiResponse,
      PostApiBillingStagesApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Billing/Stages`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    getApiBillingStages: build.query<
      GetApiBillingStagesApiResponse,
      GetApiBillingStagesApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Billing/Stages`,
        params: {
          Limit: queryArg.limit,
          Offset: queryArg.offset,
          OrderingField: queryArg.orderingField,
          OrderingType: queryArg.orderingType,
        },
      }),
    }),
    getApiBillingStagesById: build.query<
      GetApiBillingStagesByIdApiResponse,
      GetApiBillingStagesByIdApiArg
    >({
      query: (queryArg) => ({ url: `/Api/Billing/Stages/${queryArg}` }),
    }),
    deleteApiBillingStagesById: build.mutation<
      DeleteApiBillingStagesByIdApiResponse,
      DeleteApiBillingStagesByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Billing/Stages/${queryArg}`,
        method: 'DELETE',
      }),
    }),
    getApiBillingStagesCount: build.query<
      GetApiBillingStagesCountApiResponse,
      GetApiBillingStagesCountApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Billing/Stages/count`,
        params: { requestDto: queryArg },
      }),
    }),
    getApiBillingTargetsServicesLists: build.query<
      GetApiBillingTargetsServicesListsApiResponse,
      GetApiBillingTargetsServicesListsApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Billing/TargetsServicesLists`,
        params: { TargetsIds: queryArg },
      }),
    }),
    postApiBillingTariffs: build.mutation<
      PostApiBillingTariffsApiResponse,
      PostApiBillingTariffsApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Billing/Tariffs`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    getApiBillingTariffs: build.query<
      GetApiBillingTariffsApiResponse,
      GetApiBillingTariffsApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Billing/Tariffs`,
        params: {
          Limit: queryArg.limit,
          Offset: queryArg.offset,
          OrderingField: queryArg.orderingField,
          OrderingType: queryArg.orderingType,
        },
      }),
    }),
    getApiBillingTariffsById: build.query<
      GetApiBillingTariffsByIdApiResponse,
      GetApiBillingTariffsByIdApiArg
    >({
      query: (queryArg) => ({ url: `/Api/Billing/Tariffs/${queryArg}` }),
    }),
    deleteApiBillingTariffsById: build.mutation<
      DeleteApiBillingTariffsByIdApiResponse,
      DeleteApiBillingTariffsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Billing/Tariffs/${queryArg}`,
        method: 'DELETE',
      }),
    }),
    getApiBillingTariffsCount: build.query<
      GetApiBillingTariffsCountApiResponse,
      GetApiBillingTariffsCountApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Billing/Tariffs/count`,
        params: { requestDto: queryArg },
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type GetApiBillingBalancesApiResponse =
  /** status 200 Success */ BalanceDtoIReadOnlyCollectionResponseBase;
export type GetApiBillingBalancesApiArg = {
  balanceId?: string;
  userId?: string;
  dateFrom?: string;
  dateTo?: string;
  limit?: number;
  offset?: number;
  orderingField?: string;
  orderingType?: OrderingType;
};
export type GetApiBillingBalancesCountApiResponse =
  /** status 200 Success */ UInt32ResponseBase;
export type GetApiBillingBalancesCountApiArg = {
  balanceId?: string;
  userId?: string;
  dateFrom?: string;
  dateTo?: string;
};
export type GetApiBillingBalancesTransactionsApiResponse =
  /** status 200 Success */ BalanceTransactionDtoIReadOnlyCollectionResponseBase;
export type GetApiBillingBalancesTransactionsApiArg = {
  balanceId?: string;
  userId?: string;
  operation?: Operation;
  dateFrom?: string;
  dateTo?: string;
  limit?: number;
  offset?: number;
  orderingField?: string;
  orderingType?: OrderingType;
};
export type GetApiBillingBalancesTransactionsCountApiResponse =
  /** status 200 Success */ UInt32ResponseBase;
export type GetApiBillingBalancesTransactionsCountApiArg = {
  balanceId?: string;
  userId?: string;
  operation?: Operation;
  dateFrom?: string;
  dateTo?: string;
};
export type PostApiBillingBalancesActionCreateApiResponse =
  /** status 200 Success */ GuidResponseBase;
export type PostApiBillingBalancesActionCreateApiArg = CreateBalanceRequestDto;
export type PostApiBillingBalancesActionSetApiResponse =
  /** status 200 Success */ ResponseBase;
export type PostApiBillingBalancesActionSetApiArg = SetBalanceRequestDto;
export type PostApiBillingBalancesActionCreditApiResponse =
  /** status 200 Success */ ResponseBase;
export type PostApiBillingBalancesActionCreditApiArg = CreditBalanceRequestDto;
export type PostApiBillingBalancesActionDebitApiResponse =
  /** status 200 Success */ ResponseBase;
export type PostApiBillingBalancesActionDebitApiArg = DebitBalanceRequestDto;
export type PostApiBillingBalancesActionResetApiResponse =
  /** status 200 Success */ ResponseBase;
export type PostApiBillingBalancesActionResetApiArg = ResetBalanceRequestDto;
export type PostApiBillingBalancesActionFreezeApiResponse =
  /** status 200 Success */ ResponseBase;
export type PostApiBillingBalancesActionFreezeApiArg = FreezeBalanceRequestDto;
export type PostApiBillingBalancesActionUnfreezeApiResponse =
  /** status 200 Success */ ResponseBase;
export type PostApiBillingBalancesActionUnfreezeApiArg =
  UnfreezeBalanceRequestDto;
export type GetApiBillingBillingsByBillingIdApiResponse =
  /** status 200 Success */ BillingDtoResponseBase;
export type GetApiBillingBillingsByBillingIdApiArg = string;
export type GetApiBillingBillingsApiResponse =
  /** status 200 Success */ BillingDtoIReadOnlyCollectionResponseBase;
export type GetApiBillingBillingsApiArg = {
  balanceId?: string;
  sessionId?: string;
  userId?: string;
  status?: BillingStatus;
  dateFrom?: string;
  dateTo?: string;
  serviceType?: ServiceType;
  limit?: number;
  offset?: number;
  orderingField?: string;
  orderingType?: OrderingType;
};
export type GetApiBillingBillingsCountApiResponse =
  /** status 200 Success */ UInt32ResponseBase;
export type GetApiBillingBillingsCountApiArg = {
  balanceId?: string;
  sessionId?: string;
  userId?: string;
  status?: BillingStatus;
  dateFrom?: string;
  dateTo?: string;
  serviceType?: ServiceType;
};
export type PostApiBillingBillingsActionCloseApiResponse =
  /** status 200 Success */ ResponseBase;
export type PostApiBillingBillingsActionCloseApiArg = CloseBillingRequestDto;
export type PostApiBillingBillingsActionCloseactiveApiResponse =
  /** status 200 Success */ ResponseBase;
export type PostApiBillingBillingsActionCloseactiveApiArg =
  CloseActiveBillingRequestDto;
export type PostApiBillingBillingsActionCalculatelastApiResponse =
  /** status 200 Success */ BillingDtoResponseBase;
export type PostApiBillingBillingsActionCalculatelastApiArg =
  CalculateUserLastBillingPaymentRequestDto;
export type GetApiBillingReportsUsersPaymentSumApiResponse =
  /** status 200 Success */ UsersPaymentSumReportDtoResponseBase;
export type GetApiBillingReportsUsersPaymentSumApiArg = {
  dateFrom?: string;
  dateTo?: string;
  balancesIds?: string[];
};
export type GetApiBillingReportsUsersInvoiceSumApiResponse =
  /** status 200 Success */ UsersInvoiceSumReportDtoResponseBase;
export type GetApiBillingReportsUsersInvoiceSumApiArg = {
  dateFrom?: string;
  dateTo?: string;
  countryId?: string;
};
export type PostApiBillingActionBeginApiResponse =
  /** status 200 Success */ GuidResponseBase;
export type PostApiBillingActionBeginApiArg = BeginProvidedServiceRequestDto;
export type PostApiBillingActionEndApiResponse =
  /** status 200 Success */ ResponseBase;
export type PostApiBillingActionEndApiArg = CompleteProvidedServiceRequestDto;
export type PostApiBillingActionEndactiveApiResponse =
  /** status 200 Success */ ResponseBase;
export type PostApiBillingActionEndactiveApiArg = string;
export type PostApiBillingServicesApiResponse =
  /** status 200 Success */ ServiceDtoResponseBase;
export type PostApiBillingServicesApiArg = CreateServiceRequestDto;
export type GetApiBillingServicesApiResponse =
  /** status 200 Success */ ServiceDtoIReadOnlyCollectionResponseBase;
export type GetApiBillingServicesApiArg = {
  limit?: number;
  offset?: number;
  orderingField?: string;
  orderingType?: OrderingType;
};
export type GetApiBillingServicesByIdApiResponse =
  /** status 200 Success */ ServiceDtoResponseBase;
export type GetApiBillingServicesByIdApiArg = string;
export type DeleteApiBillingServicesByIdApiResponse =
  /** status 200 Success */ ResponseBase;
export type DeleteApiBillingServicesByIdApiArg = string;
export type GetApiBillingServicesCountApiResponse =
  /** status 200 Success */ UInt32ResponseBase;
export type GetApiBillingServicesCountApiArg =
  GetServicesCountByFilterRequestDto;
export type PostApiBillingServicesListLinksApiResponse =
  /** status 200 Success */ ServicesListLinkDtoResponseBase;
export type PostApiBillingServicesListLinksApiArg =
  CreateServicesListLinkRequestDto;
export type GetApiBillingServicesListLinksApiResponse =
  /** status 200 Success */ ServicesListLinkDtoIReadOnlyCollectionResponseBase;
export type GetApiBillingServicesListLinksApiArg = {
  servicesListId?: string;
  limit?: number;
  offset?: number;
};
export type GetApiBillingServicesListLinksCountApiResponse =
  /** status 200 Success */ UInt32ResponseBase;
export type GetApiBillingServicesListLinksCountApiArg = string;
export type DeleteApiBillingServicesListLinksByIdApiResponse =
  /** status 200 Success */ ResponseBase;
export type DeleteApiBillingServicesListLinksByIdApiArg = string;
export type GetApiBillingServicesListsByIdApiResponse =
  /** status 200 Success */ ServicesListDtoResponseBase;
export type GetApiBillingServicesListsByIdApiArg = string;
export type PatchApiBillingServicesListsByIdApiResponse =
  /** status 200 Success */ ServicesListDtoResponseBase;
export type PatchApiBillingServicesListsByIdApiArg = {
  id: string;
  updateServicesListRequestDto: UpdateServicesListRequestDto;
};
export type DeleteApiBillingServicesListsByIdApiResponse =
  /** status 200 Success */ ResponseBase;
export type DeleteApiBillingServicesListsByIdApiArg = string;
export type GetApiBillingServicesListsApiResponse =
  /** status 200 Success */ ServicesListDtoIReadOnlyCollectionResponseBase;
export type GetApiBillingServicesListsApiArg = {
  serviceId?: string;
  limit?: number;
  offset?: number;
};
export type PostApiBillingServicesListsApiResponse =
  /** status 200 Success */ ServicesListDtoResponseBase;
export type PostApiBillingServicesListsApiArg = CreateServicesListRequestDto;
export type GetApiBillingServicesListsCountApiResponse =
  /** status 200 Success */ UInt32ResponseBase;
export type GetApiBillingServicesListsCountApiArg = string;
export type PostApiBillingStagesApiResponse =
  /** status 200 Success */ StageDtoResponseBase;
export type PostApiBillingStagesApiArg = CreateStageRequestDto;
export type GetApiBillingStagesApiResponse =
  /** status 200 Success */ StageDtoIReadOnlyCollectionResponseBase;
export type GetApiBillingStagesApiArg = {
  limit?: number;
  offset?: number;
  orderingField?: string;
  orderingType?: OrderingType;
};
export type GetApiBillingStagesByIdApiResponse =
  /** status 200 Success */ StageDtoResponseBase;
export type GetApiBillingStagesByIdApiArg = string;
export type DeleteApiBillingStagesByIdApiResponse =
  /** status 200 Success */ ResponseBase;
export type DeleteApiBillingStagesByIdApiArg = string;
export type GetApiBillingStagesCountApiResponse =
  /** status 200 Success */ UInt32ResponseBase;
export type GetApiBillingStagesCountApiArg = GetStagesCountByFilterRequestDto;
export type GetApiBillingTargetsServicesListsApiResponse =
  /** status 200 Success */ TargetsServicesListsDtoIReadOnlyCollectionResponseBase;
export type GetApiBillingTargetsServicesListsApiArg = string[];
export type PostApiBillingTariffsApiResponse =
  /** status 200 Success */ TariffDtoResponseBase;
export type PostApiBillingTariffsApiArg = CreateTariffRequestDto;
export type GetApiBillingTariffsApiResponse =
  /** status 200 Success */ TariffDtoIReadOnlyCollectionResponseBase;
export type GetApiBillingTariffsApiArg = {
  limit?: number;
  offset?: number;
  orderingField?: string;
  orderingType?: OrderingType;
};
export type GetApiBillingTariffsByIdApiResponse =
  /** status 200 Success */ TariffDtoResponseBase;
export type GetApiBillingTariffsByIdApiArg = string;
export type DeleteApiBillingTariffsByIdApiResponse =
  /** status 200 Success */ ResponseBase;
export type DeleteApiBillingTariffsByIdApiArg = string;
export type GetApiBillingTariffsCountApiResponse =
  /** status 200 Success */ UInt32ResponseBase;
export type GetApiBillingTariffsCountApiArg = GetTariffsCountByFilterRequestDto;
export type BalanceName = 'PERSONAL' | 'BUSINESS';
export type BalanceDto = {
  id: string;
  name: BalanceName;
  userId: string;
  value: number;
  countryId: string;
  isFrozen: boolean;
  createdDate: string;
  lastUpdate: string;
};
export type Status = 'SUCCESS' | 'ERROR' | 'PART_UNAVAILABLE';
export type UnavailableServices =
  | 'CHARGE_POINT_SERVICE'
  | 'CP_WS_CONTROLLER'
  | 'CP_SOAP_CONTROLLER'
  | 'BILLING_SERVICE'
  | 'PAYMENT_SERVICE'
  | 'FISCAL_SERVICE'
  | 'SBER_PAY'
  | 'ARMENIA_PAY'
  | 'E_PAY'
  | 'FISCAL_RUSSIA'
  | 'FISCAL_ARMENIA'
  | 'BONUS_SERVICE'
  | 'DISCOUNT_SERVICE'
  | 'MOBILE_API_BFF'
  | 'USER_SERVICE'
  | 'RESERVE_SERVICE'
  | 'OCPI_SERVICE'
  | 'SMS_SERVICE'
  | 'PUSH_NOTIFICATION_SERVICE'
  | 'SYSTEM_INFROMATION_SERVICE'
  | 'IDENTITY_SERVICE'
  | 'ANALYTICS_SERVICE';
export type BalanceDtoIReadOnlyCollectionResponseBase = {
  data?: BalanceDto[] | null;
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
};
export type OrderingType = 'ASC' | 'DESC';
export type UInt32ResponseBase = {
  data?: number;
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
};
export type Operation = 'UNDEFINED' | 'DEBIT' | 'CREDIT' | 'RESET' | 'SET';
export type BalanceTransactionDto = {
  id: string;
  balanceId: string;
  operation: Operation;
  value: number;
  reason: string;
  createdDate: string;
};
export type BalanceTransactionDtoIReadOnlyCollectionResponseBase = {
  data?: BalanceTransactionDto[] | null;
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
};
export type GuidResponseBase = {
  data?: string;
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
};
export type CreateBalanceRequestDto = {
  userId: string;
  countryId: string;
};
export type ResponseBase = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
};
export type SetBalanceRequestDto = {
  balanceId: string;
  value: number;
};
export type CreditBalanceRequestDto = {
  balanceId: string;
  value: number;
};
export type DebitBalanceRequestDto = {
  balanceId: string;
  value: number;
};
export type ResetBalanceRequestDto = {
  balanceId: string;
};
export type FreezeBalanceRequestDto = {
  balanceId: string;
};
export type UnfreezeBalanceRequestDto = {
  balanceId: string;
};
export type BillingStatus = 'ERROR' | 'IN_PROCESS' | 'COMPLETED';
export type PaymentStatus =
  | 'NOT_PAID'
  | 'ERROR'
  | 'IN_PROCESS'
  | 'NOT_REQUIRED'
  | 'PAID'
  | 'ERROR_PAYMENT';
export type FiscalStatus =
  | 'NOT_FISCALIZED'
  | 'ERROR'
  | 'IN_PROCESS'
  | 'NOT_REQUIRED'
  | 'FISCALIZED';
export type ServiceType = 'RESERVING' | 'CHARGING' | 'PARKING';
export type ServiceStatus = 'ERROR' | 'RUNNING' | 'COMPLETED';
export type StageEndType =
  | 'MANUAL'
  | 'RIGHT_NOW'
  | 'ENERGY_PERCENT'
  | 'TIME_MINUTE'
  | 'POWER_LOSS_KW';
export type TariffType = 'ENERGY_KWH' | 'FLAT_FEE' | 'TIME_MINUTE';
export type ProvidedStageDto = {
  id: string;
  providedServiceId: string;
  stageId: string;
  stageName: string;
  stageEndType: StageEndType;
  tariffId: string;
  tariffName: string;
  tariffType: TariffType;
  tariffPrice: number;
  beginDate: string;
  endDate?: string | null;
  value?: number | null;
  payment: number;
  discount: number;
  benefitPayment: number;
  reachedEndConditionDate?: string | null;
  lastUpdate: string;
};
export type ProvidedServiceDto = {
  id: string;
  serviceId: string;
  serviceName: string;
  serviceType: ServiceType;
  billingId: string;
  sessionId: string;
  status: ServiceStatus;
  beginDate: string;
  endDate?: string | null;
  payment: number;
  discount: number;
  benefitPayment: number;
  lastUpdate: string;
  providedStages: ProvidedStageDto[];
};
export type BillingDto = {
  id: string;
  userId: string;
  balanceId: string;
  balanceName: BalanceName;
  countryId: string;
  chargePointId: string;
  status: BillingStatus;
  paymentStatus: PaymentStatus;
  fiscalStatus: FiscalStatus;
  createdDate: string;
  closedDate?: string | null;
  balancePayment: number;
  discount: number;
  payment: number;
  bonusesPayment: number;
  benefitPayment: number;
  invoiceSum: number;
  lastUpdate: string;
  providedServices: ProvidedServiceDto[];
};
export type BillingDtoResponseBase = {
  data?: BillingDto;
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
};
export type BillingDtoIReadOnlyCollectionResponseBase = {
  data?: BillingDto[] | null;
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
};
export type CloseBillingRequestDto = {
  billingId: string;
};
export type CloseActiveBillingRequestDto = {
  userId: string;
};
export type CalculateUserLastBillingPaymentRequestDto = {
  userId: string;
};
export type UserCalculatedValueDto = {
  userId: string;
  calculatedValue: number;
};
export type UsersPaymentSumReportDto = {
  usersCalculatedValues: UserCalculatedValueDto[];
};
export type UsersPaymentSumReportDtoResponseBase = {
  data?: UsersPaymentSumReportDto;
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
};
export type UsersInvoiceSumReportDto = {
  usersCalculatedValues: UserCalculatedValueDto[];
};
export type UsersInvoiceSumReportDtoResponseBase = {
  data?: UsersInvoiceSumReportDto;
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
};
export type BeginProvidedServiceRequestDto = {
  userId: string;
  sessionId: string;
  balanceId: string;
  serviceId: string;
  chargePointId: string;
};
export type CompleteProvidedServiceRequestDto = {
  providedServiceId: string;
};
export type TariffDto = {
  id: string;
  name: string;
  countryId: string;
  type: TariffType;
  price: number;
  createdDate: string;
};
export type StageWithTariffDto = {
  id: string;
  name: string;
  serviceId: string;
  endType: StageEndType;
  endConditionValue: number;
  serialNumber: number;
  createdDate: string;
  tariff: TariffDto;
};
export type ServiceDto = {
  id: string;
  name: string;
  type: ServiceType;
  createdDate: string;
  stages?: StageWithTariffDto[] | null;
};
export type ServiceDtoResponseBase = {
  data?: ServiceDto;
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
};
export type CreateServiceRequestDto = {
  type: ServiceType;
  name: string;
};
export type ServiceDtoIReadOnlyCollectionResponseBase = {
  data?: ServiceDto[] | null;
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
};
export type GetServicesCountByFilterRequestDto = object;
export type ServicesListLinkDto = {
  id: string;
  targetId: string;
  servicesListId: string;
  lastUpdate: string;
};
export type ServicesListLinkDtoResponseBase = {
  data?: ServicesListLinkDto;
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
};
export type CreateServicesListLinkRequestDto = {
  targetId: string;
  servicesListId: string;
};
export type ServicesListLinkDtoIReadOnlyCollectionResponseBase = {
  data?: ServicesListLinkDto[] | null;
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
};
export type ServicesListDto = {
  id: string;
  name: string;
  lastUpdate: string;
  services?: ServiceDto[] | null;
};
export type ServicesListDtoResponseBase = {
  data?: ServicesListDto;
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
};
export type UpdateServicesListRequestDto = {
  name?: string | null;
  servicesIds?: string[] | null;
};
export type ServicesListDtoIReadOnlyCollectionResponseBase = {
  data?: ServicesListDto[] | null;
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
};
export type CreateServicesListRequestDto = {
  name: string;
  servicesIds?: string[] | null;
};
export type StageDto = {
  id: string;
  name: string;
  serviceId: string;
  tariffId: string;
  endType: StageEndType;
  endConditionValue: number;
  serialNumber: number;
  createdDate: string;
};
export type StageDtoResponseBase = {
  data?: StageDto;
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
};
export type CreateStageRequestDto = {
  name: string;
  serviceId: string;
  tariffId: string;
  endType: StageEndType;
  endConditionValue: number;
  serialNumber: number;
};
export type StageDtoIReadOnlyCollectionResponseBase = {
  data?: StageDto[] | null;
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
};
export type GetStagesCountByFilterRequestDto = object;
export type TargetsServicesListsDto = {
  targetId?: string;
  servicesListId?: string;
  name?: string | null;
  lastUpdate?: string;
  services?: ServiceDto[] | null;
};
export type TargetsServicesListsDtoIReadOnlyCollectionResponseBase = {
  data?: TargetsServicesListsDto[] | null;
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
};
export type TariffDtoResponseBase = {
  data?: TariffDto;
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
};
export type CreateTariffRequestDto = {
  name: string;
  countryId: string;
  type: TariffType;
  price: number;
};
export type TariffDtoIReadOnlyCollectionResponseBase = {
  data?: TariffDto[] | null;
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
};
export type GetTariffsCountByFilterRequestDto = object;
export const {
  useGetApiBillingBalancesQuery,
  useGetApiBillingBalancesCountQuery,
  useGetApiBillingBalancesTransactionsQuery,
  useGetApiBillingBalancesTransactionsCountQuery,
  usePostApiBillingBalancesActionCreateMutation,
  usePostApiBillingBalancesActionSetMutation,
  usePostApiBillingBalancesActionCreditMutation,
  usePostApiBillingBalancesActionDebitMutation,
  usePostApiBillingBalancesActionResetMutation,
  usePostApiBillingBalancesActionFreezeMutation,
  usePostApiBillingBalancesActionUnfreezeMutation,
  useGetApiBillingBillingsByBillingIdQuery,
  useGetApiBillingBillingsQuery,
  useGetApiBillingBillingsCountQuery,
  usePostApiBillingBillingsActionCloseMutation,
  usePostApiBillingBillingsActionCloseactiveMutation,
  usePostApiBillingBillingsActionCalculatelastMutation,
  useGetApiBillingReportsUsersPaymentSumQuery,
  useGetApiBillingReportsUsersInvoiceSumQuery,
  usePostApiBillingActionBeginMutation,
  usePostApiBillingActionEndMutation,
  usePostApiBillingActionEndactiveMutation,
  usePostApiBillingServicesMutation,
  useGetApiBillingServicesQuery,
  useGetApiBillingServicesByIdQuery,
  useDeleteApiBillingServicesByIdMutation,
  useGetApiBillingServicesCountQuery,
  usePostApiBillingServicesListLinksMutation,
  useGetApiBillingServicesListLinksQuery,
  useGetApiBillingServicesListLinksCountQuery,
  useDeleteApiBillingServicesListLinksByIdMutation,
  useGetApiBillingServicesListsByIdQuery,
  usePatchApiBillingServicesListsByIdMutation,
  useDeleteApiBillingServicesListsByIdMutation,
  useGetApiBillingServicesListsQuery,
  usePostApiBillingServicesListsMutation,
  useGetApiBillingServicesListsCountQuery,
  usePostApiBillingStagesMutation,
  useGetApiBillingStagesQuery,
  useGetApiBillingStagesByIdQuery,
  useDeleteApiBillingStagesByIdMutation,
  useGetApiBillingStagesCountQuery,
  useGetApiBillingTargetsServicesListsQuery,
  usePostApiBillingTariffsMutation,
  useGetApiBillingTariffsQuery,
  useGetApiBillingTariffsByIdQuery,
  useDeleteApiBillingTariffsByIdMutation,
  useGetApiBillingTariffsCountQuery,
} = injectedRtkApi;
