import { z } from 'zod';

import { postApiBillingTariffsBody } from 'shared/api/services/billing/orval/zod/schemas';
import { TariffType } from 'shared/api/services/billing/rtk/generated';
import { requiredNumberInString } from 'shared/lib/schema';
import { toCurrencySubunit } from 'shared/lib';

export const tariffType: z.ZodType<TariffType> = z.enum([
  'ENERGY_KWH',
  'FLAT_FEE',
  'TIME_MINUTE',
]);

export const FormSchema = postApiBillingTariffsBody
  .omit({
    type: true,
    price: true,
  })
  .extend({
    type: tariffType.nullish().transform((val, ctx) => {
      if (val === undefined || val === null) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Required field',
        });

        return z.NEVER;
      }

      return val;
    }),
    price: requiredNumberInString.pipe(z.number().gte(0)),
  });

export type FormInput = z.input<typeof FormSchema>;
export type FormOutput = z.output<typeof FormSchema>;

export const DEFAULT_VALUES: FormInput = {
  name: '',
  countryId: '',
};
