import { baseApi as api } from '../../../../base-new/base-api';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getApiChargePointSessionsById: build.query<
      GetApiChargePointSessionsByIdApiResponse,
      GetApiChargePointSessionsByIdApiArg
    >({
      query: (queryArg) => ({ url: `/Api/ChargePoint/Sessions/${queryArg}` }),
    }),
    getApiChargePointSessions: build.query<
      GetApiChargePointSessionsApiResponse,
      GetApiChargePointSessionsApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/Sessions`,
        params: {
          ChargePointId: queryArg.chargePointId,
          ConnectorId: queryArg.connectorId,
          UserId: queryArg.userId,
          Status: queryArg.status,
          TransactionId: queryArg.transactionId,
          DateCreateFrom: queryArg.dateCreateFrom,
          DateCreateTo: queryArg.dateCreateTo,
          EndDateFrom: queryArg.endDateFrom,
          EndDateTo: queryArg.endDateTo,
          DateFrom: queryArg.dateFrom,
          DateTo: queryArg.dateTo,
          ChargePointGroupId: queryArg.chargePointGroupId,
          OrderingField: queryArg.orderingField,
          OrderingType: queryArg.orderingType,
          Offset: queryArg.offset,
          Limit: queryArg.limit,
          SearchField: queryArg.searchField,
          SearchText: queryArg.searchText,
        },
      }),
    }),
    getApiChargePointSessionsCount: build.query<
      GetApiChargePointSessionsCountApiResponse,
      GetApiChargePointSessionsCountApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/Sessions/Count`,
        params: {
          ChargePointId: queryArg.chargePointId,
          ConnectorId: queryArg.connectorId,
          UserId: queryArg.userId,
          Status: queryArg.status,
          TransactionId: queryArg.transactionId,
          DateCreateFrom: queryArg.dateCreateFrom,
          DateCreateTo: queryArg.dateCreateTo,
          EndDateFrom: queryArg.endDateFrom,
          EndDateTo: queryArg.endDateTo,
          DateFrom: queryArg.dateFrom,
          DateTo: queryArg.dateTo,
        },
      }),
    }),
    getApiChargePointSessionsByIdGraphMeterValues: build.query<
      GetApiChargePointSessionsByIdGraphMeterValuesApiResponse,
      GetApiChargePointSessionsByIdGraphMeterValuesApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/Sessions/${queryArg.id}/_graphMeterValues`,
        params: { DateFrom: queryArg.dateFrom },
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type GetApiChargePointSessionsByIdApiResponse =
  /** status 200 Success */ GetSessionVmBaseResponse;
export type GetApiChargePointSessionsByIdApiArg = /** Идентификатор */ string;
export type GetApiChargePointSessionsApiResponse =
  /** status 200 Success */ GetSessionVmIReadOnlyCollectionBaseResponse;
export type GetApiChargePointSessionsApiArg = {
  chargePointId?: string;
  connectorId?: string;
  userId?: string;
  status?: SessionStatus;
  transactionId?: string;
  dateCreateFrom?: string;
  dateCreateTo?: string;
  endDateFrom?: string;
  endDateTo?: string;
  dateFrom?: string;
  dateTo?: string;
  chargePointGroupId?: string;
  orderingField?: string;
  orderingType?: OrderBy;
  offset?: number;
  limit?: number;
  searchField?: string;
  searchText?: string;
};
export type GetApiChargePointSessionsCountApiResponse =
  /** status 200 Success */ Int64BaseResponse;
export type GetApiChargePointSessionsCountApiArg = {
  chargePointId?: string;
  connectorId?: string;
  userId?: string;
  status?: SessionStatus;
  transactionId?: string;
  dateCreateFrom?: string;
  dateCreateTo?: string;
  endDateFrom?: string;
  endDateTo?: string;
  dateFrom?: string;
  dateTo?: string;
};
export type GetApiChargePointSessionsByIdGraphMeterValuesApiResponse =
  /** status 200 Success */ GetGraphMeterVmIReadOnlyCollectionBaseResponse;
export type GetApiChargePointSessionsByIdGraphMeterValuesApiArg = {
  id: string;
  /** Временной промежуток ОТ */
  dateFrom?: string;
};
export type Status = 'SUCCESS' | 'ERROR' | 'PART_UNAVAILABLE';
export type UnavailableServices =
  | 'CHARGE_POINT_SERVICE'
  | 'CP_WS_CONTROLLER'
  | 'CP_SOAP_CONTROLLER'
  | 'BILLING_SERVICE'
  | 'PAYMENT_SERVICE'
  | 'FISCAL_SERVICE'
  | 'SBER_PAY'
  | 'ARMENIA_PAY'
  | 'E_PAY'
  | 'FISCAL_RUSSIA'
  | 'FISCAL_ARMENIA'
  | 'BONUS_SERVICE'
  | 'DISCOUNT_SERVICE'
  | 'MOBILE_API_BFF'
  | 'USER_SERVICE'
  | 'RESERVE_SERVICE'
  | 'OCPI_SERVICE'
  | 'SMS_SERVICE'
  | 'PUSH_NOTIFICATION_SERVICE'
  | 'SYSTEM_INFROMATION_SERVICE'
  | 'IDENTITY_SERVICE'
  | 'ANALYTICS_SERVICE';
export type GetSessionVmChargePointVm = {
  chargePointId: string;
  name: string;
  groupId?: string | null;
  companyId?: string | null;
};
export type SessionStatus =
  | 'NEW'
  | 'PREPARING'
  | 'CHARGING'
  | 'COMPLETED_WITH_SUCCESS'
  | 'COMPLETED_WITH_ERROR';
export type GetSessionVm = {
  id: string;
  chargePoint: GetSessionVmChargePointVm;
  chargePointId: string;
  connectorId: string;
  userId: string;
  status: SessionStatus;
  meterStart: number;
  meterStop?: number | null;
  energy: number;
  instantPower: number;
  instantCurrent: number;
  startChargingPercentage?: number | null;
  chargingPercentage?: number | null;
  maxPower: number;
  maxCurrent: number;
  stopReason?: string | null;
  transactionId: string;
  createdDate: string;
  closedDate: string;
  lastUpdate: string;
};
export type GetSessionVmBaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: GetSessionVm;
};
export type GetSessionVmIReadOnlyCollectionBaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: GetSessionVm[] | null;
};
export type OrderBy = 'Asc' | 'Desc';
export type Int64BaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: number;
};
export type GetGraphMeterVm = {
  date: string;
  power: number;
  chargingPercentage: number;
  energy: number;
};
export type GetGraphMeterVmIReadOnlyCollectionBaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: GetGraphMeterVm[] | null;
};
export const {
  useGetApiChargePointSessionsByIdQuery,
  useGetApiChargePointSessionsQuery,
  useGetApiChargePointSessionsCountQuery,
  useGetApiChargePointSessionsByIdGraphMeterValuesQuery,
} = injectedRtkApi;
