import React from 'react';

import { ErrorMessage } from 'shared/ui';
import { getServerErrorText } from 'shared/lib';

import { ChargePointsLocations } from '../charge-points-locations';
import { useGetChargePoints } from 'shared/api/services/chargepoint/rtk';
import { useGetChargePointsMapData } from 'shared/api/services/chargepoint/rtk/enhanced/charge-points';

export function ConnectedChargePointsLocations() {
  const chargePointsMapData = useGetChargePointsMapData();

  if (chargePointsMapData.data) {
    // TODO: server error
    // return <ErrorMessage text={chargePointsMapDatadata.statusMessage} />;
  }

  if (chargePointsMapData.error) {
    return (
      <ErrorMessage text={getServerErrorText(chargePointsMapData.error)} />
    );
  }

  return (
    <ChargePointsLocations
      loading={chargePointsMapData.isFetching}
      chargePoints={chargePointsMapData.data ?? []}
    />
  );
}

export default React.memo(ConnectedChargePointsLocations);
