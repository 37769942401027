import { z } from 'zod';

import { EmptyData, ErrorMessage } from 'shared/ui';
import { getServerErrorText } from 'shared/lib';
import { useTypedParams } from 'shared/lib/router/use-typed-params';
import {
  useGetServiceList,
  useGetTariffs,
} from 'shared/api/services/billing/rtk/enhanced';

import { Form } from './form';

const pageParamsSchema = z.object({
  serviceListId: z.string(),
});

export function FormContainer() {
  const { serviceListId } = useTypedParams(pageParamsSchema);

  const tariffsQuery = useGetTariffs({});
  const serviceListQuery = useGetServiceList(serviceListId);

  const isLoading = tariffsQuery.isFetching || serviceListQuery.isFetching;

  const apiResponseError = [tariffsQuery.data, serviceListQuery.data].find(
    (el) => el && el.status === 'ERROR'
  );

  const error = [tariffsQuery.error, serviceListQuery.error].find(
    (err) => err !== undefined
  );

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (apiResponseError) {
    return <ErrorMessage text={apiResponseError.statusMessage} />;
  }

  if (error) {
    return <ErrorMessage text={getServerErrorText(error)} />;
  }

  if (!tariffsQuery?.data?.data || !serviceListQuery?.data?.data) {
    return <EmptyData />;
  }

  return (
    <Form
      tariffs={tariffsQuery.data.data}
      serviceList={serviceListQuery.data.data}
    />
  );
}
