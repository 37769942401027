import {
  MonitoringGridLayout,
  MonitoringContentWrapper,
  MonitoringFiltersContainer,
} from './styles';

import { MonitoringChargePointsMap } from '../../components/MonitoringChargePointsMap';

import { ShorthandedFilters } from 'widgets/charge-point/filters';
import { selectShowCharts } from 'pages/monitoring/store';
import { useAppSelector } from 'shared/redux/types';
import { MetricsCards } from 'features/metrics/cards';
import { MetricsGraphs } from 'features/metrics/graphs';

export function MonitoringPage() {
  const showCharts = useAppSelector(selectShowCharts);

  return (
    <MonitoringContentWrapper>
      <MonitoringFiltersContainer>
        <ShorthandedFilters />
      </MonitoringFiltersContainer>
      <MonitoringGridLayout>
        <MetricsCards />
        {showCharts ? <MetricsGraphs /> : null}

        <MonitoringChargePointsMap isFullWidth={!showCharts} />
      </MonitoringGridLayout>
    </MonitoringContentWrapper>
  );
}
