import { enhancedApi as api } from '../generated/support';

const enhancedApi = api.enhanceEndpoints({
  endpoints: {
    getApiInformationSupport: (endpoint) => {
      endpoint.providesTags = [{ type: 'Supports', id: 'LIST' }];
    },
    getApiInformationSupportById: (endpoint) => {
      endpoint.providesTags = (result, error, arg) => [
        { type: 'Supports', id: arg },
      ];
    },
    postApiInformationSupport: (endpoint) => {
      endpoint.invalidatesTags = [{ type: 'Supports', id: 'LIST' }];
    },
    patchApiInformationSupportById: (endpoint) => {
      endpoint.invalidatesTags = (result, error, arg) => [
        { type: 'Supports', id: 'LIST' },
        {
          type: 'Supports',
          id: arg.id,
        },
      ];
    },
  },
});

export const {
  useGetApiInformationSupportQuery: useGetSupports,
  useGetApiInformationSupportByIdQuery: useGetSupport,
  usePostApiInformationSupportMutation: useAddSupport,
  usePatchApiInformationSupportByIdMutation: useUpdateSupport,
} = enhancedApi;
