import { enhancedApi as api } from '../generated/events';

export const enhancedApi = api.enhanceEndpoints({
  endpoints: {
    getApiChargePointEvents: (endpoint) => {
      endpoint.providesTags = [{ type: 'Events', id: 'LIST' }];
    },
  },
});

export const {
  useGetApiChargePointEventsQuery: useGetEvents,
  useGetApiChargePointEventsCountQuery: useGetEventsCount,
} = enhancedApi;
