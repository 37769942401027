import { ChargePointsMetricsCard } from '../charge-points';
import { ChargingSessionsMetricsCard } from '../charging-sessions';
import { ConnectorsMetricsCard } from '../connectors';

import { CardsContainer } from './styles';

export function MetricsCards() {
  // получить фильтры из стора

  return (
    <CardsContainer>
      <ChargePointsMetricsCard />
      <ConnectorsMetricsCard />
      <ChargingSessionsMetricsCard />
    </CardsContainer>
  );
}
