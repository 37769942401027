import {
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import { useState } from 'react';

import { ClientTableLayout, useClientPagination } from 'shared/ui/table-new';
import {
  BatchDto,
  TemplateDto,
} from 'shared/api/services/pushnotification/rtk/generated';

import { useColumns } from './use-columns';

type Props = {
  data: BatchDto[];
  templatesDict: Record<string, TemplateDto>;
  loading: boolean;
};

export function TableInstance({ data, loading, templatesDict }: Props) {
  const [sorting, setSorting] = useState<SortingState>([]);

  // Достать из слайса pageSize (если нет - дефолт 20)

  const [pagination, setPagination] = useClientPagination();

  const columns = useColumns({ templatesDict });

  const table = useReactTable({
    data,
    columns,
    state: { sorting, pagination },
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  return <ClientTableLayout table={table} loading={loading} />;
}
