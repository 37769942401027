import { Col, Spin } from 'antd';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm, FormProvider, useFormContext } from 'react-hook-form';
import { useState } from 'react';
import NiceModal from '@ebay/nice-modal-react';

import {
  ButtonsContainer,
  UiSubmitButton,
  UiEditFormButton,
  UiCancelFormButton,
  FormControlsContainer,
  UiDeleteFormButton,
} from 'shared/ui';
import { CustomInput } from 'shared/ui/form/custom-input';
import {
  isErrorWithMessage,
  openErrorNotification,
  openSuccessNotification,
} from 'shared/lib';
import { useAbility } from 'shared/lib/ability/context';
import { useUpdateIdentityRole } from 'shared/api/services/identity/rtk/enhanced';

import { FormSchema, FormInput, FormOutput } from '../consts/schema';

import { EDIT_ERROR, EDIT_SUCCESS } from '../consts';

import { CardFormWrapper, FormRow } from '../../../../common-styles';
import { DeleteIdentityRoleModal } from 'features/identity-role/delete';
import { ApplicationDtoRoleWithUsers } from 'shared/api/services/identity/rtk/generated';

type Props = {
  role: ApplicationDtoRoleWithUsers;
};

export function Form({ role }: Props) {
  const form = useForm<FormInput, void, FormOutput>({
    resolver: zodResolver(FormSchema),
    defaultValues: { ...role },
  });

  const [trigger, { isLoading }] = useUpdateIdentityRole();

  const [isDisabled, setIsDisabled] = useState(true);

  const enableForm = () => setIsDisabled(false);

  const handleSubmit = form.handleSubmit(async (data) => {
    try {
      const res = await trigger({
        id: role.id,
        name: data.name,
      }).unwrap();

      openSuccessNotification(EDIT_SUCCESS);
    } catch (err) {
      const hasErrorMessage = isErrorWithMessage(err);

      const errorText = hasErrorMessage ? err.data.statusMessage : EDIT_ERROR;

      openErrorNotification(errorText);
    }
  });

  return (
    <CardFormWrapper title={role.name}>
      <FormProvider {...form}>
        <Spin spinning={isLoading}>
          <form onSubmit={handleSubmit}>
            <Form.Fields isDisabled={isDisabled} />
            <Form.Buttons
              isDisabled={isDisabled}
              enableForm={enableForm}
              roleName={role.name}
            />
          </form>
        </Spin>
      </FormProvider>
    </CardFormWrapper>
  );
}

type FieldsProps = {
  isDisabled: boolean;
};

Form.Fields = function Fields({ isDisabled }: FieldsProps) {
  const {
    formState: { errors },
  } = useFormContext<FormInput, void, FormOutput>();

  return (
    <>
      <FormRow gutter={20}>
        <Col span={24}>
          <CustomInput<FormInput>
            name="name"
            label="Наименование"
            required
            disabled={isDisabled}
          />
        </Col>
      </FormRow>
    </>
  );
};

type ButtonsProps = {
  enableForm: () => void;
  isDisabled: boolean;
  roleName: string;
};

Form.Buttons = function Buttons({
  enableForm,
  isDisabled,
  roleName,
}: ButtonsProps) {
  const ability = useAbility();

  if (!ability.can('Write', 'Identity')) {
    return null;
  }

  const showDeleteModal = () => {
    NiceModal.show(DeleteIdentityRoleModal, { roleName });
  };

  return (
    <FormControlsContainer>
      <ButtonsContainer>
        {isDisabled ? (
          <UiEditFormButton onClick={enableForm} />
        ) : (
          <UiSubmitButton />
        )}
        <UiCancelFormButton />
        <UiDeleteFormButton onClick={showDeleteModal} />
      </ButtonsContainer>
    </FormControlsContainer>
  );
};
