import NiceModal, { useModal } from '@ebay/nice-modal-react';

import { UiModal } from 'shared/ui';

import { FORM_TITLE } from '../consts';
import { FormContainer } from './form/container';

export const Modal = NiceModal.create(() => {
  const modal = useModal();

  return (
    <UiModal
      visible={modal.visible}
      onCancel={modal.hide}
      afterClose={modal.remove}
      title={FORM_TITLE}
    >
      <FormContainer closeModal={modal.hide} />
    </UiModal>
  );
});
