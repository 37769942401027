import { useState } from 'react';
import { Col, Spin } from 'antd';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm, FormProvider, useFormContext } from 'react-hook-form';

import {
  ButtonsContainer,
  UiSubmitButton,
  UiCancelFormButton,
  FormControlsContainer,
  UiEditFormButton,
} from 'shared/ui';
import { CustomInput } from 'shared/ui/form/custom-input';
import {
  isErrorWithMessage,
  openErrorNotification,
  openSuccessNotification,
} from 'shared/lib';
import { CustomSelect } from 'shared/ui/form/custom-select';
import { useUpdateBank } from 'shared/api/services/payment/rtk/enhanced';

import { FormInput, FormOutput, FormSchema } from '../consts/schema';
import { EDIT_ERROR, EDIT_SUCCESS } from '../consts';

import { CardFormWrapper, FormRow } from '../../../../common-styles';
import { GetCountryVm } from 'shared/api/services/information/rtk/generated/countries';
import { BankDto } from 'shared/api/services/payment/rtk/generated';

type Props = {
  countries: GetCountryVm[];
  bank: BankDto;
};

export function Form({ countries, bank }: Props) {
  const form = useForm<FormInput, void, FormOutput>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      ...bank,
    },
  });

  const [trigger, { isLoading }] = useUpdateBank();

  const [isDisabled, setIsDisabled] = useState(true);

  const enableForm = () => setIsDisabled(false);

  const handleSubmit = form.handleSubmit(async (data) => {
    try {
      const res = await trigger({
        id: bank.id,
        updateBankRequestDto: data,
      }).unwrap();

      openSuccessNotification(EDIT_SUCCESS);
    } catch (err) {
      const hasErrorMessage = isErrorWithMessage(err);

      const errorText = hasErrorMessage ? err.data.statusMessage : EDIT_ERROR;

      openErrorNotification(errorText);
    }
  });

  return (
    <CardFormWrapper title={bank.name}>
      <FormProvider {...form}>
        <Spin spinning={isLoading}>
          <form onSubmit={handleSubmit}>
            <Form.Fields countries={countries} isDisabled={isDisabled} />
            <Form.Buttons isDisabled={isDisabled} enableForm={enableForm} />
          </form>
        </Spin>
      </FormProvider>
    </CardFormWrapper>
  );
}

type FieldsProps = {
  countries: GetCountryVm[];
  isDisabled: boolean;
};

Form.Fields = function Fields({ countries, isDisabled }: FieldsProps) {
  const {
    formState: { errors },
  } = useFormContext<FormInput, void, FormOutput>();

  return (
    <>
      <FormRow gutter={20}>
        <Col span={24}>
          <CustomInput<FormInput>
            name="name"
            label="Наименование"
            required
            disabled={isDisabled}
          />
        </Col>
      </FormRow>
      {/* <FormRow gutter={20}>
        <Col span={24}>
          <CustomSelect<FormSchemaType>
            name="countryId"
            options={countries.map(({ id, name }) => ({
              value: id,
              label: name,
            }))}
            label="Страна"
          />
        </Col>
      </FormRow> */}
    </>
  );
};

type ButtonsProps = {
  enableForm: () => void;
  isDisabled: boolean;
};

Form.Buttons = function Buttons({ enableForm, isDisabled }: ButtonsProps) {
  return (
    <FormControlsContainer>
      <ButtonsContainer>
        {isDisabled ? (
          <UiEditFormButton onClick={enableForm} />
        ) : (
          <UiSubmitButton />
        )}
        <UiCancelFormButton />
      </ButtonsContainer>
    </FormControlsContainer>
  );
};
