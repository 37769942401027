import { z } from 'zod';

import { postApiInformationSupportBody } from 'shared/api/services/information/orval/zod/schemas';

export const FormSchema = postApiInformationSupportBody;

export type FormInput = z.input<typeof FormSchema>;
export type FormOutput = z.output<typeof FormSchema>;

export const DEFAULT_VALUES: FormInput = {
  countryId: '',
  supportPhone: '',
  urlFaq: '',
  userAgreementUrl: '',
};
