import { baseApi as api } from '../../../../base-new/base-api';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getApiUserAppInfo: build.query<
      GetApiUserAppInfoApiResponse,
      GetApiUserAppInfoApiArg
    >({
      query: () => ({ url: `/Api/User/AppInfo` }),
    }),
    getApiUserCarConnectorsByCarConnectorId: build.query<
      GetApiUserCarConnectorsByCarConnectorIdApiResponse,
      GetApiUserCarConnectorsByCarConnectorIdApiArg
    >({
      query: (queryArg) => ({ url: `/Api/User/CarConnectors/${queryArg}` }),
    }),
    patchApiUserCarConnectorsByCarConnectorId: build.mutation<
      PatchApiUserCarConnectorsByCarConnectorIdApiResponse,
      PatchApiUserCarConnectorsByCarConnectorIdApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/User/CarConnectors/${queryArg.carConnectorId}`,
        method: 'PATCH',
        body: queryArg.updateCarConnectorRequestDto,
      }),
    }),
    deleteApiUserCarConnectorsByCarConnectorId: build.mutation<
      DeleteApiUserCarConnectorsByCarConnectorIdApiResponse,
      DeleteApiUserCarConnectorsByCarConnectorIdApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/User/CarConnectors/${queryArg}`,
        method: 'DELETE',
      }),
    }),
    getApiUserCarConnectors: build.query<
      GetApiUserCarConnectorsApiResponse,
      GetApiUserCarConnectorsApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/User/CarConnectors`,
        params: { CarId: queryArg },
      }),
    }),
    postApiUserCarConnectors: build.mutation<
      PostApiUserCarConnectorsApiResponse,
      PostApiUserCarConnectorsApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/User/CarConnectors`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    getApiUserCarsByCarId: build.query<
      GetApiUserCarsByCarIdApiResponse,
      GetApiUserCarsByCarIdApiArg
    >({
      query: (queryArg) => ({ url: `/Api/User/Cars/${queryArg}` }),
    }),
    patchApiUserCarsByCarId: build.mutation<
      PatchApiUserCarsByCarIdApiResponse,
      PatchApiUserCarsByCarIdApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/User/Cars/${queryArg.carId}`,
        method: 'PATCH',
        body: queryArg.updateCarRequestDto,
      }),
    }),
    deleteApiUserCarsByCarId: build.mutation<
      DeleteApiUserCarsByCarIdApiResponse,
      DeleteApiUserCarsByCarIdApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/User/Cars/${queryArg}`,
        method: 'DELETE',
      }),
    }),
    getApiUserCars: build.query<
      GetApiUserCarsApiResponse,
      GetApiUserCarsApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/User/Cars`,
        params: { UserId: queryArg },
      }),
    }),
    postApiUserCars: build.mutation<
      PostApiUserCarsApiResponse,
      PostApiUserCarsApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/User/Cars`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    getApiUserFavoriteChargePoints: build.query<
      GetApiUserFavoriteChargePointsApiResponse,
      GetApiUserFavoriteChargePointsApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/User/FavoriteChargePoints`,
        params: { UserId: queryArg },
      }),
    }),
    postApiUserFavoriteChargePoints: build.mutation<
      PostApiUserFavoriteChargePointsApiResponse,
      PostApiUserFavoriteChargePointsApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/User/FavoriteChargePoints`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    deleteApiUserFavoriteChargePoints: build.mutation<
      DeleteApiUserFavoriteChargePointsApiResponse,
      DeleteApiUserFavoriteChargePointsApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/User/FavoriteChargePoints`,
        method: 'DELETE',
        params: {
          ChargePointId: queryArg.chargePointId,
          UserId: queryArg.userId,
        },
      }),
    }),
    getApiUserGroupsByGroupId: build.query<
      GetApiUserGroupsByGroupIdApiResponse,
      GetApiUserGroupsByGroupIdApiArg
    >({
      query: (queryArg) => ({ url: `/Api/User/Groups/${queryArg}` }),
    }),
    patchApiUserGroupsByGroupId: build.mutation<
      PatchApiUserGroupsByGroupIdApiResponse,
      PatchApiUserGroupsByGroupIdApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/User/Groups/${queryArg.groupId}`,
        method: 'PATCH',
        body: queryArg.updateGroupRequestDto,
      }),
    }),
    deleteApiUserGroupsByGroupId: build.mutation<
      DeleteApiUserGroupsByGroupIdApiResponse,
      DeleteApiUserGroupsByGroupIdApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/User/Groups/${queryArg}`,
        method: 'DELETE',
      }),
    }),
    getApiUserGroups: build.query<
      GetApiUserGroupsApiResponse,
      GetApiUserGroupsApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/User/Groups`,
        params: { UserId: queryArg },
      }),
    }),
    postApiUserGroups: build.mutation<
      PostApiUserGroupsApiResponse,
      PostApiUserGroupsApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/User/Groups`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    postApiUserLoginCode: build.mutation<
      PostApiUserLoginCodeApiResponse,
      PostApiUserLoginCodeApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/User/Login/code`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    postApiUserLogin: build.mutation<
      PostApiUserLoginApiResponse,
      PostApiUserLoginApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/User/Login`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    postApiUserLoginRefreshTokens: build.mutation<
      PostApiUserLoginRefreshTokensApiResponse,
      PostApiUserLoginRefreshTokensApiArg
    >({
      query: () => ({ url: `/Api/User/Login/refreshTokens`, method: 'POST' }),
    }),
    postApiUserLoginLock: build.mutation<
      PostApiUserLoginLockApiResponse,
      PostApiUserLoginLockApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/User/Login/lock`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    postApiUserOldActionLoginCode: build.mutation<
      PostApiUserOldActionLoginCodeApiResponse,
      PostApiUserOldActionLoginCodeApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/User/OldAction/login/code`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    postApiUserOldActionLogin: build.mutation<
      PostApiUserOldActionLoginApiResponse,
      PostApiUserOldActionLoginApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/User/OldAction/login`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    postApiUserOldActionGetsession: build.mutation<
      PostApiUserOldActionGetsessionApiResponse,
      PostApiUserOldActionGetsessionApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/User/OldAction/getsession`,
        method: 'POST',
        params: { sessionToken: queryArg },
      }),
    }),
    getApiUserUsersByUserId: build.query<
      GetApiUserUsersByUserIdApiResponse,
      GetApiUserUsersByUserIdApiArg
    >({
      query: (queryArg) => ({ url: `/Api/User/Users/${queryArg}` }),
    }),
    patchApiUserUsersByUserId: build.mutation<
      PatchApiUserUsersByUserIdApiResponse,
      PatchApiUserUsersByUserIdApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/User/Users/${queryArg.userId}`,
        method: 'PATCH',
        body: queryArg.updateUserRequestDto,
      }),
    }),
    deleteApiUserUsersByUserId: build.mutation<
      DeleteApiUserUsersByUserIdApiResponse,
      DeleteApiUserUsersByUserIdApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/User/Users/${queryArg}`,
        method: 'DELETE',
      }),
    }),
    getApiUserUsers: build.query<
      GetApiUserUsersApiResponse,
      GetApiUserUsersApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/User/Users`,
        params: {
          Login: queryArg.login,
          RegistrationDateFrom: queryArg.registrationDateFrom,
          RegistrationDateTo: queryArg.registrationDateTo,
          GroupId: queryArg.groupId,
          Limit: queryArg.limit,
          Offset: queryArg.offset,
          OrderingField: queryArg.orderingField,
          OrderingType: queryArg.orderingType,
        },
      }),
    }),
    postApiUserUsers: build.mutation<
      PostApiUserUsersApiResponse,
      PostApiUserUsersApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/User/Users`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    getApiUserUsersCount: build.query<
      GetApiUserUsersCountApiResponse,
      GetApiUserUsersCountApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/User/Users/count`,
        params: {
          Login: queryArg.login,
          RegistrationDateFrom: queryArg.registrationDateFrom,
          RegistrationDateTo: queryArg.registrationDateTo,
          GroupId: queryArg.groupId,
        },
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type GetApiUserAppInfoApiResponse = /** status 200 Success */ string;
export type GetApiUserAppInfoApiArg = void;
export type GetApiUserCarConnectorsByCarConnectorIdApiResponse =
  /** status 200 Success */ CarConnectorDtoResponseBase;
export type GetApiUserCarConnectorsByCarConnectorIdApiArg = string;
export type PatchApiUserCarConnectorsByCarConnectorIdApiResponse =
  /** status 200 Success */ CarConnectorDtoResponseBase;
export type PatchApiUserCarConnectorsByCarConnectorIdApiArg = {
  carConnectorId: string;
  updateCarConnectorRequestDto: UpdateCarConnectorRequestDto;
};
export type DeleteApiUserCarConnectorsByCarConnectorIdApiResponse =
  /** status 200 Success */ ResponseBase;
export type DeleteApiUserCarConnectorsByCarConnectorIdApiArg = string;
export type GetApiUserCarConnectorsApiResponse =
  /** status 200 Success */ CarConnectorDtoIReadOnlyCollectionResponseBase;
export type GetApiUserCarConnectorsApiArg = string;
export type PostApiUserCarConnectorsApiResponse =
  /** status 200 Success */ CarConnectorDtoResponseBase;
export type PostApiUserCarConnectorsApiArg = CreateCarConnectorRequestDto;
export type GetApiUserCarsByCarIdApiResponse =
  /** status 200 Success */ CarDtoResponseBase;
export type GetApiUserCarsByCarIdApiArg = string;
export type PatchApiUserCarsByCarIdApiResponse =
  /** status 200 Success */ CarDtoResponseBase;
export type PatchApiUserCarsByCarIdApiArg = {
  carId: string;
  updateCarRequestDto: UpdateCarRequestDto;
};
export type DeleteApiUserCarsByCarIdApiResponse =
  /** status 200 Success */ ResponseBase;
export type DeleteApiUserCarsByCarIdApiArg = string;
export type GetApiUserCarsApiResponse =
  /** status 200 Success */ CarDtoIReadOnlyCollectionResponseBase;
export type GetApiUserCarsApiArg = string;
export type PostApiUserCarsApiResponse =
  /** status 200 Success */ CarDtoResponseBase;
export type PostApiUserCarsApiArg = CreateCarRequestDto;
export type GetApiUserFavoriteChargePointsApiResponse =
  /** status 200 Success */ GuidIReadOnlyCollectionResponseBase;
export type GetApiUserFavoriteChargePointsApiArg = string;
export type PostApiUserFavoriteChargePointsApiResponse =
  /** status 200 Success */ FavoriteChargePointDtoResponseBase;
export type PostApiUserFavoriteChargePointsApiArg =
  CreateFavoriteChargePointRequestDto;
export type DeleteApiUserFavoriteChargePointsApiResponse =
  /** status 200 Success */ ResponseBase;
export type DeleteApiUserFavoriteChargePointsApiArg = {
  chargePointId: string;
  userId: string;
};
export type GetApiUserGroupsByGroupIdApiResponse =
  /** status 200 Success */ GroupDtoResponseBase;
export type GetApiUserGroupsByGroupIdApiArg = string;
export type PatchApiUserGroupsByGroupIdApiResponse =
  /** status 200 Success */ GroupDtoResponseBase;
export type PatchApiUserGroupsByGroupIdApiArg = {
  groupId: string;
  updateGroupRequestDto: UpdateGroupRequestDto;
};
export type DeleteApiUserGroupsByGroupIdApiResponse =
  /** status 200 Success */ ResponseBase;
export type DeleteApiUserGroupsByGroupIdApiArg = string;
export type GetApiUserGroupsApiResponse =
  /** status 200 Success */ GroupDtoIReadOnlyCollectionResponseBase;
export type GetApiUserGroupsApiArg = string;
export type PostApiUserGroupsApiResponse =
  /** status 200 Success */ GroupDtoResponseBase;
export type PostApiUserGroupsApiArg = CreateGroupRequestDto;
export type PostApiUserLoginCodeApiResponse =
  /** status 200 Success */ ResponseBase;
export type PostApiUserLoginCodeApiArg = SendVerificationCodeRequestDto;
export type PostApiUserLoginApiResponse =
  /** status 200 Success */ JwtTokensDtoResponseBase;
export type PostApiUserLoginApiArg = IssueAuthTokensRequestDto;
export type PostApiUserLoginRefreshTokensApiResponse =
  /** status 200 Success */ JwtTokensDtoResponseBase;
export type PostApiUserLoginRefreshTokensApiArg = void;
export type PostApiUserLoginLockApiResponse =
  /** status 200 Success */ ResponseBase;
export type PostApiUserLoginLockApiArg = LockUserRequestDto;
export type PostApiUserOldActionLoginCodeApiResponse =
  /** status 200 Success */ ResponseBase;
export type PostApiUserOldActionLoginCodeApiArg =
  SendVerificationCodeRequestDto;
export type PostApiUserOldActionLoginApiResponse =
  /** status 200 Success */ SessionAuthTokenDtoResponseBase;
export type PostApiUserOldActionLoginApiArg = IssueAuthTokensRequestDto;
export type PostApiUserOldActionGetsessionApiResponse =
  /** status 200 Success */ SessionUserDtoResponseBase;
export type PostApiUserOldActionGetsessionApiArg = string;
export type GetApiUserUsersByUserIdApiResponse =
  /** status 200 Success */ UserDtoResponseBase;
export type GetApiUserUsersByUserIdApiArg = string;
export type PatchApiUserUsersByUserIdApiResponse =
  /** status 200 Success */ UserDtoResponseBase;
export type PatchApiUserUsersByUserIdApiArg = {
  userId: string;
  updateUserRequestDto: UpdateUserRequestDto;
};
export type DeleteApiUserUsersByUserIdApiResponse =
  /** status 200 Success */ ResponseBase;
export type DeleteApiUserUsersByUserIdApiArg = string;
export type GetApiUserUsersApiResponse =
  /** status 200 Success */ UserDtoIReadOnlyCollectionResponseBase;
export type GetApiUserUsersApiArg = {
  login?: string;
  registrationDateFrom?: string;
  registrationDateTo?: string;
  groupId?: string;
  limit?: number;
  offset?: number;
  orderingField?: string;
  orderingType?: OrderingType;
};
export type PostApiUserUsersApiResponse =
  /** status 200 Success */ UserDtoResponseBase;
export type PostApiUserUsersApiArg = CreateUserRequestDto;
export type GetApiUserUsersCountApiResponse =
  /** status 200 Success */ UInt32ResponseBase;
export type GetApiUserUsersCountApiArg = {
  login?: string;
  registrationDateFrom?: string;
  registrationDateTo?: string;
  groupId?: string;
};
export type CarConnectorType =
  | 'SCHUKO'
  | 'TYPE2'
  | 'CCS2'
  | 'CHADEMO'
  | 'GBTDC'
  | 'GBTAC'
  | 'CCS1'
  | 'TYPE1'
  | 'TESLA';
export type CarConnectorDto = {
  id: string;
  carId: string;
  type: CarConnectorType;
  isAdapterNeeded: boolean;
};
export type Status = 'SUCCESS' | 'ERROR' | 'PART_UNAVAILABLE';
export type UnavailableServices =
  | 'CHARGE_POINT_SERVICE'
  | 'CP_WS_CONTROLLER'
  | 'CP_SOAP_CONTROLLER'
  | 'BILLING_SERVICE'
  | 'PAYMENT_SERVICE'
  | 'FISCAL_SERVICE'
  | 'SBER_PAY'
  | 'ARMENIA_PAY'
  | 'E_PAY'
  | 'FISCAL_RUSSIA'
  | 'FISCAL_ARMENIA'
  | 'BONUS_SERVICE'
  | 'DISCOUNT_SERVICE'
  | 'MOBILE_API_BFF'
  | 'USER_SERVICE'
  | 'RESERVE_SERVICE'
  | 'OCPI_SERVICE'
  | 'SMS_SERVICE'
  | 'PUSH_NOTIFICATION_SERVICE'
  | 'SYSTEM_INFROMATION_SERVICE'
  | 'IDENTITY_SERVICE'
  | 'ANALYTICS_SERVICE';
export type CarConnectorDtoResponseBase = {
  data?: CarConnectorDto;
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
};
export type UpdateCarConnectorRequestDto = {
  carId?: string | null;
  type?: CarConnectorType;
  isAdapterNeeded?: boolean | null;
};
export type ResponseBase = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
};
export type CarConnectorDtoIReadOnlyCollectionResponseBase = {
  data?: CarConnectorDto[] | null;
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
};
export type CreateCarConnectorRequestDto = {
  carId: string;
  type: CarConnectorType;
  isAdapterNeeded: boolean;
};
export type CarDto = {
  id: string;
  name: string;
  userId: string;
  carModelId: string;
};
export type CarDtoResponseBase = {
  data?: CarDto;
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
};
export type UpdateCarRequestDto = {
  name?: string | null;
  userId?: string | null;
  carModelId?: string | null;
};
export type CarDtoIReadOnlyCollectionResponseBase = {
  data?: CarDto[] | null;
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
};
export type CreateCarRequestDto = {
  name: string;
  userId: string;
  carModelId: string;
};
export type GuidIReadOnlyCollectionResponseBase = {
  data?: string[] | null;
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
};
export type FavoriteChargePointDto = {
  chargePointId: string;
  userId: string;
};
export type FavoriteChargePointDtoResponseBase = {
  data?: FavoriteChargePointDto;
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
};
export type CreateFavoriteChargePointRequestDto = {
  chargePointId: string;
  userId: string;
};
export type GroupDto = {
  id: string;
  name: string;
  createdDate: string;
  lastUpdate: string;
  comment?: string | null;
  parentId?: string | null;
  isDefault: boolean;
  children: GroupDto[];
};
export type GroupDtoResponseBase = {
  data?: GroupDto;
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
};
export type UpdateGroupRequestDto = {
  name?: string | null;
  comment?: string | null;
  parentId?: string | null;
  isDefault?: boolean | null;
};
export type GroupDtoIReadOnlyCollectionResponseBase = {
  data?: GroupDto[] | null;
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
};
export type CreateGroupRequestDto = {
  name: string;
  comment?: string | null;
  parentId?: string | null;
  isDefault: boolean;
};
export type SendVerificationCodeRequestDto = {
  login: string;
};
export type JwtTokensDto = {
  accessToken: string;
  refreshToken: string;
};
export type JwtTokensDtoResponseBase = {
  data?: JwtTokensDto;
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
};
export type IssueAuthTokensRequestDto = {
  login: string;
  countryId: string;
  mobileAppId: string;
  code: string;
};
export type LockUserRequestDto = {
  userId: string;
  lockDateTo: string;
};
export type SessionAuthTokenDto = {
  sessionToken: string;
};
export type SessionAuthTokenDtoResponseBase = {
  data?: SessionAuthTokenDto;
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
};
export type SessionUserDto = {
  userId: string;
};
export type SessionUserDtoResponseBase = {
  data?: SessionUserDto;
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
};
export type Sex = 'UNKNOWN' | 'MALE' | 'FEMALE';
export type Language = 'RU' | 'BY' | 'AM' | 'KZ' | 'KG';
export type UserDto = {
  id: string;
  login: string;
  lockoutEndDate: string;
  email?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  middleName?: string | null;
  birthDate?: string | null;
  sex?: Sex;
  registrationDate: string;
  countryId: string;
  iconUrl?: string | null;
  groupId?: string | null;
  notesRelatedWithUser?: string | null;
  userAgreementAccepted: boolean;
  interfaceLanguage: Language;
  isDebug: boolean;
};
export type UserDtoResponseBase = {
  data?: UserDto;
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
};
export type UpdateUserRequestDto = {
  login?: string | null;
  lockoutEndDate?: string | null;
  email?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  middleName?: string | null;
  birthDate?: string | null;
  sex?: Sex;
  countryId?: string | null;
  iconUrl?: string | null;
  groupId?: string | null;
  notesRelatedWithUser?: string | null;
  userAgreementAccepted?: boolean | null;
  interfaceLanguage?: Language;
  isDebug?: boolean | null;
};
export type UserDtoIReadOnlyCollectionResponseBase = {
  data?: UserDto[] | null;
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
};
export type OrderingType = 'ASC' | 'DESC';
export type CreateUserRequestDto = {
  login: string;
  lockoutEndDate?: string | null;
  email?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  middleName?: string | null;
  birthDate?: string | null;
  sex?: Sex;
  countryId: string;
  iconUrl?: string | null;
  groupId?: string | null;
  notesRelatedWithUser?: string | null;
  userAgreementAccepted: boolean;
  interfaceLanguage?: Language;
  isDebug?: boolean | null;
};
export type UInt32ResponseBase = {
  data?: number;
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
};
export const {
  useGetApiUserAppInfoQuery,
  useGetApiUserCarConnectorsByCarConnectorIdQuery,
  usePatchApiUserCarConnectorsByCarConnectorIdMutation,
  useDeleteApiUserCarConnectorsByCarConnectorIdMutation,
  useGetApiUserCarConnectorsQuery,
  usePostApiUserCarConnectorsMutation,
  useGetApiUserCarsByCarIdQuery,
  usePatchApiUserCarsByCarIdMutation,
  useDeleteApiUserCarsByCarIdMutation,
  useGetApiUserCarsQuery,
  usePostApiUserCarsMutation,
  useGetApiUserFavoriteChargePointsQuery,
  usePostApiUserFavoriteChargePointsMutation,
  useDeleteApiUserFavoriteChargePointsMutation,
  useGetApiUserGroupsByGroupIdQuery,
  usePatchApiUserGroupsByGroupIdMutation,
  useDeleteApiUserGroupsByGroupIdMutation,
  useGetApiUserGroupsQuery,
  usePostApiUserGroupsMutation,
  usePostApiUserLoginCodeMutation,
  usePostApiUserLoginMutation,
  usePostApiUserLoginRefreshTokensMutation,
  usePostApiUserLoginLockMutation,
  usePostApiUserOldActionLoginCodeMutation,
  usePostApiUserOldActionLoginMutation,
  usePostApiUserOldActionGetsessionMutation,
  useGetApiUserUsersByUserIdQuery,
  usePatchApiUserUsersByUserIdMutation,
  useDeleteApiUserUsersByUserIdMutation,
  useGetApiUserUsersQuery,
  usePostApiUserUsersMutation,
  useGetApiUserUsersCountQuery,
} = injectedRtkApi;
