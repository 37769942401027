import { z } from 'zod';

import { postApiBillingStagesBody } from 'shared/api/services/billing/orval/zod/schemas';
import { StageEndType } from 'shared/api/services/billing/rtk/generated';
import { requiredNumberInString } from 'shared/lib/schema';

const stageEndType: z.ZodType<StageEndType> = z.enum([
  'MANUAL',
  'RIGHT_NOW',
  'ENERGY_PERCENT',
  'TIME_MINUTE',
  'POWER_LOSS',
]);

const billingStage = postApiBillingStagesBody
  .omit({
    endType: true,
    endConditionValue: true,
    serviceId: true,
  })
  .extend({
    endType: stageEndType.nullish().transform((val, ctx) => {
      if (val === undefined || val === null) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Required field',
        });

        return z.NEVER;
      }

      return val;
    }),
    endConditionValue: requiredNumberInString.pipe(z.number().gte(0)),
  });

export const FormSchema = z.object({
  reservingStages: z.array(billingStage),
  chargingStages: z.array(billingStage),
});

export type FormInput = z.input<typeof FormSchema>;
export type FormOutput = z.output<typeof FormSchema>;

export const DEFAULT_VALUES: FormInput = {
  chargingStages: [],
  reservingStages: [],
};
