import { useFieldArray } from 'react-hook-form';
import { Col, Divider, Space, Spin } from 'antd';

import { DeleteIcon } from 'shared/icons/delete';

import { AddServiceButton } from '../add-service-button';
import { AddStageButton } from '../add-stage-button';
import {
  ControlButton,
  FormFieldsGroup,
  ServiceHeader,
  ServiceName,
  ServiceTitle,
} from './styles';

import { CardFormWrapper, FormRow } from '../../../../../../common-styles';
import { CustomInput } from 'shared/ui/form/custom-input';
import { AddTariffButton } from 'features/tariff/add/ui/add-tariff-button';
import { CustomSelect } from 'shared/ui/form/custom-select';
import { STAGE_END_TYPE } from 'entities/billing/consts';
import { TariffDto } from 'shared/api/services/billing/rtk/generated';
import { getTariffOptionsList } from 'entities/billing/lib/get-tariff-options-list';

type Props = {
  label: string;
  addButtonLabel: string;
  fieldArray: ReturnType<typeof useFieldArray>;
  name: string;
  tariffs: TariffDto[];
};

export function Service({
  label,
  fieldArray,
  addButtonLabel,
  name,
  tariffs,
}: Props) {
  const { fields, append, remove } = fieldArray;

  if (fields.length === 0) {
    return (
      <AddServiceButton
        label={addButtonLabel}
        onClick={() =>
          append({
            name: '',
            serialNumber: fields.length,
            tariffId: '',
            serviceId: '',
          })
        }
      />
    );
  }

  return (
    <FormFieldsGroup>
      <ServiceHeader>
        <ServiceName>{label}</ServiceName>
        <ControlButton onClick={() => remove()}>
          <DeleteIcon />
        </ControlButton>
      </ServiceHeader>

      {fields.map((field, index) => {
        return (
          <FormRow gutter={12}>
            <Col flex="auto">
              <FormRow gutter={20}>
                <Col span={6}>
                  <CustomInput<FormInput>
                    name={`${name}.${index}.name`}
                    label="Название стадии услуги"
                    required
                  />
                </Col>

                <Col span={6}>
                  <CustomSelect<FormInput>
                    name={`${name}.${index}.tariffId`}
                    label="Стоимость"
                    options={getTariffOptionsList(tariffs)}
                    dropdownRender={(menu) => {
                      return (
                        <>
                          {menu}
                          <Divider style={{ margin: '8px 0' }} />
                          <AddTariffButton />
                        </>
                      );
                    }}
                    required
                  />
                </Col>

                <Col span={6}>
                  <CustomSelect<FormInput>
                    name={`${name}.${index}.endType`}
                    label="Триггер окончания"
                    options={Object.entries(STAGE_END_TYPE).map((entry) => {
                      const [key, label] = entry;

                      return { value: key, label };
                    })}
                    required
                  />
                </Col>

                <Col span={6}>
                  <CustomInput<FormInput>
                    name={`${name}.${index}.endConditionValue`}
                    label="Значение % батареи / мин"
                    required
                  />
                </Col>

                {/* <IconsContainer></IconsContainer> */}
              </FormRow>
            </Col>

            <Space
              size={20}
              style={{ alignSelf: 'flex-start', paddingTop: '28px' }}
            >
              {/* <BurgerIcon /> */}
              <ControlButton onClick={() => remove(index)}>
                <DeleteIcon />
              </ControlButton>
            </Space>
          </FormRow>
        );
      })}
      <AddStageButton
        onClick={() =>
          append({
            name: '',
            serialNumber: fields.length,
            tariffId: '',
            serviceId: '',
          })
        }
      />
    </FormFieldsGroup>
  );
}
