import { baseApi as api } from '../../../../base-new/base-api';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getApiChargePointGroupsById: build.query<
      GetApiChargePointGroupsByIdApiResponse,
      GetApiChargePointGroupsByIdApiArg
    >({
      query: (queryArg) => ({ url: `/Api/ChargePoint/Groups/${queryArg}` }),
    }),
    patchApiChargePointGroupsById: build.mutation<
      PatchApiChargePointGroupsByIdApiResponse,
      PatchApiChargePointGroupsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/Groups/${queryArg.id}`,
        method: 'PATCH',
        body: queryArg.updateGroupRequest,
      }),
    }),
    deleteApiChargePointGroupsById: build.mutation<
      DeleteApiChargePointGroupsByIdApiResponse,
      DeleteApiChargePointGroupsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/Groups/${queryArg}`,
        method: 'DELETE',
      }),
    }),
    getApiChargePointGroups: build.query<
      GetApiChargePointGroupsApiResponse,
      GetApiChargePointGroupsApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/Groups`,
        params: {
          OrderingField: queryArg.orderingField,
          OrderingType: queryArg.orderingType,
          Offset: queryArg.offset,
          Limit: queryArg.limit,
        },
      }),
    }),
    postApiChargePointGroups: build.mutation<
      PostApiChargePointGroupsApiResponse,
      PostApiChargePointGroupsApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/Groups`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    getApiChargePointGroupsCount: build.query<
      GetApiChargePointGroupsCountApiResponse,
      GetApiChargePointGroupsCountApiArg
    >({
      query: () => ({ url: `/Api/ChargePoint/Groups/Count` }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type GetApiChargePointGroupsByIdApiResponse =
  /** status 200 Success */ GetGroupVmBaseResponse;
export type GetApiChargePointGroupsByIdApiArg = string;
export type PatchApiChargePointGroupsByIdApiResponse =
  /** status 200 Success */ GetGroupVmBaseResponse;
export type PatchApiChargePointGroupsByIdApiArg = {
  /** Идентификатор сущности */
  id: string;
  /** Параметры */
  updateGroupRequest: UpdateGroupRequest;
};
export type DeleteApiChargePointGroupsByIdApiResponse =
  /** status 200 Success */ BaseResponse;
export type DeleteApiChargePointGroupsByIdApiArg =
  /** Идентификатор сущности */ string;
export type GetApiChargePointGroupsApiResponse =
  /** status 200 Success */ GetGroupVmIReadOnlyCollectionBaseResponse;
export type GetApiChargePointGroupsApiArg = {
  orderingField?: string;
  orderingType?: OrderBy;
  offset?: number;
  limit?: number;
};
export type PostApiChargePointGroupsApiResponse =
  /** status 200 Success */ GetGroupVmBaseResponse;
export type PostApiChargePointGroupsApiArg = AddGroupRequest;
export type GetApiChargePointGroupsCountApiResponse =
  /** status 200 Success */ Int64BaseResponse;
export type GetApiChargePointGroupsCountApiArg = void;
export type Status = 'SUCCESS' | 'ERROR' | 'PART_UNAVAILABLE';
export type UnavailableServices =
  | 'CHARGE_POINT_SERVICE'
  | 'CP_WS_CONTROLLER'
  | 'CP_SOAP_CONTROLLER'
  | 'BILLING_SERVICE'
  | 'PAYMENT_SERVICE'
  | 'FISCAL_SERVICE'
  | 'SBER_PAY'
  | 'ARMENIA_PAY'
  | 'E_PAY'
  | 'FISCAL_RUSSIA'
  | 'FISCAL_ARMENIA'
  | 'BONUS_SERVICE'
  | 'DISCOUNT_SERVICE'
  | 'MOBILE_API_BFF'
  | 'USER_SERVICE'
  | 'RESERVE_SERVICE'
  | 'OCPI_SERVICE'
  | 'SMS_SERVICE'
  | 'PUSH_NOTIFICATION_SERVICE'
  | 'SYSTEM_INFROMATION_SERVICE'
  | 'IDENTITY_SERVICE'
  | 'ANALYTICS_SERVICE';
export type GetGroupVm = {
  id: string;
  name: string;
  comment?: string | null;
  children?: any[] | null;
  parentId?: string | null;
  isDefault: boolean;
  mobileAppIds?: string[] | null;
  createdDate: string;
  lastUpdate: string;
};
export type GetGroupVmBaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: GetGroupVm;
};
export type UpdateGroupRequest = {
  name?: string | null;
  comment?: string | null;
  parentId?: string | null;
  isDefault?: boolean | null;
  mobileAppIds?: string[] | null;
};
export type BaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
};
export type GetGroupVmIReadOnlyCollectionBaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: GetGroupVm[] | null;
};
export type OrderBy = 'Asc' | 'Desc';
export type AddGroupRequest = {
  name: string;
  comment?: string | null;
  parentId?: string | null;
  isDefault: boolean;
  mobileAppIds?: string[] | null;
};
export type Int64BaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: number;
};
export const {
  useGetApiChargePointGroupsByIdQuery,
  usePatchApiChargePointGroupsByIdMutation,
  useDeleteApiChargePointGroupsByIdMutation,
  useGetApiChargePointGroupsQuery,
  usePostApiChargePointGroupsMutation,
  useGetApiChargePointGroupsCountQuery,
} = injectedRtkApi;
