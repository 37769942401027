import { baseApi as api } from '../../../../base-new/base-api';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getApiCompanyAppInfo: build.query<
      GetApiCompanyAppInfoApiResponse,
      GetApiCompanyAppInfoApiArg
    >({
      query: () => ({ url: `/Api/Company/AppInfo` }),
    }),
    getApiCompanyCompaniesByCompanyId: build.query<
      GetApiCompanyCompaniesByCompanyIdApiResponse,
      GetApiCompanyCompaniesByCompanyIdApiArg
    >({
      query: (queryArg) => ({ url: `/Api/Company/Companies/${queryArg}` }),
    }),
    patchApiCompanyCompaniesByCompanyId: build.mutation<
      PatchApiCompanyCompaniesByCompanyIdApiResponse,
      PatchApiCompanyCompaniesByCompanyIdApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Company/Companies/${queryArg.companyId}`,
        method: 'PATCH',
        body: queryArg.updateCompanyRequestDto,
      }),
    }),
    deleteApiCompanyCompaniesByCompanyId: build.mutation<
      DeleteApiCompanyCompaniesByCompanyIdApiResponse,
      DeleteApiCompanyCompaniesByCompanyIdApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Company/Companies/${queryArg}`,
        method: 'DELETE',
      }),
    }),
    getApiCompanyCompanies: build.query<
      GetApiCompanyCompaniesApiResponse,
      GetApiCompanyCompaniesApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Company/Companies`,
        params: { IsDefault: queryArg },
      }),
    }),
    postApiCompanyCompanies: build.mutation<
      PostApiCompanyCompaniesApiResponse,
      PostApiCompanyCompaniesApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Company/Companies`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    getApiCompanyEmployeesByEmployeeId: build.query<
      GetApiCompanyEmployeesByEmployeeIdApiResponse,
      GetApiCompanyEmployeesByEmployeeIdApiArg
    >({
      query: (queryArg) => ({ url: `/Api/Company/Employees/${queryArg}` }),
    }),
    patchApiCompanyEmployeesByEmployeeId: build.mutation<
      PatchApiCompanyEmployeesByEmployeeIdApiResponse,
      PatchApiCompanyEmployeesByEmployeeIdApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Company/Employees/${queryArg.employeeId}`,
        method: 'PATCH',
        body: queryArg.updateEmployeeRequestDto,
      }),
    }),
    deleteApiCompanyEmployeesByEmployeeId: build.mutation<
      DeleteApiCompanyEmployeesByEmployeeIdApiResponse,
      DeleteApiCompanyEmployeesByEmployeeIdApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Company/Employees/${queryArg}`,
        method: 'DELETE',
      }),
    }),
    getApiCompanyEmployees: build.query<
      GetApiCompanyEmployeesApiResponse,
      GetApiCompanyEmployeesApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Company/Employees`,
        params: {
          UserId: queryArg.userId,
          Limit: queryArg.limit,
          Offset: queryArg.offset,
          OrderingField: queryArg.orderingField,
          OrderingType: queryArg.orderingType,
        },
      }),
    }),
    postApiCompanyEmployees: build.mutation<
      PostApiCompanyEmployeesApiResponse,
      PostApiCompanyEmployeesApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Company/Employees`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    getApiCompanyEmployeesCount: build.query<
      GetApiCompanyEmployeesCountApiResponse,
      GetApiCompanyEmployeesCountApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Company/Employees/count`,
        params: { UserId: queryArg },
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type GetApiCompanyAppInfoApiResponse = /** status 200 Success */ string;
export type GetApiCompanyAppInfoApiArg = void;
export type GetApiCompanyCompaniesByCompanyIdApiResponse =
  /** status 200 Success */ CompanyDtoResponseBase;
export type GetApiCompanyCompaniesByCompanyIdApiArg = string;
export type PatchApiCompanyCompaniesByCompanyIdApiResponse =
  /** status 200 Success */ CompanyDtoResponseBase;
export type PatchApiCompanyCompaniesByCompanyIdApiArg = {
  companyId: string;
  updateCompanyRequestDto: UpdateCompanyRequestDto;
};
export type DeleteApiCompanyCompaniesByCompanyIdApiResponse =
  /** status 200 Success */ ResponseBase;
export type DeleteApiCompanyCompaniesByCompanyIdApiArg = string;
export type GetApiCompanyCompaniesApiResponse =
  /** status 200 Success */ CompanyDtoIReadOnlyCollectionResponseBase;
export type GetApiCompanyCompaniesApiArg = boolean;
export type PostApiCompanyCompaniesApiResponse =
  /** status 200 Success */ CompanyDtoResponseBase;
export type PostApiCompanyCompaniesApiArg = CreateCompanyRequestDto;
export type GetApiCompanyEmployeesByEmployeeIdApiResponse =
  /** status 200 Success */ EmployeeDtoResponseBase;
export type GetApiCompanyEmployeesByEmployeeIdApiArg = string;
export type PatchApiCompanyEmployeesByEmployeeIdApiResponse =
  /** status 200 Success */ EmployeeDtoResponseBase;
export type PatchApiCompanyEmployeesByEmployeeIdApiArg = {
  employeeId: string;
  updateEmployeeRequestDto: UpdateEmployeeRequestDto;
};
export type DeleteApiCompanyEmployeesByEmployeeIdApiResponse =
  /** status 200 Success */ ResponseBase;
export type DeleteApiCompanyEmployeesByEmployeeIdApiArg = string;
export type GetApiCompanyEmployeesApiResponse =
  /** status 200 Success */ EmployeeDtoIReadOnlyCollectionResponseBase;
export type GetApiCompanyEmployeesApiArg = {
  userId?: string;
  limit?: number;
  offset?: number;
  orderingField?: string;
  orderingType?: OrderingType;
};
export type PostApiCompanyEmployeesApiResponse =
  /** status 200 Success */ EmployeeDtoResponseBase;
export type PostApiCompanyEmployeesApiArg = CreateEmployeeRequestDto;
export type GetApiCompanyEmployeesCountApiResponse =
  /** status 200 Success */ UInt32ResponseBase;
export type GetApiCompanyEmployeesCountApiArg = string;
export type EmployeesBalancePeriod = 'FIRST_DAY_MONTH';
export type CompanyDto = {
  id: string;
  name: string;
  legalName: string;
  email: string;
  contractData: string;
  address: string;
  phoneNumber: string;
  logoUrl: string;
  inn: string;
  kpp: string;
  mobileName: string;
  directorName?: string | null;
  telegram?: string | null;
  companyBalanceForPeriod?: number | null;
  employeeBalanceForPeriod?: number | null;
  balanceRefreshPeriod?: EmployeesBalancePeriod;
  isBalanceRefreshLocked: boolean;
  creationDate: string;
  lastUpdate: string;
  isDefault: boolean;
};
export type Status = 'SUCCESS' | 'ERROR' | 'PART_UNAVAILABLE';
export type UnavailableServices =
  | 'CHARGE_POINT_SERVICE'
  | 'CP_WS_CONTROLLER'
  | 'CP_SOAP_CONTROLLER'
  | 'BILLING_SERVICE'
  | 'PAYMENT_SERVICE'
  | 'FISCAL_SERVICE'
  | 'SBER_PAY'
  | 'ARMENIA_PAY'
  | 'E_PAY'
  | 'FISCAL_RUSSIA'
  | 'FISCAL_ARMENIA'
  | 'BONUS_SERVICE'
  | 'DISCOUNT_SERVICE'
  | 'MOBILE_API_BFF'
  | 'USER_SERVICE'
  | 'RESERVE_SERVICE'
  | 'OCPI_SERVICE'
  | 'SMS_SERVICE'
  | 'PUSH_NOTIFICATION_SERVICE'
  | 'SYSTEM_INFROMATION_SERVICE'
  | 'IDENTITY_SERVICE'
  | 'ANALYTICS_SERVICE';
export type CompanyDtoResponseBase = {
  data?: CompanyDto;
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
};
export type UpdateCompanyRequestDto = {
  name?: string | null;
  legalName?: string | null;
  email?: string | null;
  contractData?: string | null;
  address?: string | null;
  phoneNumber?: string | null;
  logoUrl?: string | null;
  inn?: string | null;
  kpp?: string | null;
  mobileName?: string | null;
  directorName?: string | null;
  telegram?: string | null;
  companyBalanceForPeriod?: number | null;
  employeeBalanceForPeriod?: number | null;
  balanceRefreshPeriod?: EmployeesBalancePeriod;
  isBalanceRefreshLocked?: boolean | null;
  isDefault?: boolean | null;
};
export type ResponseBase = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
};
export type CompanyDtoIReadOnlyCollectionResponseBase = {
  data?: CompanyDto[] | null;
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
};
export type CreateCompanyRequestDto = {
  name: string;
  legalName: string;
  email: string;
  contractData: string;
  address: string;
  phoneNumber: string;
  logoUrl: string;
  inn: string;
  kpp: string;
  mobileName: string;
  directorName?: string | null;
  telegram?: string | null;
  companyBalanceForPeriod?: number | null;
  employeeBalanceForPeriod?: number | null;
  balanceRefreshPeriod?: EmployeesBalancePeriod;
  isBalanceRefreshLocked: boolean;
  isDefault: boolean;
};
export type EmployeeDto = {
  id: string;
  userId: string;
  name?: string | null;
  balanceId: string;
  companyId: string;
  creationDate: string;
  lastUpdate: string;
};
export type EmployeeDtoResponseBase = {
  data?: EmployeeDto;
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
};
export type UpdateEmployeeRequestDto = {
  userId?: string | null;
  balanceId?: string | null;
  companyId?: string | null;
  name?: string | null;
};
export type EmployeeDtoIReadOnlyCollectionResponseBase = {
  data?: EmployeeDto[] | null;
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
};
export type OrderingType = 'ASC' | 'DESC';
export type CreateEmployeeRequestDto = {
  userId: string;
  balanceId: string;
  companyId: string;
  name?: string | null;
};
export type UInt32ResponseBase = {
  data?: number;
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
};
export const {
  useGetApiCompanyAppInfoQuery,
  useGetApiCompanyCompaniesByCompanyIdQuery,
  usePatchApiCompanyCompaniesByCompanyIdMutation,
  useDeleteApiCompanyCompaniesByCompanyIdMutation,
  useGetApiCompanyCompaniesQuery,
  usePostApiCompanyCompaniesMutation,
  useGetApiCompanyEmployeesByEmployeeIdQuery,
  usePatchApiCompanyEmployeesByEmployeeIdMutation,
  useDeleteApiCompanyEmployeesByEmployeeIdMutation,
  useGetApiCompanyEmployeesQuery,
  usePostApiCompanyEmployeesMutation,
  useGetApiCompanyEmployeesCountQuery,
} = injectedRtkApi;
