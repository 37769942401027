import { FormOutput } from 'features/billing/add/add-services/consts/schema';
import { getBillingServiceWebApi } from '../../orval/axios/client';
import { enhancedApi as api } from '../generated';

const enhancedApi = api
  .enhanceEndpoints({
    endpoints: {
      // Тариф (стоимость)
      getApiBillingTariffs: (endpoint) => {
        endpoint.providesTags = [{ type: 'Tariffs', id: 'LIST' }];
      },
      postApiBillingTariffs: (endpoint) => {
        endpoint.invalidatesTags = [{ type: 'Tariffs', id: 'LIST' }];
      },
      deleteApiBillingTariffsById: (endpoint) => {
        endpoint.invalidatesTags = [{ type: 'Tariffs', id: 'LIST' }];
      },
      // Списки услуг (биллинг)
      getApiBillingServicesLists: (endpoint) => {
        endpoint.providesTags = [{ type: 'ServiceLists', id: 'LIST' }];
      },
      getApiBillingServicesListsById: (endpoint) => {
        endpoint.providesTags = (result, error, arg) => [
          { type: 'ServiceLists', id: arg },
        ];
      },
      postApiBillingServicesLists: (endpoint) => {
        endpoint.invalidatesTags = [{ type: 'ServiceLists', id: 'LIST' }];
      },
      // Стадии
      deleteApiBillingStagesById: (endpoint) => {
        // Принимает только id. Но инвалидировать надо еще ServiceList
        // TODO:
        // endpoint.invalidatesTags = [{ type: 'ServiceLists', id: 'LIST' }];
      },
      // Биллинг (оплаты)
      getApiBillingBillings: (endpoint) => {
        endpoint.providesTags = [{ type: 'Billings', id: 'LIST' }];
      },
    },
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      addServices: builder.mutation<void, { stages: FormOutput; id: string }>({
        queryFn: async (args, _queryApi, _extraOptions, baseQuery) => {
          const {
            id,
            stages: { chargingStages, reservingStages },
          } = args;

          const {
            postApiBillingServices,
            postApiBillingStages,
            patchApiBillingServicesListsId,
          } = getBillingServiceWebApi();

          try {
            if (chargingStages.length) {
              const chargingService = await postApiBillingServices({
                name: 'Услуга Заряд',
                type: 'CHARGING',
              });

              const patchServicesListRes = await patchApiBillingServicesListsId(
                id,
                { servicesIds: chargingService.data?.id }
              );

              const chargingStagesRes = await Promise.all(
                chargingStages.map((stage) =>
                  postApiBillingStages({
                    ...stage,
                    serviceId: chargingService.data?.id,
                  })
                )
              );
            }

            if (reservingStages.length) {
              const reservingService = await postApiBillingServices({
                name: 'Услуга Бронирование',
                type: 'RESERVING',
              });

              const patchServicesListRes = await patchApiBillingServicesListsId(
                id,
                { servicesIds: reservingService.data?.id }
              );

              const reservingStagesRes = await Promise.all(
                reservingStages.map((stage) =>
                  postApiBillingStages({
                    ...stage,
                    serviceId: reservingService.data?.id,
                  })
                )
              );
            }

            return { data: { status: 'SUCCESS', statusMessage: '' } };
          } catch (err) {}
        },
        invalidatesTags: ['ServiceLists'],
      }),
    }),
  });

export const {
  useGetApiBillingServicesListsQuery: useGetServiceLists,
  useGetApiBillingServicesListsByIdQuery: useGetServiceList,
  usePostApiBillingServicesListsMutation: useAddServiceList,
  // Тарифы
  useGetApiBillingTariffsQuery: useGetTariffs,
  usePostApiBillingTariffsMutation: useAddTariff,
  useDeleteApiBillingTariffsByIdMutation:
    useDeleteApiBillingTariffsByIdMutation,
  // Стадии
  useDeleteApiBillingStagesByIdMutation: useDeleteStage,
  //
  useAddServicesMutation: useAddServices,
  // Биллинги
  useGetApiBillingBillingsQuery: useGetBillings,
  useGetApiBillingBillingsCountQuery: useGetBillingsCount,
} = enhancedApi;
