/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * ChargePointService.WebApi
 * OpenAPI spec version: 1.0
 */
import { z as zod } from 'zod';

/**
 * @summary Получить сущность
 */
export const getApiChargePointChargePointsIdParams = zod.object({
  id: zod.string().uuid(),
});

export const getApiChargePointChargePointsIdResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
  data: zod
    .object({
      id: zod.string().uuid(),
      number: zod.number().nullish(),
      name: zod.string().min(1),
      speedType: zod.enum(['UNDEFINED', 'LOW_SPEED_AC', 'HIGH_SPEED_DC']),
      multiconnectorSupport: zod.boolean(),
      chargePointProtocol: zod.enum(['OCPP16', 'OCPP16J', 'OCPP20']),
      status: zod.enum(['NO_CONNECT', 'AVAILABLE', 'FAULTED', 'UNAVAILABLE']),
      isVisible: zod.boolean(),
      maxPower: zod.number(),
      commentary: zod.string().nullish(),
      publicDescription: zod.string().nullish(),
      userLocationCheckDistance: zod.number().nullish(),
      warningText: zod.string().nullish(),
      workingTime: zod.string().nullish(),
      countryCode: zod.enum(['RU', 'BY', 'AM', 'KZ', 'KG']),
      city: zod.string().min(1),
      address: zod.string().min(1),
      latitude: zod.number(),
      longitude: zod.number(),
      model: zod.string().nullish(),
      dipLinkCharby: zod.string().nullish(),
      manufacturer: zod.string().nullish(),
      softwareRevision: zod.string().nullish(),
      ocppConfig: zod.string().nullish(),
      ipAddress: zod.string().nullish(),
      simNumber: zod.string().nullish(),
      chargeBoxIdentity: zod.string().min(1),
      supportContact: zod.string().nullish(),
      companyId: zod.string().uuid().nullish(),
      createdDate: zod.string().datetime(),
      lastUpdate: zod.string().datetime(),
      isTestCp: zod.boolean(),
      isBlocked: zod.boolean(),
      ocppConnectionBlockDate: zod.string().datetime(),
      infrastructureInfo: zod.string().nullish(),
      group: zod
        .object({
          id: zod.string().uuid(),
          name: zod.string().min(1),
        })
        .optional(),
      hub: zod
        .object({
          id: zod.string().uuid(),
          name: zod.string().min(1),
          description: zod.string().nullish(),
          address: zod.string().min(1),
          latitude: zod.number(),
          longitude: zod.number(),
        })
        .optional(),
      connectors: zod
        .array(
          zod.object({
            id: zod.string().uuid(),
            type: zod.enum([
              'SCHUKO',
              'TYPE2',
              'CCS2',
              'CHADEMO',
              'GBTDC',
              'GBTAC',
              'CCS1',
              'TYPE1',
              'TESLA',
            ]),
            format: zod.enum(['SOCKET', 'CABLE']),
            status: zod.enum([
              'UNDEFINED',
              'AVAILABLE',
              'PREPARING',
              'CHARGING',
              'SUSPENDED_EVSE',
              'SUSPENDED_EV',
              'FINISHING',
              'RESERVED',
              'UNAVAILABLE',
              'FAULTED',
            ]),
            statusForUser: zod.enum([
              'UNDEFINED',
              'AVAILABLE',
              'CHARGING',
              'RESERVED',
              'UNAVAILABLE',
              'FAULTED',
            ]),
            innerConnectorId: zod.number(),
            maxPower: zod.number(),
            maxAmperage: zod.number(),
            maxVoltage: zod.number().nullish(),
            manualBlock: zod.boolean(),
            lastUpdate: zod.string().datetime(),
            liter: zod.string().nullish(),
          })
        )
        .nullish(),
      photos: zod
        .array(
          zod.object({
            url: zod.string().nullish(),
            priority: zod.number(),
          })
        )
        .nullish(),
    })
    .optional(),
});

/**
 * @summary Обновить запись
 */
export const patchApiChargePointChargePointsIdParams = zod.object({
  id: zod.string().uuid(),
});

export const patchApiChargePointChargePointsIdBody = zod.object({
  number: zod.number().nullish(),
  speedType: zod
    .enum(['UNDEFINED', 'LOW_SPEED_AC', 'HIGH_SPEED_DC'])
    .optional(),
  multiconnectorSupport: zod.boolean().nullish(),
  name: zod.string().nullish(),
  chargePointProtocol: zod.enum(['OCPP16', 'OCPP16J', 'OCPP20']).optional(),
  status: zod
    .enum(['NO_CONNECT', 'AVAILABLE', 'FAULTED', 'UNAVAILABLE'])
    .optional(),
  isVisible: zod.boolean().nullish(),
  maxPower: zod.number().optional(),
  commentary: zod.string().nullish(),
  publicDescription: zod.string().nullish(),
  userLocationCheckDistance: zod.number().nullish(),
  warningText: zod.string().nullish(),
  workingTime: zod.string().nullish(),
  countryCode: zod.enum(['RU', 'BY', 'AM', 'KZ', 'KG']).optional(),
  city: zod.string().nullish(),
  address: zod.string().nullish(),
  latitude: zod.number().nullish(),
  longitude: zod.number().nullish(),
  model: zod.string().nullish(),
  manufacturer: zod.string().nullish(),
  softwareRevision: zod.string().nullish(),
  ocppConfig: zod.string().nullish(),
  ipAddress: zod.string().nullish(),
  simNumber: zod.string().nullish(),
  dipLinkCharby: zod.string().nullish(),
  chargeBoxIdentity: zod.string().nullish(),
  supportContact: zod.string().nullish(),
  groupId: zod.string().uuid().nullish(),
  companyId: zod.string().uuid().nullish(),
  hubId: zod.string().uuid().nullish(),
  isTestCp: zod.boolean().nullish(),
});

export const patchApiChargePointChargePointsIdResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
  data: zod
    .object({
      id: zod.string().uuid(),
      number: zod.number(),
      name: zod.string().min(1),
      speedType: zod.enum(['UNDEFINED', 'LOW_SPEED_AC', 'HIGH_SPEED_DC']),
      multiconnectorSupport: zod.boolean(),
      chargePointProtocol: zod.enum(['OCPP16', 'OCPP16J', 'OCPP20']),
      status: zod.enum(['NO_CONNECT', 'AVAILABLE', 'FAULTED', 'UNAVAILABLE']),
      isVisible: zod.boolean(),
      maxPower: zod.number(),
      commentary: zod.string().nullish(),
      publicDescription: zod.string().nullish(),
      userLocationCheckDistance: zod.number().nullish(),
      isBlocked: zod.boolean(),
      ocppConnectionBlockDate: zod.string().datetime(),
      warningText: zod.string().nullish(),
      workingTime: zod.string().nullish(),
      countryCode: zod.enum(['RU', 'BY', 'AM', 'KZ', 'KG']),
      city: zod.string().min(1),
      address: zod.string().min(1),
      latitude: zod.number(),
      longitude: zod.number(),
      model: zod.string().nullish(),
      dipLinkCharby: zod.string().nullish(),
      manufacturer: zod.string().nullish(),
      softwareRevision: zod.string().nullish(),
      ocppConfig: zod.string().nullish(),
      ipAddress: zod.string().nullish(),
      simNumber: zod.string().nullish(),
      chargeBoxIdentity: zod.string().min(1),
      companyId: zod.string().uuid().nullish(),
      supportContact: zod.string().nullish(),
      createdDate: zod.string().datetime(),
      lastUpdate: zod.string().datetime(),
      isTestCp: zod.boolean(),
      group: zod
        .object({
          id: zod.string().uuid(),
          name: zod.string().min(1),
        })
        .optional(),
      hub: zod
        .object({
          id: zod.string().uuid(),
          name: zod.string().min(1),
          description: zod.string().nullish(),
          address: zod.string().min(1),
          latitude: zod.number(),
          longitude: zod.number(),
        })
        .optional(),
      connectors: zod
        .array(
          zod.object({
            id: zod.string().uuid(),
            type: zod.enum([
              'SCHUKO',
              'TYPE2',
              'CCS2',
              'CHADEMO',
              'GBTDC',
              'GBTAC',
              'CCS1',
              'TYPE1',
              'TESLA',
            ]),
            format: zod.enum(['SOCKET', 'CABLE']),
            status: zod.enum([
              'UNDEFINED',
              'AVAILABLE',
              'PREPARING',
              'CHARGING',
              'SUSPENDED_EVSE',
              'SUSPENDED_EV',
              'FINISHING',
              'RESERVED',
              'UNAVAILABLE',
              'FAULTED',
            ]),
            statusForUser: zod.enum([
              'UNDEFINED',
              'AVAILABLE',
              'CHARGING',
              'RESERVED',
              'UNAVAILABLE',
              'FAULTED',
            ]),
            innerConnectorId: zod.number(),
            maxPower: zod.number(),
            maxAmperage: zod.number(),
            maxVoltage: zod.number().nullish(),
            manualBlock: zod.boolean(),
            lastUpdate: zod.string().datetime(),
            liter: zod.string().nullish(),
          })
        )
        .nullish(),
      photos: zod
        .array(
          zod.object({
            url: zod.string().nullish(),
            priority: zod.number(),
          })
        )
        .nullish(),
    })
    .optional(),
});

/**
 * @summary Удалить сущность
 */
export const deleteApiChargePointChargePointsIdParams = zod.object({
  id: zod.string().uuid(),
});

export const deleteApiChargePointChargePointsIdResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Получить список сущностей
 */
export const getApiChargePointChargePointsQueryParams = zod.object({
  GroupId: zod.string().uuid().optional(),
  HubId: zod.string().uuid().optional(),
  MobileAppId: zod.string().uuid().optional(),
  Name: zod.string().optional(),
  ConnectorId: zod.string().uuid().optional(),
  WithTestCp: zod.boolean().optional(),
  DateFrom: zod.string().datetime().optional(),
  DateTo: zod.string().datetime().optional(),
  Statuses: zod
    .array(zod.enum(['NO_CONNECT', 'AVAILABLE', 'FAULTED', 'UNAVAILABLE']))
    .optional(),
  CompanyIds: zod.array(zod.string().uuid()).optional(),
  OrderingField: zod.string().optional(),
  OrderingType: zod.enum(['Asc', 'Desc']).optional(),
  Offset: zod.number().optional(),
  Limit: zod.number().optional(),
});

export const getApiChargePointChargePointsResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
  data: zod
    .array(
      zod.object({
        id: zod.string().uuid(),
        number: zod.number().nullish(),
        name: zod.string().min(1),
        speedType: zod.enum(['UNDEFINED', 'LOW_SPEED_AC', 'HIGH_SPEED_DC']),
        multiconnectorSupport: zod.boolean(),
        chargePointProtocol: zod.enum(['OCPP16', 'OCPP16J', 'OCPP20']),
        status: zod.enum(['NO_CONNECT', 'AVAILABLE', 'FAULTED', 'UNAVAILABLE']),
        isVisible: zod.boolean(),
        maxPower: zod.number(),
        commentary: zod.string().nullish(),
        publicDescription: zod.string().nullish(),
        userLocationCheckDistance: zod.number().nullish(),
        warningText: zod.string().nullish(),
        workingTime: zod.string().nullish(),
        countryCode: zod.enum(['RU', 'BY', 'AM', 'KZ', 'KG']),
        city: zod.string().min(1),
        address: zod.string().min(1),
        latitude: zod.number(),
        longitude: zod.number(),
        model: zod.string().nullish(),
        dipLinkCharby: zod.string().nullish(),
        manufacturer: zod.string().nullish(),
        softwareRevision: zod.string().nullish(),
        ocppConfig: zod.string().nullish(),
        ipAddress: zod.string().nullish(),
        simNumber: zod.string().nullish(),
        chargeBoxIdentity: zod.string().min(1),
        supportContact: zod.string().nullish(),
        companyId: zod.string().uuid().nullish(),
        createdDate: zod.string().datetime(),
        lastUpdate: zod.string().datetime(),
        isTestCp: zod.boolean(),
        isBlocked: zod.boolean(),
        ocppConnectionBlockDate: zod.string().datetime(),
        infrastructureInfo: zod.string().nullish(),
        group: zod
          .object({
            id: zod.string().uuid(),
            name: zod.string().min(1),
          })
          .optional(),
        hub: zod
          .object({
            id: zod.string().uuid(),
            name: zod.string().min(1),
            description: zod.string().nullish(),
            address: zod.string().min(1),
            latitude: zod.number(),
            longitude: zod.number(),
          })
          .optional(),
        connectors: zod
          .array(
            zod.object({
              id: zod.string().uuid(),
              type: zod.enum([
                'SCHUKO',
                'TYPE2',
                'CCS2',
                'CHADEMO',
                'GBTDC',
                'GBTAC',
                'CCS1',
                'TYPE1',
                'TESLA',
              ]),
              format: zod.enum(['SOCKET', 'CABLE']),
              status: zod.enum([
                'UNDEFINED',
                'AVAILABLE',
                'PREPARING',
                'CHARGING',
                'SUSPENDED_EVSE',
                'SUSPENDED_EV',
                'FINISHING',
                'RESERVED',
                'UNAVAILABLE',
                'FAULTED',
              ]),
              statusForUser: zod.enum([
                'UNDEFINED',
                'AVAILABLE',
                'CHARGING',
                'RESERVED',
                'UNAVAILABLE',
                'FAULTED',
              ]),
              innerConnectorId: zod.number(),
              maxPower: zod.number(),
              maxAmperage: zod.number(),
              maxVoltage: zod.number().nullish(),
              manualBlock: zod.boolean(),
              lastUpdate: zod.string().datetime(),
              liter: zod.string().nullish(),
            })
          )
          .nullish(),
        photos: zod
          .array(
            zod.object({
              url: zod.string().nullish(),
              priority: zod.number(),
            })
          )
          .nullish(),
      })
    )
    .nullish(),
});

/**
 * @summary Добавить сущность
 */
export const postApiChargePointChargePointsBody = zod.object({
  number: zod.number().nullish(),
  speedType: zod.enum(['UNDEFINED', 'LOW_SPEED_AC', 'HIGH_SPEED_DC']),
  multiconnectorSupport: zod.boolean(),
  chargePointProtocol: zod.enum(['OCPP16', 'OCPP16J', 'OCPP20']),
  name: zod.string().min(1),
  status: zod.enum(['NO_CONNECT', 'AVAILABLE', 'FAULTED', 'UNAVAILABLE']),
  isVisible: zod.boolean(),
  maxPower: zod.number(),
  commentary: zod.string().nullish(),
  publicDescription: zod.string().nullish(),
  userLocationCheckDistance: zod.number().nullish(),
  warningText: zod.string().nullish(),
  workingTime: zod.string().nullish(),
  countryCode: zod.enum(['RU', 'BY', 'AM', 'KZ', 'KG']),
  city: zod.string().min(1),
  address: zod.string().min(1),
  latitude: zod.number(),
  longitude: zod.number(),
  model: zod.string().nullish(),
  manufacturer: zod.string().nullish(),
  softwareRevision: zod.string().nullish(),
  ocppConfig: zod.string().nullish(),
  ipAddress: zod.string().nullish(),
  simNumber: zod.string().nullish(),
  chargeBoxIdentity: zod.string().min(1),
  supportContact: zod.string().nullish(),
  dipLinkCharby: zod.string().nullish(),
  groupId: zod.string().uuid().nullish(),
  companyId: zod.string().uuid().nullish(),
  hubId: zod.string().uuid().nullish(),
  isTestCp: zod.boolean(),
});

export const postApiChargePointChargePointsResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
  data: zod
    .object({
      id: zod.string().uuid(),
      number: zod.number().nullish(),
      name: zod.string().min(1),
      speedType: zod.enum(['UNDEFINED', 'LOW_SPEED_AC', 'HIGH_SPEED_DC']),
      multiconnectorSupport: zod.boolean(),
      chargePointProtocol: zod.enum(['OCPP16', 'OCPP16J', 'OCPP20']),
      status: zod.enum(['NO_CONNECT', 'AVAILABLE', 'FAULTED', 'UNAVAILABLE']),
      isVisible: zod.boolean(),
      maxPower: zod.number(),
      commentary: zod.string().nullish(),
      publicDescription: zod.string().nullish(),
      userLocationCheckDistance: zod.number().nullish(),
      warningText: zod.string().nullish(),
      workingTime: zod.string().nullish(),
      countryCode: zod.enum(['RU', 'BY', 'AM', 'KZ', 'KG']),
      city: zod.string().min(1),
      address: zod.string().min(1),
      latitude: zod.number(),
      longitude: zod.number(),
      model: zod.string().nullish(),
      dipLinkCharby: zod.string().nullish(),
      manufacturer: zod.string().nullish(),
      softwareRevision: zod.string().nullish(),
      ocppConfig: zod.string().nullish(),
      ipAddress: zod.string().nullish(),
      simNumber: zod.string().nullish(),
      chargeBoxIdentity: zod.string().min(1),
      supportContact: zod.string().nullish(),
      companyId: zod.string().uuid().nullish(),
      createdDate: zod.string().datetime(),
      lastUpdate: zod.string().datetime(),
      isTestCp: zod.boolean(),
      isBlocked: zod.boolean(),
      ocppConnectionBlockDate: zod.string().datetime(),
      infrastructureInfo: zod.string().nullish(),
      group: zod
        .object({
          id: zod.string().uuid(),
          name: zod.string().min(1),
        })
        .optional(),
      hub: zod
        .object({
          id: zod.string().uuid(),
          name: zod.string().min(1),
          description: zod.string().nullish(),
          address: zod.string().min(1),
          latitude: zod.number(),
          longitude: zod.number(),
        })
        .optional(),
      connectors: zod
        .array(
          zod.object({
            id: zod.string().uuid(),
            type: zod.enum([
              'SCHUKO',
              'TYPE2',
              'CCS2',
              'CHADEMO',
              'GBTDC',
              'GBTAC',
              'CCS1',
              'TYPE1',
              'TESLA',
            ]),
            format: zod.enum(['SOCKET', 'CABLE']),
            status: zod.enum([
              'UNDEFINED',
              'AVAILABLE',
              'PREPARING',
              'CHARGING',
              'SUSPENDED_EVSE',
              'SUSPENDED_EV',
              'FINISHING',
              'RESERVED',
              'UNAVAILABLE',
              'FAULTED',
            ]),
            statusForUser: zod.enum([
              'UNDEFINED',
              'AVAILABLE',
              'CHARGING',
              'RESERVED',
              'UNAVAILABLE',
              'FAULTED',
            ]),
            innerConnectorId: zod.number(),
            maxPower: zod.number(),
            maxAmperage: zod.number(),
            maxVoltage: zod.number().nullish(),
            manualBlock: zod.boolean(),
            lastUpdate: zod.string().datetime(),
            liter: zod.string().nullish(),
          })
        )
        .nullish(),
      photos: zod
        .array(
          zod.object({
            url: zod.string().nullish(),
            priority: zod.number(),
          })
        )
        .nullish(),
    })
    .optional(),
});

/**
 * @summary Получить количество
 */
export const getApiChargePointChargePointsCountQueryParams = zod.object({
  CompanyIds: zod.array(zod.string().uuid()).optional(),
  GroupId: zod.string().uuid().optional(),
  HubId: zod.string().uuid().optional(),
  MobileAppId: zod.string().uuid().optional(),
  WithTestCp: zod.boolean().optional(),
  Statuses: zod
    .array(zod.enum(['NO_CONNECT', 'AVAILABLE', 'FAULTED', 'UNAVAILABLE']))
    .optional(),
  Name: zod.string().optional(),
  DateFrom: zod.string().datetime().optional(),
  DateTo: zod.string().datetime().optional(),
});

export const getApiChargePointChargePointsCountResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
  data: zod.number().optional(),
});

/**
 * @summary Получить представление - все статусы, расположения ЭЗС и их коннекторов
 */
export const getApiChargePointChargePointsCpStatusesQueryParams = zod.object({
  CompanyIds: zod.array(zod.string().uuid()).optional(),
  GroupId: zod.string().uuid().optional(),
  HubId: zod.string().uuid().optional(),
  MobileAppId: zod.string().uuid().optional(),
  WithTestCp: zod.boolean().optional(),
  WithVisibleCp: zod.boolean().optional(),
  DateFrom: zod.string().datetime().optional(),
  DateTo: zod.string().datetime().optional(),
  Offset: zod.number().optional(),
  Limit: zod.number().optional(),
});

export const getApiChargePointChargePointsCpStatusesResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
  data: zod
    .array(
      zod.object({
        chargePointId: zod.string().uuid(),
        status: zod.enum(['NO_CONNECT', 'AVAILABLE', 'FAULTED', 'UNAVAILABLE']),
        multiconnectorSupport: zod.boolean(),
        name: zod.string().min(1),
        maxPower: zod.number(),
        city: zod.string().min(1),
        address: zod.string().min(1),
        number: zod.number().nullish(),
        warningText: zod.string().nullish(),
        dipLinkCharby: zod.string().nullish(),
        speedType: zod.enum(['UNDEFINED', 'LOW_SPEED_AC', 'HIGH_SPEED_DC']),
        groupId: zod.string().uuid().nullish(),
        hubId: zod.string().uuid().nullish(),
        latitude: zod.number(),
        longitude: zod.number(),
        lastUpdate: zod.string().datetime(),
        connectors: zod
          .array(
            zod.object({
              id: zod.string().uuid(),
              status: zod.enum([
                'UNDEFINED',
                'AVAILABLE',
                'PREPARING',
                'CHARGING',
                'SUSPENDED_EVSE',
                'SUSPENDED_EV',
                'FINISHING',
                'RESERVED',
                'UNAVAILABLE',
                'FAULTED',
              ]),
              type: zod.enum([
                'SCHUKO',
                'TYPE2',
                'CCS2',
                'CHADEMO',
                'GBTDC',
                'GBTAC',
                'CCS1',
                'TYPE1',
                'TESLA',
              ]),
              innerConnectorId: zod.number(),
              statusForUser: zod.enum([
                'UNDEFINED',
                'AVAILABLE',
                'CHARGING',
                'RESERVED',
                'UNAVAILABLE',
                'FAULTED',
              ]),
              maxPower: zod.number(),
            })
          )
          .nullish(),
      })
    )
    .nullish(),
});

/**
 * @summary Получить информацию о зарядных станций
 */
export const getApiChargePointChargePointsInfoQueryParams = zod.object({
  chargeBoxIdentity: zod.string().optional(),
});

export const getApiChargePointChargePointsInfoResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
  data: zod
    .object({
      chargePointId: zod.string().uuid(),
      isBlocked: zod.boolean(),
    })
    .optional(),
});

export const getApiChargePointChargePointsInfoByConnectorsBodyItem = zod
  .string()
  .uuid();
export const getApiChargePointChargePointsInfoByConnectorsBody = zod.array(
  getApiChargePointChargePointsInfoByConnectorsBodyItem
);

export const getApiChargePointChargePointsInfoByConnectorsResponse = zod.object(
  {
    status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
    statusMessage: zod.string().nullish(),
    unavailables: zod
      .array(
        zod.enum([
          'CHARGE_POINT_SERVICE',
          'CP_WS_CONTROLLER',
          'CP_SOAP_CONTROLLER',
          'BILLING_SERVICE',
          'PAYMENT_SERVICE',
          'FISCAL_SERVICE',
          'SBER_PAY',
          'ARMENIA_PAY',
          'E_PAY',
          'FISCAL_RUSSIA',
          'FISCAL_ARMENIA',
          'BONUS_SERVICE',
          'DISCOUNT_SERVICE',
          'MOBILE_API_BFF',
          'USER_SERVICE',
          'RESERVE_SERVICE',
          'OCPI_SERVICE',
          'SMS_SERVICE',
          'PUSH_NOTIFICATION_SERVICE',
          'SYSTEM_INFROMATION_SERVICE',
          'IDENTITY_SERVICE',
          'ANALYTICS_SERVICE',
        ])
      )
      .nullish(),
    data: zod
      .array(
        zod.object({
          chargePointId: zod.string().uuid(),
          chargePointName: zod.string().min(1),
          companyId: zod.string().uuid().nullish(),
          connectorId: zod.string().uuid(),
          connectorType: zod.enum([
            'SCHUKO',
            'TYPE2',
            'CCS2',
            'CHADEMO',
            'GBTDC',
            'GBTAC',
            'CCS1',
            'TYPE1',
            'TESLA',
          ]),
          address: zod.string().min(1),
        })
      )
      .nullish(),
  }
);

/**
 * @summary Выполняет старт зарядной станции
 */
export const postApiChargePointCommandsSTARTBody = zod.object({
  userId: zod.string().uuid(),
  chargePointId: zod.string().uuid(),
  connectorId: zod.string().uuid(),
});

export const postApiChargePointCommandsSTARTResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
  data: zod
    .object({
      sessionId: zod.string().uuid().optional(),
    })
    .optional(),
});

/**
 * @summary Выполняет стоп зарядной станции
 */
export const postApiChargePointCommandsSTOPBody = zod.object({
  userId: zod.string().uuid(),
  chargePointId: zod.string().uuid(),
  connectorId: zod.string().uuid(),
});

export const postApiChargePointCommandsSTOPResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

export const postApiChargePointCommandsSETMAXPOWERBody = zod.object({
  chargePointId: zod.string().uuid(),
  maxPower: zod.number(),
});

export const postApiChargePointCommandsSETMAXPOWERResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

export const postApiChargePointCommandsRESTARTBody = zod.object({
  chargePointId: zod.string().uuid().optional(),
  type: zod.enum(['HARD', 'SOFT']).optional(),
});

export const postApiChargePointCommandsRESTARTResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

export const postApiChargePointCommandsLOADFIRMWAREBody = zod.object({
  chargePointId: zod.string().uuid().optional(),
  downloadUrl: zod.string().nullish(),
  retrieveDate: zod.string().datetime().optional(),
});

export const postApiChargePointCommandsLOADFIRMWAREResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

export const postApiChargePointCommandsCONFIGUREBody = zod.object({
  chargePointId: zod.string().uuid().optional(),
  key: zod.string().nullish(),
  newValue: zod.string().nullish(),
});

export const postApiChargePointCommandsCONFIGUREResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

export const postApiChargePointCommandsDIAGNOSTICBody = zod.object({
  chargePointId: zod.string().uuid().optional(),
  uploadUrl: zod.string().nullish(),
});

export const postApiChargePointCommandsDIAGNOSTICResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

export const postApiChargePointCommandsCHANGEAVAILABILITYBody = zod.object({
  chargePointId: zod.string().uuid().optional(),
  innerConnectorId: zod.number().optional(),
  type: zod.enum(['INOPERATIVE', 'OPERATIVE']).optional(),
});

export const postApiChargePointCommandsCHANGEAVAILABILITYResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Получить сущность
 */
export const getApiChargePointChargePointsChargePintsIdConnectorIdParams =
  zod.object({
    chargePintsId: zod.string().uuid(),
    connectorId: zod.string().uuid(),
  });

export const getApiChargePointChargePointsChargePintsIdConnectorIdResponse =
  zod.object({
    status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
    statusMessage: zod.string().nullish(),
    unavailables: zod
      .array(
        zod.enum([
          'CHARGE_POINT_SERVICE',
          'CP_WS_CONTROLLER',
          'CP_SOAP_CONTROLLER',
          'BILLING_SERVICE',
          'PAYMENT_SERVICE',
          'FISCAL_SERVICE',
          'SBER_PAY',
          'ARMENIA_PAY',
          'E_PAY',
          'FISCAL_RUSSIA',
          'FISCAL_ARMENIA',
          'BONUS_SERVICE',
          'DISCOUNT_SERVICE',
          'MOBILE_API_BFF',
          'USER_SERVICE',
          'RESERVE_SERVICE',
          'OCPI_SERVICE',
          'SMS_SERVICE',
          'PUSH_NOTIFICATION_SERVICE',
          'SYSTEM_INFROMATION_SERVICE',
          'IDENTITY_SERVICE',
          'ANALYTICS_SERVICE',
        ])
      )
      .nullish(),
    data: zod
      .object({
        id: zod.string().uuid(),
        type: zod.enum([
          'SCHUKO',
          'TYPE2',
          'CCS2',
          'CHADEMO',
          'GBTDC',
          'GBTAC',
          'CCS1',
          'TYPE1',
          'TESLA',
        ]),
        format: zod.enum(['SOCKET', 'CABLE']),
        chargePointId: zod.string().uuid(),
        status: zod.enum([
          'UNDEFINED',
          'AVAILABLE',
          'PREPARING',
          'CHARGING',
          'SUSPENDED_EVSE',
          'SUSPENDED_EV',
          'FINISHING',
          'RESERVED',
          'UNAVAILABLE',
          'FAULTED',
        ]),
        statusForUser: zod.enum([
          'UNDEFINED',
          'AVAILABLE',
          'CHARGING',
          'RESERVED',
          'UNAVAILABLE',
          'FAULTED',
        ]),
        innerConnectorId: zod.number(),
        maxPower: zod.number(),
        maxAmperage: zod.number(),
        maxVoltage: zod.number(),
        manualBlock: zod.boolean(),
        lastUpdate: zod.string().datetime(),
        liter: zod.string().nullish(),
      })
      .optional(),
  });

/**
 * @summary Добавить коннектор
 */
export const postApiChargePointChargePointsChargePointsIdConnectorParams =
  zod.object({
    chargePointsId: zod.string().uuid(),
  });

export const postApiChargePointChargePointsChargePointsIdConnectorBody =
  zod.object({
    type: zod.enum([
      'SCHUKO',
      'TYPE2',
      'CCS2',
      'CHADEMO',
      'GBTDC',
      'GBTAC',
      'CCS1',
      'TYPE1',
      'TESLA',
    ]),
    format: zod.enum(['SOCKET', 'CABLE']),
    status: zod.enum([
      'UNDEFINED',
      'AVAILABLE',
      'PREPARING',
      'CHARGING',
      'SUSPENDED_EVSE',
      'SUSPENDED_EV',
      'FINISHING',
      'RESERVED',
      'UNAVAILABLE',
      'FAULTED',
    ]),
    innerConnectorId: zod.number(),
    maxPower: zod.number(),
    maxAmperage: zod.number().nullish(),
    maxVoltage: zod.number().nullish(),
    liter: zod.string().nullish(),
  });

export const postApiChargePointChargePointsChargePointsIdConnectorResponse =
  zod.object({
    status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
    statusMessage: zod.string().nullish(),
    unavailables: zod
      .array(
        zod.enum([
          'CHARGE_POINT_SERVICE',
          'CP_WS_CONTROLLER',
          'CP_SOAP_CONTROLLER',
          'BILLING_SERVICE',
          'PAYMENT_SERVICE',
          'FISCAL_SERVICE',
          'SBER_PAY',
          'ARMENIA_PAY',
          'E_PAY',
          'FISCAL_RUSSIA',
          'FISCAL_ARMENIA',
          'BONUS_SERVICE',
          'DISCOUNT_SERVICE',
          'MOBILE_API_BFF',
          'USER_SERVICE',
          'RESERVE_SERVICE',
          'OCPI_SERVICE',
          'SMS_SERVICE',
          'PUSH_NOTIFICATION_SERVICE',
          'SYSTEM_INFROMATION_SERVICE',
          'IDENTITY_SERVICE',
          'ANALYTICS_SERVICE',
        ])
      )
      .nullish(),
    data: zod
      .object({
        id: zod.string().uuid(),
        type: zod.enum([
          'SCHUKO',
          'TYPE2',
          'CCS2',
          'CHADEMO',
          'GBTDC',
          'GBTAC',
          'CCS1',
          'TYPE1',
          'TESLA',
        ]),
        format: zod.enum(['SOCKET', 'CABLE']),
        chargePointId: zod.string().uuid(),
        status: zod.enum([
          'UNDEFINED',
          'AVAILABLE',
          'PREPARING',
          'CHARGING',
          'SUSPENDED_EVSE',
          'SUSPENDED_EV',
          'FINISHING',
          'RESERVED',
          'UNAVAILABLE',
          'FAULTED',
        ]),
        statusForUser: zod.enum([
          'UNDEFINED',
          'AVAILABLE',
          'CHARGING',
          'RESERVED',
          'UNAVAILABLE',
          'FAULTED',
        ]),
        innerConnectorId: zod.number(),
        maxPower: zod.number(),
        maxAmperage: zod.number(),
        maxVoltage: zod.number(),
        manualBlock: zod.boolean(),
        lastUpdate: zod.string().datetime(),
        liter: zod.string().nullish(),
      })
      .optional(),
  });

/**
 * @summary Обновить коннектор
 */
export const patchApiChargePointChargePointsChargePointsIdConnectorIdParams =
  zod.object({
    chargePointsId: zod.string().uuid(),
    connectorId: zod.string().uuid(),
  });

export const patchApiChargePointChargePointsChargePointsIdConnectorIdBody =
  zod.object({
    type: zod.enum([
      'SCHUKO',
      'TYPE2',
      'CCS2',
      'CHADEMO',
      'GBTDC',
      'GBTAC',
      'CCS1',
      'TYPE1',
      'TESLA',
    ]),
    format: zod.enum(['SOCKET', 'CABLE']),
    status: zod
      .enum([
        'UNDEFINED',
        'AVAILABLE',
        'PREPARING',
        'CHARGING',
        'SUSPENDED_EVSE',
        'SUSPENDED_EV',
        'FINISHING',
        'RESERVED',
        'UNAVAILABLE',
        'FAULTED',
      ])
      .optional(),
    innerConnectorId: zod.number(),
    maxPower: zod.number(),
    maxAmperage: zod.number(),
    maxVoltage: zod.number(),
    liter: zod.string().min(1),
  });

export const patchApiChargePointChargePointsChargePointsIdConnectorIdResponse =
  zod.object({
    status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
    statusMessage: zod.string().nullish(),
    unavailables: zod
      .array(
        zod.enum([
          'CHARGE_POINT_SERVICE',
          'CP_WS_CONTROLLER',
          'CP_SOAP_CONTROLLER',
          'BILLING_SERVICE',
          'PAYMENT_SERVICE',
          'FISCAL_SERVICE',
          'SBER_PAY',
          'ARMENIA_PAY',
          'E_PAY',
          'FISCAL_RUSSIA',
          'FISCAL_ARMENIA',
          'BONUS_SERVICE',
          'DISCOUNT_SERVICE',
          'MOBILE_API_BFF',
          'USER_SERVICE',
          'RESERVE_SERVICE',
          'OCPI_SERVICE',
          'SMS_SERVICE',
          'PUSH_NOTIFICATION_SERVICE',
          'SYSTEM_INFROMATION_SERVICE',
          'IDENTITY_SERVICE',
          'ANALYTICS_SERVICE',
        ])
      )
      .nullish(),
    data: zod
      .object({
        id: zod.string().uuid(),
        type: zod.enum([
          'SCHUKO',
          'TYPE2',
          'CCS2',
          'CHADEMO',
          'GBTDC',
          'GBTAC',
          'CCS1',
          'TYPE1',
          'TESLA',
        ]),
        format: zod.enum(['SOCKET', 'CABLE']),
        chargePointId: zod.string().uuid(),
        status: zod.enum([
          'UNDEFINED',
          'AVAILABLE',
          'PREPARING',
          'CHARGING',
          'SUSPENDED_EVSE',
          'SUSPENDED_EV',
          'FINISHING',
          'RESERVED',
          'UNAVAILABLE',
          'FAULTED',
        ]),
        statusForUser: zod.enum([
          'UNDEFINED',
          'AVAILABLE',
          'CHARGING',
          'RESERVED',
          'UNAVAILABLE',
          'FAULTED',
        ]),
        innerConnectorId: zod.number(),
        maxPower: zod.number(),
        maxAmperage: zod.number(),
        maxVoltage: zod.number(),
        manualBlock: zod.boolean(),
        lastUpdate: zod.string().datetime(),
        liter: zod.string().nullish(),
      })
      .optional(),
  });

/**
 * @summary Удалить коннектор
 */
export const deleteApiChargePointChargePointsChargePointsIdConnectorIdParams =
  zod.object({
    chargePointsId: zod.string().uuid(),
    connectorId: zod.string().uuid(),
  });

export const deleteApiChargePointChargePointsChargePointsIdConnectorIdResponse =
  zod.object({
    status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
    statusMessage: zod.string().nullish(),
    unavailables: zod
      .array(
        zod.enum([
          'CHARGE_POINT_SERVICE',
          'CP_WS_CONTROLLER',
          'CP_SOAP_CONTROLLER',
          'BILLING_SERVICE',
          'PAYMENT_SERVICE',
          'FISCAL_SERVICE',
          'SBER_PAY',
          'ARMENIA_PAY',
          'E_PAY',
          'FISCAL_RUSSIA',
          'FISCAL_ARMENIA',
          'BONUS_SERVICE',
          'DISCOUNT_SERVICE',
          'MOBILE_API_BFF',
          'USER_SERVICE',
          'RESERVE_SERVICE',
          'OCPI_SERVICE',
          'SMS_SERVICE',
          'PUSH_NOTIFICATION_SERVICE',
          'SYSTEM_INFROMATION_SERVICE',
          'IDENTITY_SERVICE',
          'ANALYTICS_SERVICE',
        ])
      )
      .nullish(),
  });

/**
 * @summary Получить все имеющиеся типы конекторов
 */
export const getApiChargePointConnectorsTypesResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
  data: zod.array(zod.string()).nullish(),
});

/**
 * @summary Получить сущность
 */
export const getApiChargePointEventsIdParams = zod.object({
  id: zod.string().uuid(),
});

export const getApiChargePointEventsIdResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
  data: zod
    .object({
      id: zod.string().uuid(),
      chargePointProtocol: zod.enum(['OCPP16', 'OCPP16J', 'OCPP20']),
      name: zod.enum([
        'BOOT_NOTIFICATION',
        'REMOTE_START_TRANSACTION',
        'START_TRANSACTION',
        'REMOTE_STOP_TRANSACTION',
        'STOP_TRANSACTION',
        'STATUS_NOTIFICATION',
        'METER_VALUES',
        'RESET',
        'CHANGE_CONFIGURATION',
        'UNLOCK_CONNECTOR',
        'CHANGE_AVAILABILITY',
        'DATA_TRANSFER',
      ]),
      type: zod.enum(['REQUEST', 'CONFIRMATION']),
      chargePointId: zod.string().uuid(),
      createdDate: zod.string().datetime(),
      connectorId: zod.string().uuid().nullish(),
      connectorStatus: zod
        .enum([
          'UNDEFINED',
          'AVAILABLE',
          'PREPARING',
          'CHARGING',
          'SUSPENDED_EVSE',
          'SUSPENDED_EV',
          'FINISHING',
          'RESERVED',
          'UNAVAILABLE',
          'FAULTED',
        ])
        .optional(),
      remoteStartStopStatus: zod.enum(['ACCEPTED', 'REJECTED']).optional(),
      transactionId: zod.string().nullish(),
      idempotencyKey: zod.string().uuid(),
      sessionId: zod.string().uuid().nullish(),
      instantPower: zod.number().nullish(),
      instantCurrent: zod.number().nullish(),
      chargingPercentage: zod.number().nullish(),
      countTotalEnergy: zod.number().nullish(),
      meterStop: zod.number().nullish(),
      meterStart: zod.number().nullish(),
      data: zod.string().nullish(),
    })
    .optional(),
});

/**
 * @summary Удалить сущность
 */
export const deleteApiChargePointEventsIdParams = zod.object({
  id: zod.string().uuid(),
});

export const deleteApiChargePointEventsIdResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Получить список сущностей
 */
export const getApiChargePointEventsQueryParams = zod.object({
  Name: zod
    .enum([
      'BOOT_NOTIFICATION',
      'REMOTE_START_TRANSACTION',
      'START_TRANSACTION',
      'REMOTE_STOP_TRANSACTION',
      'STOP_TRANSACTION',
      'STATUS_NOTIFICATION',
      'METER_VALUES',
      'RESET',
      'CHANGE_CONFIGURATION',
      'UNLOCK_CONNECTOR',
      'CHANGE_AVAILABILITY',
      'DATA_TRANSFER',
    ])
    .optional(),
  Type: zod.enum(['REQUEST', 'CONFIRMATION']).optional(),
  ChargePointId: zod.string().uuid().optional(),
  ConnectorId: zod.string().uuid().optional(),
  TransactionId: zod.string().uuid().optional(),
  DateFrom: zod.string().datetime().optional(),
  DateTo: zod.string().datetime().optional(),
  OrderingField: zod.string().optional(),
  OrderingType: zod.enum(['Asc', 'Desc']).optional(),
  Offset: zod.number().optional(),
  Limit: zod.number().optional(),
});

export const getApiChargePointEventsResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
  data: zod
    .array(
      zod.object({
        id: zod.string().uuid(),
        chargePointProtocol: zod.enum(['OCPP16', 'OCPP16J', 'OCPP20']),
        name: zod.enum([
          'BOOT_NOTIFICATION',
          'REMOTE_START_TRANSACTION',
          'START_TRANSACTION',
          'REMOTE_STOP_TRANSACTION',
          'STOP_TRANSACTION',
          'STATUS_NOTIFICATION',
          'METER_VALUES',
          'RESET',
          'CHANGE_CONFIGURATION',
          'UNLOCK_CONNECTOR',
          'CHANGE_AVAILABILITY',
          'DATA_TRANSFER',
        ]),
        type: zod.enum(['REQUEST', 'CONFIRMATION']),
        chargePointId: zod.string().uuid(),
        createdDate: zod.string().datetime(),
        connectorId: zod.string().uuid().nullish(),
        connectorStatus: zod
          .enum([
            'UNDEFINED',
            'AVAILABLE',
            'PREPARING',
            'CHARGING',
            'SUSPENDED_EVSE',
            'SUSPENDED_EV',
            'FINISHING',
            'RESERVED',
            'UNAVAILABLE',
            'FAULTED',
          ])
          .optional(),
        remoteStartStopStatus: zod.enum(['ACCEPTED', 'REJECTED']).optional(),
        transactionId: zod.string().nullish(),
        idempotencyKey: zod.string().uuid(),
        sessionId: zod.string().uuid().nullish(),
        instantPower: zod.number().nullish(),
        instantCurrent: zod.number().nullish(),
        chargingPercentage: zod.number().nullish(),
        countTotalEnergy: zod.number().nullish(),
        meterStop: zod.number().nullish(),
        meterStart: zod.number().nullish(),
        data: zod.string().nullish(),
      })
    )
    .nullish(),
});

/**
 * @summary Получить кол-во сущностей
 */
export const getApiChargePointEventsCountQueryParams = zod.object({
  Name: zod
    .enum([
      'BOOT_NOTIFICATION',
      'REMOTE_START_TRANSACTION',
      'START_TRANSACTION',
      'REMOTE_STOP_TRANSACTION',
      'STOP_TRANSACTION',
      'STATUS_NOTIFICATION',
      'METER_VALUES',
      'RESET',
      'CHANGE_CONFIGURATION',
      'UNLOCK_CONNECTOR',
      'CHANGE_AVAILABILITY',
      'DATA_TRANSFER',
    ])
    .optional(),
  Type: zod.enum(['REQUEST', 'CONFIRMATION']).optional(),
  ChargePointId: zod.string().uuid().optional(),
  ConnectorId: zod.string().uuid().optional(),
  TransactionId: zod.string().uuid().optional(),
  DateFrom: zod.string().datetime().optional(),
  DateTo: zod.string().datetime().optional(),
});

export const getApiChargePointEventsCountResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
  data: zod.number().optional(),
});

export const postApiChargePointEventsDisconnectEventBody = zod.object({
  reason: zod.string().min(1),
  chargePointId: zod.string().uuid(),
});

export const postApiChargePointEventsDisconnectEventResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

export const postApiChargePointEventsBOOTNOTIFICATIONREQUESTHeader = zod.object(
  {
    IdempotencyKey: zod.string().uuid().optional(),
  }
);

export const postApiChargePointEventsBOOTNOTIFICATIONREQUESTBody = zod.object({
  chargePointProtocol: zod.enum(['OCPP16', 'OCPP16J', 'OCPP20']),
  chargePointId: zod.string().uuid(),
  data: zod.string().min(1),
});

export const postApiChargePointEventsBOOTNOTIFICATIONREQUESTResponse =
  zod.object({
    status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
    statusMessage: zod.string().nullish(),
    unavailables: zod
      .array(
        zod.enum([
          'CHARGE_POINT_SERVICE',
          'CP_WS_CONTROLLER',
          'CP_SOAP_CONTROLLER',
          'BILLING_SERVICE',
          'PAYMENT_SERVICE',
          'FISCAL_SERVICE',
          'SBER_PAY',
          'ARMENIA_PAY',
          'E_PAY',
          'FISCAL_RUSSIA',
          'FISCAL_ARMENIA',
          'BONUS_SERVICE',
          'DISCOUNT_SERVICE',
          'MOBILE_API_BFF',
          'USER_SERVICE',
          'RESERVE_SERVICE',
          'OCPI_SERVICE',
          'SMS_SERVICE',
          'PUSH_NOTIFICATION_SERVICE',
          'SYSTEM_INFROMATION_SERVICE',
          'IDENTITY_SERVICE',
          'ANALYTICS_SERVICE',
        ])
      )
      .nullish(),
    data: zod
      .object({
        date: zod.string().datetime(),
        isBlocked: zod.boolean(),
      })
      .optional(),
  });

export const postApiChargePointEventsBOOTNOTIFICATIONCONFIRMATIONHeader =
  zod.object({
    IdempotencyKey: zod.string().uuid().optional(),
  });

export const postApiChargePointEventsBOOTNOTIFICATIONCONFIRMATIONBody =
  zod.object({
    chargePointProtocol: zod.enum(['OCPP16', 'OCPP16J', 'OCPP20']),
    chargePointId: zod.string().uuid(),
    data: zod.string().min(1),
  });

export const postApiChargePointEventsBOOTNOTIFICATIONCONFIRMATIONResponse =
  zod.object({
    status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
    statusMessage: zod.string().nullish(),
    unavailables: zod
      .array(
        zod.enum([
          'CHARGE_POINT_SERVICE',
          'CP_WS_CONTROLLER',
          'CP_SOAP_CONTROLLER',
          'BILLING_SERVICE',
          'PAYMENT_SERVICE',
          'FISCAL_SERVICE',
          'SBER_PAY',
          'ARMENIA_PAY',
          'E_PAY',
          'FISCAL_RUSSIA',
          'FISCAL_ARMENIA',
          'BONUS_SERVICE',
          'DISCOUNT_SERVICE',
          'MOBILE_API_BFF',
          'USER_SERVICE',
          'RESERVE_SERVICE',
          'OCPI_SERVICE',
          'SMS_SERVICE',
          'PUSH_NOTIFICATION_SERVICE',
          'SYSTEM_INFROMATION_SERVICE',
          'IDENTITY_SERVICE',
          'ANALYTICS_SERVICE',
        ])
      )
      .nullish(),
  });

export const postApiChargePointEventsREMOTESTARTTRANSACTIONREQUESTHeader =
  zod.object({
    IdempotencyKey: zod.string().uuid().optional(),
  });

export const postApiChargePointEventsREMOTESTARTTRANSACTIONREQUESTBody =
  zod.object({
    chargePointProtocol: zod.enum(['OCPP16', 'OCPP16J', 'OCPP20']),
    chargePointId: zod.string().uuid(),
    data: zod.string().min(1),
    transactionId: zod.string().min(1),
  });

export const postApiChargePointEventsREMOTESTARTTRANSACTIONREQUESTResponse =
  zod.object({
    status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
    statusMessage: zod.string().nullish(),
    unavailables: zod
      .array(
        zod.enum([
          'CHARGE_POINT_SERVICE',
          'CP_WS_CONTROLLER',
          'CP_SOAP_CONTROLLER',
          'BILLING_SERVICE',
          'PAYMENT_SERVICE',
          'FISCAL_SERVICE',
          'SBER_PAY',
          'ARMENIA_PAY',
          'E_PAY',
          'FISCAL_RUSSIA',
          'FISCAL_ARMENIA',
          'BONUS_SERVICE',
          'DISCOUNT_SERVICE',
          'MOBILE_API_BFF',
          'USER_SERVICE',
          'RESERVE_SERVICE',
          'OCPI_SERVICE',
          'SMS_SERVICE',
          'PUSH_NOTIFICATION_SERVICE',
          'SYSTEM_INFROMATION_SERVICE',
          'IDENTITY_SERVICE',
          'ANALYTICS_SERVICE',
        ])
      )
      .nullish(),
  });

export const postApiChargePointEventsREMOTESTARTTRANSACTIONCONFIRMATIONHeader =
  zod.object({
    IdempotencyKey: zod.string().uuid().optional(),
  });

export const postApiChargePointEventsREMOTESTARTTRANSACTIONCONFIRMATIONBody =
  zod.object({
    chargePointProtocol: zod.enum(['OCPP16', 'OCPP16J', 'OCPP20']),
    chargePointId: zod.string().uuid(),
    data: zod.string().min(1),
    remoteStartStopStatus: zod.enum(['ACCEPTED', 'REJECTED']),
  });

export const postApiChargePointEventsREMOTESTARTTRANSACTIONCONFIRMATIONResponse =
  zod.object({
    status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
    statusMessage: zod.string().nullish(),
    unavailables: zod
      .array(
        zod.enum([
          'CHARGE_POINT_SERVICE',
          'CP_WS_CONTROLLER',
          'CP_SOAP_CONTROLLER',
          'BILLING_SERVICE',
          'PAYMENT_SERVICE',
          'FISCAL_SERVICE',
          'SBER_PAY',
          'ARMENIA_PAY',
          'E_PAY',
          'FISCAL_RUSSIA',
          'FISCAL_ARMENIA',
          'BONUS_SERVICE',
          'DISCOUNT_SERVICE',
          'MOBILE_API_BFF',
          'USER_SERVICE',
          'RESERVE_SERVICE',
          'OCPI_SERVICE',
          'SMS_SERVICE',
          'PUSH_NOTIFICATION_SERVICE',
          'SYSTEM_INFROMATION_SERVICE',
          'IDENTITY_SERVICE',
          'ANALYTICS_SERVICE',
        ])
      )
      .nullish(),
  });

export const postApiChargePointEventsSTARTTRANSACTIONREQUESTHeader = zod.object(
  {
    IdempotencyKey: zod.string().uuid().optional(),
  }
);

export const postApiChargePointEventsSTARTTRANSACTIONREQUESTBody = zod.object({
  chargePointProtocol: zod.enum(['OCPP16', 'OCPP16J', 'OCPP20']),
  chargePointId: zod.string().uuid(),
  innerConnectorId: zod.number(),
  meterStart: zod.number(),
  data: zod.string().min(1),
});

export const postApiChargePointEventsSTARTTRANSACTIONREQUESTResponse =
  zod.object({
    status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
    statusMessage: zod.string().nullish(),
    unavailables: zod
      .array(
        zod.enum([
          'CHARGE_POINT_SERVICE',
          'CP_WS_CONTROLLER',
          'CP_SOAP_CONTROLLER',
          'BILLING_SERVICE',
          'PAYMENT_SERVICE',
          'FISCAL_SERVICE',
          'SBER_PAY',
          'ARMENIA_PAY',
          'E_PAY',
          'FISCAL_RUSSIA',
          'FISCAL_ARMENIA',
          'BONUS_SERVICE',
          'DISCOUNT_SERVICE',
          'MOBILE_API_BFF',
          'USER_SERVICE',
          'RESERVE_SERVICE',
          'OCPI_SERVICE',
          'SMS_SERVICE',
          'PUSH_NOTIFICATION_SERVICE',
          'SYSTEM_INFROMATION_SERVICE',
          'IDENTITY_SERVICE',
          'ANALYTICS_SERVICE',
        ])
      )
      .nullish(),
  });

export const postApiChargePointEventsSTARTTRANSACTIONCONFIRMATIONHeader =
  zod.object({
    IdempotencyKey: zod.string().uuid().optional(),
  });

export const postApiChargePointEventsSTARTTRANSACTIONCONFIRMATIONBody =
  zod.object({
    chargePointProtocol: zod.enum(['OCPP16', 'OCPP16J', 'OCPP20']),
    chargePointId: zod.string().uuid(),
    data: zod.string().min(1),
    transactionId: zod.string().min(1),
  });

export const postApiChargePointEventsSTARTTRANSACTIONCONFIRMATIONResponse =
  zod.object({
    status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
    statusMessage: zod.string().nullish(),
    unavailables: zod
      .array(
        zod.enum([
          'CHARGE_POINT_SERVICE',
          'CP_WS_CONTROLLER',
          'CP_SOAP_CONTROLLER',
          'BILLING_SERVICE',
          'PAYMENT_SERVICE',
          'FISCAL_SERVICE',
          'SBER_PAY',
          'ARMENIA_PAY',
          'E_PAY',
          'FISCAL_RUSSIA',
          'FISCAL_ARMENIA',
          'BONUS_SERVICE',
          'DISCOUNT_SERVICE',
          'MOBILE_API_BFF',
          'USER_SERVICE',
          'RESERVE_SERVICE',
          'OCPI_SERVICE',
          'SMS_SERVICE',
          'PUSH_NOTIFICATION_SERVICE',
          'SYSTEM_INFROMATION_SERVICE',
          'IDENTITY_SERVICE',
          'ANALYTICS_SERVICE',
        ])
      )
      .nullish(),
  });

export const postApiChargePointEventsREMOTESTOPTRANSACTIONREQUESTHeader =
  zod.object({
    IdempotencyKey: zod.string().uuid().optional(),
  });

export const postApiChargePointEventsREMOTESTOPTRANSACTIONREQUESTBody =
  zod.object({
    chargePointProtocol: zod.enum(['OCPP16', 'OCPP16J', 'OCPP20']),
    chargePointId: zod.string().uuid(),
    data: zod.string().min(1),
    transactionId: zod.string().min(1),
  });

export const postApiChargePointEventsREMOTESTOPTRANSACTIONREQUESTResponse =
  zod.object({
    status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
    statusMessage: zod.string().nullish(),
    unavailables: zod
      .array(
        zod.enum([
          'CHARGE_POINT_SERVICE',
          'CP_WS_CONTROLLER',
          'CP_SOAP_CONTROLLER',
          'BILLING_SERVICE',
          'PAYMENT_SERVICE',
          'FISCAL_SERVICE',
          'SBER_PAY',
          'ARMENIA_PAY',
          'E_PAY',
          'FISCAL_RUSSIA',
          'FISCAL_ARMENIA',
          'BONUS_SERVICE',
          'DISCOUNT_SERVICE',
          'MOBILE_API_BFF',
          'USER_SERVICE',
          'RESERVE_SERVICE',
          'OCPI_SERVICE',
          'SMS_SERVICE',
          'PUSH_NOTIFICATION_SERVICE',
          'SYSTEM_INFROMATION_SERVICE',
          'IDENTITY_SERVICE',
          'ANALYTICS_SERVICE',
        ])
      )
      .nullish(),
  });

export const postApiChargePointEventsREMOTESTOPTRANSACTIONCONFIRMATIONHeader =
  zod.object({
    IdempotencyKey: zod.string().uuid().optional(),
  });

export const postApiChargePointEventsREMOTESTOPTRANSACTIONCONFIRMATIONBody =
  zod.object({
    chargePointProtocol: zod.enum(['OCPP16', 'OCPP16J', 'OCPP20']),
    chargePointId: zod.string().uuid(),
    data: zod.string().min(1),
    remoteStartStopStatus: zod.enum(['ACCEPTED', 'REJECTED']),
  });

export const postApiChargePointEventsREMOTESTOPTRANSACTIONCONFIRMATIONResponse =
  zod.object({
    status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
    statusMessage: zod.string().nullish(),
    unavailables: zod
      .array(
        zod.enum([
          'CHARGE_POINT_SERVICE',
          'CP_WS_CONTROLLER',
          'CP_SOAP_CONTROLLER',
          'BILLING_SERVICE',
          'PAYMENT_SERVICE',
          'FISCAL_SERVICE',
          'SBER_PAY',
          'ARMENIA_PAY',
          'E_PAY',
          'FISCAL_RUSSIA',
          'FISCAL_ARMENIA',
          'BONUS_SERVICE',
          'DISCOUNT_SERVICE',
          'MOBILE_API_BFF',
          'USER_SERVICE',
          'RESERVE_SERVICE',
          'OCPI_SERVICE',
          'SMS_SERVICE',
          'PUSH_NOTIFICATION_SERVICE',
          'SYSTEM_INFROMATION_SERVICE',
          'IDENTITY_SERVICE',
          'ANALYTICS_SERVICE',
        ])
      )
      .nullish(),
  });

export const postApiChargePointEventsSTOPTRANSACTIONREQUESTHeader = zod.object({
  IdempotencyKey: zod.string().uuid().optional(),
});

export const postApiChargePointEventsSTOPTRANSACTIONREQUESTBody = zod.object({
  chargePointProtocol: zod.enum(['OCPP16', 'OCPP16J', 'OCPP20']),
  chargePointId: zod.string().uuid(),
  data: zod.string().min(1),
  meterStop: zod.number(),
  reason: zod.string().min(1),
  transactionId: zod.string().min(1),
  instantPower: zod.number().nullish(),
  chargingPercentage: zod.number().nullish(),
  countTotalEnergy: zod.number().nullish(),
});

export const postApiChargePointEventsSTOPTRANSACTIONREQUESTResponse =
  zod.object({
    status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
    statusMessage: zod.string().nullish(),
    unavailables: zod
      .array(
        zod.enum([
          'CHARGE_POINT_SERVICE',
          'CP_WS_CONTROLLER',
          'CP_SOAP_CONTROLLER',
          'BILLING_SERVICE',
          'PAYMENT_SERVICE',
          'FISCAL_SERVICE',
          'SBER_PAY',
          'ARMENIA_PAY',
          'E_PAY',
          'FISCAL_RUSSIA',
          'FISCAL_ARMENIA',
          'BONUS_SERVICE',
          'DISCOUNT_SERVICE',
          'MOBILE_API_BFF',
          'USER_SERVICE',
          'RESERVE_SERVICE',
          'OCPI_SERVICE',
          'SMS_SERVICE',
          'PUSH_NOTIFICATION_SERVICE',
          'SYSTEM_INFROMATION_SERVICE',
          'IDENTITY_SERVICE',
          'ANALYTICS_SERVICE',
        ])
      )
      .nullish(),
  });

export const postApiChargePointEventsSTOPTRANSACTIONCONFIRMATIONHeader =
  zod.object({
    IdempotencyKey: zod.string().uuid().optional(),
  });

export const postApiChargePointEventsSTOPTRANSACTIONCONFIRMATIONBody =
  zod.object({
    chargePointProtocol: zod.enum(['OCPP16', 'OCPP16J', 'OCPP20']),
    chargePointId: zod.string().uuid(),
    data: zod.string().min(1),
  });

export const postApiChargePointEventsSTOPTRANSACTIONCONFIRMATIONResponse =
  zod.object({
    status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
    statusMessage: zod.string().nullish(),
    unavailables: zod
      .array(
        zod.enum([
          'CHARGE_POINT_SERVICE',
          'CP_WS_CONTROLLER',
          'CP_SOAP_CONTROLLER',
          'BILLING_SERVICE',
          'PAYMENT_SERVICE',
          'FISCAL_SERVICE',
          'SBER_PAY',
          'ARMENIA_PAY',
          'E_PAY',
          'FISCAL_RUSSIA',
          'FISCAL_ARMENIA',
          'BONUS_SERVICE',
          'DISCOUNT_SERVICE',
          'MOBILE_API_BFF',
          'USER_SERVICE',
          'RESERVE_SERVICE',
          'OCPI_SERVICE',
          'SMS_SERVICE',
          'PUSH_NOTIFICATION_SERVICE',
          'SYSTEM_INFROMATION_SERVICE',
          'IDENTITY_SERVICE',
          'ANALYTICS_SERVICE',
        ])
      )
      .nullish(),
  });

export const postApiChargePointEventsSTATUSNOTIFICATIONREQUESTHeader =
  zod.object({
    IdempotencyKey: zod.string().uuid().optional(),
  });

export const postApiChargePointEventsSTATUSNOTIFICATIONREQUESTBody = zod.object(
  {
    chargePointProtocol: zod.enum(['OCPP16', 'OCPP16J', 'OCPP20']),
    chargePointId: zod.string().uuid(),
    status: zod.enum([
      'UNDEFINED',
      'AVAILABLE',
      'PREPARING',
      'CHARGING',
      'SUSPENDED_EVSE',
      'SUSPENDED_EV',
      'FINISHING',
      'RESERVED',
      'UNAVAILABLE',
      'FAULTED',
    ]),
    data: zod.string().min(1),
    innerConnectorId: zod.number(),
  }
);

export const postApiChargePointEventsSTATUSNOTIFICATIONREQUESTResponse =
  zod.object({
    status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
    statusMessage: zod.string().nullish(),
    unavailables: zod
      .array(
        zod.enum([
          'CHARGE_POINT_SERVICE',
          'CP_WS_CONTROLLER',
          'CP_SOAP_CONTROLLER',
          'BILLING_SERVICE',
          'PAYMENT_SERVICE',
          'FISCAL_SERVICE',
          'SBER_PAY',
          'ARMENIA_PAY',
          'E_PAY',
          'FISCAL_RUSSIA',
          'FISCAL_ARMENIA',
          'BONUS_SERVICE',
          'DISCOUNT_SERVICE',
          'MOBILE_API_BFF',
          'USER_SERVICE',
          'RESERVE_SERVICE',
          'OCPI_SERVICE',
          'SMS_SERVICE',
          'PUSH_NOTIFICATION_SERVICE',
          'SYSTEM_INFROMATION_SERVICE',
          'IDENTITY_SERVICE',
          'ANALYTICS_SERVICE',
        ])
      )
      .nullish(),
  });

export const postApiChargePointEventsSTATUSNOTIFICATIONCONFIRMATIONHeader =
  zod.object({
    IdempotencyKey: zod.string().uuid().optional(),
  });

export const postApiChargePointEventsSTATUSNOTIFICATIONCONFIRMATIONBody =
  zod.object({
    chargePointProtocol: zod.enum(['OCPP16', 'OCPP16J', 'OCPP20']),
    chargePointId: zod.string().uuid(),
    data: zod.string().min(1),
  });

export const postApiChargePointEventsSTATUSNOTIFICATIONCONFIRMATIONResponse =
  zod.object({
    status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
    statusMessage: zod.string().nullish(),
    unavailables: zod
      .array(
        zod.enum([
          'CHARGE_POINT_SERVICE',
          'CP_WS_CONTROLLER',
          'CP_SOAP_CONTROLLER',
          'BILLING_SERVICE',
          'PAYMENT_SERVICE',
          'FISCAL_SERVICE',
          'SBER_PAY',
          'ARMENIA_PAY',
          'E_PAY',
          'FISCAL_RUSSIA',
          'FISCAL_ARMENIA',
          'BONUS_SERVICE',
          'DISCOUNT_SERVICE',
          'MOBILE_API_BFF',
          'USER_SERVICE',
          'RESERVE_SERVICE',
          'OCPI_SERVICE',
          'SMS_SERVICE',
          'PUSH_NOTIFICATION_SERVICE',
          'SYSTEM_INFROMATION_SERVICE',
          'IDENTITY_SERVICE',
          'ANALYTICS_SERVICE',
        ])
      )
      .nullish(),
  });

export const postApiChargePointEventsMETERVALUESREQUESTHeader = zod.object({
  IdempotencyKey: zod.string().uuid().optional(),
});

export const postApiChargePointEventsMETERVALUESREQUESTBody = zod.object({
  chargePointProtocol: zod.enum(['OCPP16', 'OCPP16J', 'OCPP20']),
  chargePointId: zod.string().uuid(),
  data: zod.string().min(1),
  innerConnectorId: zod.number(),
  transactionId: zod.string().min(1),
  instantPower: zod.number(),
  instantCurrent: zod.number(),
  chargingPercentage: zod.number(),
  countTotalEnergy: zod.number(),
});

export const postApiChargePointEventsMETERVALUESREQUESTResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

export const postApiChargePointEventsMETERVALUESCONFIRMATIONHeader = zod.object(
  {
    IdempotencyKey: zod.string().uuid().optional(),
  }
);

export const postApiChargePointEventsMETERVALUESCONFIRMATIONBody = zod.object({
  chargePointProtocol: zod.enum(['OCPP16', 'OCPP16J', 'OCPP20']),
  chargePointId: zod.string().uuid(),
  data: zod.string().min(1),
});

export const postApiChargePointEventsMETERVALUESCONFIRMATIONResponse =
  zod.object({
    status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
    statusMessage: zod.string().nullish(),
    unavailables: zod
      .array(
        zod.enum([
          'CHARGE_POINT_SERVICE',
          'CP_WS_CONTROLLER',
          'CP_SOAP_CONTROLLER',
          'BILLING_SERVICE',
          'PAYMENT_SERVICE',
          'FISCAL_SERVICE',
          'SBER_PAY',
          'ARMENIA_PAY',
          'E_PAY',
          'FISCAL_RUSSIA',
          'FISCAL_ARMENIA',
          'BONUS_SERVICE',
          'DISCOUNT_SERVICE',
          'MOBILE_API_BFF',
          'USER_SERVICE',
          'RESERVE_SERVICE',
          'OCPI_SERVICE',
          'SMS_SERVICE',
          'PUSH_NOTIFICATION_SERVICE',
          'SYSTEM_INFROMATION_SERVICE',
          'IDENTITY_SERVICE',
          'ANALYTICS_SERVICE',
        ])
      )
      .nullish(),
  });

export const postApiChargePointEventsCHANGECONFIGURATIONREQUESTHeader =
  zod.object({
    IdempotencyKey: zod.string().uuid().optional(),
  });

export const postApiChargePointEventsCHANGECONFIGURATIONREQUESTBody =
  zod.object({
    chargePointProtocol: zod.enum(['OCPP16', 'OCPP16J', 'OCPP20']),
    chargePointId: zod.string().uuid(),
    data: zod.string().min(1),
  });

export const postApiChargePointEventsCHANGECONFIGURATIONREQUESTResponse =
  zod.object({
    status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
    statusMessage: zod.string().nullish(),
    unavailables: zod
      .array(
        zod.enum([
          'CHARGE_POINT_SERVICE',
          'CP_WS_CONTROLLER',
          'CP_SOAP_CONTROLLER',
          'BILLING_SERVICE',
          'PAYMENT_SERVICE',
          'FISCAL_SERVICE',
          'SBER_PAY',
          'ARMENIA_PAY',
          'E_PAY',
          'FISCAL_RUSSIA',
          'FISCAL_ARMENIA',
          'BONUS_SERVICE',
          'DISCOUNT_SERVICE',
          'MOBILE_API_BFF',
          'USER_SERVICE',
          'RESERVE_SERVICE',
          'OCPI_SERVICE',
          'SMS_SERVICE',
          'PUSH_NOTIFICATION_SERVICE',
          'SYSTEM_INFROMATION_SERVICE',
          'IDENTITY_SERVICE',
          'ANALYTICS_SERVICE',
        ])
      )
      .nullish(),
  });

export const postApiChargePointEventsCHANGECONFIGURATIONCONFIRMATIONHeader =
  zod.object({
    IdempotencyKey: zod.string().uuid().optional(),
  });

export const postApiChargePointEventsCHANGECONFIGURATIONCONFIRMATIONBody =
  zod.object({
    chargePointProtocol: zod.enum(['OCPP16', 'OCPP16J', 'OCPP20']),
    chargePointId: zod.string().uuid(),
    data: zod.string().min(1),
  });

export const postApiChargePointEventsCHANGECONFIGURATIONCONFIRMATIONResponse =
  zod.object({
    status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
    statusMessage: zod.string().nullish(),
    unavailables: zod
      .array(
        zod.enum([
          'CHARGE_POINT_SERVICE',
          'CP_WS_CONTROLLER',
          'CP_SOAP_CONTROLLER',
          'BILLING_SERVICE',
          'PAYMENT_SERVICE',
          'FISCAL_SERVICE',
          'SBER_PAY',
          'ARMENIA_PAY',
          'E_PAY',
          'FISCAL_RUSSIA',
          'FISCAL_ARMENIA',
          'BONUS_SERVICE',
          'DISCOUNT_SERVICE',
          'MOBILE_API_BFF',
          'USER_SERVICE',
          'RESERVE_SERVICE',
          'OCPI_SERVICE',
          'SMS_SERVICE',
          'PUSH_NOTIFICATION_SERVICE',
          'SYSTEM_INFROMATION_SERVICE',
          'IDENTITY_SERVICE',
          'ANALYTICS_SERVICE',
        ])
      )
      .nullish(),
  });

export const postApiChargePointEventsUNLOCKCONNECTORREQUESTHeader = zod.object({
  IdempotencyKey: zod.string().uuid().optional(),
});

export const postApiChargePointEventsUNLOCKCONNECTORREQUESTBody = zod.object({
  chargePointProtocol: zod.enum(['OCPP16', 'OCPP16J', 'OCPP20']),
  chargePointId: zod.string().uuid(),
  data: zod.string().min(1),
});

export const postApiChargePointEventsUNLOCKCONNECTORREQUESTResponse =
  zod.object({
    status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
    statusMessage: zod.string().nullish(),
    unavailables: zod
      .array(
        zod.enum([
          'CHARGE_POINT_SERVICE',
          'CP_WS_CONTROLLER',
          'CP_SOAP_CONTROLLER',
          'BILLING_SERVICE',
          'PAYMENT_SERVICE',
          'FISCAL_SERVICE',
          'SBER_PAY',
          'ARMENIA_PAY',
          'E_PAY',
          'FISCAL_RUSSIA',
          'FISCAL_ARMENIA',
          'BONUS_SERVICE',
          'DISCOUNT_SERVICE',
          'MOBILE_API_BFF',
          'USER_SERVICE',
          'RESERVE_SERVICE',
          'OCPI_SERVICE',
          'SMS_SERVICE',
          'PUSH_NOTIFICATION_SERVICE',
          'SYSTEM_INFROMATION_SERVICE',
          'IDENTITY_SERVICE',
          'ANALYTICS_SERVICE',
        ])
      )
      .nullish(),
  });

export const postApiChargePointEventsUNLOCKCONNECTORCONFIRMATIONHeader =
  zod.object({
    IdempotencyKey: zod.string().uuid().optional(),
  });

export const postApiChargePointEventsUNLOCKCONNECTORCONFIRMATIONBody =
  zod.object({
    chargePointProtocol: zod.enum(['OCPP16', 'OCPP16J', 'OCPP20']),
    chargePointId: zod.string().uuid(),
    data: zod.string().min(1),
  });

export const postApiChargePointEventsUNLOCKCONNECTORCONFIRMATIONResponse =
  zod.object({
    status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
    statusMessage: zod.string().nullish(),
    unavailables: zod
      .array(
        zod.enum([
          'CHARGE_POINT_SERVICE',
          'CP_WS_CONTROLLER',
          'CP_SOAP_CONTROLLER',
          'BILLING_SERVICE',
          'PAYMENT_SERVICE',
          'FISCAL_SERVICE',
          'SBER_PAY',
          'ARMENIA_PAY',
          'E_PAY',
          'FISCAL_RUSSIA',
          'FISCAL_ARMENIA',
          'BONUS_SERVICE',
          'DISCOUNT_SERVICE',
          'MOBILE_API_BFF',
          'USER_SERVICE',
          'RESERVE_SERVICE',
          'OCPI_SERVICE',
          'SMS_SERVICE',
          'PUSH_NOTIFICATION_SERVICE',
          'SYSTEM_INFROMATION_SERVICE',
          'IDENTITY_SERVICE',
          'ANALYTICS_SERVICE',
        ])
      )
      .nullish(),
  });

export const postApiChargePointEventsCHANGEAVAILABILITYREQUESTHeader =
  zod.object({
    IdempotencyKey: zod.string().uuid().optional(),
  });

export const postApiChargePointEventsCHANGEAVAILABILITYREQUESTBody = zod.object(
  {
    chargePointProtocol: zod.enum(['OCPP16', 'OCPP16J', 'OCPP20']),
    chargePointId: zod.string().uuid(),
    data: zod.string().min(1),
  }
);

export const postApiChargePointEventsCHANGEAVAILABILITYREQUESTResponse =
  zod.object({
    status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
    statusMessage: zod.string().nullish(),
    unavailables: zod
      .array(
        zod.enum([
          'CHARGE_POINT_SERVICE',
          'CP_WS_CONTROLLER',
          'CP_SOAP_CONTROLLER',
          'BILLING_SERVICE',
          'PAYMENT_SERVICE',
          'FISCAL_SERVICE',
          'SBER_PAY',
          'ARMENIA_PAY',
          'E_PAY',
          'FISCAL_RUSSIA',
          'FISCAL_ARMENIA',
          'BONUS_SERVICE',
          'DISCOUNT_SERVICE',
          'MOBILE_API_BFF',
          'USER_SERVICE',
          'RESERVE_SERVICE',
          'OCPI_SERVICE',
          'SMS_SERVICE',
          'PUSH_NOTIFICATION_SERVICE',
          'SYSTEM_INFROMATION_SERVICE',
          'IDENTITY_SERVICE',
          'ANALYTICS_SERVICE',
        ])
      )
      .nullish(),
  });

export const postApiChargePointEventsCHANGEAVAILABILITYCONFIRMATIONHeader =
  zod.object({
    IdempotencyKey: zod.string().uuid().optional(),
  });

export const postApiChargePointEventsCHANGEAVAILABILITYCONFIRMATIONBody =
  zod.object({
    chargePointProtocol: zod.enum(['OCPP16', 'OCPP16J', 'OCPP20']),
    chargePointId: zod.string().uuid(),
    data: zod.string().min(1),
  });

export const postApiChargePointEventsCHANGEAVAILABILITYCONFIRMATIONResponse =
  zod.object({
    status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
    statusMessage: zod.string().nullish(),
    unavailables: zod
      .array(
        zod.enum([
          'CHARGE_POINT_SERVICE',
          'CP_WS_CONTROLLER',
          'CP_SOAP_CONTROLLER',
          'BILLING_SERVICE',
          'PAYMENT_SERVICE',
          'FISCAL_SERVICE',
          'SBER_PAY',
          'ARMENIA_PAY',
          'E_PAY',
          'FISCAL_RUSSIA',
          'FISCAL_ARMENIA',
          'BONUS_SERVICE',
          'DISCOUNT_SERVICE',
          'MOBILE_API_BFF',
          'USER_SERVICE',
          'RESERVE_SERVICE',
          'OCPI_SERVICE',
          'SMS_SERVICE',
          'PUSH_NOTIFICATION_SERVICE',
          'SYSTEM_INFROMATION_SERVICE',
          'IDENTITY_SERVICE',
          'ANALYTICS_SERVICE',
        ])
      )
      .nullish(),
  });

export const postApiChargePointEventsDATATRANSFERREQUESTHeader = zod.object({
  IdempotencyKey: zod.string().uuid().optional(),
});

export const postApiChargePointEventsDATATRANSFERREQUESTBody = zod.object({
  chargePointProtocol: zod.enum(['OCPP16', 'OCPP16J', 'OCPP20']),
  chargePointId: zod.string().uuid(),
  data: zod.string().min(1),
});

export const postApiChargePointEventsDATATRANSFERREQUESTResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

export const postApiChargePointEventsDATATRANSFERCONFIRMATIONHeader =
  zod.object({
    IdempotencyKey: zod.string().uuid().optional(),
  });

export const postApiChargePointEventsDATATRANSFERCONFIRMATIONBody = zod.object({
  chargePointProtocol: zod.enum(['OCPP16', 'OCPP16J', 'OCPP20']),
  chargePointId: zod.string().uuid(),
  data: zod.string().min(1),
});

export const postApiChargePointEventsDATATRANSFERCONFIRMATIONResponse =
  zod.object({
    status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
    statusMessage: zod.string().nullish(),
    unavailables: zod
      .array(
        zod.enum([
          'CHARGE_POINT_SERVICE',
          'CP_WS_CONTROLLER',
          'CP_SOAP_CONTROLLER',
          'BILLING_SERVICE',
          'PAYMENT_SERVICE',
          'FISCAL_SERVICE',
          'SBER_PAY',
          'ARMENIA_PAY',
          'E_PAY',
          'FISCAL_RUSSIA',
          'FISCAL_ARMENIA',
          'BONUS_SERVICE',
          'DISCOUNT_SERVICE',
          'MOBILE_API_BFF',
          'USER_SERVICE',
          'RESERVE_SERVICE',
          'OCPI_SERVICE',
          'SMS_SERVICE',
          'PUSH_NOTIFICATION_SERVICE',
          'SYSTEM_INFROMATION_SERVICE',
          'IDENTITY_SERVICE',
          'ANALYTICS_SERVICE',
        ])
      )
      .nullish(),
  });

export const postApiChargePointEventsRESETREQUESTHeader = zod.object({
  IdempotencyKey: zod.string().uuid().optional(),
});

export const postApiChargePointEventsRESETREQUESTBody = zod.object({
  chargePointProtocol: zod.enum(['OCPP16', 'OCPP16J', 'OCPP20']),
  chargePointId: zod.string().uuid(),
  data: zod.string().min(1),
});

export const postApiChargePointEventsRESETREQUESTResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

export const postApiChargePointEventsRESETCONFIRMATIONHeader = zod.object({
  IdempotencyKey: zod.string().uuid().optional(),
});

export const postApiChargePointEventsRESETCONFIRMATIONBody = zod.object({
  chargePointProtocol: zod.enum(['OCPP16', 'OCPP16J', 'OCPP20']),
  chargePointId: zod.string().uuid(),
  data: zod.string().min(1),
});

export const postApiChargePointEventsRESETCONFIRMATIONResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Получить прошивку
 */
export const getApiChargePointFirmwaresIdParams = zod.object({
  id: zod.string().uuid(),
});

export const getApiChargePointFirmwaresIdResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
  data: zod
    .object({
      id: zod.string().uuid(),
      name: zod.string().min(1),
      description: zod.string().nullish(),
      url: zod.string().min(1),
      objectStorageName: zod.string().min(1),
      formatUrl: zod.enum(['SOAP', 'JSON']),
      lastUpdate: zod.string().datetime(),
    })
    .optional(),
});

/**
 * @summary Обновить сущность прошивки
 */
export const patchApiChargePointFirmwaresIdParams = zod.object({
  id: zod.string().uuid(),
});

export const patchApiChargePointFirmwaresIdResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
  data: zod
    .object({
      id: zod.string().uuid(),
      name: zod.string().min(1),
      description: zod.string().nullish(),
      url: zod.string().min(1),
      objectStorageName: zod.string().min(1),
      formatUrl: zod.enum(['SOAP', 'JSON']),
      lastUpdate: zod.string().datetime(),
    })
    .optional(),
});

/**
 * @summary Удалить файл
 */
export const deleteApiChargePointFirmwaresIdParams = zod.object({
  id: zod.string().uuid(),
});

export const deleteApiChargePointFirmwaresIdResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Получить все прошивки
 */
export const getApiChargePointFirmwaresResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
  data: zod
    .array(
      zod.object({
        id: zod.string().uuid(),
        name: zod.string().min(1),
        description: zod.string().nullish(),
        url: zod.string().min(1),
        objectStorageName: zod.string().min(1),
        formatUrl: zod.enum(['SOAP', 'JSON']),
        lastUpdate: zod.string().datetime(),
      })
    )
    .nullish(),
});

/**
 * @summary Добавить прошивку
 */
export const postApiChargePointFirmwaresResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
  data: zod
    .object({
      id: zod.string().uuid(),
      name: zod.string().min(1),
      description: zod.string().nullish(),
      url: zod.string().min(1),
      objectStorageName: zod.string().min(1),
      formatUrl: zod.enum(['SOAP', 'JSON']),
      lastUpdate: zod.string().datetime(),
    })
    .optional(),
});

/**
 * @summary Получить количество прошивок
 */
export const getApiChargePointFirmwaresCountResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
  data: zod.number().optional(),
});

/**
 * @summary Получить конкретную сущность
 */
export const getApiChargePointGroupsIdParams = zod.object({
  id: zod.string().uuid(),
});

export const getApiChargePointGroupsIdResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
  data: zod
    .object({
      id: zod.string().uuid(),
      name: zod.string().min(1),
      comment: zod.string().nullish(),
      children: zod.array(zod.any()).nullish(),
      parentId: zod.string().uuid().nullish(),
      isDefault: zod.boolean(),
      mobileAppIds: zod.array(zod.string().uuid()).nullish(),
      createdDate: zod.string().datetime(),
      lastUpdate: zod.string().datetime(),
    })
    .optional(),
});

/**
 * @summary Обновить сущность
 */
export const patchApiChargePointGroupsIdParams = zod.object({
  id: zod.string().uuid(),
});

export const patchApiChargePointGroupsIdBody = zod.object({
  name: zod.string().nullish(),
  comment: zod.string().nullish(),
  parentId: zod.string().uuid().nullish(),
  isDefault: zod.boolean().nullish(),
  mobileAppIds: zod.array(zod.string().uuid()).nullish(),
});

export const patchApiChargePointGroupsIdResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
  data: zod
    .object({
      id: zod.string().uuid(),
      name: zod.string().min(1),
      comment: zod.string().nullish(),
      children: zod.array(zod.any()).nullish(),
      parentId: zod.string().uuid().nullish(),
      isDefault: zod.boolean(),
      mobileAppIds: zod.array(zod.string().uuid()).nullish(),
      createdDate: zod.string().datetime(),
      lastUpdate: zod.string().datetime(),
    })
    .optional(),
});

/**
 * @summary Удалить сущность
 */
export const deleteApiChargePointGroupsIdParams = zod.object({
  id: zod.string().uuid(),
});

export const deleteApiChargePointGroupsIdResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Получить список сущностей
 */
export const getApiChargePointGroupsQueryParams = zod.object({
  OrderingField: zod.string().optional(),
  OrderingType: zod.enum(['Asc', 'Desc']).optional(),
  Offset: zod.number().optional(),
  Limit: zod.number().optional(),
});

export const getApiChargePointGroupsResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
  data: zod
    .array(
      zod.object({
        id: zod.string().uuid(),
        name: zod.string().min(1),
        comment: zod.string().nullish(),
        children: zod.array(zod.any()).nullish(),
        parentId: zod.string().uuid().nullish(),
        isDefault: zod.boolean(),
        mobileAppIds: zod.array(zod.string().uuid()).nullish(),
        createdDate: zod.string().datetime(),
        lastUpdate: zod.string().datetime(),
      })
    )
    .nullish(),
});

/**
 * @summary Добавить группу
 */
export const postApiChargePointGroupsBody = zod.object({
  name: zod.string().min(1),
  comment: zod.string().nullish(),
  parentId: zod.string().uuid().nullish(),
  isDefault: zod.boolean(),
  mobileAppIds: zod.array(zod.string().uuid()).nullish(),
});

export const postApiChargePointGroupsResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
  data: zod
    .object({
      id: zod.string().uuid(),
      name: zod.string().min(1),
      comment: zod.string().nullish(),
      children: zod.array(zod.any()).nullish(),
      parentId: zod.string().uuid().nullish(),
      isDefault: zod.boolean(),
      mobileAppIds: zod.array(zod.string().uuid()).nullish(),
      createdDate: zod.string().datetime(),
      lastUpdate: zod.string().datetime(),
    })
    .optional(),
});

/**
 * @summary Получить количество сущностей
 */
export const getApiChargePointGroupsCountResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
  data: zod.number().optional(),
});

/**
 * @summary Получить сущность
 */
export const getApiChargePointHubsIdParams = zod.object({
  id: zod.string().uuid(),
});

export const getApiChargePointHubsIdResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
  data: zod
    .object({
      id: zod.string().uuid(),
      name: zod.string().min(1),
      description: zod.string().nullish(),
      address: zod.string().min(1),
      latitude: zod.number(),
      longitude: zod.number(),
    })
    .optional(),
});

/**
 * @summary Обновить сущность
 */
export const patchApiChargePointHubsIdParams = zod.object({
  id: zod.string().uuid(),
});

export const patchApiChargePointHubsIdBody = zod.object({
  name: zod.string().nullish(),
  description: zod.string().nullish(),
  address: zod.string().nullish(),
  latitude: zod.number().nullish(),
  longitude: zod.number().nullish(),
});

export const patchApiChargePointHubsIdResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
  data: zod
    .object({
      id: zod.string().uuid(),
      name: zod.string().min(1),
      description: zod.string().nullish(),
      address: zod.string().min(1),
      latitude: zod.number(),
      longitude: zod.number(),
    })
    .optional(),
});

/**
 * @summary Удалить сущности
 */
export const deleteApiChargePointHubsIdParams = zod.object({
  id: zod.string().uuid(),
});

export const deleteApiChargePointHubsIdResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Получить список сущностей
 */
export const getApiChargePointHubsQueryParams = zod.object({
  Limit: zod.number().optional(),
  Offset: zod.number().optional(),
});

export const getApiChargePointHubsResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
  data: zod
    .array(
      zod.object({
        id: zod.string().uuid(),
        name: zod.string().min(1),
        description: zod.string().nullish(),
        address: zod.string().min(1),
        latitude: zod.number(),
        longitude: zod.number(),
      })
    )
    .nullish(),
});

/**
 * @summary Добавить сущность
 */
export const postApiChargePointHubsBody = zod.object({
  name: zod.string().min(1),
  description: zod.string().nullish(),
  address: zod.string().min(1),
  latitude: zod.number(),
  longitude: zod.number(),
});

export const postApiChargePointHubsResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
  data: zod
    .object({
      id: zod.string().uuid(),
      name: zod.string().min(1),
      description: zod.string().nullish(),
      address: zod.string().min(1),
      latitude: zod.number(),
      longitude: zod.number(),
    })
    .optional(),
});

/**
 * @summary Количество сущностей
 */
export const getApiChargePointHubsCountResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
  data: zod.number().optional(),
});

export const getApiChargePointMetricsCHARGEPOINTSQueryParams = zod.object({
  companyIds: zod.array(zod.string().uuid()).optional(),
  isTest: zod.boolean().optional(),
  chargePointStatuses: zod
    .array(zod.enum(['NO_CONNECT', 'AVAILABLE', 'FAULTED', 'UNAVAILABLE']))
    .optional(),
});

export const getApiChargePointMetricsCHARGEPOINTSResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
  data: zod
    .object({
      count: zod.number().optional(),
      of: zod.number().optional(),
    })
    .optional(),
});

export const getApiChargePointMetricsCONNECTORSQueryParams = zod.object({
  companyIds: zod.array(zod.string().uuid()).optional(),
  isTest: zod.boolean().optional(),
  chargePointStatuses: zod
    .array(zod.enum(['NO_CONNECT', 'AVAILABLE', 'FAULTED', 'UNAVAILABLE']))
    .optional(),
  connectorStatuses: zod
    .array(
      zod.enum([
        'UNDEFINED',
        'AVAILABLE',
        'PREPARING',
        'CHARGING',
        'SUSPENDED_EVSE',
        'SUSPENDED_EV',
        'FINISHING',
        'RESERVED',
        'UNAVAILABLE',
        'FAULTED',
      ])
    )
    .optional(),
});

export const getApiChargePointMetricsCONNECTORSResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
  data: zod
    .object({
      count: zod.number().optional(),
      of: zod.number().optional(),
    })
    .optional(),
});

export const getApiChargePointMetricsSESSIONCHARGINGQueryParams = zod.object({
  companyIds: zod.array(zod.string().uuid()).optional(),
  notСompanyIds: zod.array(zod.string().uuid()).optional(),
  isTest: zod.boolean().optional(),
});

export const getApiChargePointMetricsSESSIONCHARGINGResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
  data: zod
    .object({
      count: zod.number().optional(),
    })
    .optional(),
});

export const getApiChargePointMetricsSUCCESSFULSESSIONSQueryParams = zod.object(
  {
    companyIds: zod.array(zod.string().uuid()).optional(),
    notСompanyIds: zod.array(zod.string().uuid()).optional(),
    isTest: zod.boolean().optional(),
    dateFrom: zod.string().datetime().optional(),
    dateTo: zod.string().datetime().optional(),
    groupByPeriod: zod.enum(['DAY', 'MONTH']).optional(),
  }
);

export const getApiChargePointMetricsSUCCESSFULSESSIONSResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
  data: zod
    .array(
      zod.object({
        date: zod.string().datetime().nullish(),
        value: zod.number().optional(),
      })
    )
    .nullish(),
});

export const getApiChargePointMetricsGraphAMOUNTOFCONSUMEDENERGYQueryParams =
  zod.object({
    companyIds: zod.array(zod.string().uuid()).optional(),
    notСompanyIds: zod.array(zod.string().uuid()).optional(),
    isTest: zod.boolean().optional(),
    dateFrom: zod.string().datetime().optional(),
    dateTo: zod.string().datetime().optional(),
    groupByPeriod: zod.enum(['DAY', 'MONTH']).optional(),
  });

export const getApiChargePointMetricsGraphAMOUNTOFCONSUMEDENERGYResponse =
  zod.object({
    status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
    statusMessage: zod.string().nullish(),
    unavailables: zod
      .array(
        zod.enum([
          'CHARGE_POINT_SERVICE',
          'CP_WS_CONTROLLER',
          'CP_SOAP_CONTROLLER',
          'BILLING_SERVICE',
          'PAYMENT_SERVICE',
          'FISCAL_SERVICE',
          'SBER_PAY',
          'ARMENIA_PAY',
          'E_PAY',
          'FISCAL_RUSSIA',
          'FISCAL_ARMENIA',
          'BONUS_SERVICE',
          'DISCOUNT_SERVICE',
          'MOBILE_API_BFF',
          'USER_SERVICE',
          'RESERVE_SERVICE',
          'OCPI_SERVICE',
          'SMS_SERVICE',
          'PUSH_NOTIFICATION_SERVICE',
          'SYSTEM_INFROMATION_SERVICE',
          'IDENTITY_SERVICE',
          'ANALYTICS_SERVICE',
        ])
      )
      .nullish(),
    data: zod
      .array(
        zod.object({
          date: zod.string().datetime().nullish(),
          value: zod.number().optional(),
        })
      )
      .nullish(),
  });

/**
 * @summary Получить сущность
 */
export const getApiChargePointPhotosIdParams = zod.object({
  id: zod.string().uuid(),
});

export const getApiChargePointPhotosIdResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
  data: zod
    .object({
      id: zod.string().uuid(),
      url: zod.string().min(1),
      objectStorageName: zod.string().min(1),
      chargePointId: zod.string().uuid(),
      priority: zod.number(),
    })
    .optional(),
});

/**
 * @summary Обновить сущность
 */
export const patchApiChargePointPhotosIdParams = zod.object({
  id: zod.string().uuid(),
});

export const patchApiChargePointPhotosIdResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
  data: zod
    .object({
      id: zod.string().uuid(),
      url: zod.string().min(1),
      objectStorageName: zod.string().min(1),
      chargePointId: zod.string().uuid(),
      priority: zod.number(),
    })
    .optional(),
});

/**
 * @summary Удалить файл
 */
export const deleteApiChargePointPhotosIdParams = zod.object({
  id: zod.string().uuid(),
});

export const deleteApiChargePointPhotosIdResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Получить сущности
 */
export const getApiChargePointPhotosQueryParams = zod.object({
  ChargePointId: zod.string().uuid().optional(),
});

export const getApiChargePointPhotosResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
  data: zod
    .array(
      zod.object({
        id: zod.string().uuid(),
        url: zod.string().min(1),
        objectStorageName: zod.string().min(1),
        chargePointId: zod.string().uuid(),
        priority: zod.number(),
      })
    )
    .nullish(),
});

/**
 * @summary Добавить фото
 */
export const postApiChargePointPhotosQueryParams = zod.object({
  ChargePointId: zod.string().uuid(),
  Priority: zod.number(),
});

export const postApiChargePointPhotosResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
  data: zod
    .object({
      id: zod.string().uuid(),
      url: zod.string().min(1),
      objectStorageName: zod.string().min(1),
      chargePointId: zod.string().uuid(),
      priority: zod.number(),
    })
    .optional(),
});

/**
 * @summary Получить количество сущностей
 */
export const getApiChargePointPhotosCountResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
  data: zod.number().optional(),
});

/**
 * @summary Получить сущность
 */
export const getApiChargePointRoleCpRightsIdParams = zod.object({
  id: zod.string().uuid(),
});

export const getApiChargePointRoleCpRightsIdResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
  data: zod
    .object({
      id: zod.string().uuid(),
      roleId: zod.string().uuid(),
      roleName: zod.string().min(1),
      chargePointId: zod.string().uuid(),
      read: zod.boolean(),
      write: zod.boolean(),
      execute: zod.boolean(),
    })
    .optional(),
});

/**
 * @summary Обновить сущность
 */
export const patchApiChargePointRoleCpRightsIdParams = zod.object({
  id: zod.string().uuid(),
});

export const patchApiChargePointRoleCpRightsIdBody = zod.object({
  roleId: zod.string().uuid(),
  roleName: zod.string().min(1),
  chargePointId: zod.string().uuid(),
  read: zod.boolean(),
  write: zod.boolean(),
  execute: zod.boolean(),
});

export const patchApiChargePointRoleCpRightsIdResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
  data: zod
    .object({
      id: zod.string().uuid(),
      roleId: zod.string().uuid(),
      roleName: zod.string().min(1),
      chargePointId: zod.string().uuid(),
      read: zod.boolean(),
      write: zod.boolean(),
      execute: zod.boolean(),
    })
    .optional(),
});

/**
 * @summary Удалить сущность
 */
export const deleteApiChargePointRoleCpRightsIdParams = zod.object({
  id: zod.string().uuid(),
});

export const deleteApiChargePointRoleCpRightsIdResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Получить сущности
 */
export const getApiChargePointRoleCpRightsQueryParams = zod.object({
  roleName: zod.string().optional(),
});

export const getApiChargePointRoleCpRightsResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
  data: zod
    .array(
      zod.object({
        id: zod.string().uuid(),
        roleId: zod.string().uuid(),
        roleName: zod.string().min(1),
        chargePointId: zod.string().uuid(),
        read: zod.boolean(),
        write: zod.boolean(),
        execute: zod.boolean(),
      })
    )
    .nullish(),
});

/**
 * @summary Добавить сущность
 */
export const postApiChargePointRoleCpRightsBody = zod.object({
  roleId: zod.string().uuid(),
  roleName: zod.string().min(1),
  chargePointId: zod.string().uuid(),
  read: zod.boolean(),
  write: zod.boolean(),
  execute: zod.boolean(),
});

export const postApiChargePointRoleCpRightsResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
  data: zod
    .object({
      id: zod.string().uuid(),
      roleId: zod.string().uuid(),
      roleName: zod.string().min(1),
      chargePointId: zod.string().uuid(),
      read: zod.boolean(),
      write: zod.boolean(),
      execute: zod.boolean(),
    })
    .optional(),
});

/**
 * @summary Добавить права
 */
export const postApiChargePointRoleCpRightsSetRoleCpRightsBody = zod.object({
  roleId: zod.string().uuid(),
  allCpRight: zod.boolean().nullish(),
  cpRights: zod
    .array(
      zod.object({
        chargePointId: zod.string().uuid(),
        read: zod.boolean().nullish(),
        write: zod.boolean().nullish(),
        execute: zod.boolean().nullish(),
      })
    )
    .nullish(),
});

export const postApiChargePointRoleCpRightsSetRoleCpRightsResponse = zod.object(
  {
    status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
    statusMessage: zod.string().nullish(),
    unavailables: zod
      .array(
        zod.enum([
          'CHARGE_POINT_SERVICE',
          'CP_WS_CONTROLLER',
          'CP_SOAP_CONTROLLER',
          'BILLING_SERVICE',
          'PAYMENT_SERVICE',
          'FISCAL_SERVICE',
          'SBER_PAY',
          'ARMENIA_PAY',
          'E_PAY',
          'FISCAL_RUSSIA',
          'FISCAL_ARMENIA',
          'BONUS_SERVICE',
          'DISCOUNT_SERVICE',
          'MOBILE_API_BFF',
          'USER_SERVICE',
          'RESERVE_SERVICE',
          'OCPI_SERVICE',
          'SMS_SERVICE',
          'PUSH_NOTIFICATION_SERVICE',
          'SYSTEM_INFROMATION_SERVICE',
          'IDENTITY_SERVICE',
          'ANALYTICS_SERVICE',
        ])
      )
      .nullish(),
  }
);

/**
 * @summary Получить сущность
 */
export const getApiChargePointRoleGroupCpRightsIdParams = zod.object({
  id: zod.string().uuid(),
});

export const getApiChargePointRoleGroupCpRightsIdResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
  data: zod
    .object({
      id: zod.string().uuid(),
      userId: zod.string().uuid(),
      userName: zod.string().min(1),
      groupId: zod.string().uuid(),
      read: zod.boolean(),
      write: zod.boolean(),
      execute: zod.boolean(),
    })
    .optional(),
});

/**
 * @summary Обновить сущность
 */
export const patchApiChargePointRoleGroupCpRightsIdParams = zod.object({
  id: zod.string().uuid(),
});

export const patchApiChargePointRoleGroupCpRightsIdBody = zod.object({
  userId: zod.string().uuid(),
  userName: zod.string().min(1),
  groupId: zod.string().uuid(),
  read: zod.boolean(),
  write: zod.boolean(),
  execute: zod.boolean(),
});

export const patchApiChargePointRoleGroupCpRightsIdResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
  data: zod
    .object({
      id: zod.string().uuid(),
      userId: zod.string().uuid(),
      userName: zod.string().min(1),
      groupId: zod.string().uuid(),
      read: zod.boolean(),
      write: zod.boolean(),
      execute: zod.boolean(),
    })
    .optional(),
});

/**
 * @summary Удалить сущность
 */
export const deleteApiChargePointRoleGroupCpRightsIdParams = zod.object({
  id: zod.string().uuid(),
});

export const deleteApiChargePointRoleGroupCpRightsIdResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Получить список сущностей
 */
export const getApiChargePointRoleGroupCpRightsQueryParams = zod.object({
  groupId: zod.string().uuid().optional(),
});

export const getApiChargePointRoleGroupCpRightsResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
  data: zod
    .array(
      zod.object({
        id: zod.string().uuid(),
        userId: zod.string().uuid(),
        userName: zod.string().min(1),
        groupId: zod.string().uuid(),
        read: zod.boolean(),
        write: zod.boolean(),
        execute: zod.boolean(),
      })
    )
    .nullish(),
});

/**
 * @summary Добавить сущность
 */
export const postApiChargePointRoleGroupCpRightsBody = zod.object({
  userId: zod.string().uuid(),
  userName: zod.string().min(1),
  groupId: zod.string().uuid(),
  read: zod.boolean(),
  write: zod.boolean(),
  execute: zod.boolean(),
});

export const postApiChargePointRoleGroupCpRightsResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
  data: zod
    .object({
      id: zod.string().uuid(),
      userId: zod.string().uuid(),
      userName: zod.string().min(1),
      groupId: zod.string().uuid(),
      read: zod.boolean(),
      write: zod.boolean(),
      execute: zod.boolean(),
    })
    .optional(),
});

/**
 * @summary Добавить права
 */
export const postApiChargePointRoleGroupCpRightsSetRoleGroupCpRightsBody =
  zod.object({
    roleId: zod.string().uuid(),
    allCpRight: zod.boolean().nullish(),
    groupCpRights: zod
      .array(
        zod.object({
          groupId: zod.string().uuid(),
          read: zod.boolean().nullish(),
          write: zod.boolean().nullish(),
          execute: zod.boolean().nullish(),
        })
      )
      .nullish(),
  });

export const postApiChargePointRoleGroupCpRightsSetRoleGroupCpRightsResponse =
  zod.object({
    status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
    statusMessage: zod.string().nullish(),
    unavailables: zod
      .array(
        zod.enum([
          'CHARGE_POINT_SERVICE',
          'CP_WS_CONTROLLER',
          'CP_SOAP_CONTROLLER',
          'BILLING_SERVICE',
          'PAYMENT_SERVICE',
          'FISCAL_SERVICE',
          'SBER_PAY',
          'ARMENIA_PAY',
          'E_PAY',
          'FISCAL_RUSSIA',
          'FISCAL_ARMENIA',
          'BONUS_SERVICE',
          'DISCOUNT_SERVICE',
          'MOBILE_API_BFF',
          'USER_SERVICE',
          'RESERVE_SERVICE',
          'OCPI_SERVICE',
          'SMS_SERVICE',
          'PUSH_NOTIFICATION_SERVICE',
          'SYSTEM_INFROMATION_SERVICE',
          'IDENTITY_SERVICE',
          'ANALYTICS_SERVICE',
        ])
      )
      .nullish(),
  });

/**
 * @summary Получить сессию
 */
export const getApiChargePointSessionsIdParams = zod.object({
  id: zod.string().uuid(),
});

export const getApiChargePointSessionsIdResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
  data: zod
    .object({
      id: zod.string().uuid(),
      chargePoint: zod.object({
        chargePointId: zod.string().uuid(),
        name: zod.string().min(1),
        groupId: zod.string().uuid().nullish(),
        companyId: zod.string().uuid().nullish(),
      }),
      chargePointId: zod.string().uuid(),
      connectorId: zod.string().uuid(),
      userId: zod.string().uuid(),
      status: zod.enum([
        'NEW',
        'PREPARING',
        'CHARGING',
        'COMPLETED_WITH_SUCCESS',
        'COMPLETED_WITH_ERROR',
      ]),
      meterStart: zod.number(),
      meterStop: zod.number().nullish(),
      energy: zod.number(),
      instantPower: zod.number(),
      instantCurrent: zod.number(),
      startChargingPercentage: zod.number().nullish(),
      chargingPercentage: zod.number().nullish(),
      maxPower: zod.number(),
      maxCurrent: zod.number(),
      stopReason: zod.string().nullish(),
      transactionId: zod.string().min(1),
      createdDate: zod.string().datetime(),
      closedDate: zod.string().datetime(),
      lastUpdate: zod.string().datetime(),
    })
    .optional(),
});

/**
 * @summary Получить список сессий
 */
export const getApiChargePointSessionsQueryParams = zod.object({
  ChargePointId: zod.string().uuid().optional(),
  ConnectorId: zod.string().uuid().optional(),
  UserId: zod.string().uuid().optional(),
  Status: zod
    .enum([
      'NEW',
      'PREPARING',
      'CHARGING',
      'COMPLETED_WITH_SUCCESS',
      'COMPLETED_WITH_ERROR',
    ])
    .optional(),
  TransactionId: zod.string().optional(),
  DateCreateFrom: zod.string().datetime().optional(),
  DateCreateTo: zod.string().datetime().optional(),
  EndDateFrom: zod.string().datetime().optional(),
  EndDateTo: zod.string().datetime().optional(),
  DateFrom: zod.string().datetime().optional(),
  DateTo: zod.string().datetime().optional(),
  ChargePointGroupId: zod.string().uuid().optional(),
  OrderingField: zod.string().optional(),
  OrderingType: zod.enum(['Asc', 'Desc']).optional(),
  Offset: zod.number().optional(),
  Limit: zod.number().optional(),
});

export const getApiChargePointSessionsResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
  data: zod
    .array(
      zod.object({
        id: zod.string().uuid(),
        chargePoint: zod.object({
          chargePointId: zod.string().uuid(),
          name: zod.string().min(1),
          groupId: zod.string().uuid().nullish(),
          companyId: zod.string().uuid().nullish(),
        }),
        chargePointId: zod.string().uuid(),
        connectorId: zod.string().uuid(),
        userId: zod.string().uuid(),
        status: zod.enum([
          'NEW',
          'PREPARING',
          'CHARGING',
          'COMPLETED_WITH_SUCCESS',
          'COMPLETED_WITH_ERROR',
        ]),
        meterStart: zod.number(),
        meterStop: zod.number().nullish(),
        energy: zod.number(),
        instantPower: zod.number(),
        instantCurrent: zod.number(),
        startChargingPercentage: zod.number().nullish(),
        chargingPercentage: zod.number().nullish(),
        maxPower: zod.number(),
        maxCurrent: zod.number(),
        stopReason: zod.string().nullish(),
        transactionId: zod.string().min(1),
        createdDate: zod.string().datetime(),
        closedDate: zod.string().datetime(),
        lastUpdate: zod.string().datetime(),
      })
    )
    .nullish(),
});

/**
 * @summary Получить кол-во сессий
 */
export const getApiChargePointSessionsCountQueryParams = zod.object({
  ChargePointId: zod.string().uuid().optional(),
  ConnectorId: zod.string().uuid().optional(),
  UserId: zod.string().uuid().optional(),
  Status: zod
    .enum([
      'NEW',
      'PREPARING',
      'CHARGING',
      'COMPLETED_WITH_SUCCESS',
      'COMPLETED_WITH_ERROR',
    ])
    .optional(),
  TransactionId: zod.string().uuid().optional(),
  DateCreateFrom: zod.string().datetime().optional(),
  DateCreateTo: zod.string().datetime().optional(),
  EndDateFrom: zod.string().datetime().optional(),
  EndDateTo: zod.string().datetime().optional(),
  DateFrom: zod.string().datetime().optional(),
  DateTo: zod.string().datetime().optional(),
});

export const getApiChargePointSessionsCountResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
  data: zod.number().optional(),
});

/**
 * @summary Получить список точек
 */
export const getApiChargePointSessionsIdGraphMeterValuesParams = zod.object({
  id: zod.string().uuid(),
});

export const getApiChargePointSessionsIdGraphMeterValuesQueryParams =
  zod.object({
    DateFrom: zod.string().datetime().optional(),
  });

export const getApiChargePointSessionsIdGraphMeterValuesResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
  data: zod
    .array(
      zod.object({
        date: zod.string().datetime(),
        power: zod.number().nullish(),
        chargingPercentage: zod.number(),
        energy: zod.number().nullish(),
      })
    )
    .nullish(),
});

/**
 * @summary Получить сущности
 */
export const getApiChargePointUserCpRightsIdParams = zod.object({
  id: zod.string().uuid(),
});

export const getApiChargePointUserCpRightsIdResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
  data: zod
    .object({
      id: zod.string().uuid(),
      userId: zod.string().uuid(),
      userName: zod.string().min(1),
      chargePointId: zod.string().uuid(),
      read: zod.boolean(),
      write: zod.boolean(),
      execute: zod.boolean(),
    })
    .optional(),
});

/**
 * @summary Обновить сущность
 */
export const patchApiChargePointUserCpRightsIdParams = zod.object({
  id: zod.string().uuid(),
});

export const patchApiChargePointUserCpRightsIdBody = zod.object({
  userId: zod.string().uuid(),
  userName: zod.string().min(1),
  chargePointId: zod.string().uuid(),
  read: zod.boolean(),
  write: zod.boolean(),
  execute: zod.boolean(),
});

export const patchApiChargePointUserCpRightsIdResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
  data: zod
    .object({
      id: zod.string().uuid(),
      userId: zod.string().uuid(),
      userName: zod.string().min(1),
      chargePointId: zod.string().uuid(),
      read: zod.boolean(),
      write: zod.boolean(),
      execute: zod.boolean(),
    })
    .optional(),
});

/**
 * @summary Удалить сущность
 */
export const deleteApiChargePointUserCpRightsIdParams = zod.object({
  id: zod.string().uuid(),
});

export const deleteApiChargePointUserCpRightsIdResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Получить список сущностей
 */
export const getApiChargePointUserCpRightsQueryParams = zod.object({
  userName: zod.string().optional(),
});

export const getApiChargePointUserCpRightsResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
  data: zod
    .array(
      zod.object({
        id: zod.string().uuid(),
        userId: zod.string().uuid(),
        userName: zod.string().min(1),
        chargePointId: zod.string().uuid(),
        read: zod.boolean(),
        write: zod.boolean(),
        execute: zod.boolean(),
      })
    )
    .nullish(),
});

/**
 * @summary Добавить сущность
 */
export const postApiChargePointUserCpRightsBody = zod.object({
  userId: zod.string().uuid(),
  userName: zod.string().min(1),
  chargePointId: zod.string().uuid(),
  read: zod.boolean(),
  write: zod.boolean(),
  execute: zod.boolean(),
});

export const postApiChargePointUserCpRightsResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
  data: zod
    .object({
      id: zod.string().uuid(),
      userId: zod.string().uuid(),
      userName: zod.string().min(1),
      chargePointId: zod.string().uuid(),
      read: zod.boolean(),
      write: zod.boolean(),
      execute: zod.boolean(),
    })
    .optional(),
});

/**
 * @summary Добавить права
 */
export const postApiChargePointUserCpRightsSetUserCpRightsBody = zod.object({
  userId: zod.string().uuid(),
  allCpRight: zod.boolean().nullish(),
  cpRights: zod
    .array(
      zod.object({
        chargePointId: zod.string().uuid(),
        read: zod.boolean().nullish(),
        write: zod.boolean().nullish(),
        execute: zod.boolean().nullish(),
      })
    )
    .nullish(),
});

export const postApiChargePointUserCpRightsSetUserCpRightsResponse = zod.object(
  {
    status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
    statusMessage: zod.string().nullish(),
    unavailables: zod
      .array(
        zod.enum([
          'CHARGE_POINT_SERVICE',
          'CP_WS_CONTROLLER',
          'CP_SOAP_CONTROLLER',
          'BILLING_SERVICE',
          'PAYMENT_SERVICE',
          'FISCAL_SERVICE',
          'SBER_PAY',
          'ARMENIA_PAY',
          'E_PAY',
          'FISCAL_RUSSIA',
          'FISCAL_ARMENIA',
          'BONUS_SERVICE',
          'DISCOUNT_SERVICE',
          'MOBILE_API_BFF',
          'USER_SERVICE',
          'RESERVE_SERVICE',
          'OCPI_SERVICE',
          'SMS_SERVICE',
          'PUSH_NOTIFICATION_SERVICE',
          'SYSTEM_INFROMATION_SERVICE',
          'IDENTITY_SERVICE',
          'ANALYTICS_SERVICE',
        ])
      )
      .nullish(),
  }
);

/**
 * @summary Получить сущность
 */
export const getApiChargePointUserGroupCpRightsIdParams = zod.object({
  id: zod.string().uuid(),
});

export const getApiChargePointUserGroupCpRightsIdResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
  data: zod
    .object({
      id: zod.string().uuid(),
      userId: zod.string().uuid(),
      userName: zod.string().min(1),
      groupId: zod.string().uuid(),
      read: zod.boolean(),
      write: zod.boolean(),
      execute: zod.boolean(),
    })
    .optional(),
});

/**
 * @summary Обновить сущность
 */
export const patchApiChargePointUserGroupCpRightsIdParams = zod.object({
  id: zod.string().uuid(),
});

export const patchApiChargePointUserGroupCpRightsIdBody = zod.object({
  userId: zod.string().uuid().nullish(),
  userName: zod.string().nullish(),
  groupId: zod.string().uuid().nullish(),
  read: zod.boolean().nullish(),
  write: zod.boolean().nullish(),
  execute: zod.boolean().nullish(),
});

export const patchApiChargePointUserGroupCpRightsIdResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
  data: zod
    .object({
      id: zod.string().uuid(),
      userId: zod.string().uuid(),
      userName: zod.string().min(1),
      groupId: zod.string().uuid(),
      read: zod.boolean(),
      write: zod.boolean(),
      execute: zod.boolean(),
    })
    .optional(),
});

/**
 * @summary Удалить сущность
 */
export const deleteApiChargePointUserGroupCpRightsIdParams = zod.object({
  id: zod.string().uuid(),
});

export const deleteApiChargePointUserGroupCpRightsIdResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Получить список сущностей
 */
export const getApiChargePointUserGroupCpRightsQueryParams = zod.object({
  userId: zod.string().uuid().optional(),
});

export const getApiChargePointUserGroupCpRightsResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
  data: zod
    .array(
      zod.object({
        id: zod.string().uuid(),
        userId: zod.string().uuid(),
        userName: zod.string().min(1),
        groupId: zod.string().uuid(),
        read: zod.boolean(),
        write: zod.boolean(),
        execute: zod.boolean(),
      })
    )
    .nullish(),
});

/**
 * @summary Добавить сущность
 */
export const postApiChargePointUserGroupCpRightsBody = zod.object({
  userId: zod.string().uuid(),
  userName: zod.string().min(1),
  groupId: zod.string().uuid(),
  read: zod.boolean(),
  write: zod.boolean(),
  execute: zod.boolean(),
});

export const postApiChargePointUserGroupCpRightsResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
  data: zod
    .object({
      id: zod.string().uuid(),
      userId: zod.string().uuid(),
      userName: zod.string().min(1),
      groupId: zod.string().uuid(),
      read: zod.boolean(),
      write: zod.boolean(),
      execute: zod.boolean(),
    })
    .optional(),
});

/**
 * @summary Добавить права
 */
export const postApiChargePointUserGroupCpRightsSetUserGroupCpRightsBody =
  zod.object({
    userId: zod.string().uuid(),
    allCpRight: zod.boolean().nullish(),
    groupCpRights: zod
      .array(
        zod.object({
          groupId: zod.string().uuid(),
          read: zod.boolean().nullish(),
          write: zod.boolean().nullish(),
          execute: zod.boolean().nullish(),
        })
      )
      .nullish(),
  });

export const postApiChargePointUserGroupCpRightsSetUserGroupCpRightsResponse =
  zod.object({
    status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
    statusMessage: zod.string().nullish(),
    unavailables: zod
      .array(
        zod.enum([
          'CHARGE_POINT_SERVICE',
          'CP_WS_CONTROLLER',
          'CP_SOAP_CONTROLLER',
          'BILLING_SERVICE',
          'PAYMENT_SERVICE',
          'FISCAL_SERVICE',
          'SBER_PAY',
          'ARMENIA_PAY',
          'E_PAY',
          'FISCAL_RUSSIA',
          'FISCAL_ARMENIA',
          'BONUS_SERVICE',
          'DISCOUNT_SERVICE',
          'MOBILE_API_BFF',
          'USER_SERVICE',
          'RESERVE_SERVICE',
          'OCPI_SERVICE',
          'SMS_SERVICE',
          'PUSH_NOTIFICATION_SERVICE',
          'SYSTEM_INFROMATION_SERVICE',
          'IDENTITY_SERVICE',
          'ANALYTICS_SERVICE',
        ])
      )
      .nullish(),
  });
