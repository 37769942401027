import { z } from 'zod';

import {
  postApiBillingServicesListLinksBody,
  postApiBillingServicesListsBody,
  postApiBillingStagesBody,
  postApiBillingTariffsBody,
} from 'shared/api/services/billing/orval/zod/schemas';
import {
  StageEndType,
  TariffType,
} from 'shared/api/services/billing/rtk/generated';
import { requiredNumberInString } from 'shared/lib/schema';
import { toCurrencySubunit } from 'shared/lib';

const stageEndType: z.ZodType<StageEndType> = z.enum([
  'MANUAL',
  'RIGHT_NOW',
  'ENERGY_PERCENT',
  'TIME_MINUTE',
  'POWER_LOSS',
]);

const billingStage = postApiBillingStagesBody
  .omit({
    endType: true,
    endConditionValue: true,
  })
  .extend({
    endType: stageEndType.nullish().transform((val, ctx) => {
      if (val === undefined || val === null) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Required field',
        });

        return z.NEVER;
      }

      return val;
    }),
    endConditionValue: requiredNumberInString.pipe(z.number().gte(0)),
  });

export const FormSchema = postApiBillingServicesListsBody.extend({
  reservingStages: z.array(billingStage),
  chargingStages: z.array(billingStage),
  parkingStages: z.array(billingStage),
});

export type FormInput = z.input<typeof FormSchema>;
export type FormOutput = z.output<typeof FormSchema>;

const stageInitialValue = {
  name: '',
  serialNumber: 0,
  tariffId: '',
  serviceId: '',
};

export const DEFAULT_VALUES: FormInput = {
  name: '',
  chargingStages: [stageInitialValue],
  parkingStages: [],
  reservingStages: [stageInitialValue],
};
