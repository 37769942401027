import { Controller, FieldValues, Path, useFormContext } from 'react-hook-form';

import { UiSelect } from 'shared/ui/ui-kit';

import {
  FieldErrorContainer,
  FormItemContainer,
  FormItemLabel,
} from '../styles';

type Props<T extends FieldValues> = {
  name: Path<T>;
  label: React.ReactNode;
  required?: boolean;
  disabled?: boolean;
  errorMessage?: string;
} & React.ComponentProps<typeof UiSelect>;

export function CustomSelect<T extends FieldValues>({
  label,
  required,
  disabled,
  errorMessage,
  options,
  name,
  ...rest
}: Props<T>) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => {
        const { error } = fieldState;

        return (
          <FormItemContainer>
            <FormItemLabel required={required}>{label}</FormItemLabel>
            <UiSelect
              {...field}
              options={options}
              disabled={disabled}
              {...rest}
            />
            <FieldErrorContainer>
              {error?.message || errorMessage}
            </FieldErrorContainer>
          </FormItemContainer>
        );
      }}
    />
  );
}
