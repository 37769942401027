import { Link } from 'react-router-dom';
import NiceModal from '@ebay/nice-modal-react';

import {
  useGetBillings,
  useGetServiceLists,
} from 'shared/api/services/billing/rtk/enhanced';
import { USER_ROUTES } from 'shared/consts/routes/user';
import { getServerErrorText } from 'shared/lib';
import { BILLINGS } from 'shared/consts/names';
import { BillingDetailsModal } from 'features/billing/table/ui/details/modal';
import { BILLING_ROUTES } from 'shared/consts/routes/billing';
import { useGetUser } from 'shared/api/services/user/rtk/enhanced';

import {
  ContentHeader,
  LinksContainer,
  StyledContainer,
  StyledLink,
  StyledText,
  InfoContainer,
} from './styles';

type Props = {
  sessionId: string;
  userId: string;
  visible: boolean;
  close: () => void;
};

export function PopoverContent({ sessionId, visible, userId, close }: Props) {
  const billingsQuery = useGetBillings(
    { sessionId },
    {
      skip: !visible,
    }
  );
  const userQuery = useGetUser(userId, {
    skip: !visible,
  });

  const serviceId = billingsQuery.data?.data?.[0].providedServices.find(
    (el) => el.serviceId
  )?.serviceId;

  const serviceListsQuery = useGetServiceLists(
    { serviceId },
    { skip: !visible || !serviceId }
  );

  const isLoading =
    billingsQuery.isLoading ||
    serviceListsQuery.isLoading ||
    userQuery.isLoading;

  const apiResponseError = [
    billingsQuery.data,
    serviceListsQuery.data,
    userQuery.data,
  ].find((el) => el && el.status === 'ERROR');

  const error = [
    billingsQuery.error,
    serviceListsQuery.error,
    userQuery.error,
  ].find((err) => err !== undefined);

  const isSuccess =
    billingsQuery.isSuccess &&
    serviceListsQuery.isSuccess &&
    userQuery.isSuccess;

  let content: React.ReactNode = null;

  if (isLoading) {
    content = <StyledContainer>Loading...</StyledContainer>;
  } else if (error) {
    content = <StyledContainer>{getServerErrorText(error)}</StyledContainer>;
  } else if (apiResponseError) {
    content = (
      <StyledContainer>{apiResponseError.statusMessage}</StyledContainer>
    );
  } else if (isSuccess) {
    const billing = billingsQuery.data.data?.[0];
    const serviceList = serviceListsQuery.data.data?.[0];

    const renderBenefitPayment = () => {
      if (!billing) {
        return <div>Нет данных о стоимости</div>;
      }

      const { benefitPayment } = billing;

      return `Стоимость: ${benefitPayment}`;
    };

    const renderServiceListInfo = () => {
      if (!serviceList) {
        return <div>Нет данных о биллинге</div>;
      }

      const { id, name } = serviceList;

      return (
        <StyledText>{`Тариф: ${
          serviceList && (
            <Link to={`${BILLING_ROUTES.LIST}/${id}`}>
              <StyledLink>{name}</StyledLink>
            </Link>
          )
        }`}</StyledText>
      );
    };

    const renderUserBillingsLink = () => {
      if (!userQuery.data.data) {
        return <div>Нет данных о юзере</div>;
      }

      const { groupId, id } = userQuery.data.data;

      return (
        <Link to={`${USER_ROUTES.PERSONS_LIST}/${groupId}/${id}/${BILLINGS}`}>
          <StyledLink>Открыть журнал оплат пользователя</StyledLink>
        </Link>
      );
    };

    content = (
      <StyledContainer>
        <ContentHeader>
          <StyledText>{renderBenefitPayment()}</StyledText>
        </ContentHeader>
        <InfoContainer>{renderServiceListInfo()}</InfoContainer>
        <LinksContainer>
          <StyledLink
            onClick={() => {
              close();

              NiceModal.show(BillingDetailsModal, { billing });
            }}
          >
            Открыть детализацию
          </StyledLink>
          {renderUserBillingsLink()}
        </LinksContainer>
      </StyledContainer>
    );
  }

  return content;
}
