import { z } from 'zod';
import useWindowFocus from 'use-window-focus';

import {
  useGetEvents,
  useGetEventsCount,
} from 'shared/api/services/chargepoint/rtk';
import { EmptyData, ErrorMessage } from 'shared/ui';
import {
  EventName,
  EventType,
} from 'shared/api/services/chargepoint/rtk/generated/events';
import { useTypedQueryParamsCustom } from 'shared/lib/router/use-typed-query-params';
import { getServerErrorText } from 'shared/lib';
import { POLLING_INTERVAL } from 'shared/consts';

import { TableInstance } from './table';

const name: z.ZodType<EventName> = z.enum([
  'BOOT_NOTIFICATION',
  'REMOTE_START_TRANSACTION',
  'START_TRANSACTION',
  'REMOTE_STOP_TRANSACTION',
  'STOP_TRANSACTION',
  'STATUS_NOTIFICATION',
  'METER_VALUES',
  'RESET',
  'CHANGE_CONFIGURATION',
  'UNLOCK_CONNECTOR',
  'CHANGE_AVAILABILITY',
  'DATA_TRANSFER',
]);

const type: z.ZodType<EventType> = z.enum(['REQUEST', 'CONFIRMATION']);

const pageQueryParamsSchema = z.object({
  name: name.optional().catch(undefined),
  type: type.optional().catch(undefined),
  // chargePointId: z.string().optional(),
  connectorId: z.string().optional(),
  transactionId: z.string().optional(),
  dateFrom: z.string().optional(),
  dateTo: z.string().optional(),
  page: z.string().default('1').pipe(z.coerce.number().min(1)).catch(1),
  limit: z.string().default('20').pipe(z.coerce.number().min(1)).catch(1),
  orderingField: z.string().optional().catch(undefined),
  orderingType: z.enum(['ASC', 'DESC']).optional(), // catch?
});

type Props = {
  chargePointId: string;
};

export function TableContainer({ chargePointId }: Props) {
  const isWindowFocused = useWindowFocus();

  const queryParams = useTypedQueryParamsCustom(pageQueryParamsSchema);

  const {
    isLoading: isEventsLoading,
    isFetching: isEventsFetching,
    error: eventsError,
    data: events,
    currentData: currentEvents,
  } = useGetEvents(
    {
      ...queryParams,
      chargePointId,
      offset: Math.max(queryParams.page - 1, 0) * queryParams.limit,
    },
    {
      pollingInterval: isWindowFocused ? POLLING_INTERVAL : undefined,
    }
  );

  const {
    isLoading: isEventsCountLoading,
    isFetching: isEventsCountFetching,
    error: eventsCountError,
    data: eventsCount,
    currentData: currentEventsCount,
  } = useGetEventsCount(queryParams, {
    pollingInterval: isWindowFocused ? POLLING_INTERVAL : undefined,
  });

  const isLoading = isEventsLoading || isEventsCountLoading;
  const isFetching =
    (isEventsFetching || isEventsCountFetching) &&
    (!currentEvents || !currentEventsCount);

  const apiResponseError = [events, eventsCount].find(
    (el) => el && el.status === 'ERROR'
  );

  const error = [eventsError, eventsCountError].find(
    (err) => err !== undefined
  );

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (apiResponseError) {
    return <ErrorMessage text={apiResponseError.statusMessage} />;
  }

  if (error) {
    return <ErrorMessage text={getServerErrorText(error)} />;
  }

  if (!events?.data || !eventsCount || eventsCount.data === undefined) {
    return <EmptyData />;
  }

  return (
    <TableInstance
      loading={isFetching}
      data={events.data}
      totalCount={eventsCount?.data}
    />
  );
}
