import { Route } from 'react-router-dom';

import { MainContentLayout, SectionLayout } from 'shared/ui/layout';
import { SERVICE_LIST } from 'shared/consts/routes/billing';

import { RequireAuth } from 'entities/authorization';

// import { ConnectedTariffsTable } from '../../../features/tariff/table/ui/connected-table';
import { BillingMenu } from 'features/billing/menu';

import { ServiceListLayout } from 'entities/billing/ui/service-list-layout';

import { AddBillingPage } from 'pages/billing/add';
import { EditBillingPage } from 'pages/billing/edit';
import { ADD_PREFIX, BILLINGS, CHARGE_POINTS } from 'shared/consts/names';
import { AddServicesList } from 'widgets/billing/add-services-list';

export const routes = (
  <Route
    element={<RequireAuth controllerName="ChargeTariff" rightType="Read" />}
  >
    <Route path={BILLINGS} element={<SectionLayout menu={<BillingMenu />} />}>
      {/* <Route element={<TariffsLayout />}>
        <Route index element={<ConnectedTariffsTable />} />
      </Route> */}

      <Route
        element={
          <RequireAuth controllerName="ChargeTariff" rightType="Write" />
        }
      >
        <Route
          path={ADD_PREFIX}
          element={<MainContentLayout element={<AddServicesList />} />}
        />
      </Route>
      <Route path={SERVICE_LIST} element={<ServiceListLayout />}>
        <Route index element={<EditBillingPage />} />
        <Route path={CHARGE_POINTS} element={<>ServiceListChargePoints</>} />
      </Route>
    </Route>
  </Route>
);
