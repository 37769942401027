import dayjs from 'dayjs';

import { GraphMetric } from '../model/metrics';

function padDatesInBetween(startDate: dayjs.Dayjs, endDate: dayjs.Dayjs) {
  let now = startDate.clone();

  const dates: GraphMetric[] = [];

  while (now.isBefore(endDate)) {
    dates.push({ date: now.toISOString(), value: 0 });

    now = now.add(1, 'day');
  }

  return dates;
}

export function padMetricsData(
  startDate: string,
  endDate: string,
  metrics: GraphMetric[]
): GraphMetric[] {
  const startDateObj = dayjs(startDate);
  const endDateObj = dayjs(endDate);

  if (metrics.length === 0 && startDateObj.isSame(endDateObj)) {
    return [{ date: startDateObj.toISOString(), value: 0 }];
    // { name: startDateObj.format(METRIC_RESPONSE_DATE_FORMAT), value: 0 },
  }

  if (metrics.length === 0) {
    return padDatesInBetween(startDateObj, endDateObj);
  }

  if (metrics.length === 1) {
    const singleMetric = metrics[0];

    const momentSingleMetric = dayjs(singleMetric.date);

    const beforeDateChunk = padDatesInBetween(startDateObj, momentSingleMetric);

    const afterEndDateChunk = padDatesInBetween(
      momentSingleMetric.add(1, 'days'),
      endDateObj
    );

    return [...beforeDateChunk, singleMetric, ...afterEndDateChunk];
  }

  let paddedMetrics: GraphMetric[] = [];

  const firstMetricDate = dayjs(metrics[0].date);
  const lastMetricDate = dayjs(metrics[metrics.length - 1].date);

  if (startDateObj.isBefore(firstMetricDate)) {
    const beforeStartDateChunk = padDatesInBetween(
      startDateObj,
      firstMetricDate
    );

    paddedMetrics = [...paddedMetrics, ...beforeStartDateChunk];
  }

  const metricsInBetween = metrics.reduce((acc, el, idx, arr) => {
    const momentCurrentDate = dayjs(el.date);

    if (idx === arr.length - 1) {
      return [...acc, el];
    }

    let padDates: GraphMetric[] = [];

    const momentNextDate = dayjs(arr[idx + 1].date);

    if (momentNextDate && momentNextDate.diff(momentCurrentDate, 'day') > 1) {
      const chunk = padDatesInBetween(
        momentCurrentDate.add(1, 'day'),
        momentNextDate
      );
      padDates = [...padDates, ...chunk];
    }

    return [...acc, el, ...padDates];
  }, [] as GraphMetric[]);

  paddedMetrics = [...paddedMetrics, ...metricsInBetween];

  if (endDateObj.diff(lastMetricDate, 'day') > 0) {
    const afterEndDateChunk = padDatesInBetween(
      lastMetricDate.add(1, 'days'),
      endDateObj
    );

    paddedMetrics = [...paddedMetrics, ...afterEndDateChunk];
  }

  return paddedMetrics;
}
