import { EmptyData, NavMenuError } from 'shared/ui';
import { SectionMenu } from 'shared/ui/section-menu';
import { PUSH_NOTIFICATION_ROUTES } from 'shared/consts/routes/push-notification';
import { useGetTemplates } from 'shared/api/services/pushnotification/rtk/enhanced';
import { PUSH_NOTIFICATIONS } from 'shared/consts/names';

import { AddButton } from './add-button';

export function MenuContainer() {
  const { data, isFetching, error } = useGetTemplates();

  if (data && data.status === 'ERROR') {
    return <NavMenuError text={data.statusMessage} />;
  }

  if (error) {
    return <NavMenuError />;
  }

  if (!isFetching && !data) {
    return <EmptyData />;
  }

  const items = data?.data || [];

  return (
    <SectionMenu
      loading={isFetching}
      items={items}
      menuKey={PUSH_NOTIFICATIONS}
      absolutePath={PUSH_NOTIFICATION_ROUTES.PUSH_NOTIFICATIONS}
      label="Шаблоны уведомлений"
      addButton={<AddButton />}
    />
  );
}
