import { skipToken } from '@reduxjs/toolkit/query';

import { useGetServiceLists } from 'shared/api/services/billing/rtk/enhanced';
import { BillingDto } from 'shared/api/services/billing/rtk/generated';
import { useGetChargePoint } from 'shared/api/services/chargepoint/rtk';
import { useGetSession } from 'shared/api/services/chargepoint/rtk/enhanced/sessions';
import { useGetReserveSession } from 'shared/api/services/reserve/rtk/enhanced';
import { useGetUser } from 'shared/api/services/user/rtk/enhanced';
import { getServerErrorText, openErrorNotification } from 'shared/lib';

import { Card } from './card';

type Props = {
  billing: BillingDto;
  closeModal: () => void;
};

export function CardContainer({ billing, closeModal }: Props) {
  const { userId, chargePointId, providedServices } = billing;

  const userQuery = useGetUser(userId);
  const chargePointQuery = useGetChargePoint(chargePointId);

  const service = providedServices.find((el) => el.sessionId);

  const sessionQuery = useGetSession(
    service?.sessionId && service.serviceType !== 'CHARGING'
      ? service.sessionId
      : skipToken
  );
  const reserveSessionQuery = useGetReserveSession(
    service?.sessionId && service.serviceType !== 'RESERVING'
      ? service.sessionId
      : skipToken
  );
  const serviceListsQuery = useGetServiceLists(
    service?.serviceId ? { serviceId: service.serviceId } : skipToken
  );

  const isLoading =
    userQuery.isFetching ||
    chargePointQuery.isFetching ||
    sessionQuery.isFetching ||
    reserveSessionQuery.isFetching ||
    serviceListsQuery.isFetching;

  const error = [
    userQuery.error,
    chargePointQuery.error,
    sessionQuery.error,
    reserveSessionQuery.error,
    serviceListsQuery.error,
  ].find((err) => err !== undefined);

  const apiResponseError = [
    userQuery.data,
    chargePointQuery.data,
    sessionQuery.data,
    reserveSessionQuery.data,
    serviceListsQuery.data,
  ].find((el) => el && el.status === 'ERROR');

  if (error) {
    openErrorNotification(getServerErrorText(error));
  }

  if (apiResponseError) {
    openErrorNotification(apiResponseError.statusMessage || '');
  }

  return (
    <Card
      billing={billing}
      user={userQuery.data?.data}
      chargePoint={chargePointQuery.data?.data}
      isLoading={isLoading}
      connectorId={
        sessionQuery.data?.data?.connectorId ||
        reserveSessionQuery.data?.data?.reserveId
      }
      serviceList={serviceListsQuery.data?.data?.[0]}
      closeModal={closeModal}
    />
  );
}
