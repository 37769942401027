import { baseApi as api } from '../../../../base-new/base-api';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getApiChargePointEventsById: build.query<
      GetApiChargePointEventsByIdApiResponse,
      GetApiChargePointEventsByIdApiArg
    >({
      query: (queryArg) => ({ url: `/Api/ChargePoint/Events/${queryArg}` }),
    }),
    deleteApiChargePointEventsById: build.mutation<
      DeleteApiChargePointEventsByIdApiResponse,
      DeleteApiChargePointEventsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/Events/${queryArg}`,
        method: 'DELETE',
      }),
    }),
    getApiChargePointEvents: build.query<
      GetApiChargePointEventsApiResponse,
      GetApiChargePointEventsApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/Events`,
        params: {
          Name: queryArg.name,
          Type: queryArg['type'],
          ChargePointId: queryArg.chargePointId,
          ConnectorId: queryArg.connectorId,
          TransactionId: queryArg.transactionId,
          DateFrom: queryArg.dateFrom,
          DateTo: queryArg.dateTo,
          OrderingField: queryArg.orderingField,
          OrderingType: queryArg.orderingType,
          Offset: queryArg.offset,
          Limit: queryArg.limit,
        },
      }),
    }),
    getApiChargePointEventsCount: build.query<
      GetApiChargePointEventsCountApiResponse,
      GetApiChargePointEventsCountApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/Events/Count`,
        params: {
          Name: queryArg.name,
          Type: queryArg['type'],
          ChargePointId: queryArg.chargePointId,
          ConnectorId: queryArg.connectorId,
          TransactionId: queryArg.transactionId,
          DateFrom: queryArg.dateFrom,
          DateTo: queryArg.dateTo,
        },
      }),
    }),
    postApiChargePointEventsDisconnectEvent: build.mutation<
      PostApiChargePointEventsDisconnectEventApiResponse,
      PostApiChargePointEventsDisconnectEventApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/Events/DisconnectEvent`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    postApiChargePointEventsBootNotificationRequest: build.mutation<
      PostApiChargePointEventsBootNotificationRequestApiResponse,
      PostApiChargePointEventsBootNotificationRequestApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/Events/BOOT_NOTIFICATION/REQUEST`,
        method: 'POST',
        body: queryArg.bootNotificationRequest,
        headers: { IdempotencyKey: queryArg.idempotencyKey },
      }),
    }),
    postApiChargePointEventsBootNotificationConfirmation: build.mutation<
      PostApiChargePointEventsBootNotificationConfirmationApiResponse,
      PostApiChargePointEventsBootNotificationConfirmationApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/Events/BOOT_NOTIFICATION/CONFIRMATION`,
        method: 'POST',
        body: queryArg.bootNotificationConfirmationRequest,
        headers: { IdempotencyKey: queryArg.idempotencyKey },
      }),
    }),
    postApiChargePointEventsRemoteStartTransactionRequest: build.mutation<
      PostApiChargePointEventsRemoteStartTransactionRequestApiResponse,
      PostApiChargePointEventsRemoteStartTransactionRequestApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/Events/REMOTE_START_TRANSACTION/REQUEST`,
        method: 'POST',
        body: queryArg.remoteStartTransactionRequest,
        headers: { IdempotencyKey: queryArg.idempotencyKey },
      }),
    }),
    postApiChargePointEventsRemoteStartTransactionConfirmation: build.mutation<
      PostApiChargePointEventsRemoteStartTransactionConfirmationApiResponse,
      PostApiChargePointEventsRemoteStartTransactionConfirmationApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/Events/REMOTE_START_TRANSACTION/CONFIRMATION`,
        method: 'POST',
        body: queryArg.remoteStartTransactionConfirmationRequest,
        headers: { IdempotencyKey: queryArg.idempotencyKey },
      }),
    }),
    postApiChargePointEventsStartTransactionRequest: build.mutation<
      PostApiChargePointEventsStartTransactionRequestApiResponse,
      PostApiChargePointEventsStartTransactionRequestApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/Events/START_TRANSACTION/REQUEST`,
        method: 'POST',
        body: queryArg.startTransactionRequest,
        headers: { IdempotencyKey: queryArg.idempotencyKey },
      }),
    }),
    postApiChargePointEventsStartTransactionConfirmation: build.mutation<
      PostApiChargePointEventsStartTransactionConfirmationApiResponse,
      PostApiChargePointEventsStartTransactionConfirmationApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/Events/START_TRANSACTION/CONFIRMATION`,
        method: 'POST',
        body: queryArg.startTransactionConfirmationRequest,
        headers: { IdempotencyKey: queryArg.idempotencyKey },
      }),
    }),
    postApiChargePointEventsRemoteStopTransactionRequest: build.mutation<
      PostApiChargePointEventsRemoteStopTransactionRequestApiResponse,
      PostApiChargePointEventsRemoteStopTransactionRequestApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/Events/REMOTE_STOP_TRANSACTION/REQUEST`,
        method: 'POST',
        body: queryArg.remoteStopTransactionRequest,
        headers: { IdempotencyKey: queryArg.idempotencyKey },
      }),
    }),
    postApiChargePointEventsRemoteStopTransactionConfirmation: build.mutation<
      PostApiChargePointEventsRemoteStopTransactionConfirmationApiResponse,
      PostApiChargePointEventsRemoteStopTransactionConfirmationApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/Events/REMOTE_STOP_TRANSACTION/CONFIRMATION`,
        method: 'POST',
        body: queryArg.remoteStopTransactionConfirmationRequest,
        headers: { IdempotencyKey: queryArg.idempotencyKey },
      }),
    }),
    postApiChargePointEventsStopTransactionRequest: build.mutation<
      PostApiChargePointEventsStopTransactionRequestApiResponse,
      PostApiChargePointEventsStopTransactionRequestApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/Events/STOP_TRANSACTION/REQUEST`,
        method: 'POST',
        body: queryArg.stopTransactionRequest,
        headers: { IdempotencyKey: queryArg.idempotencyKey },
      }),
    }),
    postApiChargePointEventsStopTransactionConfirmation: build.mutation<
      PostApiChargePointEventsStopTransactionConfirmationApiResponse,
      PostApiChargePointEventsStopTransactionConfirmationApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/Events/STOP_TRANSACTION/CONFIRMATION`,
        method: 'POST',
        body: queryArg.stopTransactionConfirmationRequest,
        headers: { IdempotencyKey: queryArg.idempotencyKey },
      }),
    }),
    postApiChargePointEventsStatusNotificationRequest: build.mutation<
      PostApiChargePointEventsStatusNotificationRequestApiResponse,
      PostApiChargePointEventsStatusNotificationRequestApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/Events/STATUS_NOTIFICATION/REQUEST`,
        method: 'POST',
        body: queryArg.statusNotificationRequest,
        headers: { IdempotencyKey: queryArg.idempotencyKey },
      }),
    }),
    postApiChargePointEventsStatusNotificationConfirmation: build.mutation<
      PostApiChargePointEventsStatusNotificationConfirmationApiResponse,
      PostApiChargePointEventsStatusNotificationConfirmationApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/Events/STATUS_NOTIFICATION/CONFIRMATION`,
        method: 'POST',
        body: queryArg.statusNotificationConfirmationRequest,
        headers: { IdempotencyKey: queryArg.idempotencyKey },
      }),
    }),
    postApiChargePointEventsMeterValuesRequest: build.mutation<
      PostApiChargePointEventsMeterValuesRequestApiResponse,
      PostApiChargePointEventsMeterValuesRequestApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/Events/METER_VALUES/REQUEST`,
        method: 'POST',
        body: queryArg.meterValuesRequest,
        headers: { IdempotencyKey: queryArg.idempotencyKey },
      }),
    }),
    postApiChargePointEventsMeterValuesConfirmation: build.mutation<
      PostApiChargePointEventsMeterValuesConfirmationApiResponse,
      PostApiChargePointEventsMeterValuesConfirmationApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/Events/METER_VALUES/CONFIRMATION`,
        method: 'POST',
        body: queryArg.meterValuesConfirmationRequest,
        headers: { IdempotencyKey: queryArg.idempotencyKey },
      }),
    }),
    postApiChargePointEventsChangeConfigurationRequest: build.mutation<
      PostApiChargePointEventsChangeConfigurationRequestApiResponse,
      PostApiChargePointEventsChangeConfigurationRequestApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/Events/CHANGE_CONFIGURATION/REQUEST`,
        method: 'POST',
        body: queryArg.changeConfigurationRequest,
        headers: { IdempotencyKey: queryArg.idempotencyKey },
      }),
    }),
    postApiChargePointEventsChangeConfigurationConfirmation: build.mutation<
      PostApiChargePointEventsChangeConfigurationConfirmationApiResponse,
      PostApiChargePointEventsChangeConfigurationConfirmationApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/Events/CHANGE_CONFIGURATION/CONFIRMATION`,
        method: 'POST',
        body: queryArg.changeConfigurationConfirmationRequest,
        headers: { IdempotencyKey: queryArg.idempotencyKey },
      }),
    }),
    postApiChargePointEventsUnlockConnectorRequest: build.mutation<
      PostApiChargePointEventsUnlockConnectorRequestApiResponse,
      PostApiChargePointEventsUnlockConnectorRequestApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/Events/UNLOCK_CONNECTOR/REQUEST`,
        method: 'POST',
        body: queryArg.unlockConnectorRequest,
        headers: { IdempotencyKey: queryArg.idempotencyKey },
      }),
    }),
    postApiChargePointEventsUnlockConnectorConfirmation: build.mutation<
      PostApiChargePointEventsUnlockConnectorConfirmationApiResponse,
      PostApiChargePointEventsUnlockConnectorConfirmationApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/Events/UNLOCK_CONNECTOR/CONFIRMATION`,
        method: 'POST',
        body: queryArg.unlockConnectorConfirmationRequest,
        headers: { IdempotencyKey: queryArg.idempotencyKey },
      }),
    }),
    postApiChargePointEventsChangeAvailabilityRequest: build.mutation<
      PostApiChargePointEventsChangeAvailabilityRequestApiResponse,
      PostApiChargePointEventsChangeAvailabilityRequestApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/Events/CHANGE_AVAILABILITY/REQUEST`,
        method: 'POST',
        body: queryArg.changeAvailablilityRequest,
        headers: { IdempotencyKey: queryArg.idempotencyKey },
      }),
    }),
    postApiChargePointEventsChangeAvailabilityConfirmation: build.mutation<
      PostApiChargePointEventsChangeAvailabilityConfirmationApiResponse,
      PostApiChargePointEventsChangeAvailabilityConfirmationApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/Events/CHANGE_AVAILABILITY/CONFIRMATION`,
        method: 'POST',
        body: queryArg.changeAvailablilityConfirmationRequest,
        headers: { IdempotencyKey: queryArg.idempotencyKey },
      }),
    }),
    postApiChargePointEventsDataTransferRequest: build.mutation<
      PostApiChargePointEventsDataTransferRequestApiResponse,
      PostApiChargePointEventsDataTransferRequestApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/Events/DATA_TRANSFER/REQUEST`,
        method: 'POST',
        body: queryArg.dataTransferRequest,
        headers: { IdempotencyKey: queryArg.idempotencyKey },
      }),
    }),
    postApiChargePointEventsDataTransferConfirmation: build.mutation<
      PostApiChargePointEventsDataTransferConfirmationApiResponse,
      PostApiChargePointEventsDataTransferConfirmationApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/Events/DATA_TRANSFER/CONFIRMATION`,
        method: 'POST',
        body: queryArg.dataTransferConfirmationRequest,
        headers: { IdempotencyKey: queryArg.idempotencyKey },
      }),
    }),
    postApiChargePointEventsResetRequest: build.mutation<
      PostApiChargePointEventsResetRequestApiResponse,
      PostApiChargePointEventsResetRequestApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/Events/RESET/REQUEST`,
        method: 'POST',
        body: queryArg.resetRequest,
        headers: { IdempotencyKey: queryArg.idempotencyKey },
      }),
    }),
    postApiChargePointEventsResetConfirmation: build.mutation<
      PostApiChargePointEventsResetConfirmationApiResponse,
      PostApiChargePointEventsResetConfirmationApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/Events/RESET/CONFIRMATION`,
        method: 'POST',
        body: queryArg.resetConfirmationRequest,
        headers: { IdempotencyKey: queryArg.idempotencyKey },
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type GetApiChargePointEventsByIdApiResponse =
  /** status 200 Success */ GetEventVmBaseResponse;
export type GetApiChargePointEventsByIdApiArg = /** Идентификтаор */ string;
export type DeleteApiChargePointEventsByIdApiResponse =
  /** status 200 Success */ BaseResponse;
export type DeleteApiChargePointEventsByIdApiArg = /** Идентификатор */ string;
export type GetApiChargePointEventsApiResponse =
  /** status 200 Success */ GetEventVmIReadOnlyCollectionBaseResponse;
export type GetApiChargePointEventsApiArg = {
  name?: EventName;
  type?: EventType;
  chargePointId?: string;
  connectorId?: string;
  transactionId?: string;
  dateFrom?: string;
  dateTo?: string;
  orderingField?: string;
  orderingType?: OrderBy;
  offset?: number;
  limit?: number;
};
export type GetApiChargePointEventsCountApiResponse =
  /** status 200 Success */ Int64BaseResponse;
export type GetApiChargePointEventsCountApiArg = {
  name?: EventName;
  type?: EventType;
  chargePointId?: string;
  connectorId?: string;
  transactionId?: string;
  dateFrom?: string;
  dateTo?: string;
};
export type PostApiChargePointEventsDisconnectEventApiResponse =
  /** status 200 Success */ BaseResponse;
export type PostApiChargePointEventsDisconnectEventApiArg =
  DisconnectEventRequest;
export type PostApiChargePointEventsBootNotificationRequestApiResponse =
  /** status 200 Success */ BootNotificationEventVmBaseResponse;
export type PostApiChargePointEventsBootNotificationRequestApiArg = {
  idempotencyKey?: string;
  bootNotificationRequest: BootNotificationRequest;
};
export type PostApiChargePointEventsBootNotificationConfirmationApiResponse =
  /** status 200 Success */ BaseResponse;
export type PostApiChargePointEventsBootNotificationConfirmationApiArg = {
  idempotencyKey?: string;
  bootNotificationConfirmationRequest: BootNotificationConfirmationRequest;
};
export type PostApiChargePointEventsRemoteStartTransactionRequestApiResponse =
  /** status 200 Success */ BaseResponse;
export type PostApiChargePointEventsRemoteStartTransactionRequestApiArg = {
  idempotencyKey?: string;
  remoteStartTransactionRequest: RemoteStartTransactionRequest;
};
export type PostApiChargePointEventsRemoteStartTransactionConfirmationApiResponse =
  /** status 200 Success */ BaseResponse;
export type PostApiChargePointEventsRemoteStartTransactionConfirmationApiArg = {
  idempotencyKey?: string;
  remoteStartTransactionConfirmationRequest: RemoteStartTransactionConfirmationRequest;
};
export type PostApiChargePointEventsStartTransactionRequestApiResponse =
  /** status 200 Success */ BaseResponse;
export type PostApiChargePointEventsStartTransactionRequestApiArg = {
  idempotencyKey?: string;
  startTransactionRequest: StartTransactionRequest;
};
export type PostApiChargePointEventsStartTransactionConfirmationApiResponse =
  /** status 200 Success */ BaseResponse;
export type PostApiChargePointEventsStartTransactionConfirmationApiArg = {
  idempotencyKey?: string;
  startTransactionConfirmationRequest: StartTransactionConfirmationRequest;
};
export type PostApiChargePointEventsRemoteStopTransactionRequestApiResponse =
  /** status 200 Success */ BaseResponse;
export type PostApiChargePointEventsRemoteStopTransactionRequestApiArg = {
  idempotencyKey?: string;
  remoteStopTransactionRequest: RemoteStopTransactionRequest;
};
export type PostApiChargePointEventsRemoteStopTransactionConfirmationApiResponse =
  /** status 200 Success */ BaseResponse;
export type PostApiChargePointEventsRemoteStopTransactionConfirmationApiArg = {
  idempotencyKey?: string;
  remoteStopTransactionConfirmationRequest: RemoteStopTransactionConfirmationRequest;
};
export type PostApiChargePointEventsStopTransactionRequestApiResponse =
  /** status 200 Success */ BaseResponse;
export type PostApiChargePointEventsStopTransactionRequestApiArg = {
  idempotencyKey?: string;
  stopTransactionRequest: StopTransactionRequest;
};
export type PostApiChargePointEventsStopTransactionConfirmationApiResponse =
  /** status 200 Success */ BaseResponse;
export type PostApiChargePointEventsStopTransactionConfirmationApiArg = {
  idempotencyKey?: string;
  stopTransactionConfirmationRequest: StopTransactionConfirmationRequest;
};
export type PostApiChargePointEventsStatusNotificationRequestApiResponse =
  /** status 200 Success */ BaseResponse;
export type PostApiChargePointEventsStatusNotificationRequestApiArg = {
  idempotencyKey?: string;
  statusNotificationRequest: StatusNotificationRequest;
};
export type PostApiChargePointEventsStatusNotificationConfirmationApiResponse =
  /** status 200 Success */ BaseResponse;
export type PostApiChargePointEventsStatusNotificationConfirmationApiArg = {
  idempotencyKey?: string;
  statusNotificationConfirmationRequest: StatusNotificationConfirmationRequest;
};
export type PostApiChargePointEventsMeterValuesRequestApiResponse =
  /** status 200 Success */ BaseResponse;
export type PostApiChargePointEventsMeterValuesRequestApiArg = {
  idempotencyKey?: string;
  meterValuesRequest: MeterValuesRequest;
};
export type PostApiChargePointEventsMeterValuesConfirmationApiResponse =
  /** status 200 Success */ BaseResponse;
export type PostApiChargePointEventsMeterValuesConfirmationApiArg = {
  idempotencyKey?: string;
  meterValuesConfirmationRequest: MeterValuesConfirmationRequest;
};
export type PostApiChargePointEventsChangeConfigurationRequestApiResponse =
  /** status 200 Success */ BaseResponse;
export type PostApiChargePointEventsChangeConfigurationRequestApiArg = {
  idempotencyKey?: string;
  changeConfigurationRequest: ChangeConfigurationRequest;
};
export type PostApiChargePointEventsChangeConfigurationConfirmationApiResponse =
  /** status 200 Success */ BaseResponse;
export type PostApiChargePointEventsChangeConfigurationConfirmationApiArg = {
  idempotencyKey?: string;
  changeConfigurationConfirmationRequest: ChangeConfigurationConfirmationRequest;
};
export type PostApiChargePointEventsUnlockConnectorRequestApiResponse =
  /** status 200 Success */ BaseResponse;
export type PostApiChargePointEventsUnlockConnectorRequestApiArg = {
  idempotencyKey?: string;
  unlockConnectorRequest: UnlockConnectorRequest;
};
export type PostApiChargePointEventsUnlockConnectorConfirmationApiResponse =
  /** status 200 Success */ BaseResponse;
export type PostApiChargePointEventsUnlockConnectorConfirmationApiArg = {
  idempotencyKey?: string;
  unlockConnectorConfirmationRequest: UnlockConnectorConfirmationRequest;
};
export type PostApiChargePointEventsChangeAvailabilityRequestApiResponse =
  /** status 200 Success */ BaseResponse;
export type PostApiChargePointEventsChangeAvailabilityRequestApiArg = {
  idempotencyKey?: string;
  changeAvailablilityRequest: ChangeAvailablilityRequest;
};
export type PostApiChargePointEventsChangeAvailabilityConfirmationApiResponse =
  /** status 200 Success */ BaseResponse;
export type PostApiChargePointEventsChangeAvailabilityConfirmationApiArg = {
  idempotencyKey?: string;
  changeAvailablilityConfirmationRequest: ChangeAvailablilityConfirmationRequest;
};
export type PostApiChargePointEventsDataTransferRequestApiResponse =
  /** status 200 Success */ BaseResponse;
export type PostApiChargePointEventsDataTransferRequestApiArg = {
  idempotencyKey?: string;
  dataTransferRequest: DataTransferRequest;
};
export type PostApiChargePointEventsDataTransferConfirmationApiResponse =
  /** status 200 Success */ BaseResponse;
export type PostApiChargePointEventsDataTransferConfirmationApiArg = {
  idempotencyKey?: string;
  dataTransferConfirmationRequest: DataTransferConfirmationRequest;
};
export type PostApiChargePointEventsResetRequestApiResponse =
  /** status 200 Success */ BaseResponse;
export type PostApiChargePointEventsResetRequestApiArg = {
  idempotencyKey?: string;
  resetRequest: ResetRequest;
};
export type PostApiChargePointEventsResetConfirmationApiResponse =
  /** status 200 Success */ BaseResponse;
export type PostApiChargePointEventsResetConfirmationApiArg = {
  idempotencyKey?: string;
  resetConfirmationRequest: ResetConfirmationRequest;
};
export type Status = 'SUCCESS' | 'ERROR' | 'PART_UNAVAILABLE';
export type UnavailableServices =
  | 'CHARGE_POINT_SERVICE'
  | 'CP_WS_CONTROLLER'
  | 'CP_SOAP_CONTROLLER'
  | 'BILLING_SERVICE'
  | 'PAYMENT_SERVICE'
  | 'FISCAL_SERVICE'
  | 'SBER_PAY'
  | 'ARMENIA_PAY'
  | 'E_PAY'
  | 'FISCAL_RUSSIA'
  | 'FISCAL_ARMENIA'
  | 'BONUS_SERVICE'
  | 'DISCOUNT_SERVICE'
  | 'MOBILE_API_BFF'
  | 'USER_SERVICE'
  | 'RESERVE_SERVICE'
  | 'OCPI_SERVICE'
  | 'SMS_SERVICE'
  | 'PUSH_NOTIFICATION_SERVICE'
  | 'SYSTEM_INFROMATION_SERVICE'
  | 'IDENTITY_SERVICE'
  | 'ANALYTICS_SERVICE';
export type ChargePointProtocol = 'OCPP16' | 'OCPP16J' | 'OCPP20';
export type EventName =
  | 'BOOT_NOTIFICATION'
  | 'REMOTE_START_TRANSACTION'
  | 'START_TRANSACTION'
  | 'REMOTE_STOP_TRANSACTION'
  | 'STOP_TRANSACTION'
  | 'STATUS_NOTIFICATION'
  | 'METER_VALUES'
  | 'RESET'
  | 'CHANGE_CONFIGURATION'
  | 'UNLOCK_CONNECTOR'
  | 'CHANGE_AVAILABILITY'
  | 'DATA_TRANSFER';
export type EventType = 'REQUEST' | 'CONFIRMATION';
export type ConnectorStatus =
  | 'UNDEFINED'
  | 'AVAILABLE'
  | 'PREPARING'
  | 'CHARGING'
  | 'SUSPENDED_EVSE'
  | 'SUSPENDED_EV'
  | 'FINISHING'
  | 'RESERVED'
  | 'UNAVAILABLE'
  | 'FAULTED';
export type RemoteStartStopStatus = 'ACCEPTED' | 'REJECTED';
export type GetEventVm = {
  id: string;
  chargePointProtocol: ChargePointProtocol;
  name: EventName;
  type: EventType;
  chargePointId: string;
  createdDate: string;
  connectorId?: string | null;
  connectorStatus?: ConnectorStatus;
  remoteStartStopStatus?: RemoteStartStopStatus;
  transactionId?: string | null;
  idempotencyKey: string;
  sessionId?: string | null;
  instantPower?: number | null;
  instantCurrent?: number | null;
  chargingPercentage?: number | null;
  countTotalEnergy?: number | null;
  meterStop?: number | null;
  meterStart?: number | null;
  data?: string | null;
};
export type GetEventVmBaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: GetEventVm;
};
export type BaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
};
export type GetEventVmIReadOnlyCollectionBaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: GetEventVm[] | null;
};
export type OrderBy = 'Asc' | 'Desc';
export type Int64BaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: number;
};
export type DisconnectEventRequest = {
  reason: string;
  chargePointId: string;
};
export type BootNotificationEventVm = {
  date: string;
  isBlocked: boolean;
};
export type BootNotificationEventVmBaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: BootNotificationEventVm;
};
export type BootNotificationRequest = {
  chargePointProtocol: ChargePointProtocol;
  chargePointId: string;
  data: string;
};
export type BootNotificationConfirmationRequest = {
  chargePointProtocol: ChargePointProtocol;
  chargePointId: string;
  data: string;
};
export type RemoteStartTransactionRequest = {
  chargePointProtocol: ChargePointProtocol;
  chargePointId: string;
  data: string;
  transactionId: string;
};
export type RemoteStartTransactionConfirmationRequest = {
  chargePointProtocol: ChargePointProtocol;
  chargePointId: string;
  data: string;
  remoteStartStopStatus: RemoteStartStopStatus;
};
export type StartTransactionRequest = {
  chargePointProtocol: ChargePointProtocol;
  chargePointId: string;
  innerConnectorId: number;
  meterStart: number;
  data: string;
};
export type StartTransactionConfirmationRequest = {
  chargePointProtocol: ChargePointProtocol;
  chargePointId: string;
  data: string;
  transactionId: string;
};
export type RemoteStopTransactionRequest = {
  chargePointProtocol: ChargePointProtocol;
  chargePointId: string;
  data: string;
  transactionId: string;
};
export type RemoteStopTransactionConfirmationRequest = {
  chargePointProtocol: ChargePointProtocol;
  chargePointId: string;
  data: string;
  remoteStartStopStatus: RemoteStartStopStatus;
};
export type StopTransactionRequest = {
  chargePointProtocol: ChargePointProtocol;
  chargePointId: string;
  data: string;
  meterStop: number;
  reason: string;
  transactionId: string;
  instantPower?: number | null;
  chargingPercentage?: number | null;
  countTotalEnergy?: number | null;
};
export type StopTransactionConfirmationRequest = {
  chargePointProtocol: ChargePointProtocol;
  chargePointId: string;
  data: string;
};
export type StatusNotificationRequest = {
  chargePointProtocol: ChargePointProtocol;
  chargePointId: string;
  status: ConnectorStatus;
  data: string;
  innerConnectorId: number;
};
export type StatusNotificationConfirmationRequest = {
  chargePointProtocol: ChargePointProtocol;
  chargePointId: string;
  data: string;
};
export type MeterValuesRequest = {
  chargePointProtocol: ChargePointProtocol;
  chargePointId: string;
  data: string;
  innerConnectorId: number;
  transactionId: string;
  instantPower: number;
  instantCurrent: number;
  chargingPercentage: number;
  countTotalEnergy: number;
};
export type MeterValuesConfirmationRequest = {
  chargePointProtocol: ChargePointProtocol;
  chargePointId: string;
  data: string;
};
export type ChangeConfigurationRequest = {
  chargePointProtocol: ChargePointProtocol;
  chargePointId: string;
  data: string;
};
export type ChangeConfigurationConfirmationRequest = {
  chargePointProtocol: ChargePointProtocol;
  chargePointId: string;
  data: string;
};
export type UnlockConnectorRequest = {
  chargePointProtocol: ChargePointProtocol;
  chargePointId: string;
  data: string;
};
export type UnlockConnectorConfirmationRequest = {
  chargePointProtocol: ChargePointProtocol;
  chargePointId: string;
  data: string;
};
export type ChangeAvailablilityRequest = {
  chargePointProtocol: ChargePointProtocol;
  chargePointId: string;
  data: string;
};
export type ChangeAvailablilityConfirmationRequest = {
  chargePointProtocol: ChargePointProtocol;
  chargePointId: string;
  data: string;
};
export type DataTransferRequest = {
  chargePointProtocol: ChargePointProtocol;
  chargePointId: string;
  data: string;
};
export type DataTransferConfirmationRequest = {
  chargePointProtocol: ChargePointProtocol;
  chargePointId: string;
  data: string;
};
export type ResetRequest = {
  chargePointProtocol: ChargePointProtocol;
  chargePointId: string;
  data: string;
};
export type ResetConfirmationRequest = {
  chargePointProtocol: ChargePointProtocol;
  chargePointId: string;
  data: string;
};
export const {
  useGetApiChargePointEventsByIdQuery,
  useDeleteApiChargePointEventsByIdMutation,
  useGetApiChargePointEventsQuery,
  useGetApiChargePointEventsCountQuery,
  usePostApiChargePointEventsDisconnectEventMutation,
  usePostApiChargePointEventsBootNotificationRequestMutation,
  usePostApiChargePointEventsBootNotificationConfirmationMutation,
  usePostApiChargePointEventsRemoteStartTransactionRequestMutation,
  usePostApiChargePointEventsRemoteStartTransactionConfirmationMutation,
  usePostApiChargePointEventsStartTransactionRequestMutation,
  usePostApiChargePointEventsStartTransactionConfirmationMutation,
  usePostApiChargePointEventsRemoteStopTransactionRequestMutation,
  usePostApiChargePointEventsRemoteStopTransactionConfirmationMutation,
  usePostApiChargePointEventsStopTransactionRequestMutation,
  usePostApiChargePointEventsStopTransactionConfirmationMutation,
  usePostApiChargePointEventsStatusNotificationRequestMutation,
  usePostApiChargePointEventsStatusNotificationConfirmationMutation,
  usePostApiChargePointEventsMeterValuesRequestMutation,
  usePostApiChargePointEventsMeterValuesConfirmationMutation,
  usePostApiChargePointEventsChangeConfigurationRequestMutation,
  usePostApiChargePointEventsChangeConfigurationConfirmationMutation,
  usePostApiChargePointEventsUnlockConnectorRequestMutation,
  usePostApiChargePointEventsUnlockConnectorConfirmationMutation,
  usePostApiChargePointEventsChangeAvailabilityRequestMutation,
  usePostApiChargePointEventsChangeAvailabilityConfirmationMutation,
  usePostApiChargePointEventsDataTransferRequestMutation,
  usePostApiChargePointEventsDataTransferConfirmationMutation,
  usePostApiChargePointEventsResetRequestMutation,
  usePostApiChargePointEventsResetConfirmationMutation,
} = injectedRtkApi;
