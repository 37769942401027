import { Col, Divider, Space, Spin } from 'antd';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  useForm,
  FormProvider,
  useFormContext,
  useFieldArray,
} from 'react-hook-form';

import {
  ButtonsContainer,
  UiSubmitButton,
  UiCancelFormButton,
  FormControlsContainer,
} from 'shared/ui';
import { CustomInput } from 'shared/ui/form/custom-input';
import {
  isErrorWithMessage,
  openErrorNotification,
  openSuccessNotification,
} from 'shared/lib';

import { BurgerIcon } from 'shared/icons/burger';
import { DeleteIcon } from 'shared/icons/delete';
import {
  ServicesListDto,
  TariffDto,
} from 'shared/api/services/billing/rtk/generated';
import { CustomSelect } from 'shared/ui/form/custom-select';

import {
  DEFAULT_VALUES,
  FormSchema,
  FormInput,
  FormOutput,
} from '../consts/schema';
import { FORM_TITLE, ADD_ERROR, ADD_SUCCESS } from '../consts';

import { CardFormWrapper, FormRow } from '../../../../common-styles';
import {
  ControlButton,
  FormFieldsContainer,
  FormFieldsGroup,
  ServiceTitle,
} from './styles';

import { STAGE_END_TYPE } from 'entities/billing/consts';

import { AddStageButton } from 'features/billing/add/add-services/ui/add-stage-button';
import { AddTariffButton } from 'features/tariff/add/ui/add-tariff-button';
import { getTariffOptionsList } from 'entities/billing/lib/get-tariff-options-list';

type Props = {
  tariffs: TariffDto[];
  serviceList: ServicesListDto;
};

export function Form({ tariffs, serviceList }: Props) {
  const form = useForm<FormInput, void, FormOutput>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      ...DEFAULT_VALUES,
    },
  });

  const [trigger, { isLoading }] = useAddCompany();

  const handleSubmit = form.handleSubmit(async (data) => {
    // try {
    //   const res = await trigger(data).unwrap();
    //   openSuccessNotification(ADD_SUCCESS);
    // } catch (err) {
    //   const hasErrorMessage = isErrorWithMessage(err);
    //   const errorText = hasErrorMessage ? err.data.statusMessage : ADD_ERROR;
    //   openErrorNotification(errorText);
    // }
  });

  return (
    <CardFormWrapper title={serviceList.name}>
      <FormProvider {...form}>
        <Spin spinning={isLoading}>
          <form onSubmit={handleSubmit}>
            <Form.Fields tariffs={tariffs} />
            <Form.Buttons />
          </form>
        </Spin>
      </FormProvider>
    </CardFormWrapper>
  );
}

type FieldsProps = {
  tariffs: TariffDto[];
};

Form.Fields = function Fields({ tariffs }: FieldsProps) {
  const {
    control,
    formState: { errors },
  } = useFormContext<FormInput, void, FormOutput>();

  const {
    fields: reservingStagesFields,
    append: reservingStagesAppend,
    remove: reservingStagesRemove,
  } = useFieldArray({
    name: 'reservingStages',
    control,
  });

  const {
    fields: chargingStagesFields,
    append: chargingStagesAppend,
    remove: chargingStagesRemove,
  } = useFieldArray({
    name: 'chargingStages',
    control,
  });

  return (
    <FormFieldsContainer>
      <FormFieldsGroup>
        <FormRow gutter={20}>
          <Col span={6}>
            <CustomInput<FormInput>
              name="name"
              label="Название списка услуг"
              errorMessage={errors.name?.message}
              required
            />
          </Col>
          {/* <Col span={6}>
          <CustomInput<FormInput>
            name="name"
            label="Описание"
            errorMessage={errors.name?.message}
            required
            disabled
          />
        </Col> */}
        </FormRow>
      </FormFieldsGroup>

      <FormFieldsGroup>
        <ServiceTitle>Услуга Бронирование</ServiceTitle>

        {reservingStagesFields.map((field, index) => {
          return (
            <FormRow gutter={12}>
              <Col flex="auto">
                <FormRow gutter={20}>
                  <Col span={6}>
                    <CustomInput<FormInput>
                      name={`reservingStages.${index}.name`}
                      label="Название стадии услуги"
                      errorMessage={
                        errors.reservingStages?.[index]?.name?.message
                      }
                      required
                    />
                  </Col>

                  <Col span={6}>
                    <CustomSelect<FormInput>
                      name={`reservingStages.${index}.tariffId`}
                      label="Стоимость"
                      options={getTariffOptionsList(tariffs)}
                      errorMessage={
                        errors.reservingStages?.[index]?.tariffId?.message
                      }
                      dropdownRender={(menu) => {
                        return (
                          <>
                            {menu}
                            <Divider style={{ margin: '8px 0' }} />
                            <AddTariffButton />
                          </>
                        );
                      }}
                      required
                    />
                  </Col>

                  <Col span={6}>
                    <CustomSelect<FormInput>
                      name={`reservingStages.${index}.endType`}
                      label="Триггер окончания"
                      errorMessage={
                        errors.reservingStages?.[index]?.endType?.message
                      }
                      options={Object.entries(STAGE_END_TYPE).map((entry) => {
                        const [key, label] = entry;

                        return { value: key, label };
                      })}
                      required
                    />
                  </Col>

                  <Col span={6}>
                    <CustomInput<FormInput>
                      name={`reservingStages.${index}.endConditionValue`}
                      label="Значение % батареи / мин"
                      errorMessage={
                        errors.reservingStages?.[index]?.endConditionValue
                          ?.message
                      }
                      required
                    />
                  </Col>

                  {/* <IconsContainer></IconsContainer> */}
                </FormRow>
              </Col>
              <Col flex="60px">
                <Space size={20}>
                  <ControlButton onClick={() => reservingStagesRemove(index)}>
                    <DeleteIcon />
                  </ControlButton>
                </Space>
              </Col>
            </FormRow>
          );
        })}
        <AddStageButton
          onClick={() =>
            reservingStagesAppend({
              name: '',
              serialNumber: reservingStagesFields.length,
              tariffId: '',
              serviceId: '',
            })
          }
        />
      </FormFieldsGroup>

      <FormFieldsGroup>
        <ServiceTitle>Услуга Заряд</ServiceTitle>

        {chargingStagesFields.map((field, index) => {
          return (
            <FormRow gutter={12}>
              <Col flex="auto">
                <FormRow gutter={20}>
                  <Col span={6}>
                    <CustomInput<FormInput>
                      name={`chargingStages.${index}.name`}
                      label="Название стадии услуги"
                      errorMessage={
                        errors.chargingStages?.[index]?.name?.message
                      }
                      required
                    />
                  </Col>

                  <Col span={6}>
                    <CustomSelect<FormInput>
                      name={`chargingStages.${index}.tariffId`}
                      label="Стоимость"
                      options={getTariffOptionsList(tariffs)}
                      errorMessage={
                        errors.chargingStages?.[index]?.tariffId?.message
                      }
                      dropdownRender={(menu) => {
                        return (
                          <>
                            {menu}
                            <Divider style={{ margin: '8px 0' }} />
                            <AddTariffButton />
                          </>
                        );
                      }}
                      required
                    />
                  </Col>

                  <Col span={6}>
                    <CustomSelect<FormInput>
                      name={`chargingStages.${index}.endType`}
                      label="Триггер окончания"
                      errorMessage={
                        errors.chargingStages?.[index]?.endType?.message
                      }
                      options={Object.entries(STAGE_END_TYPE).map((entry) => {
                        const [key, label] = entry;

                        return { value: key, label };
                      })}
                      required
                    />
                  </Col>

                  <Col span={6}>
                    <CustomInput<FormInput>
                      name={`chargingStages.${index}.endConditionValue`}
                      label="Значение % батареи / мин"
                      errorMessage={
                        errors.chargingStages?.[index]?.endConditionValue
                          ?.message
                      }
                      required
                    />
                  </Col>

                  {/* <IconsContainer></IconsContainer> */}
                </FormRow>
              </Col>
              <Col flex="60px">
                <Space size={20}>
                  <ControlButton onClick={() => chargingStagesRemove(index)}>
                    <DeleteIcon />
                  </ControlButton>
                </Space>
              </Col>
            </FormRow>
          );
        })}
        <AddStageButton
          onClick={() =>
            chargingStagesAppend({
              name: '',
              serialNumber: chargingStagesFields.length,
              tariffId: '',
              serviceId: '',
            })
          }
        />
      </FormFieldsGroup>
    </FormFieldsContainer>
  );
};

Form.Buttons = function Buttons() {
  return (
    <FormControlsContainer>
      <ButtonsContainer>
        <UiSubmitButton />
        <UiCancelFormButton />
      </ButtonsContainer>
    </FormControlsContainer>
  );
};
